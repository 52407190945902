/* Type imports ------------------------------------------------------------- */
import type { CodeLabel } from 'API/__generated__/Api'

/* findCodeLabel helper function ----------------------- */
export const findCodeLabel = (codeLabelArray: CodeLabel[], code?: string): CodeLabel =>
  codeLabelArray.find((codeLabel) => codeLabel.code === code) ?? { code: '', libelle: '' }

export const findCodeLabelUndefined = (codeLabelArray: CodeLabel[], code?: string): CodeLabel | undefined =>
  codeLabelArray.find((codeLabel) => codeLabel.code === code) ?? undefined

export const findCodeLabelUndefinedOrStrict = (codeLabelArray: CodeLabel[], findCode?: string): CodeLabel | undefined => {
  const found = codeLabelArray.find((codeLabel) => codeLabel.code === findCode)
  if (found) {
    const { code, libelle } = found
    return { code, libelle }
  }
  return undefined
}

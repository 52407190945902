/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useIsConnected } from 'helpers/hooks/useIsConnected'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Select,
  MenuItem,
  OutlinedInput,
} from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'
import ReportClosureModal from './ReportClosureModal/ReportClosureModal'

/* Type imports ------------------------------------------------------------- */
import type { SelectChangeEvent } from '@mui/material'
import { ReportStatus } from 'types/ReportStatus'

/* Type declaration ----------------------------------- */
type StatusColor = {color: string; backgroundColor: string}
type Color = {[x: string]: StatusColor}

/* Internal Variables ----------------------------------- */
const colors: Color = {
  [ReportStatus.ARediger]: {
    color: '#BD2323',
    backgroundColor: '#FCECEC',
  },
  [ReportStatus.EnAttente]: {
    color: '#0A145F',
    backgroundColor: '#E0E3FB',
  },
  [ReportStatus.RemisALaFrappe]: {
    color: '#025C97',
    backgroundColor: '#E5F4FE',
  },
  [ReportStatus.Depose]: {
    color: '#125A26',
    backgroundColor: '#D5F5DE',
  },
}

/* Styled components ---------------------------------- */
interface ReportStatusColorProps {
  customcolor: string;
  custombackgroundcolor?: string;
}

const SelectColoredArrow = styled(KeyboardArrowDown)<ReportStatusColorProps>`
  color: ${(props) => props.customcolor};
  font-size: 28px;
  margin-left: -30px;
  position: absolute !important;
  right: 0 !important;
  pointer-events: none !important;
`

const ColoredOutlinedInput = styled(OutlinedInput)<ReportStatusColorProps>`
  color: ${(props) => props.customcolor};
  background-color: ${(props) => props.custombackgroundcolor};
  font-weight: bold;
  border: 2px solid ${(props) => props.customcolor};

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  &:hover {
    background-color: ${(props) => `${props.custombackgroundcolor}55`};
  }
`

const ColoredButton = styled(Button)<ReportStatusColorProps>`
  color: ${(props) => props.customcolor};
  background-color: ${(props) => props.custombackgroundcolor};
  font-size: 16px;
  border: 2px solid ${(props) => props.customcolor};
  padding: 5px 15px;
  height: 100%;
`

const ColoredMenuItem = styled(MenuItem)<ReportStatusColorProps>`
  color: ${(props) => props.customcolor};
`

const ReportStatusContainer = styled.div`
  margin-left: 20px;
  .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-inputSizeSmall {
    font-size: 14px;
  }
  button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root {
    font-size: 14px;
    height: 44px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface ReportStatusButtonProps {
  status: ReportStatus;
  caseId: string;
  reportId: string;
}

const ReportStatusButton: React.FC<ReportStatusButtonProps> = ({ status, caseId }) => {
  const isConnected = useIsConnected()
  const [ newStatus, setNewStatus ] = useState<ReportStatus>(status)
  const [ isClosureModalOpen, setIsClosureModalOpen ] = useState<boolean>(false)

  useEffect(() => {
    setNewStatus(status)
  }, [ status ])

  const handleChange = (event: SelectChangeEvent): void => {
    if (event.target.value as ReportStatus !== ReportStatus.Depose) {
      setNewStatus(event.target.value as ReportStatus)
    }
  }

  const handleClose = (isReportClosed?: boolean): void => {
    if (isReportClosed) {
      setNewStatus(ReportStatus.Depose)
    }
    setIsClosureModalOpen(false)
  }

  const onReportSubmit = (): void => {
    setIsClosureModalOpen(true)
  }

  return (
    <ReportStatusContainer onClick={(e): void => e.stopPropagation()}>
      {
        newStatus === ReportStatus.Depose ?
          <ColoredButton
            customcolor={colors[newStatus].color}
            custombackgroundcolor={colors[newStatus].backgroundColor}
          >
            Déposé
          </ColoredButton> :
          <Select
            value={newStatus}
            onChange={handleChange}
            input={
              <ColoredOutlinedInput
                customcolor={colors[newStatus].color}
                custombackgroundcolor={colors[newStatus].backgroundColor}
                size="small"
              />
            }
            IconComponent={(): React.ReactElement => <SelectColoredArrow customcolor={colors[newStatus].color} />}
          >
            <ColoredMenuItem
              key={ReportStatus.ARediger}
              value={ReportStatus.ARediger}
              customcolor={colors[ReportStatus.ARediger].color}
            >
              À rédiger
            </ColoredMenuItem>
            <ColoredMenuItem
              key={ReportStatus.EnAttente}
              value={ReportStatus.EnAttente}
              customcolor={colors[ReportStatus.EnAttente].color}
            >
              En attente
            </ColoredMenuItem>
            <ColoredMenuItem
              key={ReportStatus.RemisALaFrappe}
              value={ReportStatus.RemisALaFrappe}
              customcolor={colors[ReportStatus.RemisALaFrappe].color}
            >
              Remis à la frappe
            </ColoredMenuItem>
            <ColoredMenuItem
              key={ReportStatus.Depose}
              value={ReportStatus.Depose}
              customcolor={colors[ReportStatus.Depose].color}
              onClick={onReportSubmit}
              disabled={!isConnected}
            >
              Déposé
            </ColoredMenuItem>
          </Select>
      }
      <ReportClosureModal
        open={isClosureModalOpen}
        handleClose={handleClose}
        type="PRG"
        sinapps={false}
        caseId={caseId}
      />
    </ReportStatusContainer>
  )
}

export default ReportStatusButton

/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */

/* Component imports -------------------------------------------------------- */
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'

/* Styling --------------------------------------------- */
const CustomButton = styled(SubmitFormButton)`
  height: 40px;
  margin-left: 1rem;
`

/* Component declaration ---------------------------------------------------- */
interface HeaderCustomButtonProps {
  text: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

const HeaderCustomButton: React.FC<HeaderCustomButtonProps> = ({ text, onClick, disabled }) => {
  return (
    <CustomButton
      variant="contained"
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </CustomButton>
  )
}

export default HeaderCustomButton

/* Type imports ------------------------------------------------------------- */
import type { LocalisationGPS } from 'API/__generated__/Api'
import { LatLng } from 'leaflet'

export const getCoordinates = (coordinates?: LocalisationGPS): LatLng => {
  let lat: number = 0
  let lon: number = 0

  if (coordinates) {
    if (coordinates.latitude !== null && coordinates.latitude !== undefined && !isNaN(coordinates.latitude)) {
      lat = coordinates.latitude
    }
    if (coordinates.longitude !== null && coordinates.longitude !== undefined && !isNaN(coordinates.longitude)) {
      lon = coordinates.longitude
    }
  }

  return (new LatLng(lat, lon))
}

export const hasCoordinates = (coordinates?: LocalisationGPS): boolean => {
  if (!coordinates ||
    coordinates.latitude === null || coordinates.latitude === undefined || isNaN(coordinates.latitude) ||
    coordinates.longitude === null || coordinates.longitude === undefined || isNaN(coordinates.longitude)
  ) {
    return false
  }
  return true
}

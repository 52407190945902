/* Module imports -------------------------------------- */
import { instantiateStreaming } from 'assemblyscript/lib/loader'

/* Type imports ------------------------------------------------------------- */

/* webAssembly helper function -------------------------- */
export interface GexsiWebAssembly {
  getPrixUnitaireHT: (prixUnitaireHT: number) => number;
  getPrixUnitaireHTByPrixUnitaireTTC: (prixUnitaireTTC: number, tauxTVA: number) => number;
  getMontantHT: (quantite: number, prixUnitaireHT: number) => number;
  getMontantHTByPrixUnitaireTTC: (quantite: number, prixUnitaireTTC: number, tauxTVA: number) => number;
  getMontantTTC: (quantite: number, prixUnitaireHT: number, tauxTVA: number) => number;
  getMontantTTCByPrixUnitaireTTC: (quantite: number, prixUnitaireTTC: number, tauxTVA: number) => number;
  getMontantTVA: (quantite: number, prixUnitaireHT: number, tauxTVA: number) => number;
  getMontantTVAByPrixUnitaireTTC: (quantite: number, prixUnitaireTTC: number, tauxTVA: number) => number;
  getMontantVetuste: (montantTTC: number, tauxVetuste: number) => number;
  getMontantVetusteDeduite: (montantTTC: number, tauxVetuste: number) => number;
  getMontantVetusteRecuperable: (montantTTC: number, tauxVetuste: number, tauxLimiteVetuste: number) => number;
}

async function instantiateWasm(): Promise<GexsiWebAssembly> {
  const instance = await instantiateStreaming(fetch(`/gexsiCalcul.wasm`))

  return instance.exports as unknown as GexsiWebAssembly
}

export const gexsiWebAssembly = instantiateWasm()

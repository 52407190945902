/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom'
import { enumToSegmentedButtonOptions } from 'helpers/enumToSegmentedButtonOptions'
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'
import { findCodeLabelUndefined } from 'helpers/findCodeLabel'
import { isValidString } from 'helpers/isValidString'
import DateUtils from 'helpers/DateUtils'
import {
  useGetCaseInfosQuery,
  useGetJustificationListQuery,
  useGetNatureBienListQuery,
  useGetNatureDetailBienListQuery,
  useGetReparabiliteListQuery,
  useGetRoomTypeListQuery,
  useGetTVARateListQuery,
  useGetUniteMesureListQuery,
} from 'store/api'
import { gexsiWebAssembly } from 'helpers/webAssembly'
import { calculateAllCompensations } from 'services/CompensationService'
import { createStakeholerUuid } from 'helpers/uuidUtils'
import { handleNumberVerification } from 'helpers/numberUtils'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  MenuItem,
} from '@mui/material'
import {
  EditOutlined,
  AddRounded,
} from '@mui/icons-material'
import { Field } from 'formik'
import {
  Select,
  TextField,
} from 'formik-mui'
import PageContainer from 'layouts/PageContainer/PageContainer'
import HeaderWithBackArrowTwoSmallButtons from 'layouts/MainLayout/Headers/HeaderWithBackArrowTwoSmallButtons'
import HeaderTravelerAction from 'layouts/MainLayout/Headers/HeadersComponents/HeaderTravelerAction'
import Loader from 'components/Loader/Loader'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'
import CheckableButton from 'components/CheckableButton/CheckableButton'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'
import ErrorMessage from 'components/ErrorMessage/ErrorMessage'
import TravelerLargeTitle from 'components/TravelerLargeTitle/TravelerLargeTitle'
import FormikDatePicker from 'components/DateTimePickers/FormikDatePicker'
import PriceField from 'components/FieldWithInputAdornment/PriceField'
import RateField from 'components/FieldWithInputAdornment/RateField'
import NumberField from 'components/FieldWithInputAdornment/NumberField'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'
import AddRoomModal from './AddRoomModal/AddRoomModal'

/* Type imports ------------------------------------------------------------- */
import type {
  ActeurTraveller,
  Dommage,
  DommageVetuste,
  JustificationsEnumLabel,
  PersonnePiece,
  ReparabiliteMobEnumLabel,
  TauxTVA,
} from 'API/__generated__/Api'
import {
  Taxe,
  TypeDeBien,
  TypeDeReparation,
} from 'API/__generated__/Api'
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'
import type {
  NewDommageRequest,
  TravelerForm,
} from 'types/TravelerForm'
import type { GexsiWebAssembly } from 'helpers/webAssembly'

/* Type declarations -------------------------------------------------------- */
type ValueFields = 'quantite' | 'prixUnitaire' | 'taxe' | 'montantHT' | 'tauxTva.taux' | 'montantTTC'
type VetusteFields = 'tauxVetuste' | 'tauxLimiteVetuste'

/* Styled components -------------------------------------------------------- */
const GridContainer = styled.div`
  display: grid;
  gap: 10px;
  align-items: flex-start;
  justify-content: stretch;

  margin-bottom: 10px;
`

interface ErrorField {
  error?: boolean;
}

const TwoGridContainer = styled(GridContainer)<ErrorField>`
  grid-template-columns: repeat(2, 1fr);
  @media screen and (max-width: 450px) {
    grid-template-columns: 1fr;
  }
  border: ${(props) => props.error ? '1px solid #d32f2f' : undefined};
  border-radius: 4px;
`

const FourGridContainer = styled(GridContainer)`
  grid-template-columns: repeat(4, 1fr);
`

const FourGridNoMarginContainer = styled(FourGridContainer)`
  margin-bottom: 0px;
`

const CardContentContainer = styled(CardContent)`
  padding-top: 5px;
  padding-bottom: 5px !important;
`

const FormSmallTitle = styled(FormBoldTitle)`
  font-size: 14px !important;
  margin-top: 5px;
`

const Separation = styled.div`
  height: 5px;
`

const SmallFormikDatePicker = styled(FormikDatePicker)`
  input {
    height: 0.4375em;
  }
`

const CheckableFormControlLabel = styled(FormControlLabel)`
  width: 100%;
  min-height: 50px;
  height: 100%;
  margin-left: unset;
  border: 2px solid ${(props) => props.theme.palette.primary.main};
  border-radius: 4px;
  color: ${(props) => props.theme.palette.primary.main};
  background-color: white;
`

const FormContainer = styled.div`
  padding-bottom: 40px;
`

const TvaErrorMessage = styled(ErrorMessage)`
  margin-top: 0px;
`

const EditRoomNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

/* Component declaration ---------------------------------------------------- */
interface AddDamagePageProps {}

const AddDamagePage: React.FC<AddDamagePageProps> = () => {
  const formikForm = useOutletContext<TravelerForm>()
  const navigate = useNavigate()
  const { caseId = '', personId, damageType, damageId, roomIndex } = useParams<{
    caseId: string;
    personId: string;
    damageType: TypeDeBien;
    damageId?: string;
    roomIndex?: string;
  }>()
  const [ roomModal, setRoomModal ] = useState<{open: boolean; compteur?: number}>({ open: false })
  const [ calculate, setCalculate ] = useState<GexsiWebAssembly | null>(null)

  const {
    currentData: caseInfos,
    isFetching: isFetchingCaseInfos,
  } = useGetCaseInfosQuery(caseId)
  const {
    currentData: natureDetailBien = [],
    isFetching: isFetchingNatureDetailBien,
  } = useGetNatureDetailBienListQuery(caseId)
  const {
    currentData: natureBienList = [],
    isFetching: isFetchingNatureBienList,
  } = useGetNatureBienListQuery()
  const {
    currentData: reparabiliteList = [],
    isFetching: isFetchingReparabiliteList,
  } = useGetReparabiliteListQuery()
  const {
    currentData: justificationList = [],
    isFetching: isFetchingJustificationList,
  } = useGetJustificationListQuery()
  const {
    currentData: roomTypeList = [],
    isFetching: isFetchinRoomTypeList,
  } = useGetRoomTypeListQuery()
  const {
    currentData: uniteMesureList = [],
    isFetching: isFetchingUniteMesureList,
  } = useGetUniteMesureListQuery()
  const {
    currentData: tvaRateList = [],
    isFetching: isFetchingTvaRateList,
  } = useGetTVARateListQuery()

  const natureDetailBienList = useMemo(() => [ ...natureDetailBien ], [ natureDetailBien ])

  const filterNatureDetailBien = () => {
    switch (formikForm.values.newDamage.typeBien) {
      case TypeDeBien.Immobilier:
        return natureDetailBienList.find((nature) => nature.code === '01')?.detailsDuBien ?? []
      case TypeDeBien.Embellissement:
        return (natureDetailBienList.find((nature) => nature.code === '02') ?? natureDetailBienList.find((nature) => nature.code === '01'))?.detailsDuBien ?? []
      case TypeDeBien.Mobilier:
        return natureDetailBienList.find((nature) => nature.code === '03')?.detailsDuBien ?? []
      case TypeDeBien.Autre:
        return natureDetailBienList.find((nature) => nature.code === '04')?.detailsDuBien ?? []
      default:
        return []
    }
  }

  const getNatureBien = (typeBien: TypeDeBien) => {
    switch (typeBien) {
      case TypeDeBien.Immobilier || TypeDeBien.Embellissement:
        return natureDetailBienList[0]
      case TypeDeBien.Mobilier:
        return natureDetailBienList[1]
      case TypeDeBien.Autre:
        return natureDetailBienList[2]
      default:
        return { code: '', libelle: '' }
    }
  }

  const onGoBack = () => {
    formikForm.setFieldValue('newDamage.vetuste.tauxLimiteVetuste', formikForm.initialValues.newDamage.vetuste.tauxLimiteVetuste)
    navigate(`/dossiers/${caseId}/traveller/dommages`)
  }

  useEffect(() => {
    const previousTauxLimiteVetuste = formikForm.values?.newDamage?.vetuste?.tauxLimiteVetuste

    formikForm.resetForm({
      values: {
        ...formikForm.values,
        newDamage: {
          ...formikForm.initialValues.newDamage,
          personneConcernee: personId || '',
          typeBien: damageType ?? TypeDeBien.Immobilier,
        },
      },
    })

    formikForm.setFieldValue('newDamage.vetuste.tauxLimiteVetuste', previousTauxLimiteVetuste)

    if (damageId && formikForm.values.acteurs && personId) {
      let damage: Dommage | undefined = undefined
      if (roomIndex !== undefined) {
        damage = formikForm.values.acteurs
          .find((acteur) => acteur.id === personId)?.pieces
          ?.find((room) => room.compteur === parseInt(roomIndex))?.dommagesImmobilierEmbellissement
          .find((dmg) => dmg.id === damageId)
      } else {
        damage = formikForm.values.acteurs
          .find((acteur) => acteur.id === personId)?.dommagesMobilierDivers
          ?.find((dmg) => dmg.id === damageId)
      }

      formikForm.setFieldValue('newDamage', {
        ...damage,
        personneConcernee: personId || '',
        typeBien: damageType ?? TypeDeBien.Immobilier,
        pieceCompteur: roomIndex ? parseInt(roomIndex) : -1,
      })
    }
  }, [ damageId, personId, damageType, roomIndex ])

  useEffect(() => {
    if (isFetchingTvaRateList) {
      return
    }
    if (!formikForm.values?.newDamage) {
      return
    }

    if ((formikForm.values.newDamage.valeur.tauxTva?.taux === 0 && formikForm.values.newDamage.valeur.tauxTva?.tva.code === '') ||
    ((formikForm.values.newDamage.typeBien === TypeDeBien.Immobilier || formikForm.values.newDamage.typeBien === TypeDeBien.Embellissement) && formikForm.values.newDamage.valeur.tauxTva?.taux === 20) ||
    ((formikForm.values.newDamage.typeBien === TypeDeBien.Mobilier || formikForm.values.newDamage.typeBien === TypeDeBien.Autre) && formikForm.values.newDamage.valeur.tauxTva?.taux === 10)
    ) {
      let newRate: TauxTVA | undefined = undefined
      if ((formikForm.values.newDamage.typeBien ?? damageType) === TypeDeBien.Immobilier || (formikForm.values.newDamage.typeBien ?? damageType) === TypeDeBien.Embellissement) {
        newRate = tvaRateList.find((rate) => rate.taux === 10)
      } else {
        newRate = tvaRateList.find((rate) => rate.taux === 20)
      }
      if (newRate === undefined) {
        return
      }
      formikForm.setFieldValue('newDamage.valeur.tauxTva', newRate)
    }
  }, [ isFetchingTvaRateList, formikForm.values?.newDamage.typeBien, formikForm.values?.newDamage?.valeur?.tauxTva?.taux ])

  useEffect(() => {
    if (!filterNatureDetailBien().find((detail) => detail.code === formikForm.values.newDamage.detailDuBien?.code) && formikForm.values.newDamage.detailDuBien !== undefined) {
      formikForm.setFieldValue('newDamage.detailDuBien', undefined)
    }
  }, [ formikForm.values.newDamage.typeBien ])

  useEffect(() => {
    gexsiWebAssembly.then((webAssembly) => setCalculate(webAssembly)).catch(console.error)
  }, [])

  const acteurIndex = useMemo(() => formikForm.values.acteurs?.findIndex((acteur) => acteur.id === formikForm.values.newDamage.personneConcernee),
    [
      formikForm.values.newDamage.personneConcernee,
      formikForm.values.acteurs,
    ])

  useEffect(() => {
    if (!formikForm.values?.newDamage?.pieceCompteur ||
        formikForm.values?.newDamage?.pieceCompteur === -1 ||
        acteurIndex === -1) {
      return
    }

    if (!formikForm.values.acteurs[acteurIndex].pieces?.find((room) => room.compteur === formikForm.values?.newDamage?.pieceCompteur)) {
      formikForm.setFieldValue('newDamage.pieceCompteur', -1)
    }
  }, [ formikForm.values?.newDamage?.personneConcernee ])

  useEffect(() => {
    if (calculate === null) {
      return
    }
    const montantTTC = formikForm.values.newDamage.valeur.montantTTC || 0
    const vetuste = formikForm.values.newDamage.vetuste

    const newVetuste: DommageVetuste = {
      ...vetuste,
      montantVetuste: calculate.getMontantVetuste(montantTTC, vetuste.tauxVetuste || 0),
      montantVetusteDeduite: calculate.getMontantVetusteDeduite(montantTTC, vetuste.tauxVetuste || 0),
      montantVetusteRecuperable: calculate.getMontantVetusteRecuperable(montantTTC, vetuste.tauxVetuste || 0, vetuste.tauxLimiteVetuste || 0),
    }
    formikForm.setFieldValue('newDamage.vetuste', newVetuste)
  }, [ formikForm.values?.newDamage?.valeur?.montantTTC ])

  const addNewDamage = (goBack: boolean = false): void => {
    formikForm.submitForm().catch(console.error)
    formikForm.validateForm()
      .then((errors) => {
        if (errors.newDamage === undefined) {
          let acteurs: ActeurTraveller[] = structuredClone(formikForm.values.acteurs)
          const newDamage = formikForm.values.newDamage
          const { code, libelle } = getNatureBien(newDamage.typeBien as TypeDeBien)
          const createdDamage: Dommage = {
            ...newDamage,
            id: isValidString(newDamage.id) ? newDamage.id : createStakeholerUuid(),
            typeReparation: {
              code: newDamage.typeReparation?.code ?? TypeDeReparation.AR,
              libelle: newDamage.typeReparation?.code,
            },
            natureDuBien: { code, libelle },
            detailDuBien: findCodeLabelUndefined(filterNatureDetailBien(), newDamage.detailDuBien?.code),
            valeur: {
              ...newDamage.valeur,
              uniteMesure: findCodeLabelUndefined(uniteMesureList, newDamage.valeur.uniteMesure?.code),
              tauxTva: tvaRateList.find((tva) => tva.tva.code === newDamage.valeur.tauxTva?.tva.code) ?? tvaRateList[0],
            },
            vetuste: {
              ...newDamage.vetuste,
              reparation: {
                reparabilite: findCodeLabelUndefined(reparabiliteList, newDamage.vetuste.reparation?.reparabilite?.code) as ReparabiliteMobEnumLabel,
                justificatif: findCodeLabelUndefined(justificationList, newDamage.vetuste.reparation?.justificatif?.code) as JustificationsEnumLabel,
              },
              dateAchat: DateUtils.dateStringToApiLocalTimeString(newDamage.vetuste.dateAchat),
            },
            sinapps: {
              detailDuBienSinapps: findCodeLabelUndefined(filterNatureDetailBien(), newDamage.detailDuBien?.code),
              natureDuBienSinapps: { code, libelle },
              typeDommageSinapps: filterNatureDetailBien().find((detail) => detail.code === newDamage.detailDuBien?.code)?.typeDommageSinapps,
            },
          }

          if (damageId) {
            const modifiedActorIndex = acteurs.findIndex((person) => person.id === personId)
            let oldDamage: Dommage | undefined = undefined
            if (roomIndex !== undefined) {
              const dmgIndex = acteurs[modifiedActorIndex].pieces?.find((room) => room.compteur === parseInt(roomIndex))?.dommagesImmobilierEmbellissement?.findIndex((dmg) => dmg.id === damageId) || 0
              oldDamage = acteurs[modifiedActorIndex].pieces
                ?.find((piece) => piece.compteur === parseInt(roomIndex))?.dommagesImmobilierEmbellissement
                .splice(dmgIndex, 1)[0]
            } else {
              const dmgIndex = acteurs[modifiedActorIndex].dommagesMobilierDivers?.findIndex((dmg) => dmg.id === damageId) || 0
              oldDamage = acteurs[modifiedActorIndex].dommagesMobilierDivers?.splice(dmgIndex, 1)[0]
            }
            if ((!createdDamage.detailDuBien?.code && !oldDamage?.detailDuBien?.code) || createdDamage.detailDuBien?.code === oldDamage?.detailDuBien?.code) {
              createdDamage.sinapps = oldDamage?.sinapps
            }
          }

          if (newDamage.typeBien === TypeDeBien.Mobilier || newDamage.typeBien === TypeDeBien.Autre) {
            acteurs[acteurIndex].dommagesMobilierDivers?.push(createdDamage)
          } else {
            acteurs[acteurIndex].pieces
              ?.find((piece) => piece.compteur === newDamage.pieceCompteur)?.dommagesImmobilierEmbellissement
              .push(createdDamage)
          }

          acteurs = acteurs.map((person) => {
            return ({
              ...person,
              indemnisation: calculateAllCompensations({
                damages: [
                  ...person.pieces?.flatMap((room) => room.dommagesImmobilierEmbellissement) ?? [],
                  ...person.dommagesMobilierDivers ?? [],
                ],
                natureDetailBien,
                isSinapps: caseInfos?.mission.origine?.code === 'SIN',
                oldCompensation: person.indemnisation,
              }),
            })
          })

          formikForm.resetForm({
            values: {
              ...formikForm.values,
              acteurs,
              newDamage: formikForm.initialValues.newDamage,
            },
          })

          const resetDamage: Dommage & NewDommageRequest = {
            ...formikForm.initialValues.newDamage,
            vetuste: {
              ...formikForm.initialValues.newDamage.vetuste,
              tauxLimiteVetuste: createdDamage.vetuste.tauxLimiteVetuste,
            },
            personneConcernee: newDamage.personneConcernee,
            pieceCompteur: newDamage.pieceCompteur,
            typeBien: newDamage.typeBien,
          }

          formikForm.setFieldValue('newDamage', resetDamage)

          goBack && onGoBack()
        } else {
          console.log('New Damage errors', errors.newDamage)
        }
      })
      .catch(console.error)
  }

  const onEditRoomClick = (compteur: number, name: string) => (event: React.MouseEvent) => {
    event.stopPropagation()
    formikForm.setFieldValue('newDamage.pieceLibelle', name)
    setRoomModal({ open: true, compteur })
  }

  const handleEditNewRoomClose = (edit: boolean = false): void => {
    if (isValidString(formikForm.values.newDamage.pieceLibelle) && edit && !formikForm.values.disabled) {
      const rooms: PersonnePiece[] = [ ...formikForm.values.acteurs[acteurIndex]?.pieces ?? [] ]

      // edit room name
      if (roomModal.compteur !== undefined) {
        if (rooms.filter((room) => room.compteur !== roomModal.compteur).map((room) => room.libelle).includes(formikForm.values.newDamage.pieceLibelle)) {
          formikForm.setFieldError('newDamage.pieceLibelle', 'Une pièce avec le même nom existe déjà')
        } else {
          formikForm.setFieldValue(`acteurs[${acteurIndex}].pieces[${rooms.findIndex((room) => room.compteur === roomModal.compteur)}].libelle`, formikForm.values.newDamage.pieceLibelle)
          formikForm.setFieldValue('newDamage.pieceLibelle', '')
          setRoomModal({ open: false })
        }
      }
      // add room
      else if (rooms.map((room) => room.libelle).includes(formikForm.values.newDamage.pieceLibelle)) {
        formikForm.setFieldError('newDamage.pieceLibelle', 'Une pièce avec le même nom existe déjà')
      } else {
        rooms.push({ libelle: formikForm.values.newDamage.pieceLibelle, compteur: rooms.length + 1, dommagesImmobilierEmbellissement: []})
        formikForm.setFieldValue(`acteurs[${acteurIndex}].pieces`, rooms)
        formikForm.setFieldValue('newDamage.pieceLibelle', '')
        setRoomModal({ open: false })
      }
    }
    if (!edit) {
      setRoomModal({ open: false })
    }
  }

  const handleValue = (type: string, value?: boolean | string | string[] | number | TauxTVA): void => {
    if (type === 'pieceLibelle' && !formikForm.touched.newDamage?.pieceLibelle) {
      formikForm.setFieldTouched('newDamage.pieceLibelle', true)
    }
    formikForm.setFieldValue(`newDamage.${type}`, value)
  }

  const handleDamageValueChange = (type: ValueFields, value: string): void => {
    if (calculate === null) {
      return
    }
    const newValue = handleNumberVerification(value, 2)
    const valeur = formikForm.values.newDamage.valeur

    const modifyAmount = (taxe?: Taxe, quantity: number = 0, unitPrice: number = 0, rate: number = 0): void => {
      if (taxe === Taxe.HT) {
        handleValue('valeur.montantHT', calculate.getMontantHT(quantity, unitPrice))
        handleValue('valeur.montantTTC', calculate.getMontantTTC(quantity, unitPrice, rate))
      } else {
        handleValue('valeur.montantHT', calculate.getMontantHTByPrixUnitaireTTC(quantity, unitPrice, rate))
        handleValue('valeur.montantTTC', calculate.getMontantTTCByPrixUnitaireTTC(quantity, unitPrice, rate))
      }
    }

    if (type === 'tauxTva.taux') {
      const newRate = tvaRateList.find((rate) => rate.taux.toString() === value.toString())
      if (newRate === undefined) {
        return
      }
      handleValue('valeur.tauxTva', newRate)
      modifyAmount(valeur.taxe, valeur.quantite, valeur.prixUnitaire, newRate.taux)
    } else if (type === 'taxe') {
      handleValue('valeur.taxe', value)
      modifyAmount(value as Taxe, valeur.quantite, valeur.prixUnitaire, valeur.tauxTva?.taux || 0)
    } else {
      handleValue(`valeur.${type}`, newValue)
    }
    if (type === 'quantite') {
      modifyAmount(valeur.taxe, newValue, valeur.prixUnitaire, valeur.tauxTva?.taux || 0)
    }
    if (type === 'prixUnitaire') {
      modifyAmount(valeur.taxe, valeur.quantite, newValue, valeur.tauxTva?.taux || 0)
    }
    if (type === 'montantHT') {
      handleValue('valeur.quantite', 1)
      if (valeur.taxe === Taxe.HT) {
        const prixUnitaireHT = calculate.getPrixUnitaireHT(newValue)
        handleValue('valeur.prixUnitaire', prixUnitaireHT)
        handleValue('valeur.montantTTC', calculate.getMontantTTC(1, prixUnitaireHT, valeur.tauxTva?.taux || 0))
      } else {
        const prixUnitaireTTC = calculate.getMontantTTC(1, newValue, valeur.tauxTva?.taux || 0)
        handleValue('valeur.prixUnitaire', prixUnitaireTTC)
        handleValue('valeur.montantTTC', prixUnitaireTTC)
      }
    }
    if (type === 'montantTTC') {
      handleValue('valeur.quantite', 1)
      if (valeur.taxe === Taxe.HT) {
        const prixUnitaireHT = calculate.getPrixUnitaireHTByPrixUnitaireTTC(newValue, valeur.tauxTva?.taux || 0)
        handleValue('valeur.prixUnitaire', prixUnitaireHT)
        handleValue('valeur.montantHT', prixUnitaireHT)
      } else {
        const prixUnitaireTTC = calculate.getPrixUnitaireHT(newValue)
        handleValue('valeur.prixUnitaire', prixUnitaireTTC)
        handleValue('valeur.montantHT', calculate.getMontantHTByPrixUnitaireTTC(1, prixUnitaireTTC, valeur.tauxTva?.taux || 0))
      }
    }
  }

  const handleDamageVetusteChange = (type: VetusteFields, value: string): void => {
    const newValue = parseFloat(value)

    if (calculate === null) {
      return
    }
    if (isNaN(newValue)) {
      handleValue(`vetuste.${type}`, 0)
      return
    }

    const vetuste = formikForm.values.newDamage.vetuste
    const montantTTC = formikForm.values.newDamage.valeur.montantTTC || 0
    const newVetuste: DommageVetuste = structuredClone(vetuste)

    if (type === 'tauxVetuste') {
      newVetuste.tauxVetuste = newValue
      newVetuste.montantVetuste = calculate.getMontantVetuste(montantTTC, newValue)
      newVetuste.montantVetusteDeduite = calculate.getMontantVetusteDeduite(montantTTC, newValue)
      newVetuste.montantVetusteRecuperable = calculate.getMontantVetusteRecuperable(montantTTC, newValue, vetuste.tauxLimiteVetuste || 0)
    }
    if (type === 'tauxLimiteVetuste') {
      newVetuste.tauxLimiteVetuste = newValue
      newVetuste.montantVetusteRecuperable = calculate.getMontantVetusteRecuperable(montantTTC, vetuste.tauxVetuste || 0, newValue)
    }
    formikForm.setFieldValue('newDamage.vetuste', newVetuste)
  }

  const damageNumber = useMemo(() => {
    if (formikForm.values.acteurs !== undefined) {
      const persons = formikForm.values.acteurs?.filter((person) => !person.isDeleted)
      return (persons.flatMap((person) => person.dommagesMobilierDivers).length || 0) + (persons.flatMap((person) => person.pieces).flatMap((room) => room?.dommagesImmobilierEmbellissement).length || 0)
    }
    return 0
  }, [ formikForm.values.acteurs ])

  const isLoading = useMemo(() => isFetchingNatureDetailBien || isFetchingReparabiliteList || isFetchingJustificationList || isFetchinRoomTypeList || isFetchingUniteMesureList || isFetchingTvaRateList || isFetchingNatureBienList || isFetchingCaseInfos,
    [
      isFetchingNatureDetailBien,
      isFetchingReparabiliteList,
      isFetchingJustificationList,
      isFetchinRoomTypeList,
      isFetchingUniteMesureList,
      isFetchingTvaRateList,
      isFetchingNatureBienList,
      isFetchingCaseInfos,
    ])

  const typeDeBienOptions: SegmentedButtonOption[] = enumToSegmentedButtonOptions(TypeDeBien)
  const typeDeReparationOptions: SegmentedButtonOption[] = useMemo(() => {
    return enumToSegmentedButtonOptions(TypeDeReparation).filter(({ value }) => {
      if (value === 'RM' ||
      (value === 'RAN' && formikForm?.values?.newDamage?.typeBien !== TypeDeBien.Mobilier) ||
      (value === 'REN' && formikForm?.values?.newDamage?.typeBien === TypeDeBien.Mobilier)
      ) {
        return false
      }
      return true
    })
  }, [ formikForm?.values?.newDamage?.typeBien ])
  const taxeOptions: SegmentedButtonOption[] = enumToSegmentedButtonOptions(Taxe)
  const natureDuBienOptions: SegmentedButtonOption[] = natureBienList.map((option) => ({ value: option.code, label: option.libelle, ref: React.createRef() }))
  const reparabiliteOptions: SegmentedButtonOption[] = reparabiliteList.map((option) => ({ value: option.code, label: option.libelle, ref: React.createRef() }))
  const justificatifOptions: SegmentedButtonOption[] = justificationList.map((option) => ({ value: option.code, label: option.libelle, ref: React.createRef() }))
  const personneConcerneeOptions: SegmentedButtonOption[] = formikForm.values.acteurs?.length > 0 ?
    formikForm.values.acteurs.filter((person) => !person.isDeleted).map(({ id, nom }) => ({ value: id || '', label: nom || id || '', ref: React.createRef() })) :
    [ { value: 'Nom', ref: React.createRef() } ]

  return (
    <>
      <HeaderWithBackArrowTwoSmallButtons
        title={damageId ? 'Modification du dommage' : 'Ajout de dommages'}
        caseId={caseId}
        onClickBack={onGoBack}
      />
      <HeaderTravelerAction
        title={isNaN(damageNumber) ? '' : `${damageNumber} dommage${damageNumber > 1 ? 's' : ''} ajouté${damageNumber > 1 ? 's' : ''}`}
      >
        <SubmitFormButton
          variant={damageId ? 'contained' : 'outlined'}
          disabled={isLoading || formikForm.values.disabled}
          onClick={() => addNewDamage(true)}
        >
          {damageId ? 'Valider la modification' : 'Valider et terminer'}
        </SubmitFormButton>
        {
          !damageId &&
            <SubmitFormButton
              variant="contained"
              disabled={isLoading || formikForm.values.disabled}
              onClick={() => addNewDamage(false)}
            >
              Valider et ajouter nouveau
            </SubmitFormButton>
        }
      </HeaderTravelerAction>
      <PageContainer>
        {
          isLoading ?
            <Loader /> :
            formikForm.values.acteurs !== undefined && acteurIndex !== -1 &&
              <FormContainer>
                <TravelerLargeTitle>
                  {`Dommage ${damageId ? formikForm.values.newDamage.libelle : damageNumber + 1}`}
                </TravelerLargeTitle>
                <FormBoldTitle>
                  Personne concernée
                </FormBoldTitle>
                <SegmentedButtons
                  options={personneConcerneeOptions}
                  selectedOption={formikForm.values.newDamage.personneConcernee}
                  setSelectedOption={(newVal): void => handleValue('personneConcernee', newVal)}
                  smaller
                />
                <FormBoldTitle>
                  Type de bien
                </FormBoldTitle>
                <SegmentedButtons
                  options={typeDeBienOptions}
                  selectedOption={formikForm.values.newDamage.typeBien}
                  setSelectedOption={(newVal): void => handleValue('typeBien', newVal)}
                  smaller
                />
                {
                  (formikForm.values.newDamage.typeBien === TypeDeBien.Immobilier || formikForm.values.newDamage.typeBien === TypeDeBien.Embellissement) &&
                    <>
                      <FormBoldTitle required>
                        Pièce
                      </FormBoldTitle>
                      <TwoGridContainer error={formikForm.touched.newDamage?.pieceCompteur !== undefined && formikForm.errors.newDamage?.pieceCompteur !== undefined}>
                        {
                          formikForm.values.acteurs[acteurIndex].pieces?.map((piece, index) => (
                            <CheckableButton
                              key={`${piece.compteur}-${index}`}
                              checked={formikForm.values.newDamage.pieceCompteur === piece.compteur}
                              onChange={() => handleValue('pieceCompteur', piece.compteur)}
                              type="radio"
                              label={
                                <EditRoomNameContainer>
                                  {piece.libelle}
                                  <CustomIconButton
                                    Icon={EditOutlined}
                                    color={formikForm.values.newDamage.pieceCompteur === piece.compteur ? 'info' : 'primary'}
                                    onClick={onEditRoomClick(piece.compteur, piece.libelle || '')}
                                  />
                                </EditRoomNameContainer>
                              }
                            />
                          ))
                        }
                        <CheckableFormControlLabel
                          checked
                          onChange={() => setRoomModal({ open: true })}
                          label="Ajouter une pièce"
                          control={<Checkbox checkedIcon={<AddRounded color="primary" />} />}
                        />
                      </TwoGridContainer>
                      <ErrorMessage name="newDamage.pieceCompteur" />
                    </>
                }
                {
                  formikForm.values.newDamage.typeBien === TypeDeBien.Autre &&
                    <>
                      <FormBoldTitle>
                        Nature du bien
                      </FormBoldTitle>
                      <SegmentedButtons
                        options={natureDuBienOptions}
                        selectedOption={formikForm.values.newDamage.natureDuBien?.code || ''}
                        setSelectedOption={(newVal): void => handleValue('natureDuBien.code', newVal)}
                        smaller
                      />
                    </>
                }
                <FormBoldTitle>
                  Détail de bien
                </FormBoldTitle>
                <Field
                  component={Select}
                  name="newDamage.detailDuBien.code"
                  displayEmpty
                  size="small"
                  value={
                    filterNatureDetailBien()
                      .find((detail) => detail.code === formikForm.values.newDamage.detailDuBien?.code) ?
                      formikForm.values.newDamage.detailDuBien?.code :
                      ''
                  }
                  renderValue={
                    verifySelectFieldValue(
                      filterNatureDetailBien()
                        .find((detail) => detail.code === formikForm.values.newDamage.detailDuBien?.code) ?
                        formikForm.values.newDamage.detailDuBien?.code :
                        '',
                    )
                  }
                >
                  {
                    filterNatureDetailBien().map((detail, index) => (
                      <MenuItem
                        value={detail.code}
                        key={`${detail.code}-${index}`}
                      >
                        {detail.libelle}
                      </MenuItem>
                    ))
                  }
                </Field>
                <FormBoldTitle required>
                  Libellé
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Libellé du dommage"
                  name="newDamage.libelle"
                  size="small"
                />
                <FormBoldTitle>
                  Type de réparation
                </FormBoldTitle>
                <SegmentedButtons
                  options={typeDeReparationOptions}
                  selectedOption={formikForm.values.newDamage.typeReparation?.code || ''}
                  setSelectedOption={(newVal): void => handleValue('typeReparation.code', newVal)}
                  smaller
                />
                <ErrorMessage name="newDamage.typeReparation" />
                <FormBoldTitle>
                  Valeur
                </FormBoldTitle>
                <Card>
                  <CardContentContainer>
                    <FourGridNoMarginContainer>
                      <FormSmallTitle required>
                        Quantité
                      </FormSmallTitle>
                      <FormSmallTitle>
                        Unité
                      </FormSmallTitle>
                      <FormSmallTitle required>
                        Prix unitaire
                      </FormSmallTitle>
                      <FormSmallTitle required>
                        Taxe
                      </FormSmallTitle>
                    </FourGridNoMarginContainer>
                    <FourGridContainer>
                      <NumberField
                        name="newDamage.valeur.quantite"
                        value={formikForm.values.newDamage.valeur.quantite}
                        onChange={(e) => handleDamageValueChange('quantite', e.target.value)}
                        size="small"
                      />
                      <Field
                        component={Select}
                        name="newDamage.valeur.uniteMesure.code"
                        displayEmpty
                        renderValue={verifySelectFieldValue(formikForm.values.newDamage.valeur?.uniteMesure?.code)}
                        value={formikForm.values.newDamage.valeur?.uniteMesure?.code || ''}
                        size="small"
                      >
                        {
                          uniteMesureList.map((value, index) => (
                            <MenuItem
                              value={value.code}
                              key={`${value.code}-${index}`}
                            >
                              {value.libelle}
                            </MenuItem>
                          ))
                        }
                      </Field>
                      <PriceField
                        name="newDamage.valeur.prixUnitaire"
                        value={formikForm.values.newDamage.valeur.prixUnitaire}
                        onChange={(e): void => handleDamageValueChange('prixUnitaire', e.target.value)}
                        size="small"
                      />
                      <SegmentedButtons
                        options={taxeOptions}
                        selectedOption={formikForm.values.newDamage.valeur?.taxe}
                        setSelectedOption={(newVal): void => handleDamageValueChange('taxe', newVal)}
                        smaller
                      />
                    </FourGridContainer>
                    <FourGridNoMarginContainer>
                      <FormSmallTitle>
                        Montant HT
                      </FormSmallTitle>
                      <FormSmallTitle required>
                        TVA
                      </FormSmallTitle>
                      <FormSmallTitle>
                        Montant TTC
                      </FormSmallTitle>
                    </FourGridNoMarginContainer>
                    <FourGridContainer>
                      <PriceField
                        name="newDamage.valeur.montantHT"
                        value={formikForm.values.newDamage.valeur.montantHT}
                        onChange={(e): void => handleDamageValueChange('montantHT', e.target.value)}
                        size="small"
                      />
                      <div>
                        <Field
                          component={Select}
                          name="newDamage.valeur.tauxTva.taux"
                          displayEmpty
                          renderValue={verifySelectFieldValue(formikForm.values.newDamage.valeur?.tauxTva?.taux?.toString())}
                          error={formikForm.touched.newDamage?.valeur?.tauxTva !== undefined && Boolean(formikForm.errors.newDamage?.valeur?.tauxTva)}
                          value={formikForm.values.newDamage.valeur.tauxTva?.taux || ''}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDamageValueChange('tauxTva.taux', e.target.value)}
                          size="small"
                        >
                          {
                            tvaRateList.map((value, index) => (
                              <MenuItem
                                value={value.taux}
                                key={`${value.taux}-${index}`}
                              >
                                {value.tva.libelle}
                              </MenuItem>
                            ))
                          }
                        </Field>
                        <TvaErrorMessage name="newDamage.valeur.tauxTva" />
                      </div>
                      <PriceField
                        name="newDamage.valeur.montantTTC"
                        value={formikForm.values.newDamage.valeur.montantTTC}
                        onChange={(e): void => handleDamageValueChange('montantTTC', e.target.value)}
                        size="small"
                      />
                    </FourGridContainer>
                  </CardContentContainer>
                </Card>
                <FormBoldTitle>
                  Vétusté
                </FormBoldTitle>
                <Card>
                  <CardContentContainer>
                    <FourGridNoMarginContainer>
                      <FormSmallTitle>
                        % vétusté
                      </FormSmallTitle>
                      <FormSmallTitle>
                        Âge
                      </FormSmallTitle>
                      {
                        formikForm.values.newDamage.typeBien === TypeDeBien.Mobilier ?
                          <FormSmallTitle>
                            Date d’achat
                          </FormSmallTitle> :
                          <div />
                      }
                      <div />
                    </FourGridNoMarginContainer>
                    <FourGridContainer>
                      <RateField
                        name="newDamage.vetuste.tauxVetuste"
                        placeholder="Taux"
                        value={formikForm.values.newDamage.vetuste.tauxVetuste}
                        onChange={(e) => handleDamageVetusteChange('tauxVetuste', e.target.value)}
                        size="small"
                      />
                      <NumberField
                        name="newDamage.vetuste.age"
                        placeholder="Âge"
                        value={formikForm.values.newDamage.vetuste.age || 0}
                        onChange={(e) => handleValue('vetuste.age', handleNumberVerification(e.target.value, 0))}
                        size="small"
                      />
                      {
                        formikForm.values.newDamage.typeBien === TypeDeBien.Mobilier ?
                          <SmallFormikDatePicker name="newDamage.vetuste.dateAchat" /> :
                          <div />
                      }
                      <div />
                    </FourGridContainer>
                    <FourGridNoMarginContainer>
                      <FormSmallTitle>
                        Montant vétusté
                      </FormSmallTitle>
                      <FormSmallTitle>
                        Limite vétusté
                      </FormSmallTitle>
                      <FormSmallTitle>
                        Vétusté récupérable
                      </FormSmallTitle>
                      <FormSmallTitle>
                        VVD
                      </FormSmallTitle>
                    </FourGridNoMarginContainer>
                    <FourGridContainer>
                      <PriceField
                        name="newDamage.vetuste.montantVetuste"
                        size="small"
                        disabled
                      />
                      <RateField
                        name="newDamage.vetuste.tauxLimiteVetuste"
                        placeholder="Limite"
                        value={formikForm.values.newDamage.vetuste.tauxLimiteVetuste}
                        onChange={(e): void => handleDamageVetusteChange('tauxLimiteVetuste', e.target.value)}
                        size="small"
                      />
                      <PriceField
                        name="newDamage.vetuste.montantVetusteRecuperable"
                        size="small"
                        disabled
                      />
                      <PriceField
                        name="newDamage.vetuste.montantVetusteDeduite"
                        size="small"
                        disabled
                      />
                    </FourGridContainer>
                    {
                      formikForm.values.newDamage.typeBien === TypeDeBien.Mobilier &&
                        <>
                          <FormSmallTitle>
                            Bien volé réparable/non réparable
                          </FormSmallTitle>
                          <SegmentedButtons
                            options={reparabiliteOptions}
                            selectedOption={formikForm.values.newDamage.vetuste.reparation?.reparabilite?.code || ''}
                            setSelectedOption={(newVal): void => handleValue('vetuste.reparation.reparabilite.code', newVal)}
                            smaller
                          />
                          <Separation />
                          <FormSmallTitle>
                            Justificatif
                          </FormSmallTitle>
                          <SegmentedButtons
                            options={justificatifOptions}
                            selectedOption={formikForm.values.newDamage.vetuste.reparation?.justificatif?.code || ''}
                            setSelectedOption={(newVal): void => handleValue('vetuste.reparation.justificatif.code', newVal)}
                            smaller
                          />
                          <Separation />
                        </>
                    }
                  </CardContentContainer>
                </Card>
                {
                  roomModal.open &&
                    <AddRoomModal
                      selectList={roomTypeList}
                      open={roomModal.open}
                      handleClose={handleEditNewRoomClose}
                      handleValue={handleValue}
                      disabled={formikForm.values.disabled}
                    />
                }
              </FormContainer>
        }
      </PageContainer>
    </>
  )
}

export default AddDamagePage

/* Framework imports -------------------------------------------------------- */
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'
/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import DateUtils from 'helpers/DateUtils'
import {
  useLazyGetInternalExchangesRecipientListQuery,
  usePostNotViewedInternalExchangeMutation,
  usePostViewedInternalExchangeMutation,
} from 'store/api'
import { isValidString } from 'helpers/isValidString'

/* Component imports -------------------------------------------------------- */
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import {
  Button,
  CircularProgress,
  Card,
  CardContent,
} from '@mui/material'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import CaseItemTitleContainer from 'components/CasesList/CaseItem/CaseItemTitleContainer/CaseItemTitleContainer'
import ClickableColoredSquareChip from 'components/ColoredSquareChip/ClickableColoredSquareChip'
import AutocompleteField from 'components/FieldWithInputAdornment/AutocompleteField'

/* Type imports ------------------------------------------------------------- */
import type { FormikHelpers } from 'formik'
import type {
  CodeLabel,
  EchangeInterne,
  Pastille,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const messageSchema = Yup.object({
  message: Yup.string().required('Le message est obligatoire'),
  action: Yup.mixed<'transferer'>().required().nullable(),
  transfererA: Yup.mixed<CodeLabel>().when('action', {
    is: 'transferer',
    then: () => Yup.mixed<CodeLabel>().test(
      'is-not-empty',
      'Le destinataire est obligatoire',
      (value) => isValidString(value?.code),
    ).required('Le destinataire est obligatoire'),
  }),
})

type MessageRequest = Yup.InferType<typeof messageSchema>

/* Internal variables ------------------------------------------------------- */

/* Styled components -------------------------------------------------------- */
const GridContainer = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 4fr;

  align-items: center;
  justify-content: stretch;

  margin-bottom: 10px;
`

const CardContainer = styled(Card)`
  margin-top: 20px;
  margin-bottom: 20px;
`

const CardTitleContainer = styled(CardContent)`
  padding: 10px 5px 0px 10px;
`

const BoldSeparator = styled.div`
  width: calc(100% + 40px);
  margin-left: -20px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
  margin-top: 10px;
`

const BoldText = styled.div`
  font-weight: bold;
  display: inline;
`

const ObjectTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`

const DateContainer = styled.div`
  display: flex;
  height: fit-content;
  align-items: center;
  gap: 10px;
`

const ButtonLine = styled.div`
  margin-top: 20px;
`

const AnswerButton = styled(Button)`
  min-width: 180px;
  margin-right: 10px;
`

const ForwardTo = styled(FormBoldTitle)`
  margin-top: 0px;
  margin-right: 20px;
`

/* Component declaration ---------------------------------------------------- */
interface NotificationsMessageProps {
  notification: EchangeInterne;
  caseChips?: Pastille[];
  isFetchingChip: boolean;
}

const NotificationsMessage: React.FC<NotificationsMessageProps> = ({
  notification,
  caseChips = [],
  isFetchingChip,
}) => {
  const [
    getRecipientList,
    {
      currentData: recipientList = [],
      isFetching: isFetchingRecipientList,
    },
  ] = useLazyGetInternalExchangesRecipientListQuery()

  const [
    submitViewed,
    { isLoading: isSubmittingViewed },
  ] = usePostViewedInternalExchangeMutation()

  const [
    submitNotViewed,
    { isLoading: isSubmittingNotViewed },
  ] = usePostNotViewedInternalExchangeMutation()

  const onSubmit = (values: MessageRequest, { setSubmitting }: FormikHelpers<MessageRequest>): void => {
    console.log('values', values)
    setSubmitting(false)
  }

  const formikForm = useForm<MessageRequest>(
    {
      initialValues: {
        message: '',
        action: null,
        transfererA: { code: '', libelle: '' },
      },
      onSubmit: onSubmit,
      validationSchema: messageSchema,
    },
  )

  const handleValue = (type: string, value?: string | boolean | string[] | null): void => {
    formikForm.setFieldValue(type, value).catch(console.error)
  }

  useEffect(() => {
    if (formikForm.values.action === 'transferer') {
      getRecipientList({
        dossier: notification.dossier.id,
        assistante: true,
        expert: true,
      }).catch(console.error)
    }
  }, [
    formikForm.values.action,
    getRecipientList,
    notification.dossier.id,
  ])

  const handleChipClick = (): void => {
    if (notification.lu) {
      submitNotViewed({ id: notification.id }).catch(console.error)
    } else {
      submitViewed({ id: notification.id }).catch(console.error)
    }
  }

  return (
    <Form form={formikForm}>
      <CardContainer>
        <CardTitleContainer>
          <CaseItemTitleContainer
            caseId={notification.dossier.id}
            reportId={`${notification.dossier.id}-1`}
            assure={notification.dossier.nomAssure}
            reportObjective={notification.dossier.objectifCharteRapport}
            company={notification.dossier.mandant}
            caseChips={caseChips}
            isFetchingChip={isFetchingChip}
          />
        </CardTitleContainer>
        <BoldSeparator />
        <CardContent>
          <ObjectTitle>
            <div>
              <div>
                {'De : '}
                <BoldText>
                  {notification.emetteur.nom}
                </BoldText>
              </div>
              <div>
                {'Objet : ' }
                <BoldText>
                  {notification.objet}
                </BoldText>
              </div>
            </div>
            <DateContainer>
              {DateUtils.apiStrToLocalDateString(notification.date, { hour: '2-digit', minute: '2-digit' })}
              {
                isSubmittingNotViewed || isSubmittingViewed ?
                  <CircularProgress /> :
                  <ClickableColoredSquareChip
                    color={notification.lu ? 'green' : 'red'}
                    onClick={handleChipClick}
                  >
                    {notification.lu ? 'Lu' : 'Nouveau'}
                  </ClickableColoredSquareChip>
              }
            </DateContainer>
          </ObjectTitle>
          {notification.message}
          {
            formikForm.values.action !== null &&
              <ButtonLine>
                {
                  formikForm.values.action === 'transferer' &&
                    <GridContainer>
                      <ForwardTo required>
                        Transférer à :
                      </ForwardTo>
                      {
                        isFetchingRecipientList ?
                          <CircularProgress /> :
                          <AutocompleteField
                            name="transfererA"
                            options={recipientList}
                          />
                      }
                    </GridContainer>
                }
                <Field
                  component={TextField}
                  rows={3}
                  multiline
                  name="message"
                  placeholder="Votre message"
                />
              </ButtonLine>
          }
          <ButtonLine>
            {
              formikForm.values.action !== null &&
                <AnswerButton
                  variant="contained"
                  type="submit"
                  disabled={isFetchingRecipientList}
                >
                  {
                    isFetchingRecipientList ?
                      <CircularProgress /> :
                      'Envoyer'
                  }
                </AnswerButton>
            }
            {
              formikForm.values.action !== 'transferer' &&
                <AnswerButton
                  variant="outlined"
                  onClick={() => handleValue('action', 'transferer')}
                >
                  Transférer
                </AnswerButton>
            }
          </ButtonLine>
        </CardContent>
      </CardContainer>
    </Form>
  )
}

export default NotificationsMessage

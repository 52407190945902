/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useLocation } from 'react-router-dom'

/* Component imports -------------------------------------------------------- */
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import RouteTitle from 'routers/Router/RouteTitle'
import NavBarItemButton from './NavBarItemButton'

/* Type imports ------------------------------------------------------------- */

/* Internal variables ------------------------------------------------------- */

/* Styled components -------------------------------------------------------- */
const NavBarContainer = styled.nav`
  bottom: 0;
  min-height: 5rem;
  z-index: 203;

  background-color: #0A145F;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  flex-shrink: 0;
  flex-grow: 0;

  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
`

/* Component declaration ---------------------------------------------------- */
interface NavBarProps {
  exchangesCount: number;
  notificationsCount: number;
  tasksCount: number;
}

const NavBar: React.FC<NavBarProps> = ({
  exchangesCount,
  notificationsCount,
  tasksCount,
}) => {
  const location = useLocation()
  const [ title, setTitle ] = useState<string>('')

  const tabs = [
    {
      to: 'planning',
      title: 'Agenda',
      Icon: <CalendarMonthIcon />,
      notificationCount: 0,
    },
    {
      to: 'dossiers',
      title: 'Dossiers',
      Icon: <FolderOutlinedIcon />,
      notificationCount: 0,
    },
    {
      to: 'echanges-internes',
      title: 'Échanges',
      Icon: <ChatBubbleOutlineOutlinedIcon />,
      notificationCount: exchangesCount,
    },
    {
      to: 'notifications',
      title: 'Notifications',
      Icon: <NotificationsNoneOutlinedIcon />,
      notificationCount: notificationsCount,
    },
    {
      to: 'taches',
      title: 'Tâches',
      Icon: <PlaylistAddCheckIcon />,
      notificationCount: tasksCount,
    },
    {
      to: 'compte',
      title: 'Mon compte',
      Icon: <AccountCircleOutlinedIcon />,
      notificationCount: 0,
    },
  ]

  useEffect(() => {
    const newTabValue = tabs.findIndex((tab) => location.pathname.indexOf(tab.to) !== -1)
    if (tabs[newTabValue]?.title) {
      setTitle(tabs[newTabValue].title)
    }
  }, [ location.pathname ])

  return (
    <NavBarContainer>
      <RouteTitle title={title} />
      {
        tabs.map((tab) => (
          <NavBarItemButton
            key={tab.to}
            to={tab.to}
            title={tab.title}
            Icon={tab.Icon}
            notificationCount={tab.notificationCount}
          />
        ))
      }
    </NavBarContainer>
  )
}

export default NavBar

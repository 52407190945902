/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom'
import {
  useGetCaseDocumentsQuery,
  useGetCaseEventDocumentsQuery,
  useGetCaseInfosQuery,
} from 'store/api'
import { useMediaLibrary } from 'helpers/hooks/useMediaLibrary'
import { useIsConnected } from 'helpers/hooks/useIsConnected'
import { useAppSelector } from 'store/hooks'
import { getRouterLastPath } from 'store/slices/routerHistorySlice'

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'
import {
  CameraAltOutlined,
  DriveFileRenameOutlineRounded,
  GradingRounded,
  InsertPhotoOutlined,
  PushPinOutlined,
  TopicOutlined,
} from '@mui/icons-material'
import HeaderWithBackArrowTwoSmallButtons from 'layouts/MainLayout/Headers/HeaderWithBackArrowTwoSmallButtons'
import PageContainer from 'layouts/PageContainer/PageContainer'
import TravelerButton from './TravelerButton/TravelerButton'
import ReportSyncStatusButton from './TravelerButton/ReportSyncStatusButton'
import ReportMenu from './ReportMenu/ReportMenu'
import DocumentsModal from './DocumentsModal/DocumentsModal'
import PostItModal from './PostItModal/PostItModal'
import MediaSidebar from './MediaSidebar/MediaSidebar'
import CaseTravelerTabs from './CaseTravelerTabs/CaseTravelerTabs'

/* Type imports ------------------------------------------------------------- */
import type { TravelerButtonProperties } from 'types/TravelerButton'
import type { TravelerForm } from 'types/TravelerForm'

/* Type declaration ---------------------------------------- */

/* Internal variables ------------------------------------------------------- */

/* Styled components -------------------------------------------------------- */
const TravelerButtonContainer = styled.div`
  margin-left: auto;
  margin-right: 1rem;
  margin-top: .5rem;
  display: flex;
`

/* Component declaration ---------------------------------------------------- */
interface TravelerLayoutProps {}

const TravelerLayout: React.FC<TravelerLayoutProps> = () => {
  const { formikForm, errorForm } = useOutletContext<{formikForm: TravelerForm; errorForm: TravelerForm}>()
  const { caseId = '' } = useParams<{ caseId: string }>()
  const navigate = useNavigate()
  const isConnected = useIsConnected()
  const mediaLibrary = useMediaLibrary()
  const routerLastPath: string | null = useAppSelector(getRouterLastPath)
  const [ openDocumentsModal, setOpenDocumentsModal ] = useState<boolean>(false)
  const [ openPostItModal, setOpenPostItModal ] = useState<boolean>(false)
  const [ buttons, setButtons ] = useState<TravelerButtonProperties[]>([])

  const {
    currentData: caseInfos,
    isFetching: isFetchingCaseInfos,
  } = useGetCaseInfosQuery(caseId)
  const {
    currentData: documents = [],
    isFetching: isFetchingDocuments,
  } = useGetCaseDocumentsQuery({ dossier: caseId })
  const {
    currentData: eventDocuments = [],
    isFetching: isFetchingEventDocuments,
  } = useGetCaseEventDocumentsQuery({ dossier: caseId, avecBrouillons: true })

  useEffect(() => {
    if (!isFetchingDocuments && !isFetchingCaseInfos && !isFetchingEventDocuments && caseInfos !== undefined) {
      setButtons(
        [
          {
            buttonType: 'media',
            icon: InsertPhotoOutlined,
            action: () => mediaLibrary.openMediaSidebar(),
            badge: documents.length,
            variant: 'outlined',
            tooltip: 'Médiathèque',
            disable: true,
          },
          {
            buttonType: 'documents',
            icon: TopicOutlined,
            action: () => setOpenDocumentsModal(true),
            badge: eventDocuments.filter((doc) => doc.familleAction?.code === 'LA' || doc.familleAction?.code === 'PV' || doc.familleAction?.code === 'RA').length,
            variant: 'outlined',
            tooltip: 'Documents',
          },
          {
            buttonType: 'postIt',
            icon: PushPinOutlined,
            action: () => setOpenPostItModal(true),
            badge: caseInfos.postIt !== null && caseInfos.postIt !== undefined && caseInfos.postIt !== '' ? 1 : 0,
            variant: 'outlined',
            tooltip: 'Post-it',
          },
          {
            buttonType: 'sinappsReport',
            icon: GradingRounded,
            action: (): void => navigate(`/dossiers/${caseId}/traveller/actions/compte-rendu`),
            badge: -1,
            variant: 'contained',
            tooltip: 'Compte-rendu',
          },
          {
            buttonType: 'attachment',
            icon: CameraAltOutlined,
            action: () => console.log('attachment'),
            badge: -1,
            variant: 'contained',
            tooltip: 'Nouvelle pièce-jointe',
            disable: true,
          },
          {
            buttonType: 'notepad',
            icon: DriveFileRenameOutlineRounded,
            action: (): void => navigate(`/dossiers/${caseId}/traveller/actions/bloc-note`),
            badge: -1,
            variant: 'contained',
            tooltip: 'Bloc-note',
          },
        ],
      )
    }
  }, [
    isFetchingDocuments,
    isFetchingCaseInfos,
    isFetchingEventDocuments,
    caseInfos,
    documents,
    eventDocuments,
    caseId,
    navigate,
  ])

  const onGoBack = () => {
    routerLastPath !== null ? navigate(routerLastPath) : navigate(-1)
  }

  return (
    <>
      <HeaderWithBackArrowTwoSmallButtons
        title={`Dossier ${caseId}`}
        caseId={caseId}
        onClickBack={onGoBack}
        reportObjective={formikForm.values?.objectifCharte}
        caseChips={formikForm.values?.infosDossier?.pastilles ?? []}
        company={formikForm.values?.infosDossier?.compagnie?.libelle || ''}
      />
      <ReportMenu caseId={caseId}>
        <TravelerButtonContainer onClick={(e: React.MouseEvent): void => e.stopPropagation()}>
          {
            buttons.length > 0 ?
              buttons
                .filter((button) => caseInfos?.mission.origine?.code === 'SIN' || button.buttonType !== 'sinappsReport')
                .map((button) => (
                  <TravelerButton
                    key={button.buttonType}
                    caseId={caseId}
                    buttonProperty={button}
                    isConnected={isConnected}
                  />
                )) :
              <CircularProgress />
          }
          {
            buttons.length > 0 && formikForm && <ReportSyncStatusButton
              formikForm={formikForm}
              errorForm={errorForm}
            />
          }
        </TravelerButtonContainer>
      </ReportMenu>
      <DocumentsModal
        open={openDocumentsModal}
        handleClose={() => setOpenDocumentsModal(false)}
        disabled={formikForm.values.disabled}
        mandant={formikForm.values?.acteurs?.[0]?.intervenants?.find((stakeholder) => stakeholder.mandant === true)?.id || ''}
      />
      <PostItModal
        open={openPostItModal}
        handleClose={() => setOpenPostItModal(false)}
        postIt={caseInfos?.postIt}
      />
      <MediaSidebar />
      <PageContainer>
        <CaseTravelerTabs
          caseId={caseId}
          irsiData={formikForm.values.irsiData}
          isConnected={isConnected}
        />
        <Outlet context={formikForm} />
      </PageContainer>
    </>
  )
}

export default TravelerLayout

/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useLocation } from 'react-router-dom'

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'

/* Type imports ------------------------------------------------------------- */

/* Styled components -------------------------------------------------------- */
interface MessageProps {
  error?: boolean;
}

const Message = styled.div<MessageProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${(props) => props.error ? props.theme.palette.error.main : props.theme.palette.primary.main};
  gap: 10px;
`

/* Component declaration ---------------------------------------------------- */
interface MediaProcessorFallbackProps {
  children: React.ReactNode;
  type: 'pdf' | 'word';
}

const MediaProcessorFallback: React.FC<MediaProcessorFallbackProps> = ({ children, type }) => {
  const { pathname } = useLocation()

  return (
    <ErrorBoundary
      pathname={pathname}
      errorMessage={
        <Message error>
          Le chargement de
          {' '}
          {type === 'pdf' ? 'la visionneuse de PDF' : "l'éditeur de document"}
          {' '}
          à échoué.
          <br />
          Vous pouvez tenter de réessayer, ou bien contacter les développeurs.
        </Message>
      }
    >
      <React.Suspense fallback={
        <Message>
          Chargement de
          {' '}
          {type === 'pdf' ? 'la visionneuse de PDF' : "l'éditeur de document"}
          {'...'}
          <CircularProgress />
        </Message>
      }
      >
        {children}
      </React.Suspense>
    </ErrorBoundary>
  )
}

export default MediaProcessorFallback

/* Framework imports -------------------------------------------------------- */
import { createSlice } from '@reduxjs/toolkit'

/* Module imports ----------------------------------------------------------- */

/* Type imports ------------------------------------------------------------- */
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'store/store'
import type { PieceJointe } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
export interface MediaLibraryState {
  mediaLibraryOpen: boolean;
  document: PieceJointe | null;
  mediaSidebarOpen: boolean;
}

/* MediaLibrary Redux slice ---------------------------- */
const mediaLibrarySlice = createSlice(
  {
    name: 'mediaLibrary',
    initialState: {
      mediaLibraryOpen: false,
      document: null,
      mediaSidebarOpen: false,
    } as MediaLibraryState,
    reducers: {
      openMediaLibrary: (state, { payload }: PayloadAction<PieceJointe>) => {
        state.mediaLibraryOpen = true
        state.document = payload
      },
      closeMediaLibrary: (state) => {
        state.mediaLibraryOpen = false
        state.document = null
      },
      setMediaLibraryOpenState: (state, { payload }: PayloadAction<boolean>) => {
        state.mediaLibraryOpen = payload
      },
      openMediaSidebar: (state) => {
        state.mediaSidebarOpen = true
      },
      closeMediaSidebar: (state) => {
        state.mediaSidebarOpen = false
      },
    },
  },
)

/* Export slice components ----------------------------- */
export const {
  openMediaLibrary,
  closeMediaLibrary,
  setMediaLibraryOpenState,
  openMediaSidebar,
  closeMediaSidebar,
} = mediaLibrarySlice.actions

export default mediaLibrarySlice.reducer

export const selectMediaLibrary = (state: RootState): MediaLibraryState => {
  return state.mediaLibrary
}

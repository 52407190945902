/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { onLogoutReset } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
import Button from '@mui/material/Button'

/* Type imports ------------------------------------------------------------- */

/* Styled components -------------------------------------------------------- */
const SignOutButtonStyled = styled(Button)`
  background-color: ${(props) => props.theme.palette.error.dark};

  &:hover {
    background-color: ${(props) => props.theme.palette.error.light};
  }
`

/* Component declaration ---------------------------------------------------- */
interface SignOutButtonProps {}

const SignOutButton: React.FC<SignOutButtonProps> = () => {

  const onSignOut: React.MouseEventHandler<HTMLButtonElement> = () => {
    onLogoutReset()
  }

  return (
    <SignOutButtonStyled
      variant="contained"
      onClick={onSignOut}
      fullWidth
    >
      Se déconnecter
    </SignOutButtonStyled>
  )
}

export default SignOutButton

/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import CloseButton from 'components/CloseButton/CloseButton'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'

/* Type imports ------------------------------------------------------------- */
import type { FormikHelpers } from 'formik'

/* Type declarations -------------------------------------------------------- */
const renameFileSchema = Yup.object({
  name: Yup.string().required('Ce champ est obligatoire'),
})

type RenameFileRequest = Yup.InferType<typeof renameFileSchema>

/* Internal variables ------------------------------------------------------- */

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;
`

const DialogContentContainer = styled(DialogContent)`
  padding-bottom: 0;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin-bottom: 20px;
`

const FormButton = styled(SubmitFormButton)`
  margin-left: 0;
`

const CardContainer = styled(Card)`
  margin-bottom: 15px;
  background-color: #F6F9FC;
`

const CardContentContainer = styled(CardContent)`
  padding: 15px;
  padding-top: 0;
`

/* Component declaration ---------------------------------------------------- */
interface MediaLibraryRenameModalProps {
  open: boolean;
  handleClose: () => void;
  fileName: string;
}

const MediaLibraryRenameModal: React.FC<MediaLibraryRenameModalProps> = ({
  open,
  handleClose,
  fileName,
}) => {

  const onSubmit = (values: RenameFileRequest, { setSubmitting }: FormikHelpers<RenameFileRequest>): void => {
    setSubmitting(false)
    handleClose()
  }

  const formikForm = useForm<RenameFileRequest>(
    {
      initialValues: {
        name: fileName,
      },
      onSubmit: onSubmit,
      validationSchema: renameFileSchema,
    },
  )

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      maxWidth="xs"
      fullWidth
    >
      <Form form={formikForm}>
        <DialogTitleContainer>
          Renommer
          <CloseButton handleClose={handleClose} />
        </DialogTitleContainer>
        <DialogContentContainer>
          <CardContainer>
            <CardContentContainer>
              <FormBoldTitle required>
                Nouveau nom :
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Nom du document"
                name="name"
              />
            </CardContentContainer>
          </CardContainer>
        </DialogContentContainer>
        <DialogActionContainer>
          <FormButton
            variant="contained"
            type="submit"
          >
            Valider
          </FormButton>
        </DialogActionContainer>
      </Form>
    </Dialog>
  )
}

export default MediaLibraryRenameModal

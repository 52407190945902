/* Framework imports -------------------------------------------------------- */
import { createSlice } from '@reduxjs/toolkit'

/* Module imports ----------------------------------------------------------- */

/* Type imports ------------------------------------------------------------- */
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'store/store'

/* Type declarations -------------------------------------------------------- */
export interface CaseSidebarState {
  open: boolean;
  caseId: string | null;
}

/* Case Sidebar Redux slice ---------------------------- */
const caseSidebarSlice = createSlice(
  {
    name: 'caseSidebar',
    initialState: {
      open: false,
      caseId: null,
    } as CaseSidebarState,
    reducers: {
      open: (state, { payload }: PayloadAction<CaseSidebarState>) => {
        return payload
      },
      close: (state) => {
        state.open = false
        state.caseId = null
      },
      toggle: (state) => {
        state.open = !state.open
      },
    },
  },
)

/* Export slice components ----------------------------- */
export const {
  open,
  close,
  toggle,
} = caseSidebarSlice.actions

export default caseSidebarSlice.reducer

export const selectCaseSidebar = (state: RootState): CaseSidebarState => {
  return state.caseSidebar
}

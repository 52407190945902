/* Framework imports -------------------------------------------------------- */
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { Card as MuiCard } from '@mui/material'

/* Component declaration ---------------------------------------------------- */
const Card = styled(MuiCard)`
  padding: .75rem;
  margin: 5px 0px;
`

const Title = styled.h5`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
`

const Object = styled.div`
  font-weight: bold;
  font-size: .83em;
`

const Text = styled.div`
  font-size: .9rem;
  margin-top: 5px;
  margin-bottom: 0px;
  text-align: justify;
`

const DateContainer = styled.div`
  display: flex;
  height: fit-content;
  align-items: center;
  gap: 10px;
  font-weight: normal;
`

export default {
  Card,
  Title,
  DateContainer,
  Object,
  Text,
}

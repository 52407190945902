/* Framework imports -------------------------------------------------------- */
import styled from '@emotion/styled'

/* Styled components -------------------------------------------------------- */
export interface CompensationTableRowProps {
  border?: boolean;
}

const CompensationTableRow = styled.div<CompensationTableRowProps>`
  height: 100%;
  display: grid;
  gap: 10px;
  width: 100%;
  justify-content: stretch;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 0px;
  margin-top: 20px;
  border-bottom: ${(props) => props.border ? `1px solid ${props.theme.colors.grey}` : undefined};
`

/* Export CompensationTableRow component ----------------------- */
export default CompensationTableRow

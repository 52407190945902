/* Framework imports -------------------------------------------------------- */
import React, {
  useMemo,
  useState,
} from 'react'
/* Module imports ----------------------------------------------------------- */
import {
  CalendarViewTypes,
  defaultFullCalendarOptions,
  joursFeries,
} from 'helpers/FullCalendarOptions'

/* Component imports -------------------------------------------------------- */
import FullCalendar from '@fullcalendar/react' /* Must be imported before other FullCalendar plugins */
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import PlanningCalendarEventRender from './PlanningCalendarEventRender/PlanningCalendarEventRender'

/* Type imports ------------------------------------------------------------- */
import type {
  DateSelectArg,
  EventChangeArg,
  EventClickArg,
  EventInput,
} from '@fullcalendar/core'
import type { Planning } from 'API/__generated__/Api'
import { TypeRdvType } from 'API/__generated__/Api'
import type {
  EventHandlerActionType,
  EventHandlerType,
  PopoverInfoType,
} from 'types/EventHandler'

/* Styling imports ------------------------------------- */
import './PlanningCalendar.css'

/* Component declaration ---------------------------------------------------- */
interface PlanningCalendarProps {
  initialView?: string;
  initialDate: Date;
  calendarRef: React.RefObject<FullCalendar>;
  events: Planning[];
  setEventHandler?: (values: EventHandlerType) => void;
  onEventChange?: (type?: EventHandlerActionType | null, newEvent?: Planning) => void;
}

const PlanningCalendar: React.FC<PlanningCalendarProps> = ({
  initialView,
  initialDate,
  calendarRef,
  events,
  setEventHandler,
  onEventChange,
}) => {
  const [ openPopoverInfo, setOpenPopoverInfo ] = useState<PopoverInfoType>({ id: null, x: null, y: null, isRecurring: null })

  const editEvent = (e: EventClickArg): void => {
    if (e?.event?.startEditable) {
      setEventHandler &&
      setEventHandler(
        {
          open: true,
          action: 'edit',
          event: events.find((event) => e.event.id === event.id),
          dates: null,
        },
      )
    } else if (calendarRef.current?.getApi()?.view?.type as CalendarViewTypes !== CalendarViewTypes.Day) {
      setOpenPopoverInfo({ id: e.event.id, x: e.jsEvent.clientX, y: e.jsEvent.clientY, isRecurring: e.event.extendedProps.recurring as boolean ?? false })
    }
  }

  const addEvent = (e: DateSelectArg): void => {
    setEventHandler &&
    setEventHandler(
      {
        open: true,
        action: 'add',
        event: undefined,
        dates: e,
      },
    )
  }

  const eventChange = (e: EventChangeArg): void => {
    const newEvent: Planning = {
      ...events.find((event) => event.id === e.event.id) as Planning,
      ...e.event,
      id: e.event.id ??`${e.event.start?.toISOString()}-${e.event.end?.toISOString()}`,
      dateDebut: e.event.start?.toISOString() as string,
      dateFin: e.event.end?.toISOString() as string,
    }
    onEventChange && onEventChange('edit', newEvent)
  }

  const calendarFullYear = calendarRef.current?.getApi().getDate().getFullYear()

  const holidays = useMemo(() => joursFeries(calendarFullYear ?? new Date().getFullYear()), [ calendarFullYear ])

  return (
    <FullCalendar
      ref={calendarRef}
      {...defaultFullCalendarOptions}
      plugins={
        [
          dayGridPlugin,
          timeGridPlugin,
          listPlugin,
          interactionPlugin,
        ]
      }
      height={calendarRef.current?.getApi()?.view?.type === CalendarViewTypes.Day? 900 : 700}
      initialView={initialView}
      initialDate={initialDate}
      events={
        [
          ...events.map((event: Planning): EventInput => (
            {
              start: event.dateDebut,
              end: event.dateFin,
              title: event.assure ?? event.typeRDV.libelle,
              editable: event.typeRDV.type === TypeRdvType.ActionGenerale ? event.idRecurrence ? false : true : false,
              id: event.id,
              textColor: event.typeRDV?.color,
              allDay: event.journeeComplete ?? false,
              extendedProps: {
                recurring: event.idRecurrence ? true : false,
              },
            }
          )),
          ...Object.keys(holidays).map((holiday) => (
            {
              start: holidays[holiday].toISOString(),
              title: holiday,
              id: holiday,
              display: 'background',
              backgroundColor: '#bcd1d2a0',
              allDay: true,
            }
          )),
        ]
      }
      scrollTime="07:00"
      slotLaneClassNames={calendarRef.current?.getApi()?.view?.type === CalendarViewTypes.Day ? 'day-view' : 'week-view'}
      eventContent={
        (eventInfo) => (
          <PlanningCalendarEventRender
            eventInfo={eventInfo}
            event={events.find((evt) => evt.id === eventInfo.event.id) as Planning}
            viewType={calendarRef.current?.getApi()?.view?.type as CalendarViewTypes ?? CalendarViewTypes.Month}
            clearPopoverInfo={() => setOpenPopoverInfo({ id: null, x: null, y: null, isRecurring: null })}
            openPopoverInfo={openPopoverInfo}
          />
        )
      }
      eventChange={eventChange}
      eventClick={editEvent}
      select={addEvent}
    />
  )
}

export default PlanningCalendar

/* Framework imports -------------------------------------------------------- */
import React from 'react'
/* Module imports ----------------------------------------------------------- */
import {
  // useGetSinappsSolicitAllowedTypeListQuery,
  useGetSinappsSolicitInterventionTypeListQuery,
  useGetSinappsSolicitPrestationListQuery,
  useGetSinappsSolicitInterventionSubtypeListQuery,
  useGetSinappsSolicitDamageNatureListQuery,
  useGetSinappsSolicitOperationNatureListQuery,
  useGetSinappsSolicitMandateEntityListQuery,
  useGetSinappsSolicitCriticiteListQuery,
  useGetSinappsSolicitUrgencyReasonsListQuery,
  useGetSinappsSolicitConventionListQuery,
  useGetSinappsSolicitIrsiTrancheListQuery,
} from 'store/api'

/* Component imports -------------------------------------------------------- */
import { MenuItem } from '@mui/material'
import { Field } from 'formik'
import {
  TextField,
  Select,
} from 'formik-mui'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import Loader from 'components/Loader/Loader'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'

/* Type imports ------------------------------------------------------------- */
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'
import {
  Criticite,
  TypeIntervention,
} from 'API/__generated__/Api'

/* Component declaration ---------------------------------------------------- */
interface QaSinappsSolicitStakeholderProps {
  handleChange: (type: string, value: string | boolean) => void;
  typeDestinataire?: string;
  natureOperation?: string | null;
  estComplexe?: boolean;
  jePeuxIntervenir?: boolean;
  criticite?: string | null;
  detailConvention?: string | null;
  intervention?: string;
  convention?: string | null;
}

const QaSinappsSolicitStakeholder: React.FC<QaSinappsSolicitStakeholderProps> = ({
  handleChange,
  typeDestinataire,
  natureOperation,
  estComplexe,
  jePeuxIntervenir,
  criticite,
  detailConvention,
  intervention,
  convention,
}) => {

  // const {
  //   currentData: allowedTypeList = [],
  //   isFetching: isFetchingAllowedTypeList,
  // } = useGetSinappsSolicitAllowedTypeListQuery();
  const {
    currentData: interventionTypeList = [],
    isFetching: isFetchingInterventionTypeList,
  } = useGetSinappsSolicitInterventionTypeListQuery()
  const {
    currentData: prestationList = [],
    isFetching: isFetchingPrestationList,
  } = useGetSinappsSolicitPrestationListQuery()
  const {
    currentData: interventionSubTypeList = [],
    isFetching: isFetchingInterventionSubTypeList,
  } = useGetSinappsSolicitInterventionSubtypeListQuery()
  const {
    currentData: damageNatureList = [],
    isFetching: isFetchingDamageNatureList,
  } = useGetSinappsSolicitDamageNatureListQuery()
  const {
    currentData: operationNatureList = [],
    isFetching: isFetchingOperationNatureList,
  } = useGetSinappsSolicitOperationNatureListQuery()
  const {
    currentData: mandateEntityList = [],
    isFetching: isFetchingMandateEntityList,
  } = useGetSinappsSolicitMandateEntityListQuery()
  const {
    currentData: criticiteList = [],
    isFetching: isFetchingCriticiteList,
  } = useGetSinappsSolicitCriticiteListQuery()
  const {
    currentData: urgencyList = [],
    isFetching: isFetchingUrgencyList,
  } = useGetSinappsSolicitUrgencyReasonsListQuery()
  const {
    currentData: conventionList = [],
    isFetching: isFetchingConventionList,
  } = useGetSinappsSolicitConventionListQuery()
  const {
    currentData: trancheIrsiList = [],
    isFetching: isFetchingTrancheIrsiList,
  } = useGetSinappsSolicitIrsiTrancheListQuery()

  const destinataireOptions: SegmentedButtonOption[] = [ { value: 'Assureur' } ].map((option) => ({ ...option, ref: React.createRef() }))
  const operationNatureOptions: SegmentedButtonOption[] = operationNatureList.map(({ code, libelle }) => ({ value: code, label: libelle, ref: React.createRef() }) )
  const complexityOptions: SegmentedButtonOption[] = [ { value: 'true', label: 'Oui' }, { value: 'false', label: 'Non' } ].map((option) => ({ ...option, ref: React.createRef() }))
  const mandateEntityOptions: SegmentedButtonOption[] = mandateEntityList.map(({ code, libelle }) => ({ value: code, label: libelle, ref: React.createRef() }) )
  const criticiteOptions: SegmentedButtonOption[] = criticiteList.map(({ code, libelle }) => ({ value: code, label: libelle, ref: React.createRef() }) )
  const trancheIrsiOptions: SegmentedButtonOption[] = trancheIrsiList.map(({ code, libelle }) => ({ value: code, label: libelle, ref: React.createRef() }) )

  return (
    <div>
      {
        // isFetchingAllowedTypeList ||
        isFetchingPrestationList ||
        isFetchingInterventionSubTypeList ||
        isFetchingDamageNatureList ||
        isFetchingOperationNatureList ||
        isFetchingMandateEntityList ||
        isFetchingCriticiteList ||
        isFetchingUrgencyList ||
        isFetchingConventionList ||
        isFetchingTrancheIrsiList ||
        isFetchingInterventionTypeList ?
          <Loader /> :
          <div>
            <FormBoldTitle>
              Destinataire
            </FormBoldTitle>
            <SegmentedButtons
              options={destinataireOptions}
              selectedOption={typeDestinataire}
              setSelectedOption={(newVal): void => handleChange('typeDestinataire', newVal)}
            />
            {/* <FormBoldTitle>
              Type de sollicitation à formuler
            </FormBoldTitle> */}
            {/* <Field
              component={Select}
              displayEmpty
            >
              <MenuItem
                value=""
                disabled
              >
                Sélectionner
              </MenuItem>
              {
                allowedTypeList.map((value) => (
                  <MenuItem
                    value={value.code}
                    key={value.code}
                  >
                    {value.libelle}
                  </MenuItem>
                ))
              }
            </Field> */}
            <FormBoldTitle>
              Type de l'intervention
            </FormBoldTitle>
            <Field
              component={Select}
              name="solicitStakeholder.intervention"
              displayEmpty
            >
              <MenuItem
                value=""
                disabled
              >
                Sélectionner
              </MenuItem>
              {
                interventionTypeList.map((value) => (
                  <MenuItem
                    value={value.code}
                    key={value.code}
                  >
                    {value.libelle}
                  </MenuItem>
                ))
              }
            </Field>
            {
              (intervention === TypeIntervention.EAD || intervention === TypeIntervention.ESS) &&
                <>
                  <FormBoldTitle>
                    Complément de prestation
                  </FormBoldTitle>
                  <Field
                    component={Select}
                    name="solicitStakeholder.complementPrestation"
                    displayEmpty
                  >
                    <MenuItem
                      value=""
                      disabled
                    >
                      Sélectionner
                    </MenuItem>
                    {
                      prestationList.map((value) => (
                        <MenuItem
                          value={value.code}
                          key={value.code}
                        >
                          {value.libelle}
                        </MenuItem>
                      ))
                    }
                  </Field>
                  <FormBoldTitle>
                    Nature des dommages
                  </FormBoldTitle>
                  <Field
                    component={Select}
                    name="solicitStakeholder.natureDommages"
                    displayEmpty
                  >
                    <MenuItem
                      value=""
                      disabled
                    >
                      Sélectionner
                    </MenuItem>
                    {
                      damageNatureList.map((value) => (
                        <MenuItem
                          value={value.code}
                          key={value.code}
                        >
                          {value.libelle}
                        </MenuItem>
                      ))
                    }
                  </Field>
                </>
            }
            {
              intervention === TypeIntervention.ReparationEnNature &&
                <>
                  <FormBoldTitle>
                    Sous-type
                  </FormBoldTitle>
                  <Field
                    component={Select}
                    name="solicitStakeholder.sousTypeIntervention"
                    displayEmpty
                  >
                    <MenuItem
                      value=""
                      disabled
                    >
                      Sélectionner
                    </MenuItem>
                    {
                      interventionSubTypeList.map((value) => (
                        <MenuItem
                          value={value.code}
                          key={value.code}
                        >
                          {value.libelle}
                        </MenuItem>
                      ))
                    }
                  </Field>
                </>
            }
            {
              intervention === TypeIntervention.RechercheDeFuite &&
                <>
                  <FormBoldTitle>
                    Nature de l’opération de la recherche de fuite
                  </FormBoldTitle>
                  <SegmentedButtons
                    options={operationNatureOptions}
                    selectedOption={natureOperation || ''}
                    setSelectedOption={(newVal): void => handleChange('natureOperation', newVal)}
                  />
                </>
            }
            <FormBoldTitle>
              Complexité
            </FormBoldTitle>
            <SegmentedButtons
              options={complexityOptions}
              selectedOption={String(estComplexe)}
              setSelectedOption={(newVal): void => handleChange('estComplexe', newVal === 'true')}
            />
            <FormBoldTitle>
              Entité à mandater
            </FormBoldTitle>
            <SegmentedButtons
              options={mandateEntityOptions}
              selectedOption={String(jePeuxIntervenir)}
              setSelectedOption={(newVal): void => handleChange('jePeuxIntervenir', newVal === '-1')}
            />
            <FormBoldTitle>
              Criticité
            </FormBoldTitle>
            <SegmentedButtons
              options={criticiteOptions}
              selectedOption={criticite || ''}
              setSelectedOption={(newVal): void => handleChange('criticite', newVal)}
            />
            {
              criticite === Criticite.Urgente &&
                <>
                  <FormBoldTitle>
                    Urgence
                  </FormBoldTitle>
                  <Field
                    component={Select}
                    name="solicitStakeholder.motifUrgence"
                    displayEmpty
                  >
                    <MenuItem
                      value=""
                      disabled
                    >
                      Sélectionner
                    </MenuItem>
                    {
                      urgencyList.map((value) => (
                        <MenuItem
                          value={value.code}
                          key={value.code}
                        >
                          {value.libelle}
                        </MenuItem>
                      ))
                    }
                  </Field>
                </>
            }
            <FormBoldTitle>
              Convention applicable
            </FormBoldTitle>
            <Field
              component={Select}
              name="solicitStakeholder.convention"
              displayEmpty
            >
              <MenuItem
                value=""
                disabled
              >
                Sélectionner
              </MenuItem>
              {
                conventionList.map((value) => (
                  <MenuItem
                    value={value.code}
                    key={value.code}
                  >
                    {value.libelle}
                  </MenuItem>
                ))
              }
            </Field>
            {
              convention === 'Irsi' &&
                <>
                  <FormBoldTitle>
                    Tranche IRSI
                  </FormBoldTitle>
                  <SegmentedButtons
                    options={trancheIrsiOptions}
                    selectedOption={detailConvention || ''}
                    setSelectedOption={(newVal): void => handleChange('detailConvention', newVal)}
                  />
                </>
            }
            <FormBoldTitle>
              Précision sur la demande de nouvelle intervention :
            </FormBoldTitle>
            <Field
              component={TextField}
              name="solicitStakeholder.commentaire"
              placeholder="Votre message"
              rows={3}
              multiline
            />
          </div>
      }
    </div>
  )
}

export default QaSinappsSolicitStakeholder

/* DateUtils methods ----------------------------------- */
const isValidDate = (d: unknown): d is Date => {
  return d instanceof Date &&
    !Number.isNaN(d.valueOf())
}

const dateToApiStr = (pDate: Date): string => {
  return pDate.toISOString()
}

const dateToShortApiStr = (pDate: Date): string => {
  return dateToApiStr(pDate)
    .split('T')[0]
}

const apiStrToDate = (pDateStr: string): Date => {
  return new Date(pDateStr)
}

const apiStrToDateOrUndefined = (pDateStr?: string | null): Date | undefined => {
  if (pDateStr === undefined || pDateStr === null) {
    return
  }
  const date = new Date(pDateStr)
  if (!isValidDate(date)) {
    return
  }

  return date
}

const apiStrToLocalDateString = (pDateStr?: string | null, options?: Intl.DateTimeFormatOptions): string => {
  if (pDateStr === undefined || pDateStr === null) {
    return ''
  }
  const date = new Date(pDateStr)
  if (!isValidDate(date)) {
    return ''
  }

  return date.toLocaleDateString('fr-FR', options)
}

const apiStrToLocalTimeString = (pDateStr?: string | null, options?: Intl.DateTimeFormatOptions): string => {
  if (pDateStr === undefined || pDateStr === null) {
    return ''
  }
  const date = new Date(pDateStr)
  if (!isValidDate(date)) return ''

  return date.toLocaleTimeString('fr-FR', options)
}

const dateStringToApiLocalTimeString = (pDateStr?: string | null): string => {
  if (pDateStr === undefined || pDateStr === null) {
    return ''
  }
  const date = new Date(pDateStr)
  if (!isValidDate(date)) {
    return ''
  }

  return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString()
}

const planningStartDateApi = dateToShortApiStr(new Date(new Date().setDate(new Date().getDate() - 21)))
const planningEndDateApi = dateToShortApiStr(new Date(new Date().setMonth(new Date().getMonth() + 3)))

/* Export DateUtils ------------------------------------ */
export default {
  isValidDate,
  dateToApiStr,
  dateToShortApiStr,
  apiStrToDate,
  apiStrToDateOrUndefined,
  apiStrToLocalDateString,
  apiStrToLocalTimeString,
  dateStringToApiLocalTimeString,
  planningStartDateApi,
  planningEndDateApi,
}

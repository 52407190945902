/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import CaseSidebarTiersCard from './CaseSidebarTiersCard/CaseSidebarTiersCard'

/* Type imports ------------------------------------------------------------- */
import type { PersonneResumeAvecIntervenants } from 'API/__generated__/Api'
import { TypePersonne } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */

/* Component declaration ---------------------------------------------------- */
interface CaseSidebarTiersProps {
  stakeholders?: PersonneResumeAvecIntervenants[] | null;
  isFetching: boolean;
  insuredPerson?: PersonneResumeAvecIntervenants;
}

const CaseSidebarTiers: React.FC<CaseSidebarTiersProps> = ({ isFetching, stakeholders, insuredPerson }) => {

  if (isFetching) {
    return <CircularProgress />
  } else if (stakeholders === undefined || stakeholders === null) {
    return null
  }

  return (
    <div>
      <FormBoldTitle>
        Tiers
      </FormBoldTitle>
      <CaseSidebarTiersCard person={insuredPerson} />
      {
        stakeholders.filter((person) => person.type !== TypePersonne.Assure).map((person, index) => (
          <CaseSidebarTiersCard
            key={`${person.id}-${index}`}
            person={person}
          />
        ))
      }
    </div>
  )
}

export default CaseSidebarTiers

/* Framework imports -------------------------------------------------------- */
import React, { useMemo } from 'react'
import styled from '@emotion/styled'
/* Module imports ----------------------------------------------------------- */
import { useGetSinappsDifficultyListQuery } from 'store/api'
import dateUtils from 'helpers/DateUtils'
import { isValidString } from 'helpers/isValidString'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  TextField as MuiTextField,
} from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'
import Loader from 'components/Loader/Loader'

/* Type imports ------------------------------------------------------------- */
import type {
  Difficulte,
  CommandeLeverDifficulteRequest,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */

/* Internal variables ------------------------------------------------------- */

/* Styled components -------------------------------------------------------- */
const LiftedFormControlLabel = styled(FormControlLabel)`
  width: 100%;
  height: 40px;
  margin: 0;
  color: ${(props) => props.theme.palette.secondary.main};
`

const LiftedCheckbox = styled(Checkbox)`
  padding-left: 0;
`

const DifficultyTitle = styled(FormBoldTitle)`
  margin-top: 10px;
  text-transform: uppercase;
`

const CardContentContainer = styled(CardContent)`
  overflow-x: overlay;
`

const DifficultyTitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
`

const InfoGrid = styled.div`
  display: grid;

  grid-template-columns: 1fr 1fr .5fr 1fr 1fr;
  gap: 10px;

  align-items: stretch;
  justify-content: stretch;
  margin-top: 10px;
  padding-bottom: 20px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
  font-size: 15px;
`

const BoldText = styled.div`
  font-weight: bold;
`

const CommentContainer = styled.div`
  margin-top: 10px;
  margin-bottom: -10px;
  display: flex;
  gap: 5px;
`

const BorderedDiv = styled.div`
  padding-right: 10px;
  border-right: 2px solid ${(props) => props.theme.colors.grey};
`

interface DifficultyCardProps {
  checked: boolean;
}

const DifficultyCard = styled(Card)<DifficultyCardProps>`
  margin-bottom: 40px;
  border: ${(props) => props.checked ? `2px solid ${props.theme.palette.primary.main} !important` : 'inherit'};
`

/* Component declaration ---------------------------------------------------- */
interface QaSinappsLiftDifficultyProps {
  caseId: string;
  handleChange: (value: CommandeLeverDifficulteRequest[]) => void;
  values: CommandeLeverDifficulteRequest[];
}

const QaSinappsLiftDifficulty: React.FC<QaSinappsLiftDifficultyProps> = ({
  caseId,
  handleChange,
  values,
}) => {

  const {
    currentData: difficultyList = [],
    isFetching: isFetchingDifficulties,
  } = useGetSinappsDifficultyListQuery(caseId)

  const handleChecked = (e: React.SyntheticEvent, difficulty: Difficulte): void => {
    const checked = (e.target as HTMLInputElement).checked
    let oldValues: CommandeLeverDifficulteRequest[] = [ ...values ]

    if (checked) {
      oldValues.push({ idDifficulte: difficulty.idDifficulte, commentaire: difficulty.commentaireLevee || '', compteur: difficulty.refDossierCpt.cpt })
    } else {
      oldValues = oldValues.filter((val) => val.idDifficulte !== difficulty.idDifficulte)
    }

    handleChange(oldValues)
  }

  const isChecked = (difficulty: Difficulte): boolean => values.some((val) => val.compteur === difficulty.refDossierCpt.cpt)

  const difficulties = useMemo(() => [ ...difficultyList ].sort((a, b) => (a.levee === b.levee)? b.dateCreation.localeCompare(a.dateCreation) : a.levee? 1 : -1), [ difficultyList ])

  return (
    <>
      {isFetchingDifficulties && <Loader />}
      <FormBoldTitle>
        Difficultés à lever
      </FormBoldTitle>
      {
        difficulties.map((difficulty, index) => (
          <DifficultyCard
            key={`${difficulty.refDossierCpt.cpt}-${index}`}
            checked={isChecked(difficulty)}
          >
            <CardContentContainer>
              <DifficultyTitleContainer>
                <div>
                  {
                    !difficulty.levee ?
                      <LiftedFormControlLabel
                        checked={isChecked(difficulty)}
                        control={<LiftedCheckbox />}
                        onChange={(e): void => handleChecked(e, difficulty)}
                        label={
                          <DifficultyTitle>
                            Difficulté N°
                            {difficulty.refDossierCpt.cpt}
                          </DifficultyTitle>
                        }
                      /> :
                      <DifficultyTitle>
                        Difficulté N°
                        {difficulty.refDossierCpt.cpt}
                      </DifficultyTitle>
                  }
                </div>
                <ColoredSquareChip color={!difficulty.levee ? 'green' : 'orange'}>
                  {
                    !difficulty.levee ?
                      'Difficulté non levée':
                      'Difficulté levée'
                  }
                </ColoredSquareChip>
              </DifficultyTitleContainer>
              <InfoGrid>
                <BorderedDiv>
                  Etape :
                  <BoldText>
                    {difficulty.etape.libelle}
                  </BoldText>
                </BorderedDiv>
                <BorderedDiv>
                  Motif :
                  <BoldText>
                    {difficulty.motif.libelle}
                  </BoldText>
                </BorderedDiv>
                <BorderedDiv>
                  Type :
                  <BoldText>
                    {difficulty.type}
                  </BoldText>
                </BorderedDiv>
                <BorderedDiv>
                  Date de création :
                  <BoldText>
                    {dateUtils.apiStrToLocalDateString(difficulty.dateCreation)}
                  </BoldText>
                </BorderedDiv>
                <div>
                  Date prév. de reprise :
                  <BoldText>
                    {
                      difficulty.type === 'DELAI' ?
                        difficulty.dateReprise !== undefined ? dateUtils.apiStrToLocalDateString(difficulty.dateReprise) : null :
                        '-'
                    }
                  </BoldText>
                </div>
              </InfoGrid>
              {
                isValidString(difficulty.commentaire) &&
                  <CommentContainer>
                    Commentaire :
                    {' '}
                    <BoldText>
                      {difficulty.commentaire}
                    </BoldText>
                  </CommentContainer>
              }
              <FormBoldTitle>
                Commentaire de levée
              </FormBoldTitle>
              {
                difficulty.levee === false && isChecked(difficulty) ?
                  <Field
                    component={TextField}
                    name={`liftDifficulty[${values.findIndex((val) => val.compteur === difficulty.refDossierCpt.cpt)}].commentaire`}
                    placeholder="Votre message"
                    rows={3}
                    multiline
                  /> :
                  <MuiTextField
                    value={difficulty.commentaireLevee || ''}
                    multiline
                    disabled
                  />
              }
            </CardContentContainer>
          </DifficultyCard>
        ))
      }
    </>
  )
}

export default QaSinappsLiftDifficulty

/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'
import { isValidString } from 'helpers/isValidString'

/* Component imports -------------------------------------------------------- */
import {
  DatePicker,
  TimePicker,
  renderTimeViewClock,
} from '@mui/x-date-pickers'
import {
  Field,
  useField,
} from 'formik'

/* Type imports ------------------------------------------------------------- */
import type { DatePickerProps } from '@mui/x-date-pickers'
import type { FieldAttributes } from 'formik'

/* Styled components -------------------------------------------------------- */
const SizeableDatePicker = styled(DatePicker)<FieldAttributes<FormikDatePickerProps>>`
  .MuiInputBase-root {
    height: ${(props) => props.size === 'small' ? '40px' : ''};
  }
`

const SizeableTimePicker = styled(TimePicker)<FieldAttributes<FormikDatePickerProps>>`
  .MuiInputBase-root {
    height: ${(props) => props.size === 'small' ? '40px' : ''};
  }
`

/* Component declaration ---------------------------------------------------- */
interface FormikDatePickerProps extends DatePickerProps<Date, false> {
  time?: boolean;
  size?: string;
}

const FormikDatePicker: React.FC<FieldAttributes<FormikDatePickerProps>> = ({
  name,
  time = false,
  ...props
}) => {
  const [ fieldProps, meta, helpers ] = useField<Date | string>(name)

  const getIsValidDate = () => isValidString((fieldProps.value || '').toString()) && DateUtils.isValidDate(new Date(fieldProps.value))
  const getIsInvalidDate = () => isValidString((fieldProps.value || '').toString()) && !DateUtils.isValidDate(new Date(fieldProps.value))

  const getError = () => {
    if (meta.touched && meta.error) return true
    if (getIsInvalidDate()) return true
    return false
  }

  const getHelperText = () => {
    if (meta.touched) return meta.error?.toString()
    if (getIsInvalidDate()) return 'Date invalide'
    return ''
  }

  return (
    <Field
      component={time ? SizeableTimePicker : SizeableDatePicker}
      viewRenderers={
        {
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        }
      }
      slotProps={
        {
          textField: {
            helperText: getHelperText(),
            error: getError(),
          },
        }
      }
      {...fieldProps}
      {...props}
      value={getIsValidDate() ? new Date(fieldProps.value) : null}
      onChange={(value: Date) => DateUtils.isValidDate(new Date(value)) && helpers.setValue(value.toISOString())}
    />
  )
}

export default FormikDatePicker

/* Framework imports -------------------------------------------------------- */
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */

/* Component imports -------------------------------------------------------- */
import Button from '@mui/material/Button'

/* Type imports ------------------------------------------------------------- */

/* Component declaration ---------------------------------------------------- */
const SubmitFormButton = styled(Button)`
  margin-left: 10px;
  min-width: 120px;
`

export default SubmitFormButton

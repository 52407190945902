/* Framework imports -------------------------------------------------------- */
import React from 'react'
/* Module imports ----------------------------------------------------------- */
import { useGetSinappsRequestServiceEndReasonListQuery } from 'store/api'

/* Component imports -------------------------------------------------------- */
import { MenuItem } from '@mui/material'
import { Field } from 'formik'
import {
  TextField,
  Select,
} from 'formik-mui'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import Loader from 'components/Loader/Loader'

/* Type imports ------------------------------------------------------------- */

/* Type declarations -------------------------------------------------------- */

/* Internal variables ------------------------------------------------------- */

/* Styled components -------------------------------------------------------- */

/* Component declaration ---------------------------------------------------- */
interface QaSinappsRequestServiceEndProps {}

const QaSinappsRequestServiceEnd: React.FC<QaSinappsRequestServiceEndProps> = () => {
  const {
    currentData: requestServiceEndReasons = [],
    isFetching: isFetchingRequestServiceEndReasons,
  } = useGetSinappsRequestServiceEndReasonListQuery()

  return (
    <>
      {isFetchingRequestServiceEndReasons && <Loader />}
      <FormBoldTitle required>
        Motif demande fin de prestation
      </FormBoldTitle>
      <Field
        component={Select}
        name="requestServiceEnd.motif"
        displayEmpty
      >
        <MenuItem
          value=""
          disabled
        >
          Sélectionner
        </MenuItem>
        {
          requestServiceEndReasons.map((reason) => (
            <MenuItem
              value={reason.code}
              key={reason.code}
            >
              {reason.libelle}
            </MenuItem>
          ))
        }
      </Field>
      <FormBoldTitle>
        Commentaire demande fin de prestation
      </FormBoldTitle>
      <Field
        component={TextField}
        name="requestServiceEnd.commentaire"
        placeholder="Votre message"
        rows={3}
        multiline
      />
    </>
  )
}

export default QaSinappsRequestServiceEnd

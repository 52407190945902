/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useMatch,
  useNavigate,
} from 'react-router-dom'

/* Component imports -------------------------------------------------------- */
import { Badge } from '@mui/material'
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined'

/* Type imports ------------------------------------------------------------- */

/* Styled components -------------------------------------------------------- */
interface NavBarButtonProps {
  isActive?: boolean;
}

const NavBarButton = styled.button<NavBarButtonProps>`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;
  min-width: 60px;

  color: ${
  (props) => {
    if (props.isActive) {
      return '#8BD9FF'
    }

    return '#8287AE'
  }
};
  background: none;
  border: none;

  svg {
    height: 1.5rem;
  }

  &:hover {
    cursor: pointer;
  }
`

const NavBarActiveIndicator = styled.div<NavBarButtonProps>`
  position: absolute;

  top: -17px;

  background-color: #8BD9FF;
  border-radius: 50vh;
  width: 100%;
  height: 6px;
  max-width: 100px;
  display: ${
  (props) => {
    if (props.isActive) {
      return 'block'
    }

    return 'none'
  }
};

  z-index: 1000;
`

const NavBarButtonTitle = styled.span`
  margin-top: .5rem;
`

/* Component declaration ---------------------------------------------------- */
interface NavBarItemButtonProps {
  to: string;
  Icon?: React.ReactNode;
  title: string;
  notificationCount: number;
}

const NavBarItemButton: React.FC<NavBarItemButtonProps> = ({
  to,
  Icon = <HelpCenterOutlinedIcon />,
  title,
  notificationCount,
}) => {
  const navigate = useNavigate()

  const matchResult = useMatch(
    {
      path: to,
      caseSensitive: true,
      end: false,
    },
  )

  const onClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    navigate(to)
  }

  return (
    <Badge
      badgeContent={notificationCount}
      invisible={notificationCount === 0}
      color="error"
      anchorOrigin={
        {
          vertical: 'top',
          horizontal: 'right',
        }
      }
    >
      <NavBarButton
        isActive={matchResult !== null}
        onClick={onClick}
        id={title}
      >
        <NavBarActiveIndicator isActive={matchResult !== null} />
        {Icon}
        <NavBarButtonTitle>
          {title}
        </NavBarButtonTitle>
      </NavBarButton>
    </Badge>
  )
}

export default NavBarItemButton

/* Framework imports -------------------------------------------------------- */
import React from 'react'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'

/* Component imports -------------------------------------------------------- */
import DocumentNameEditor from './DocumentNameEditor'

/* Type imports ------------------------------------------------------------- */
import type { FormikHelpers } from 'formik'
import type { PieceJointe } from 'API/__generated__/Api'

/* Configuration imports ------------------------------- */

/* Type declarations -------------------------------------------------------- */
const attachmentSchema = Yup.object({
  documentId: Yup.string().required(),
  name: Yup.string().required('Le nom est obligatoire'),
  category: Yup.string(),
})

type AttachmentRequest = Yup.InferType<typeof attachmentSchema>

/* Internal variables ------------------------------------------------------- */

/* Styled components -------------------------------------------------------- */

/* Component declaration ---------------------------------------------------- */
interface DocumentNameEditorFormProps {
  document: PieceJointe;
  onEdition: () => void;
}

const DocumentNameEditorForm: React.FC<DocumentNameEditorFormProps> = ({
  document,
  onEdition,
}) => {
  const onSubmit = (values: AttachmentRequest, { setSubmitting }: FormikHelpers<AttachmentRequest>): void => {
    console.log('document name editor form submit values: ', values)
    setSubmitting(false)
  }

  const formikForm = useForm<AttachmentRequest>(
    {
      initialValues: {
        documentId: document.id || '',
        name: document.libelle,
        category: document.categorie?.code,
      },
      onSubmit: onSubmit,
      validationSchema: attachmentSchema,
    },
  )

  return (
    <Form form={formikForm}>
      <DocumentNameEditor
        document={document}
        fieldNames={{ name: 'name', category: 'category' }}
        category={formikForm.values.category}
        onEdition={onEdition}
        onRemove={() => {}}
      />
    </Form>
  )
}

export default DocumentNameEditorForm

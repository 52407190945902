/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom'
import {
  useGetFamilleAnnuaireListQuery,
  useGetPersonRoleListQuery,
  useGetQualiteListQuery,
  useGetStakeholderFamilleAnnuaireListQuery,
  useLazyGetStakeholderDirectoryListQuery,
  useLazyGetTravelerStakeholderInfosQuery,
} from 'store/api'
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'
import { findCodeLabelUndefined } from 'helpers/findCodeLabel'
import { isValidString } from 'helpers/isValidString'
import { createStakeholerUuid } from 'helpers/uuidUtils'
import { useIsConnected } from 'helpers/hooks/useIsConnected'
import { getCaseSelectedReport } from 'store/slices/reportSlice'
import { useAppSelector } from 'store/hooks'
import { isApiResponse } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  MenuItem,
} from '@mui/material'
import { Field } from 'formik'
import {
  Select,
  TextField,
} from 'formik-mui'
import PageContainer from 'layouts/PageContainer/PageContainer'
import HeaderWithBackArrowTwoSmallButtons from 'layouts/MainLayout/Headers/HeaderWithBackArrowTwoSmallButtons'
import HeaderTravelerAction from 'layouts/MainLayout/Headers/HeadersComponents/HeaderTravelerAction'
import LoadingOverlay from 'components/Loader/LoadingOverlay'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'
import CheckableButton from 'components/CheckableButton/CheckableButton'
import Table from 'components/Table/Table'

/* Type imports ------------------------------------------------------------- */
import type {
  ActeurTraveller,
  AnnuaireResume,
  CodeLabel,
  IntervenantTraveller,
  RendezVousTraveller,
} from 'API/__generated__/Api'
import {
  FiltreFamilleAnnuaire,
  TypePersonne,
} from 'API/__generated__/Api'
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'
import type { TravelerForm } from 'types/TravelerForm'
import type { ColumnHeader } from 'types/Table'

/* Type declarations -------------------------------------------------------- */
type TypePerson = 'Assure' | 'EntrepriseAgree' | 'Intervenant'

/* Styled components -------------------------------------------------------- */
const FormContainer = styled.div`
  padding-top: 10px;
  padding-bottom: 40px;
`

const FlexContainer = styled.div`
  display: flex;
  gap: 20px;
`

const LittleContainer = styled.div`
  flex: 1 1 0px;
  flex-basis: 0;
`

const IntervenantContainer = styled.div`
  padding-top: 20px;
`

const SearchButtonContainer = styled.div`
  padding-top: 40px;
  text-align: center;
`

const GridContainer = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);

  align-items: flex-start;
  justify-content: stretch;

  margin-bottom: 10px;
`

const TwoGridNoMarginContainer = styled(GridContainer)`
  margin-bottom: 0px;
`

const LongButton = styled(Button)`
  width: 200px;
  margin-bottom: 20px;
`

const NoFoundMessage = styled.div`
  font-weight: bold;
  color: red;
  margin-bottom: 10px;
`

/* Component declaration ---------------------------------------------------- */
interface AddStakeholderPageProps {}

const AddStakeholderPage: React.FC<AddStakeholderPageProps> = () => {
  const formikForm = useOutletContext<TravelerForm>()
  const { caseId = '' } = useParams<{caseId: string}>()
  const selectedReport: string | null = useAppSelector(getCaseSelectedReport(caseId))
  const navigate = useNavigate()
  const isConnected = useIsConnected()
  const [ personList, setPersonList ] = useState<AnnuaireResume[]>([])
  const [ noFoundMessage, setNoFoundMessage ] = useState<string>('')

  const personType: TypePerson = useMemo(() => {
    if (formikForm.values.newPerson?.type === TypePersonne.Tiers) {
      if (formikForm.values.newPerson?.role?.code === '31') {
        return 'EntrepriseAgree'
      }
      return 'Assure'
    }
    return 'Intervenant'
  }, [
    formikForm.values.newPerson?.role,
    formikForm.values.newPerson?.type,
  ])

  useEffect(() => {
    if (personType === 'EntrepriseAgree') {
      formikForm.setFieldValue('newPerson.recherche.famille', 'RA')
    } else {
      formikForm.setFieldValue('newPerson.recherche.famille', '')
    }
  }, [ personType ])

  const personTypeAnnuaire: FiltreFamilleAnnuaire = useMemo(() => {
    if (personType === 'EntrepriseAgree') {
      return FiltreFamilleAnnuaire.EntreprisePartenaire
    }
    if (personType === 'Intervenant') {
      if (formikForm.values.newPerson.tiersLie === formikForm.values.acteurs?.find((acteur) => acteur.type === TypePersonne.Assure)?.id) {
        return FiltreFamilleAnnuaire.IntervenantAssure
      }
      return FiltreFamilleAnnuaire.IntervenantTiers
    }
    return FiltreFamilleAnnuaire.Toutes
  }, [ personType, formikForm.values.newPerson.tiersLie ])

  const {
    currentData: qualiteList = [],
    isFetching: isFetchingQualiteList,
  } = useGetQualiteListQuery(caseId)
  const {
    currentData: roleList = [],
    isFetching: isFetchingRoleList,
  } = useGetPersonRoleListQuery(caseId)
  const {
    currentData: familleAnnuaireList = [],
    isFetching: isFetchingFamilleAnnuaireList,
  } = useGetFamilleAnnuaireListQuery()
  const {
    currentData: stakeholderFamilleAnnuaireList = [],
    isFetching: isFetchingStakeholderFamilleAnnuaireList,
  } = useGetStakeholderFamilleAnnuaireListQuery({ filtre: personTypeAnnuaire })
  const [
    getStakeholderDirectoryList,
    { isFetching: isFetchingStakeholderDirectoryList },
  ] = useLazyGetStakeholderDirectoryListQuery()
  const [
    getStakeholderInfos,
    { isFetching: isFetchingStakeholderInfos },
  ] = useLazyGetTravelerStakeholderInfosQuery()

  useEffect(() => {
    formikForm.resetForm({
      values: {
        ...formikForm.values,
        newPerson: formikForm.initialValues.newPerson,
      },
    })
  }, [])

  const isLoading = useMemo(() => isFetchingQualiteList || isFetchingFamilleAnnuaireList || isFetchingStakeholderFamilleAnnuaireList || isFetchingStakeholderDirectoryList || isFetchingStakeholderInfos || isFetchingRoleList, [
    isFetchingQualiteList,
    isFetchingFamilleAnnuaireList,
    isFetchingStakeholderFamilleAnnuaireList,
    isFetchingStakeholderDirectoryList,
    isFetchingStakeholderInfos,
    isFetchingRoleList,
  ])

  const handleValue = (type: string, value?: string | boolean | CodeLabel): void => {
    formikForm.setFieldValue(`newPerson.${type}`, value)
  }

  useEffect(() => {
    if (!isFetchingStakeholderFamilleAnnuaireList) {
      setPersonList([])
    }
  }, [ isFetchingStakeholderFamilleAnnuaireList ])

  const handleTypeValue = (value: TypePersonne | 'Entreprise'): void => {
    if (value === TypePersonne.Intervenant || value === TypePersonne.Tiers) {
      handleValue('type', value)
      handleValue('entreprise', false)
      handleValue('role', undefined)
    }
    else if (value === 'Entreprise') {
      handleValue('type', TypePersonne.Tiers)
      handleValue('role', { code: '31', libelle: "ENTREPRISE AGREE PAR L' ASSUREUR" })
    }
  }

  const clearFormAndExit = (acteurs?: {acteurs: ActeurTraveller[]}, rendezVous?: {rendezVous: RendezVousTraveller[]}): void => {
    formikForm.resetForm({
      values: {
        ...formikForm.values,
        ...acteurs,
        ...rendezVous,
        newPerson: formikForm.initialValues.newPerson,
      },
    })
    navigate(`/dossiers/${caseId}/traveller/intervenants`)
  }

  const addPerson = (): void => {
    console.log('AddStakeholder values', formikForm.values.newPerson)
    formikForm.submitForm().catch(console.error)
    formikForm.validateForm()
      .then((errors) => {
        if (!errors.newPerson || !Object.keys(errors.newPerson).some((error) => error.indexOf('recherche'))) {
          const acteurs: ActeurTraveller[] = structuredClone(formikForm.values.acteurs)
          const rendezVous: RendezVousTraveller[] = structuredClone(formikForm.values.rendezVous)
          const newPerson = formikForm.values.newPerson
          const id = createStakeholerUuid()
          if (personType === 'Assure') {
            acteurs.push({
              ...newPerson,
              id,
              libelle: `Tiers ${acteurs.length}`,
              qualite: findCodeLabelUndefined(qualiteList, newPerson.qualite?.code),
            })
          }
          if (personType === 'Intervenant') {
            if (acteurs[acteurs.findIndex((acteur) => acteur.id === newPerson.tiersLie)].intervenants?.find((stakeholder) => stakeholder.nom === newPerson.nom && stakeholder.saisieRapide)) {
              formikForm.setFieldError('newPerson.nom', 'Une autre saisie rapide avec le même nom existe déjà sur cette personne')
              return
            }
            acteurs[acteurs.findIndex((acteur) => acteur.id === newPerson.tiersLie)].intervenants?.push({
              ...newPerson,
              id,
              saisieRapide: true,
              qualite: findCodeLabelUndefined(familleAnnuaireList, newPerson.qualite?.code),
            })
          }
          rendezVous[0]?.acteursStatus?.push({
            ...newPerson.acteurStatus,
            acteurId: id,
          })
          clearFormAndExit({ acteurs: acteurs }, { rendezVous: rendezVous })
        } else {
          console.log('AddStakeholder errors', errors.newPerson)
        }
      })
      .catch(console.error)
  }

  const onAddResearchPersonClick = (intervenantId: string): void => {
    getStakeholderInfos({
      id: selectedReport !== null ? selectedReport : `${caseId}-1`,
      assure: personTypeAnnuaire === FiltreFamilleAnnuaire.IntervenantAssure ? true : false,
      intervenantId: intervenantId,
    }).then((response) => {
      if (isApiResponse<IntervenantTraveller>(response)) {
        const acteurs: ActeurTraveller[] = structuredClone(formikForm.values.acteurs)
        const rendezVous: RendezVousTraveller[] = structuredClone(formikForm.values.rendezVous)
        const newPerson = formikForm.values.newPerson
        if (personType === 'Intervenant') {
          acteurs[acteurs.findIndex((acteur) => acteur.id === newPerson.tiersLie)].intervenants?.push({
            ...response.data,
            qualite: findCodeLabelUndefined(stakeholderFamilleAnnuaireList, newPerson.recherche.famille),
          })
        }
        if (personType === 'EntrepriseAgree') {
          acteurs.push({
            ...response.data,
            libelle: 'Entreprise agréée',
            qualifiants: [],
            qualite: findCodeLabelUndefined(stakeholderFamilleAnnuaireList, response.data.qualite?.code ?? newPerson.recherche.famille),
            role: findCodeLabelUndefined(roleList, '31'),
            type: TypePersonne.Tiers,
          })
        }
        rendezVous[0]?.acteursStatus?.push({
          ...newPerson.acteurStatus,
          acteurId: response.data.id,
        })
        clearFormAndExit({ acteurs: acteurs }, { rendezVous: rendezVous })
      }
    }).catch(console.error)
  }

  const onSearchClick = () => {
    formikForm.submitForm().catch(console.error)
    formikForm.validateForm()
      .then((errors) => {
        if (errors.newPerson?.recherche === undefined) {
          return getStakeholderDirectoryList(formikForm.values.newPerson.recherche)
        }
      })
      .then((response) => {
        if (isApiResponse<AnnuaireResume[]>(response)) {
          if (response.data.length === 0) {
            setNoFoundMessage('Aucune entreprise trouvée avec ce(s) critère(s)')
          } else {
            setNoFoundMessage('')
          }
          setPersonList(response.data)
        }
      })
      .catch(console.error)
  }

  const cols: ColumnHeader[] = [
    {
      id: 'nom',
      label: 'Nom',
    },
    {
      id: 'codePostal',
      label: 'Code postal',
    },
    {
      id: 'ville',
      label: 'ville',
    },
    {
      id: 'telephone',
      label: 'Téléphone',
    },
    {
      id: 'id',
      label: '',
      render: (value: string) => (
        <Button
          variant="contained"
          onClick={() => onAddResearchPersonClick(value)}
          disabled={!isConnected}
        >
          Ajouter
        </Button>
      ),
    },
  ]

  const typeOptions: SegmentedButtonOption[] = [
    { value: TypePersonne.Tiers, label: 'Tiers', ref: React.createRef() },
    { value: TypePersonne.Intervenant, label: 'Intervenant lié à un tiers', ref: React.createRef() },
    { value: 'Entreprise', label: 'Entreprise agréée', ref: React.createRef() },
  ]
  const actionOptions: SegmentedButtonOption[] = [
    { value: 'true', label: 'Ajout rapide', ref: React.createRef() },
    { value: 'false', label: 'Recherche annuaire', ref: React.createRef() },
  ]

  return (
    <>
      <HeaderWithBackArrowTwoSmallButtons
        title="Ajouter un internevant"
        caseId={caseId}
        onClickBack={() => clearFormAndExit()}
      />
      <HeaderTravelerAction
        title={
          isValidString(formikForm.values.newPerson.nom) ?
            `${formikForm.values.newPerson?.nom} ${isValidString(formikForm.values.newPerson?.prenom) ? formikForm.values.newPerson?.prenom : ''}` :
            'Nouvel intervenant'
        }
      >
        <SubmitFormButton
          variant="contained"
          id="AddStakeholder"
          disabled={
            isLoading ||
            formikForm.values.disabled ||
            personType === 'EntrepriseAgree' ||
            (personType === 'Intervenant' && !formikForm.values.newPerson?.saisieRapide)
          }
          onClick={addPerson}
        >
          Ajouter
        </SubmitFormButton>
      </HeaderTravelerAction>
      <PageContainer>
        <LoadingOverlay isLoading={isLoading}>
          {
            formikForm.values.newPerson !== undefined && formikForm.values.acteurs !== undefined &&
              <FormContainer>
                <SegmentedButtons
                  options={typeOptions}
                  selectedOption={personType === 'EntrepriseAgree' ? 'Entreprise' : formikForm.values.newPerson.type}
                  setSelectedOption={(newVal): void => handleTypeValue(newVal as TypePersonne | 'Entreprise')}
                />
                {
                  personType !== 'Assure' &&
                    <IntervenantContainer>
                      {
                        personType === 'Intervenant' &&
                          <>
                            <SegmentedButtons
                              options={actionOptions}
                              selectedOption={String(formikForm.values.newPerson.saisieRapide)}
                              setSelectedOption={(newVal): void => handleValue('saisieRapide', newVal === 'true')}
                            />
                            <FormBoldTitle required>
                              Tiers lié
                            </FormBoldTitle>
                            <Field
                              component={Select}
                              name="newPerson.tiersLie"
                              displayEmpty
                              value={formikForm.values.newPerson.tiersLie || ''}
                              renderValue={verifySelectFieldValue(formikForm.values.newPerson.tiersLie)}
                            >
                              {
                                formikForm.values.acteurs.filter((person) => !person.isDeleted).map((value: ActeurTraveller) => (
                                  <MenuItem
                                    value={value.id || ''}
                                    key={value.id}
                                  >
                                    {value.nom}
                                  </MenuItem>
                                ))
                              }
                            </Field>
                          </>
                      }
                      {
                        (personType === 'Intervenant' && !formikForm.values.newPerson.saisieRapide) &&
                          <FlexContainer>
                            <LittleContainer>
                              <FormBoldTitle required>
                                Famille
                              </FormBoldTitle>
                              <Field
                                component={Select}
                                name="newPerson.recherche.famille"
                                displayEmpty
                                value={formikForm.values.newPerson.recherche.famille || ''}
                                renderValue={verifySelectFieldValue(formikForm.values.newPerson.recherche.famille)}
                                formHelperText={{ children: formikForm.touched.newPerson?.recherche?.famille !== undefined && formikForm.errors.newPerson?.recherche?.famille !== undefined ? formikForm.errors.newPerson?.qualite : null }}
                                formControl={{ error: formikForm.touched.newPerson?.recherche?.famille && formikForm.errors.newPerson?.recherche?.famille !== undefined }}
                                disabled={!isConnected}
                              >
                                {
                                  stakeholderFamilleAnnuaireList.map((value) => (
                                    <MenuItem
                                      value={value.code}
                                      key={value.code}
                                    >
                                      {value.libelle}
                                    </MenuItem>
                                  ))
                                }
                              </Field>
                            </LittleContainer>
                          </FlexContainer>
                      }
                    </IntervenantContainer>
                }
                {
                  ((personType !== 'EntrepriseAgree' && formikForm.values.newPerson.saisieRapide) || personType === 'Assure') &&
                    <>
                      <TwoGridNoMarginContainer>
                        <FormBoldTitle required>
                          Nom
                        </FormBoldTitle>
                        <FormBoldTitle>
                          Prénom
                        </FormBoldTitle>
                      </TwoGridNoMarginContainer>
                      <GridContainer>
                        <Field
                          component={TextField}
                          placeholder="Nom"
                          name="newPerson.nom"
                        />
                        <Field
                          component={TextField}
                          placeholder="Prénom"
                          name="newPerson.prenom"
                        />
                      </GridContainer>
                      <FlexContainer>
                        <LittleContainer>
                          <FormBoldTitle required>
                            Qualité
                          </FormBoldTitle>
                          <Field
                            component={Select}
                            name="newPerson.qualite.code"
                            displayEmpty
                            value={formikForm.values.newPerson.qualite?.code || ''}
                            renderValue={verifySelectFieldValue(formikForm.values.newPerson.qualite?.code)}
                            formHelperText={{ children: formikForm.touched.newPerson?.qualite !== undefined && formikForm.errors.newPerson?.qualite !== undefined ? formikForm.errors.newPerson?.qualite : null }}
                            formControl={{ error: formikForm.touched.newPerson?.qualite && formikForm.errors.newPerson?.qualite !== undefined }}
                          >
                            {
                              (personType === 'Assure' ? qualiteList : familleAnnuaireList)?.map((value) => (
                                <MenuItem
                                  value={value.code}
                                  key={value.code}
                                >
                                  {value.libelle}
                                </MenuItem>
                              ))
                            }
                          </Field>
                        </LittleContainer>
                        {
                          personType === 'Assure' &&
                            <LittleContainer>
                              <FormBoldTitle>
                                Rôle
                              </FormBoldTitle>
                              <Field
                                component={Select}
                                name="newPerson.role.code"
                                displayEmpty
                                value={formikForm.values.newPerson.role?.code || ''}
                                renderValue={verifySelectFieldValue(formikForm.values.newPerson.role?.code)}
                              >
                                {
                                  roleList.filter((role) => role.code !== '31').map((value) => (
                                    <MenuItem
                                      value={value.code}
                                      key={value.code}
                                    >
                                      {value.libelle}
                                    </MenuItem>
                                  ))
                                }
                              </Field>
                            </LittleContainer>
                        }
                      </FlexContainer>
                      <FlexContainer>
                        {
                          personType === 'Assure' &&
                            <LittleContainer>
                              <FormBoldTitle>
                                Police
                              </FormBoldTitle>
                              <Field
                                component={TextField}
                                placeholder="Police"
                                name="newPerson.police"
                              />
                            </LittleContainer>
                        }
                        <LittleContainer>
                          <FormBoldTitle>
                            Référence
                          </FormBoldTitle>
                          <Field
                            component={TextField}
                            placeholder="Référence"
                            name="newPerson.reference"
                          />
                        </LittleContainer>
                      </FlexContainer>
                    </>
                }
                <FormBoldTitle required>
                  {`Lors du ${formikForm.values.rendezVous[0]?.libelle}`}
                </FormBoldTitle>
                <FlexContainer>
                  <LittleContainer>
                    <CheckableButton
                      checked={formikForm.values.newPerson.acteurStatus.present ?? false}
                      onChange={(e, c): void => handleValue('acteurStatus.present', c)}
                      label="Présent"
                    />
                  </LittleContainer>
                  <LittleContainer>
                    <CheckableButton
                      checked={formikForm.values.newPerson.acteurStatus.represente ?? false}
                      onChange={(e, c): void => handleValue('acteurStatus.represente', c)}
                      label="Représentant"
                    />
                  </LittleContainer>
                </FlexContainer>
                {
                  formikForm.values.newPerson.acteurStatus.represente &&
                    <>
                      <FormBoldTitle>
                        Nom du représentant
                      </FormBoldTitle>
                      <Field
                        component={TextField}
                        value={formikForm.values.newPerson.acteurStatus.representePar || ''}
                        placeholder="Nom prénom"
                        name="newPerson.acteurStatus.representePar"
                      />
                    </>
                }
                {
                  (personType === 'EntrepriseAgree' ||
                  (personType === 'Intervenant' && !formikForm.values.newPerson.saisieRapide)) &&
                    <>
                      <FlexContainer>
                        <LittleContainer>
                          <FormBoldTitle>
                            Code
                          </FormBoldTitle>
                          <Field
                            component={TextField}
                            placeholder="Code"
                            name="newPerson.recherche.code"
                            disabled={!isConnected}
                          />
                        </LittleContainer>
                        <LittleContainer>
                          <FormBoldTitle>
                            Téléphone
                          </FormBoldTitle>
                          <Field
                            component={TextField}
                            placeholder="Téléphone"
                            name="newPerson.recherche.telephone"
                            disabled={!isConnected}
                          />
                        </LittleContainer>
                      </FlexContainer>
                      <FlexContainer>
                        <LittleContainer>
                          <FormBoldTitle>
                            Département
                          </FormBoldTitle>
                          <Field
                            component={TextField}
                            placeholder="Département"
                            name="newPerson.recherche.departement"
                            disabled={!isConnected}
                          />
                        </LittleContainer>
                        <LittleContainer>
                          <FormBoldTitle>
                            Ville
                          </FormBoldTitle>
                          <Field
                            component={TextField}
                            placeholder="Ville"
                            name="newPerson.recherche.ville"
                            disabled={!isConnected}
                          />
                        </LittleContainer>
                      </FlexContainer>
                      <SearchButtonContainer>
                        <LongButton
                          variant="contained"
                          onClick={onSearchClick}
                          disabled={!isConnected}
                        >
                          Rechercher
                        </LongButton>
                        <NoFoundMessage>
                          {noFoundMessage}
                        </NoFoundMessage>
                      </SearchButtonContainer>
                      <Card>
                        <Table
                          rows={personList}
                          setRows={(rows) => setPersonList(rows as AnnuaireResume[])}
                          colHeaders={cols}
                          limit={25}
                        />
                      </Card>
                    </>
                }
              </FormContainer>
          }
        </LoadingOverlay>
      </PageContainer>
    </>
  )
}

export default AddStakeholderPage

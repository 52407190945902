/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'
import {
  usePostViewedInternalExchangeMutation,
  usePostNotViewedInternalExchangeMutation,
} from 'store/api'

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'
import CaseSidebarItemComponents from 'layouts/CaseSidebar/CaseSidebarComponents/CaseSidebarItemComponents/CaseSidebarItemComponents'
import ShowMoreLessText from 'components/ShowMoreLessText/ShowMoreLessText'
import ClickableColoredSquareChip from 'components/ColoredSquareChip/ClickableColoredSquareChip'

/* Type imports ------------------------------------------------------------- */
import type { EchangeInterne } from 'API/__generated__/Api'

/* Component declaration ---------------------------------------------------- */
interface CaseSidebarNotificationItemProps {
  notification: EchangeInterne;
  strLenLimit: number;
}

const CaseSidebarNotificationItem: React.FC<CaseSidebarNotificationItemProps> = ({
  notification,
  strLenLimit,
}) => {

  const [
    submitViewed,
    { isLoading: isSubmittingViewed },
  ] = usePostViewedInternalExchangeMutation()

  const [
    submitNotViewed,
    { isLoading: isSubmittingNotViewed },
  ] = usePostNotViewedInternalExchangeMutation()

  const handleChipClick = (): void => {
    if (notification.lu) {
      submitNotViewed({ id: notification.id }).catch(console.error)
    } else {
      submitViewed({ id: notification.id }).catch(console.error)
    }
  }

  return (
    <CaseSidebarItemComponents.Card>
      <CaseSidebarItemComponents.Title>
        {notification.emetteur?.nom}
        <CaseSidebarItemComponents.DateContainer>
          {DateUtils.apiStrToLocalDateString(notification.date, { hour: '2-digit', minute: '2-digit' })}
          {
            isSubmittingNotViewed || isSubmittingViewed ?
              <CircularProgress /> :
              <ClickableColoredSquareChip
                color={notification.lu ? 'green' : 'red'}
                onClick={handleChipClick}
              >
                {notification.lu ? 'Lu' : 'Nouveau'}
              </ClickableColoredSquareChip>
          }
        </CaseSidebarItemComponents.DateContainer>
      </CaseSidebarItemComponents.Title>
      <CaseSidebarItemComponents.Object>
        {notification.objet}
      </CaseSidebarItemComponents.Object>
      <ShowMoreLessText
        content={notification.message}
        strLenLimit={strLenLimit}
      />
    </CaseSidebarItemComponents.Card>
  )
}

export default CaseSidebarNotificationItem

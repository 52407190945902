/* Framework imports -------------------------------------------------------- */
import React, {
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useNavigate } from 'react-router-dom'
import { useMediaLibrary } from 'helpers/hooks/useMediaLibrary'
import { getMediaFileType } from 'helpers/getMediaFileType'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'
import { BottomSheet } from 'react-spring-bottom-sheet'
import CloseButton from 'components/CloseButton/CloseButton'
import MediaLibraryData from './MediaLibraryData/MediaLibraryData'
import MediaLibraryRenameModal from './MediaLibraryRenameModal/MediaLibraryRenameModal'

/* Type imports ------------------------------------------------------------- */
import type { BottomSheetRef } from 'react-spring-bottom-sheet'
import type { FileType } from 'helpers/getMediaFileType'

/* Type declaration ----------------------------------- */

/* Styled components ----------------------------- */
const HeaderContainer = styled.div`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.2rem;
  text-align: left;
  text-transform: uppercase;
`

const ButtonContainer = styled(Button)`
  margin-left: 20px;
  min-width: 120px;
`

const BottomSheetContainer = styled(BottomSheet)`
  [data-rsbs-overlay],
  [data-rsbs-backdrop],
  [data-rsbs-root]:after {
    z-index: 202;
  }

  &.opened {
    [data-rsbs-scroll] > div {
      height: 100%;
      padding-bottom: 5rem;
    }
  }
`

/* Component declaration ---------------------------------------------------- */
interface MediaLibraryBottomSheetProps {}

const MediaLibraryBottomSheet: React.FC<MediaLibraryBottomSheetProps> = () => {
  const navigate = useNavigate()
  const library = useMediaLibrary()
  const sheetRef = useRef<BottomSheetRef | null>(null)
  const [ openRenameModal, setOpenRenameModal ] = useState<boolean>(false)
  const [ sheetHeight, setSheetHeight ] = useState<number>(0)

  const displayType: FileType = useMemo(() => {
    if (library.state.document === null || library.state.document === undefined) {
      return 'img'
    }

    return getMediaFileType(library.state.document)
  }, [ library.state.document ])

  if (library.state.document === null || library.state.document === undefined) {
    return null
  }

  const handleClose = (): void => {
    library.closeMediaLibrary()
  }

  const handleEdit = (): void => {
    handleClose()
    navigate(`/mediatheque/${library.state.document?.id}`)
  }

  const handleRename = (): void => {
    setOpenRenameModal(true)
  }

  const getName = (): string => library.state.document?.libelle ?? 'Sans nom'

  const onSpringEnd = (): void => {
    setSheetHeight(sheetRef.current?.height || 0)
  }

  return (
    <BottomSheetContainer
      open={library.state.mediaLibraryOpen}
      ref={sheetRef}
      blocking={false}
      defaultSnap={({ maxHeight }): number => maxHeight / 2}
      snapPoints={
        ({ maxHeight }): number[] => [
          maxHeight,
          maxHeight / 2,
          150,
        ]
      }
      onSpringEnd={onSpringEnd}
      className={`${library.state.mediaLibraryOpen && sheetRef.current !== null ? 'opened' : 'closed'}`}
      header={
        <HeaderContainer>
          {getName()}
          <ButtonContainer
            variant="outlined"
          >
            Supprimer
          </ButtonContainer>
          <ButtonContainer
            variant="contained"
            onClick={handleEdit}
          >
            Editer
          </ButtonContainer>
          <ButtonContainer
            variant="contained"
            onClick={handleRename}
          >
            Renommer
          </ButtonContainer>
          <CloseButton handleClose={handleClose} />
        </HeaderContainer>
      }
    >
      <MediaLibraryData
        displayType={displayType}
        sheetHeight={sheetHeight}
        document={library.state.document}
      />
      {
        openRenameModal &&
          <MediaLibraryRenameModal
            fileName={getName()}
            open={openRenameModal}
            handleClose={() => setOpenRenameModal(false)}
          />
      }
    </BottomSheetContainer>
  )
}

export default MediaLibraryBottomSheet

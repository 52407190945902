/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'
import { isValidString } from 'helpers/isValidString'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  CardContent,
} from '@mui/material'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'

/* Type imports ------------------------------------------------------------- */
import type { ContratRex } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */

/* Internal variables ------------------------------------------------------- */
const getFranchiseLibelle = (franchise?: string | null): string | undefined => {
  if (isValidString(franchise)) {
    return `${franchise} - `
  }
  return
}

const getFranchiseMontant = (montant?: number): string | undefined => {
  if (montant !== undefined && montant > 0) {
    return `${montant}€`
  }
  return
}

const getFranchiseTaux = (taux?: number): string | undefined => {
  if (taux !== undefined && taux > 0) {
    return `${taux}%`
  }
  return
}

/* Styled components -------------------------------------------------------- */
const CardContainer = styled(Card)`
  margin-bottom: 10px;
  font-size: 13px;
`

const ContentContainer = styled(CardContent)`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr .75fr .75fr;
  gap: 5px;
  align-items: center;
  justify-content: stretch;
  align-items: flex-start;
  padding-bottom: 15px !important;
  padding-top: 15px !important;
`

const GarantieContentContainer = styled(ContentContainer)`
  grid-template-columns: 1fr 3.5fr;
`

const GarantieListContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  /* gap: 5px; */
  align-items: center;
  justify-content: stretch;
  align-items: flex-start;
  margin-bottom: 15px;
`

const PlafondListContainer = styled(GarantieListContainer)`
  grid-template-columns: 1fr .75fr;
  margin-bottom: 0px;
`

const BoldText = styled.div`
  font-weight: bold;
`

const BoldUppercaseText = styled.div`
  font-weight: bold;
  text-transform: uppercase;
`

/* Component declaration ---------------------------------------------------- */
interface ContractInfoProps {
  contractInfo: ContratRex;
}

const RiskComplianceContractInfo: React.FC<ContractInfoProps> = ({ contractInfo }) => {
  return (
    <div>
      <FormBoldTitle bigger>
        Infos contrat
      </FormBoldTitle>
      <CardContainer>
        <ContentContainer>
          Nom du contrat
          <BoldText>
            {contractInfo.nom}
          </BoldText>
        </ContentContainer>
      </CardContainer>
      <CardContainer>
        <ContentContainer>
          Date de souscription
          <BoldText>
            {DateUtils.apiStrToLocalDateString(contractInfo.dateSouscription)}
          </BoldText>
        </ContentContainer>
      </CardContainer>
      <CardContainer>
        <ContentContainer>
          Franchises
          <BoldText>
            Franchise absolue
          </BoldText>
          <BoldText>
            {(contractInfo.franchise ?? -1) < 0 ? 'Inconnue' : `${contractInfo.franchise}€` }
          </BoldText>
        </ContentContainer>
      </CardContainer>
      {
        contractInfo.plafondsDetailDeBien !== undefined &&
        contractInfo.plafondsDetailDeBien !== null &&
        contractInfo.plafondsDetailDeBien.length > 0 && (
          <CardContainer>
            <ContentContainer>
              Plafonds
              {
                contractInfo.plafondsDetailDeBien?.map((plafond, index) => (
                  <React.Fragment key={`title-${plafond.code}-${index}`}>
                    <BoldText>
                      {plafond.libelle}
                    </BoldText>
                    <BoldText>
                      {plafond.value}
                      €
                    </BoldText>
                    <div />
                    <div />
                  </React.Fragment>
                ))
              }
            </ContentContainer>
          </CardContainer>
        )
      }
      {
        contractInfo.garanties !== undefined &&
        contractInfo.garanties !== null &&
        contractInfo.garanties.length > 0 && (
          <CardContainer>
            <GarantieContentContainer>
              Garanties
              <div>
                {
                  contractInfo.garanties?.map((garantie, index) => (
                    <GarantieListContainer key={`${garantie.code}-${index}`}>
                      <PlafondListContainer>
                        <BoldUppercaseText>
                          {garantie.libelle}
                        </BoldUppercaseText>
                        <BoldText>
                          {
                            garantie.franchises?.map((franchise, franchiseIndex) => (
                              <div key={`${franchise.typeFranchise?.code}-${franchiseIndex}`}>
                                {getFranchiseLibelle(garantie.franchises?.[franchiseIndex]?.typeFranchise?.libelle)}
                                {getFranchiseMontant(garantie.franchises?.[franchiseIndex]?.montantFranchise)}
                                {getFranchiseTaux(garantie.franchises?.[franchiseIndex]?.tauxFranchise)}
                              </div>
                            ))
                          }
                        </BoldText>
                      </PlafondListContainer>
                      {
                        garantie.plafonds?.map((plafond, plafondIndex) => (
                          <PlafondListContainer key={`${plafond.code}-${plafondIndex}`}>
                            <BoldText>
                              {plafond.libelle}
                            </BoldText>
                            <BoldText>
                              {`${plafond.value}€`}
                            </BoldText>
                          </PlafondListContainer>
                        ))
                      }
                    </GarantieListContainer>
                  ))
                }
              </div>
            </GarantieContentContainer>
          </CardContainer>
        )
      }
    </div>
  )
}

export default RiskComplianceContractInfo

/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'

/* Type imports ------------------------------------------------------------- */

/* Styled components -------------------------------------------------------- */
interface TabButtonProps {
  selected: boolean;
}

const TabButton = styled(Button)<TabButtonProps>`
  color: ${(props) => props.selected ? props.theme.palette.primary.main : props.theme.palette.secondary.main};
  border: ${(props) => props.selected ? `2px solid ${props.theme.palette.primary.main}` : `1px solid ${props.theme.colors.grey}`};
  font-weight: ${(props) => props.selected ? '600' : '200'};
  font-size: 1.1rem;
  min-width: 140px;
  min-height: 60px;

  &:hover {
    border: ${(props) => props.selected ? `2px solid ${props.theme.palette.primary.main}` : `1px solid ${props.theme.palette.secondary.main}`};
  }
`

/* Component declaration ---------------------------------------------------- */
interface CaseTravelerTabButtonProps {
  title: string;
  selected: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

const CaseTravelerTabButton: React.FC<CaseTravelerTabButtonProps> = ({ selected, title, onClick, disabled }) => {

  return (
    <TabButton
      onClick={onClick}
      variant="outlined"
      size="large"
      id={title}
      selected={selected}
      disabled={disabled}
    >
      {title}
    </TabButton>
  )
}

export default CaseTravelerTabButton

/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'
import CaseSidebarArrowTitle from '../CaseSidebarComponents/CaseSidebarArrowTitle/CaseSidebarArrowTitle'
import CaseSidebarNotificationItem from './CaseSidebarNotificationItem'

/* Type imports ------------------------------------------------------------- */
import type { EchangeInterne } from 'API/__generated__/Api'

/* Internal variables ------------------------------------------------------- */
const STR_LEN_DEFAULT_LIMIT: number = 250

/* Styled components -------------------------------------------------------- */

/* Component declaration ---------------------------------------------------- */
interface CaseSidebarNotificationProps {
  notifications?: EchangeInterne[];
  isFetching: boolean;
  strLenLimit?: number;
  setOpenedMenu: () => void;
}

const CaseSidebarNotification: React.FC<CaseSidebarNotificationProps> = ({
  notifications = [],
  isFetching,
  strLenLimit = STR_LEN_DEFAULT_LIMIT,
  setOpenedMenu,
}) => {
  return (
    <>
      <CaseSidebarArrowTitle
        setOpenedMenu={setOpenedMenu}
        title="Notifications"
      />
      {
        isFetching ?
          <CircularProgress /> :
          notifications.filter((notification) => !notification.lu).map((notification: EchangeInterne, index: number) => (
            <CaseSidebarNotificationItem
              key={`notification-${notification.id}-${index}`}
              notification={notification}
              strLenLimit={strLenLimit}
            />
          ))
      }
    </>
  )
}

export default CaseSidebarNotification

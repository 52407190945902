/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useParams } from 'react-router-dom'
import { useAppDispatch } from 'store/hooks'
import { openMediaLibrary } from 'store/slices/mediaLibrarySlice'
import { useGetCaseDocumentsQuery } from 'store/api'
import { useMediaLibrary } from 'helpers/hooks/useMediaLibrary'
import { isValidString } from 'helpers/isValidString'
import { isEditableFileType } from 'helpers/getMediaFileType'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  CircularProgress,
} from '@mui/material'
import ImportAttachmentModal from 'components/ImportAttachmentModal/ImportAttachmentModal'
import Drawer from 'components/Drawer/Drawer'
import DrawerContent from 'components/Drawer/DrawerContent'
import VignetteRendererWithFilename from 'components/MediaRenderer/VignetteRendererWithFilename'
import DocumentNameEditorForm from 'components/MediaRenderer/DocumentNameEditorForm'
import AttachmentButton from 'components/AttachmentButton/AttachmentButton'
import MediaSidebarHeaderSubMenu from './MediaSidebarHeader/MediaSidebarHeader'

/* Type imports ------------------------------------------------------------- */
import type { PieceJointe } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */

/* Internal variables ------------------------------------------------------- */

/* Styled components -------------------------------------------------------- */
const DrawerContentContainer = styled(DrawerContent)`
  margin-top: 0px;
  min-height: calc(100vh - 115px);
`

const GridContainer = styled.div`
  display: grid;
  gap: 10px;
  align-items: stretch;
  justify-content: stretch;

  grid-template-columns: repeat(auto-fit, minmax(100px, auto));
`

const ButtonContainer = styled(Button)`
  min-width: 120px;
`

const Span = styled.span`
  min-width: 120px !important;
`

/* Component declaration ---------------------------------------------------- */
interface MediaSidebarProps {}

const MediaSidebar: React.FC<MediaSidebarProps> = () => {
  const { caseId = '' } = useParams<{caseId: string}>()
  const dispatch = useAppDispatch()
  const mediaLibrary = useMediaLibrary()

  const [ openEditMenu, setOpenEditMenu ] = useState<boolean>(false)
  const [ openImportModal, setOpenImportModal ] = useState<boolean>(false)
  const [ files, setFiles ] = useState<File[]>([])

  const view: HTMLElement | null = document.getElementById('media-sidebar')

  const {
    currentData: documents = [],
    isFetching: isFetchingDocuments,
  } = useGetCaseDocumentsQuery({ dossier: caseId })

  useEffect(() => {
    setOpenEditMenu(false)
  }, [ mediaLibrary.state.mediaSidebarOpen ])

  useEffect(() => {
    view?.scroll({
      top: 0,
      left: 0,
      behavior: 'auto',
    })
  }, [ openEditMenu, view ])

  const handleMediaClick = (document: PieceJointe): void => {
    if (isEditableFileType(document)) {
      dispatch(openMediaLibrary(document))
      mediaLibrary.closeMediaSidebar()
    } else if (isValidString(document.url)) {
      window.open(document.url || '', '_blank', 'noreferrer')
    }
  }

  const handleCloseImportModal = (): void => {
    setOpenImportModal(false)
    setFiles([])
  }

  const handleOnFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files?.length !== undefined && e.target.files.length > 0) {
      setFiles([ ...Object.values(e.target.files ?? {}) ])
      setOpenImportModal(true)
    }
  }

  const handleClickBack = (): void => {
    openEditMenu ? setOpenEditMenu(false) : mediaLibrary.closeMediaSidebar()
  }

  const getIdNumber = (id: string): number => parseInt(id.slice(id.lastIndexOf('-') + 1))

  const documentList = [ ...documents ].sort((a, b) => getIdNumber(b.id ?? '-0') - getIdNumber(a.id ?? '-0'))

  return (
    <Drawer
      open={mediaLibrary.state.mediaSidebarOpen}
      onClose={() => { mediaLibrary.closeMediaSidebar() }}
      width="500px"
      id="media-sidebar"
    >
      <MediaSidebarHeaderSubMenu
        title={`Médias${openEditMenu ? ' / Modification' : ''}`}
        caseId={caseId}
        onClickBack={handleClickBack}
      >
        <>
          {
            openEditMenu === false &&
              <ButtonContainer
                variant="outlined"
                onClick={() => setOpenEditMenu(true)}
              >
                Editer
              </ButtonContainer>
          }
          <AttachmentButton onChange={handleOnFileChange}>
            <Button
              variant="contained"
              component={Span}
            >
              Importer
            </Button>
          </AttachmentButton>
        </>
      </MediaSidebarHeaderSubMenu>
      <DrawerContentContainer>
        {isFetchingDocuments && <CircularProgress />}
        {
          openEditMenu === false ?
            <GridContainer>
              {
                documentList.map((document, index) => (
                  <VignetteRendererWithFilename
                    key={`${document.fileName}-${index}`}
                    document={document}
                    onClick={() => handleMediaClick(document)}
                    width="min(100px, 100%)"
                    height="100px"
                  />
                ))
              }
            </GridContainer> :
            documentList.map((document, index) => (
              <DocumentNameEditorForm
                key={`${document.fileName}-${index}`}
                document={document}
                onEdition={() => handleMediaClick(document)}
              />
            ))
        }
      </DrawerContentContainer>
      {
        files.length > 0 &&
          <ImportAttachmentModal
            open={openImportModal}
            handleClose={handleCloseImportModal}
            files={files}
            setFiles={setFiles}
            caseId={caseId}
          />
      }
    </Drawer>
  )
}

export default MediaSidebar

/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */

/* Component imports -------------------------------------------------------- */
import {
  Button,
  MenuItem,
  Select,
} from '@mui/material'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'

/* Styled components -------------------------------------------------------- */
const GridContainer = styled.div`
  display: grid;
  gap: 20px;
  align-items: end;
  justify-content: stretch;

  grid-template-columns: repeat(3, minmax(0, 1fr));
`

const SelectLimitContainer = styled.div`
  justify-self: right;
`

/* Component declaration ---------------------------------------------------- */
interface CaseListPageChangerProps {
  disablePrev: boolean;
  disableNext: boolean;
  limitValue: number;
  onClickPrev: () => void;
  onClickNext: () => void;
  onLimitChange: (value: number) => void;
  limitList?: number[];
}

const CaseListPageChanger: React.FC<CaseListPageChangerProps> = ({
  disablePrev,
  disableNext,
  onClickPrev,
  onClickNext,
  limitValue,
  onLimitChange,
  limitList = [ 5, 10, 20, 50 ],
}) => {

  return (
    <GridContainer>
      <Button
        variant="outlined"
        disabled={disablePrev}
        onClick={onClickPrev}
        startIcon={<ArrowBackIosNewRoundedIcon />}
      >
        Dossiers précédents
      </Button>
      <Button
        variant="outlined"
        disabled={disableNext}
        onClick={onClickNext}
        endIcon={<ArrowForwardIosRoundedIcon />}
      >
        Dossiers suivants
      </Button>
      <SelectLimitContainer>
        <Select
          value={limitValue}
          onChange={(e): void => onLimitChange(parseInt(e.target.value.toString()))}
          size="small"
        >
          {
            limitList.map((limit, index) => (
              <MenuItem
                value={limit}
                key={`${limit}-${index}`}
              >
                {limit}
              </MenuItem>
            ))
          }
        </Select>
      </SelectLimitContainer>
    </GridContainer>
  )
}

export default CaseListPageChanger

/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Select,
  MenuItem,
  OutlinedInput,
} from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'
import DocumentStatusConfirmationModal from './DocumentStatusConfirmationModal'

/* Type imports ------------------------------------------------------------- */
import type { SelectChangeEvent } from '@mui/material'
import { TypePJ } from 'API/__generated__/Api'

/* Type declaration ----------------------------------- */
type StatusColor = {color: string; backgroundColor: string}
type Color = {[x: string]: StatusColor}

/* Internal Variables ----------------------------------- */
const colors: Color = {
  [TypePJ.DocEvenementielBrouillon]: {
    color: '#025C97',
    backgroundColor: '#E5F4FE',
  },
  [TypePJ.DocEvenementiel]: {
    color: '#125A26',
    backgroundColor: '#D5F5DE',
  },
}

/* Styled components ---------------------------------- */
interface DocumentStatusColorProps {
  customcolor: string;
  custombackgroundcolor?: string;
}

const SelectColoredArrow = styled(KeyboardArrowDown)<DocumentStatusColorProps>`
  color: ${(props) => props.customcolor};
  font-size: 28px;
  margin-left: -30px;
  position: absolute !important;
  right: 0 !important;
  pointer-events: none !important;
`

const ColoredOutlinedInput = styled(OutlinedInput)<DocumentStatusColorProps>`
  color: ${(props) => props.customcolor};
  background-color: ${(props) => props.custombackgroundcolor};
  font-weight: bold;
  border: 2px solid ${(props) => props.customcolor};

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  &:hover {
    background-color: ${(props) => `${props.custombackgroundcolor}55`};
  }
`

const ColoredButton = styled(Button)<DocumentStatusColorProps>`
  color: ${(props) => props.customcolor};
  background-color: ${(props) => props.custombackgroundcolor};
  font-size: 16px;
  border: 2px solid ${(props) => props.customcolor};
  padding: 5px 15px;
`

const ColoredMenuItem = styled(MenuItem)<DocumentStatusColorProps>`
  color: ${(props) => props.customcolor};
`

const DocumentStatusContainer = styled.div`
  margin-left: 20px;
`

/* Component declaration ---------------------------------------------------- */
interface DocumentStatusButtonProps {
  status: TypePJ;
  disabled?: boolean;
}

const DocumentStatusButton: React.FC<DocumentStatusButtonProps> = ({ status, disabled = false }) => {
  const [ newStatus, setNewStatus ] = useState<TypePJ>(status)
  const [ confirmationModalOpen, setConfirmationModalOpen ] = useState<boolean>(false)

  const handleChange = (event: SelectChangeEvent): void => {
    if (event.target.value as TypePJ !== TypePJ.DocEvenementiel) {
      setNewStatus(event.target.value as TypePJ)
    }
  }

  const handleClose = (isDocumentDefinitive?: boolean): void => {
    if (isDocumentDefinitive) {
      setNewStatus(TypePJ.DocEvenementiel)
    }
    setConfirmationModalOpen(false)
  }

  return (
    <DocumentStatusContainer>
      {
        newStatus === TypePJ.DocEvenementiel ?
          <ColoredButton
            customcolor={colors[newStatus].color}
            custombackgroundcolor={colors[newStatus].backgroundColor}
          >
            Définitif
          </ColoredButton> :
          <Select
            value={newStatus}
            onChange={handleChange}
            disabled={disabled}
            input={
              <ColoredOutlinedInput
                customcolor={colors[newStatus].color}
                custombackgroundcolor={colors[newStatus].backgroundColor}
                size="small"
              />
            }
            IconComponent={(): React.ReactElement => <SelectColoredArrow customcolor={colors[newStatus].color} />}
          >
            <ColoredMenuItem
              key={TypePJ.DocEvenementielBrouillon}
              value={TypePJ.DocEvenementielBrouillon}
              customcolor={colors[TypePJ.DocEvenementielBrouillon].color}
            >
              Brouillon
            </ColoredMenuItem>
            <ColoredMenuItem
              key={TypePJ.DocEvenementiel}
              value={TypePJ.DocEvenementiel}
              customcolor={colors[TypePJ.DocEvenementiel].color}
              onClick={() => setConfirmationModalOpen(true)}
            >
              Définitif
            </ColoredMenuItem>
          </Select>
      }
      <DocumentStatusConfirmationModal
        open={confirmationModalOpen}
        handleClose={handleClose}
      />
    </DocumentStatusContainer>
  )
}

export default DocumentStatusButton

/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'
/* Module imports ----------------------------------------------------------- */

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
} from '@mui/icons-material'
import { DatePicker } from '@mui/x-date-pickers'

/* PlanningCalendarButtonsContainer component ---------- */
const PlanningCalendarButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const PlanningCalendarTitleContainer = styled.div`
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;
  text-transform: uppercase;
  font-size: 2rem;

  margin: 0 .25rem;

  @media(max-width: 800px) {
    font-size: 1.2rem;
  }
`

const ArrowButton = styled(Button)`
  height: 50px;
`

const CalendarButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  @media ${(props) => props.theme.media.mobile.main} {
    flex-direction: column;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`

const TodayButton = styled(Button)`
  height: 48px;
  width: 140px;
`

const CalendarInput = styled(DatePicker)`
  width: 160px;
`

/* Component declaration ---------------------------------------------------- */
interface PlanningCalendarButtonsProps {
  calendarViewTitle: string;
  onPreviousClicked: React.MouseEventHandler<HTMLButtonElement>;
  onTodayClicked: React.MouseEventHandler<HTMLButtonElement>;
  onNextClicked: React.MouseEventHandler<HTMLButtonElement>;
  dateToClick: Date | null;
  onDateClicked: (value: Date | null) => void;
}

const PlanningCalendarButtons: React.FC<PlanningCalendarButtonsProps> = ({
  calendarViewTitle,
  onPreviousClicked,
  onTodayClicked,
  onNextClicked,
  dateToClick,
  onDateClicked,
}) => {
  return (
    <PlanningCalendarButtonsContainer>
      <CalendarButtonsContainer>
        <ButtonsContainer>
          <ArrowButton
            onClick={onPreviousClicked}
            color="secondary"
            id="previous"
          >
            <ArrowBackIosRounded fontSize="large" />
          </ArrowButton>
          <PlanningCalendarTitleContainer id="planning-calendar-period">
            {calendarViewTitle}
          </PlanningCalendarTitleContainer>
          <ArrowButton
            onClick={onNextClicked}
            color="secondary"
            id="next"
          >
            <ArrowForwardIosRounded fontSize="large" />
          </ArrowButton>
        </ButtonsContainer>
        <CalendarInput
          value={dateToClick}
          onChange={onDateClicked}
        />
      </CalendarButtonsContainer>
      <TodayButton
        variant="outlined"
        onClick={onTodayClicked}
      >
        Aujourd'hui
      </TodayButton>
    </PlanningCalendarButtonsContainer>
  )
}

export default PlanningCalendarButtons

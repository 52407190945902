/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom'
import {
  useGetConventionListQuery,
  useGetLettreAcceptationListQuery,
} from 'store/api'
import { enumToSegmentedButtonOptions } from 'helpers/enumToSegmentedButtonOptions'
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  CardContent,
  MenuItem,
} from '@mui/material'
import { Field } from 'formik'
import { Select } from 'formik-mui'
import Loader from 'components/Loader/Loader'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import TravelerLargeTitle from 'components/TravelerLargeTitle/TravelerLargeTitle'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'
import CheckableButton from 'components/CheckableButton/CheckableButton'
import RecourseCard from './RecourseCard'

/* Type imports ------------------------------------------------------------- */
import type { Garantie } from 'API/__generated__/Api'
import {
  TypeResponsabilite,
  Responsabilite,
} from 'API/__generated__/Api'
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'
import type { TravelerForm } from 'types/TravelerForm'

/* Styled components -------------------------------------------------------- */
const BoldUppercaseTitle = styled(FormBoldTitle)`
  text-transform: uppercase;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const FormContainer = styled.div`
  padding-bottom: 40px;
`

const RecoursTitle = styled(FormBoldTitle)`
  display: flex;
  margin-bottom: 20px;
  margin-top: 30px;
  justify-content: space-between;
  align-items: center;
`

const CardContainer = styled(Card)`
  margin-bottom: 20px;
  font-size: 14px;
`

const CardContentContainer = styled(CardContent)`
  padding-top: 0px;
`

const SmallBoldTitle = styled(FormBoldTitle)`
  font-size: 14px;
`

const ConventionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  align-items: stretch;
  justify-content: stretch;

  @media screen and (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`

const FlexCheckableButton = styled(CheckableButton)`
  height: 100%;

  label {
    padding: 5px 0px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface RecoursePageProps {}

const RecoursePage: React.FC<RecoursePageProps> = () => {
  const formikForm = useOutletContext<TravelerForm>()
  const navigate = useNavigate()
  const { caseId = '' } = useParams<{ caseId: string }>()

  const {
    currentData: conventions = [],
    isFetching: isFetchingConventions,
  } = useGetConventionListQuery(caseId)

  const {
    currentData: lettreAccepationList = [],
    isFetching: isFetchingLettreAccepationList,
  } = useGetLettreAcceptationListQuery()

  const addRecourse = (): void => {
    navigate(`/dossiers/${caseId}/traveller/nouveau/recours`)
  }

  const handleValue = (type: string, value?: string | boolean | string[] | Garantie[]): void => {
    formikForm.setFieldValue(type, value)
  }

  const handleDelete = (acteurIndex: number, recourseIndex: number) => {
    const acteurs = structuredClone(formikForm.values.acteurs)

    acteurs[acteurIndex].recours?.splice(recourseIndex, 1)
    formikForm.setFieldValue('acteurs', acteurs)
  }

  const handleCheckedResponsabilityButton = (value: TypeResponsabilite, checked: boolean, acteurIndex: number): void => {
    const newValues = [ ...(formikForm.values.acteurs[acteurIndex].responsabiliteRecours?.typeResponsabilites ?? []) ]

    if (!checked) {
      handleValue(`acteurs.[${acteurIndex}].responsabiliteRecours.typeResponsabilites`, newValues.filter((val) => val !== value))
    }
    else {
      newValues.push(value)
      handleValue(`acteurs.[${acteurIndex}].responsabiliteRecours.typeResponsabilites`, newValues)
    }
  }

  const createNewResponsabiliteOptions = (): SegmentedButtonOption[] => enumToSegmentedButtonOptions(Responsabilite)

  if (formikForm.values?.acteurs === undefined) {
    return null
  }

  return (
    <FormContainer>
      {(isFetchingConventions || isFetchingLettreAccepationList) && <Loader />}
      <TravelerLargeTitle>
        Recours
      </TravelerLargeTitle>
      <FormBoldTitle>
        Responsabilité
      </FormBoldTitle>
      {
        formikForm.values.acteurs.filter((person) => !person.isDeleted).map((acteur, index) => (
          <CardContainer key={`responsability-${index}`}>
            <CardContentContainer>
              <BoldUppercaseTitle>
                {acteur.nom}
                {' '}
                {acteur.prenom}
                {' - '}
                {acteur.libelle}
              </BoldUppercaseTitle>
              <SmallBoldTitle>
                Responsabilité
              </SmallBoldTitle>
              <SegmentedButtons
                options={createNewResponsabiliteOptions()}
                selectedOption={acteur.responsabiliteRecours?.responsabilite}
                setSelectedOption={(newVal): void => handleValue(`acteurs.[${index}].responsabiliteRecours.responsabilite`, newVal)}
                disabled={formikForm.values.disabled}
              />
              <SmallBoldTitle>
                Type de responsabilité
              </SmallBoldTitle>
              <ConventionGrid>
                {
                  Object.entries(TypeResponsabilite)
                    .filter((value): boolean => isNaN(Number(value[1])))
                    .map(([ , option ]) => (
                      <CheckableButton
                        key={option}
                        checked={acteur.responsabiliteRecours?.typeResponsabilites.some(((respo) => respo === option)) ?? false}
                        onChange={(e, c) => handleCheckedResponsabilityButton(option, c, index)}
                        label={option}
                      />
                    ))
                }
              </ConventionGrid>
              <SmallBoldTitle>
                Lettre acceptation
              </SmallBoldTitle>
              <Field
                name={`acteurs.[${index}].responsabiliteRecours.lettreAcceptation.code`}
                component={Select}
                displayEmpty
                renderValue={verifySelectFieldValue(acteur.responsabiliteRecours?.lettreAcceptation?.code)}
                value={acteur.responsabiliteRecours?.lettreAcceptation?.code || ''}
                disabled={formikForm.values.disabled}
              >
                {
                  lettreAccepationList.map((value, index) => (
                    <MenuItem
                      value={value.code}
                      key={`${value.code}-${index}`}
                    >
                      {value.libelle}
                    </MenuItem>
                  ))
                }
              </Field>
            </CardContentContainer>
          </CardContainer>
        ))
      }
      <FormBoldTitle>
        Convention applicable
      </FormBoldTitle>
      <ConventionGrid>
        {
          conventions.map((value) => (
            <FlexCheckableButton
              key={value.code}
              checked={formikForm.values.convention?.code === value.code}
              onChange={() => handleValue('convention.code', value.code)}
              label={value.libelle}
              type="radio"
              disabled={formikForm.values.disabled}
            />
          ))
        }
      </ConventionGrid>
      <RecoursTitle>
        Recours
        <Button
          variant="contained"
          onClick={addRecourse}
          disabled={formikForm.values.disabled}
        >
          Ajouter un recours
        </Button>
      </RecoursTitle>
      {
        formikForm.values.acteurs.filter((person) => !person.isDeleted).map((acteur, acteurIndex) => acteur.recours?.map((recourse, recourseIndex) => (
          <RecourseCard
            key={`${recourse.compteur}-${acteur.id}-${recourseIndex}`}
            recourse={recourse}
            personName={`${acteur.nom} ${acteur.prenom || ''}`}
            onDelete={() => handleDelete(acteurIndex, recourseIndex)}
            disabled={formikForm.values.disabled}
          />
        )))
      }
    </FormContainer>
  )
}

export default RecoursePage

/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom'
import {
  useGetFamilleAnnuaireListQuery,
  useGetPersonQualifiantListQuery,
  useGetPersonRoleListQuery,
  useGetPolitenessListQuery,
  useGetQualiteListQuery,
  useGetResponsabilityListQuery,
  useGetTVAAssujettissementListQuery,
  useGetYesNoIndeterminedQuery,
} from 'store/api'
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'
import { isValidString } from 'helpers/isValidString'
import {
  findCodeLabel,
  findCodeLabelUndefined,
} from 'helpers/findCodeLabel'
import { isStakeholderUuid } from 'helpers/uuidUtils'

/* Component imports -------------------------------------------------------- */
import { Field } from 'formik'
import {
  Select,
  TextField,
} from 'formik-mui'
import { MenuItem } from '@mui/material'
import PageContainer from 'layouts/PageContainer/PageContainer'
import HeaderWithBackArrowTwoSmallButtons from 'layouts/MainLayout/Headers/HeaderWithBackArrowTwoSmallButtons'
import HeaderTravelerAction from 'layouts/MainLayout/Headers/HeadersComponents/HeaderTravelerAction'
import Loader from 'components/Loader/Loader'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'

/* Type imports ------------------------------------------------------------- */
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'
import type { TravelerForm } from 'types/TravelerForm'
import type { ActeurTraveller } from 'API/__generated__/Api'
import { TypePersonne } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
type TypePerson = 'Assure' | 'EntrepriseAgree' | 'Intervenant'

/* Styled components -------------------------------------------------------- */
const FormContainer = styled.div`
  padding-bottom: 40px;
`

const GridContainer = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);

  align-items: flex-start;
  justify-content: stretch;

  margin-bottom: 10px;
`

const TwoGridNoMarginContainer = styled(GridContainer)`
  margin-bottom: 0px;
`

const ThreeGridContainer = styled(GridContainer)`
  grid-template-columns: repeat(3, 1fr);
`

const ThreeGridNoMarginContainer = styled(ThreeGridContainer)`
  margin-bottom: 0px;
`

/* Component declaration ---------------------------------------------------- */
interface EditStakeholderPageProps {}

const EditStakeholderPage: React.FC<EditStakeholderPageProps> = () => {
  const formikForm = useOutletContext<TravelerForm>()
  const { caseId = '', stakeholderId } = useParams<{caseId: string; stakeholderId: string}>()
  const navigate = useNavigate()

  const {
    currentData: qualiteList = [],
    isFetching: isFetchingQualiteList,
  } = useGetQualiteListQuery(caseId)
  const {
    currentData: roleList = [],
    isFetching: isFetchingRoleList,
  } = useGetPersonRoleListQuery(caseId)
  const {
    currentData: qualifiantList = [],
    isFetching: isFetchingQualifiantList,
  } = useGetPersonQualifiantListQuery()
  const {
    currentData: responsabilityList = [],
    isFetching: isFetchingResponsabilityList,
  } = useGetResponsabilityListQuery()
  const {
    currentData: leseList = [],
    isFetching: isFetchingLeseList,
  } = useGetYesNoIndeterminedQuery()
  const {
    currentData: TVAList = [],
    isFetching: isFetchingTVAList,
  } = useGetTVAAssujettissementListQuery()
  const {
    currentData: familleAnnuaireList = [],
    isFetching: isFetchingFamilleAnnuaireList,
  } = useGetFamilleAnnuaireListQuery()
  const {
    currentData: politenessList = [],
    isFetching: isFetchingPolitenessList,
  } = useGetPolitenessListQuery()

  useEffect(() => {
    if (formikForm.values?.acteurs?.length > 0 && isValidString(stakeholderId)) {
      const foundAssureOrTiers = [ ...formikForm.values.acteurs ].find((stakeholder) => stakeholder.id === stakeholderId)
      if (foundAssureOrTiers) {
        formikForm.setFieldValue('newPerson', { ...foundAssureOrTiers, customQualifiants: foundAssureOrTiers.qualifiants?.map((qualifiant) => qualifiant.code) })
      } else {
        const foundIntervenant = [ ...formikForm.values.acteurs ].flatMap((person) => person.intervenants).find((person) => person?.id === stakeholderId)
        formikForm.setFieldValue('newPerson', { ...foundIntervenant })
      }
    }
  }, [ formikForm.values?.acteurs?.length, stakeholderId ])

  const handleValue = (type: string, value: string | boolean): void => {
    formikForm.setFieldValue(`newPerson.${type}`, value)
  }

  const personType: TypePerson = useMemo(() => {
    if (formikForm.values.newPerson?.type === TypePersonne.Assure) {
      return 'Assure'
    }
    if (formikForm.values.newPerson?.type === TypePersonne.Tiers) {
      if (formikForm.values.newPerson?.role?.code === '31') {
        return 'EntrepriseAgree'
      }
      return 'Assure'
    }
    return 'Intervenant'
  }, [
    formikForm.values.newPerson?.role,
    formikForm.values.newPerson?.type,
  ])

  const cantBeEdited = useMemo(() => {
    if (isStakeholderUuid(stakeholderId || '')) {
      return false
    }
    if (personType === 'Assure') {
      return false
    }
    return true
  }, [ stakeholderId, personType ])

  const isLoading = useMemo(() => isFetchingQualiteList || isFetchingQualifiantList || isFetchingResponsabilityList || isFetchingLeseList || isFetchingTVAList || isFetchingFamilleAnnuaireList || isFetchingRoleList || isFetchingPolitenessList,
    [
      isFetchingQualiteList,
      isFetchingQualifiantList,
      isFetchingResponsabilityList,
      isFetchingLeseList,
      isFetchingTVAList,
      isFetchingFamilleAnnuaireList,
      isFetchingRoleList,
      isFetchingPolitenessList,
    ])

  const editPerson = (): void => {
    formikForm.submitForm().catch(console.error)
    formikForm.validateForm()
      .then((errors) => {
        console.log('EditStakeholder values', formikForm.values.newPerson)
        if (!errors.newPerson || !Object.keys(errors.newPerson).some((error) => error.indexOf('recherche'))) {
          if (!cantBeEdited) {
            const acteurs: ActeurTraveller[] = structuredClone(formikForm.values.acteurs)
            const newPerson = formikForm.values.newPerson

            if (personType === 'Assure' || personType === 'EntrepriseAgree') {
              acteurs[acteurs.findIndex((acteur) => acteur.id === newPerson.id)] = {
                ...newPerson,
                // Add more code label if needed somewhere to display offline
                qualite: findCodeLabelUndefined(qualiteList, newPerson.qualite?.code),
                qualifiants: newPerson.customQualifiants?.map((qualif) => findCodeLabel(qualifiantList, qualif)) ?? [],
              }
            } else {
              const acteurIndex = acteurs.findIndex(((acteur) => acteur.intervenants?.find((stakeholder) => stakeholder.id === newPerson.id)))
              const stakeholderIndex = acteurs[acteurIndex].intervenants?.findIndex((stakeholder) => stakeholder.id === newPerson.id)
              if (stakeholderIndex !== undefined) {
                const stakeholders = acteurs[acteurIndex].intervenants ?? []
                if (stakeholders.find((stakeholder) => stakeholder.id !== newPerson.id && stakeholder.saisieRapide && stakeholder.nom === newPerson.nom)) {
                  formikForm.setFieldError('newPerson.nom', 'Une autre saisie rapide avec le même nom existe déjà sur cette personne')
                  return
                }
                stakeholders[stakeholderIndex] = {
                  ...newPerson,
                  // Add more code label if needed somewhere to display offline
                  qualite: findCodeLabelUndefined(familleAnnuaireList, newPerson.qualite?.code),
                }
                acteurs[acteurIndex].intervenants = stakeholders
              }
            }
            formikForm.resetForm({
              values: {
                ...formikForm.values,
                acteurs,
                newPerson: formikForm.initialValues.newPerson,
              },
            })
            navigate(`/dossiers/${caseId}/traveller/intervenants`)
          }
        } else {
          console.log('EditStakeholder errors', errors.newPerson)
        }
      })
      .catch(console.error)
  }

  const clearFormAndExit = (): void => {
    formikForm.resetForm({
      values: {
        ...formikForm.values,
        newPerson: formikForm.initialValues.newPerson,
      },
    })
    navigate(`/dossiers/${caseId}/traveller/intervenants`)
  }

  const responsabiliteOptions: SegmentedButtonOption[] = useMemo(() => responsabilityList.map(({ code, libelle }) => ({ value: code, label: libelle, ref: React.createRef() })), [ responsabilityList ])
  const leseOptions: SegmentedButtonOption[] = useMemo(() => leseList.map(({ code, libelle }) => ({ value: code, label: libelle, ref: React.createRef() })), [ leseList ])
  const tvaOptions: SegmentedButtonOption[] = useMemo(() => TVAList.map(({ code, libelle }) => ({ value: code, label: libelle, ref: React.createRef() })), [ TVAList ])

  return (
    <>
      <HeaderWithBackArrowTwoSmallButtons
        title="Modifier un internevant"
        caseId={caseId}
        onClickBack={clearFormAndExit}
      />
      <HeaderTravelerAction
        title={
          `${isValidString(formikForm.values.newPerson?.nom) ? formikForm.values.newPerson?.nom : '' }
          ${isValidString(formikForm.values.newPerson?.prenom) ? formikForm.values.newPerson?.prenom : ''}`
        }
      >
        <SubmitFormButton
          variant="contained"
          disabled={isLoading || cantBeEdited || formikForm.values.disabled}
          onClick={editPerson}
        >
          Valider la modification
        </SubmitFormButton>
      </HeaderTravelerAction>
      <PageContainer>
        {
          isLoading ?
            <Loader /> :
            formikForm.values.newPerson !== undefined &&
              <FormContainer>
                {
                  personType === 'Intervenant' &&
                    <>
                      <FormBoldTitle>
                        Famille intervenant
                      </FormBoldTitle>
                      <Field
                        component={Select}
                        name="newPerson.qualite.code"
                        displayEmpty
                        value={formikForm.values.newPerson.qualite?.code || ''}
                        renderValue={verifySelectFieldValue(formikForm.values.newPerson.qualite?.code)}
                        disabled={cantBeEdited}
                      >
                        {
                          familleAnnuaireList.map((value) => (
                            <MenuItem
                              value={value.code}
                              key={value.code}
                            >
                              {value.libelle}
                            </MenuItem>
                          ))
                        }
                      </Field>
                    </>
                }
                <FormBoldTitle>
                  Politesse
                </FormBoldTitle>
                <Field
                  component={Select}
                  name="newPerson.politesse.code"
                  displayEmpty
                  value={formikForm.values.newPerson.politesse?.code || ''}
                  renderValue={verifySelectFieldValue(formikForm.values.newPerson.politesse?.code)}
                  disabled={cantBeEdited}
                >
                  {
                    politenessList.map(({ code, libelle }) => (
                      <MenuItem
                        value={code}
                        key={code}
                      >
                        {libelle}
                      </MenuItem>
                    ))
                  }
                </Field>
                <TwoGridNoMarginContainer>
                  {
                    personType !== 'Assure' ?
                      <FormBoldTitle>
                        Nom / RS
                      </FormBoldTitle> :
                      <FormBoldTitle required>
                        Nom
                      </FormBoldTitle>
                  }
                  <FormBoldTitle>
                    Prénom
                  </FormBoldTitle>
                </TwoGridNoMarginContainer>
                <GridContainer>
                  <Field
                    component={TextField}
                    placeholder="Nom"
                    name="newPerson.nom"
                    value={formikForm.values.newPerson.nom || ''}
                    disabled={!isStakeholderUuid(stakeholderId || '')}
                  />
                  <Field
                    component={TextField}
                    placeholder="Prénom"
                    name="newPerson.prenom"
                    value={formikForm.values.newPerson.prenom || ''}
                    disabled={cantBeEdited}
                  />
                </GridContainer>
                <FormBoldTitle>
                  Adresse
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Adresse"
                  name="newPerson.adresse.adresse1"
                  value={formikForm.values.newPerson.adresse.adresse1 || ''}
                  disabled={cantBeEdited}
                />
                <br />
                <br />
                <Field
                  component={TextField}
                  placeholder="Adresse"
                  name="newPerson.adresse.adresse2"
                  value={formikForm.values.newPerson.adresse.adresse2 || ''}
                  disabled={cantBeEdited}
                />
                <br />
                <br />
                <Field
                  component={TextField}
                  placeholder="Adresse"
                  name="newPerson.adresse.adresse3"
                  value={formikForm.values.newPerson.adresse.adresse3 || ''}
                  disabled={cantBeEdited}
                />
                <ThreeGridNoMarginContainer>
                  <FormBoldTitle>
                    Code postal
                  </FormBoldTitle>
                  <FormBoldTitle>
                    Ville
                  </FormBoldTitle>
                  <FormBoldTitle>
                    Digicode
                  </FormBoldTitle>
                </ThreeGridNoMarginContainer>
                <ThreeGridContainer>
                  <Field
                    component={TextField}
                    placeholder="Code postal"
                    name="newPerson.adresse.codePostal"
                    value={formikForm.values.newPerson.adresse.codePostal || ''}
                    disabled={cantBeEdited}
                  />
                  <Field
                    component={TextField}
                    placeholder="Ville"
                    name="newPerson.adresse.ville"
                    value={formikForm.values.newPerson.adresse.ville || ''}
                    disabled={cantBeEdited}
                  />
                  <Field
                    component={TextField}
                    placeholder="Digicode"
                    name="newPerson.adresse.digicode"
                    value={formikForm.values.newPerson.adresse.digicode || ''}
                    disabled={cantBeEdited}
                  />
                </ThreeGridContainer>
                {
                  personType !== 'Assure' &&
                    <>
                      <FormBoldTitle>
                        Pays
                      </FormBoldTitle>
                      <Field
                        component={TextField}
                        placeholder="Pays"
                        name="newPerson.adresse.pays"
                        value={formikForm.values.newPerson.adresse.pays || ''}
                        disabled={cantBeEdited}
                      />
                    </>
                }
                {
                  personType === 'Assure' &&
                    <>
                      <FormBoldTitle>
                        Police
                      </FormBoldTitle>
                      <Field
                        component={TextField}
                        placeholder="Police"
                        name="newPerson.police"
                        value={formikForm.values.newPerson.police || ''}
                      />
                    </>
                }
                <FormBoldTitle>
                  Référence
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Référence"
                  name="newPerson.reference"
                  value={formikForm.values.newPerson.reference || ''}
                  disabled={cantBeEdited}
                />
                <ThreeGridNoMarginContainer>
                  <FormBoldTitle>
                    Téléphone domicile
                  </FormBoldTitle>
                  <FormBoldTitle>
                    Téléphone bureau
                  </FormBoldTitle>
                  <FormBoldTitle>
                    Téléphone portable
                  </FormBoldTitle>
                </ThreeGridNoMarginContainer>
                <ThreeGridContainer>
                  <Field
                    component={TextField}
                    placeholder="Téléphone domicile"
                    name="newPerson.telDomicile"
                    value={formikForm.values.newPerson.telDomicile || ''}
                    disabled={cantBeEdited}
                  />
                  <Field
                    component={TextField}
                    placeholder="Téléphone bureau"
                    name="newPerson.telBureau"
                    value={formikForm.values.newPerson.telBureau || ''}
                    disabled={cantBeEdited}
                  />
                  <Field
                    component={TextField}
                    placeholder="Téléphone portable"
                    name="newPerson.telPortable"
                    value={formikForm.values.newPerson.telPortable || ''}
                    disabled={cantBeEdited}
                  />
                </ThreeGridContainer>
                <FormBoldTitle>
                  Email
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Email"
                  name="newPerson.mail"
                  value={formikForm.values.newPerson.mail || ''}
                  disabled={cantBeEdited}
                />
                {
                  personType === 'Assure' &&
                    <>
                      <FormBoldTitle>
                        Profession
                      </FormBoldTitle>
                      <Field
                        component={TextField}
                        placeholder="Profession"
                        name="newPerson.profession"
                        value={formikForm.values.newPerson.profession || ''}
                      />
                    </>
                }
                {
                  personType !== 'Intervenant' &&
                    <>
                      <FormBoldTitle>
                        Rôle
                      </FormBoldTitle>
                      <Field
                        component={Select}
                        name="newPerson.role.code"
                        displayEmpty
                        value={formikForm.values.newPerson.role?.code || ''}
                        renderValue={verifySelectFieldValue(formikForm.values.newPerson.role?.code)}
                        disabled={cantBeEdited}
                      >
                        {
                          roleList.filter((role) => (personType === 'Assure' && role.code !== '31') || personType === 'EntrepriseAgree').map((value) => (
                            <MenuItem
                              value={value.code}
                              key={value.code}
                            >
                              {value.libelle}
                            </MenuItem>
                          ))
                        }
                      </Field>
                      <FormBoldTitle>
                        Qualité
                      </FormBoldTitle>
                      <Field
                        component={Select}
                        name="newPerson.qualite.code"
                        displayEmpty
                        value={formikForm.values.newPerson.qualite?.code || ''}
                        renderValue={verifySelectFieldValue(formikForm.values.newPerson.qualite?.code)}
                        disabled={cantBeEdited}
                      >
                        {
                          (personType === 'Assure' ? qualiteList : familleAnnuaireList).map((value) => (
                            <MenuItem
                              value={value.code}
                              key={value.code}
                            >
                              {value.libelle}
                            </MenuItem>
                          ))
                        }
                      </Field>
                    </>
                }
                {
                  personType === 'Assure' &&
                    <>
                      <FormBoldTitle>
                        Qualifiant
                      </FormBoldTitle>
                      <Field
                        component={Select}
                        name="newPerson.customQualifiants"
                        multiple
                        displayEmpty
                        renderValue={verifySelectFieldValue(formikForm.values.newPerson.customQualifiants?.[0])}
                      >
                        {
                          qualifiantList.map((value) => (
                            <MenuItem
                              value={value.code}
                              key={value.code}
                            >
                              {value.libelle}
                            </MenuItem>
                          ))
                        }
                      </Field>
                      <FormBoldTitle>
                        Responsabilité
                      </FormBoldTitle>
                      <SegmentedButtons
                        options={responsabiliteOptions}
                        selectedOption={formikForm.values.newPerson.responsabilite?.code || ''}
                        setSelectedOption={(newVal): void => handleValue('responsabilite.code', newVal)}
                      />
                      <FormBoldTitle>
                        Lésé
                      </FormBoldTitle>
                      <SegmentedButtons
                        options={leseOptions}
                        selectedOption={formikForm.values.newPerson.lese?.code || ''}
                        setSelectedOption={(newVal): void => handleValue('lese.code', newVal)}
                      />
                    </>
                }
                {
                  personType !== 'Intervenant' &&
                    <>
                      <FormBoldTitle>
                        Tva
                      </FormBoldTitle>
                      <SegmentedButtons
                        options={tvaOptions}
                        selectedOption={formikForm.values.newPerson.assujettissementTva?.code || ''}
                        setSelectedOption={(newVal): void => handleValue('assujettissementTva.code', newVal)}
                      />
                    </>
                }
              </FormContainer>
        }
      </PageContainer>
    </>
  )
}

export default EditStakeholderPage

/* Type imports ------------------------------------------------------------- */
import type {
  DataType,
  Row,
  Order,
} from 'types/Table'

/* tableUtils methods ---------------------------------- */
export const getReducedRowContent = (row: Row, path: string): DataType => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return path.split('.').reduce((a, b) => a?.[b] || '', row)
}

const descendingComparator = (a: Row, b: Row, orderBy: string) => {
  if ((getReducedRowContent(b, orderBy)) < (getReducedRowContent(a, orderBy))) {
    return -1
  }
  if ((getReducedRowContent(b, orderBy)) > (getReducedRowContent(a, orderBy))) {
    return 1
  }
  return 0
}

export const getRowsComparator = (order: Order, orderBy: string) => {
  return order === 'desc' ?
    (a: Row, b: Row) => descendingComparator(a, b, orderBy) :
    (a: Row, b: Row) => -descendingComparator(a, b, orderBy)
}

/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetCaseDocumentsQuery,
  useGetSinappsReportTodoListQuery,
  usePostSinappsReportMutation,
} from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import { toast } from 'react-toastify'
import PageContainer from 'layouts/PageContainer/PageContainer'
import HeaderAction from 'layouts/MainLayout/Headers/HeadersComponents/HeaderAction'
import Loader from 'components/Loader/Loader'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import CheckableButton from 'components/CheckableButton/CheckableButton'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'
import ErrorMessage from 'components/ErrorMessage/ErrorMessage'
import CheckableCollapseCard from 'components/CheckableCollapseCard/CheckableCollapseCard'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikHelpers,
  FormikContextType,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  CommandeDeposerCompteRenduRequest,
  PieceJointe,
} from 'API/__generated__/Api'
import { TypePJ } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
interface SinappsReportCommentRequest {
  isCommentMandatory: boolean;
}

type SinappsReportRequest = SinappsReportCommentRequest & CommandeDeposerCompteRenduRequest

const sinappsReportSchema = Yup.object<Shape<SinappsReportRequest>>({
  resteAFaire: Yup.array(Yup.string().required()).min(1, 'Merci de sélectionner au moins une option').required(),
  commentaire: Yup.string().when('isCommentMandatory', {
    is: true,
    then: (schema) => schema.required('Le commentaire est obligatoire'),
  }),
  idFichiers: Yup.array(Yup.string().required()),
}).required()

type SinappsReportForm = FormikContextType<SinappsReportRequest>

/* Styled components -------------------------------------------------------- */
interface ErrorField {
  error?: boolean;
}
const GridContainer = styled.div<ErrorField>`
  display: grid;

  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  border: ${(props) => props.error ? '1px solid #d32f2f' : undefined};
  border-radius: 4px;

  align-items: stretch;
  justify-content: stretch;

  margin-bottom: 10px;
`

const BigCheckableButton = styled(CheckableButton)`
  height: 80px;
`

/* Component declaration ---------------------------------------------------- */
interface SinappsReportPageProps {}

const SinappsReportPage: React.FC<SinappsReportPageProps> = () => {
  const { caseId = '' } = useParams<{caseId: string}>()
  const navigate = useNavigate()
  const [ isAttachmentOpen, setIsAttachmentOpen ] = useState<boolean>(false)

  const {
    currentData: todoList = [],
    isFetching: isFetchingTodoList,
  } = useGetSinappsReportTodoListQuery()
  const {
    currentData: documents = [],
    isFetching: isFetchingDocuments,
  } = useGetCaseDocumentsQuery(
    { dossier: caseId },
    { skip: !isAttachmentOpen },
  )
  const [
    submitSinappsReport,
  ] = usePostSinappsReportMutation()

  const onSubmit = async (values: SinappsReportRequest, { setSubmitting, resetForm }: FormikHelpers<SinappsReportRequest>): Promise<void> => {
    const response = await submitSinappsReport({
      caseId,
      data: values,
    })

    if (!isApiError(response)) {
      resetForm()
      navigate(-1)
    } else {
      toast.error('Une erreur est survenue lors de l‘envoi du rapport Sinapps. Veuillez vérifier le formulaire.')
      setSubmitting(false)
    }
  }

  const formikForm: SinappsReportForm = useForm<SinappsReportRequest>(
    {
      initialValues: {
        commentaire: '',
        resteAFaire: [],
        idFichiers: [],
        isCommentMandatory: false,
      },
      onSubmit: onSubmit,
      validationSchema: sinappsReportSchema,
    },
  )

  const handleValue = (type: string, value?: string | string[]): void => {
    formikForm.setFieldValue(type, value)
  }

  const handleCheckedButton = (type: 'resteAFaire' | 'idFichiers', value: string, checked: boolean): void => {
    const newValues: string[] | undefined = [ ...(formikForm.values[type] ?? []) ]

    if (!checked) {
      handleValue(type, newValues?.filter((val) => val !== value))
    }
    else {
      newValues?.push(value)
      handleValue(type, newValues)
    }
  }

  useEffect(() => {
    if (formikForm.values.resteAFaire.some((formTodo) => todoList.find((todoType) => todoType.codeResteAFaire.code === formTodo)?.commentaireObligatoire)) {
      formikForm.setFieldValue('isCommentMandatory', true)
    } else {
      formikForm.setFieldValue('isCommentMandatory', false)
    }

  }, [ formikForm.values.resteAFaire ])

  useEffect(() => {
    if (!isAttachmentOpen) {
      handleValue('idFichiers', [])
    }
  }, [ isAttachmentOpen ])

  const documentList = useMemo(() => [ ...documents ?? [] ].filter((file: PieceJointe) => file.type === TypePJ.Doc || file.type === TypePJ.DocEvenementiel), [ documents ])

  return (
    <>
      <HeaderAction
        title="Compte rendu"
        onSubmit={formikForm.handleSubmit}
      >
        <SubmitFormButton
          type="submit"
          variant="contained"
          disabled={formikForm.isSubmitting || isFetchingTodoList}
        >
          Valider
        </SubmitFormButton>
      </HeaderAction>
      <PageContainer>
        {(formikForm.isSubmitting || isFetchingTodoList) && <Loader />}
        <Form form={formikForm}>
          <FormBoldTitle />
          <GridContainer error={formikForm.touched.resteAFaire !== undefined && formikForm.errors.resteAFaire !== undefined}>
            {
              todoList.map((todoType, index) => (
                <BigCheckableButton
                  key={`${todoType.codeResteAFaire.code}-${index}`}
                  checked={formikForm.values.resteAFaire.some((to) => to === todoType.codeResteAFaire.code)}
                  onChange={(e, c): void => handleCheckedButton('resteAFaire', todoType.codeResteAFaire.code, c)}
                  label={todoType.codeResteAFaire.libelle}
                />
              ))
            }
          </GridContainer>
          <ErrorMessage name="resteAFaire" />
          <FormBoldTitle required={formikForm.values.isCommentMandatory}>
            Commentaire
          </FormBoldTitle>
          <Field
            component={TextField}
            placeholder="Votre message"
            name="commentaire"
            rows={3}
            multiline
          />
          <CheckableCollapseCard
            label="Joindre des documents"
            checked={isAttachmentOpen}
            onChange={() => setIsAttachmentOpen(!isAttachmentOpen)}
          >
            <GridContainer>
              {
                isFetchingDocuments ?
                  <CircularProgress /> :
                  documentList.map((file, index) => (
                    <CheckableButton
                      key={`${file.fileName}-${index}`}
                      checked={formikForm.values.idFichiers?.some((value) => value === file.id) ?? false}
                      onChange={(e, c): void => handleCheckedButton('idFichiers', file.id || '', c)}
                      label={file.libelle}
                    />
                  ))
              }
            </GridContainer>
          </CheckableCollapseCard>
        </Form>
      </PageContainer>
    </>
  )
}

export default SinappsReportPage

/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useNavigate } from 'react-router-dom'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  CircularProgress,
} from '@mui/material'
import DrawerContent from 'components/Drawer/DrawerContent'
import CaseSidebarInternalExchangeMenuItem from './CaseSidebarInternalExchangeMenuItem'

/* Type imports ------------------------------------------------------------- */
import type { EchangeInterne } from 'API/__generated__/Api'

/* Internal variables ------------------------------------------------------- */

/* Styled components -------------------------------------------------------- */
const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;
  font-size: 1rem;
  padding: 10px 10px 0px 20px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseSidebarInternalExchangeMenuProps {
  exchanges?: EchangeInterne[];
  isFetching: boolean;
  caseId: string;
  onClose: () => void;
}

const CaseSidebarInternalExchangeMenu: React.FC<CaseSidebarInternalExchangeMenuProps> = ({
  exchanges = [],
  isFetching,
  caseId,
  onClose,
}) => {
  const navigate = useNavigate()

  if (isFetching) {
    return <CircularProgress />
  }

  const handleClick = (): void => {
    onClose()
    navigate(`/actions-rapides/${caseId}/echange-interne`)
  }

  return (
    <>
      <TitleContainer>
        Échanges internes
        <Button
          variant="contained"
          onClick={handleClick}
        >
          Nouvel échange interne
        </Button>
      </TitleContainer>
      <DrawerContent>
        {
          isFetching ?
            <CircularProgress /> :
            exchanges.map((exchange: EchangeInterne, index: number) => (
              <CaseSidebarInternalExchangeMenuItem
                key={`exchange-${exchange.id}-${index}`}
                exchange={exchange}
              />
            ))
        }
      </DrawerContent>
    </>
  )
}

export default CaseSidebarInternalExchangeMenu

/* Framework imports -------------------------------------------------------- */
/* Module imports ----------------------------------------------------------- */
import OpenAPI from 'API/OpenAPI'
import { store } from 'store/store'
import { setReportLastSync } from 'store/slices/reportSlice'

/* Type imports ------------------------------------------------------------- */
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import type { TravelerForm } from 'types/TravelerForm'

/* Type declaration ------------------------------------ */
interface ErrorType {
  error: {
    errors: {
      [x: string]: string[];
    };
  };
}

type QueryErrorType = FetchBaseQueryError & ErrorType

/* Internal variables ------------------------------------------------------- */

/* TravelerService methods ----------------------------- */
interface SubmitTravelerProps {
  formikForm: TravelerForm;
  errorForm?: TravelerForm;
  caseId: string;
  reportId: string;
  validation: boolean;
  setIsSubmitting?: (value: boolean) => void;
}

const submitTraveler = ({ formikForm, caseId, reportId, validation, errorForm, setIsSubmitting }: SubmitTravelerProps): void => {
  setIsSubmitting && setIsSubmitting(true)
  formikForm.submitForm().catch(console.error)
  formikForm.validateForm()
    .then((errors) => {
      console.log('TravelerSubmit values', formikForm.values, formikForm.errors)
      if (!Object.keys(errors).some((error) => !error.startsWith('new') && !error.startsWith('irsiData'))) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { newDamage, newRecourse, newPerson, newPayment, disabled, irsiData, ...data } = structuredClone(formikForm.values)
        return OpenAPI.dossier.travellerDossierCreate({ id: reportId, validation }, data)
      } else {
        console.log('Traveler validation errors', errors)
      }
    })
    .then(() => {
      store.dispatch(setReportLastSync({ caseId, reportId, validation, sync: new Date().toISOString() }))
      if (errorForm) {
        errorForm.setFieldValue('disabled', false)
      }
    })
    .catch((error: QueryErrorType) => {
      const errors = error?.error?.errors
      console.log('TravelerSubmit errors', errors)
      if (error.status === 500 && errorForm) {
        errorForm.setFieldError('etat', 'Crash')
      }
      if (errors) {
        Object.keys(errors).map((key) => formikForm.setFieldError(key, errors[key][0]))
        if (errorForm) {
          Object.keys(errors).map((key) => errorForm.setFieldError(key.toLowerCase(), errors[key][0]))
        }
      }
    })
    .finally(() => setIsSubmitting && setIsSubmitting(false))
}

export default {
  submitTraveler,
}

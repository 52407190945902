/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'

/* Module imports ----------------------------------------------------------- */
import {
  useGetCaseDocumentsQuery,
  useGetCaseInfosQuery,
  useGetCaseWorkflowQuery,
  useGetHistoriqueQuery,
  useGetInternalExchangesQuery,
  useGetNotificationsQuery,
  useGetTasksQuery,
} from 'store/api'
import { useAuthInfo } from 'store/hooks'
import { useCaseSidebar } from 'helpers/hooks/useCaseSidebar'
import { useIsConnected } from 'helpers/hooks/useIsConnected'
import { isValidString } from 'helpers/isValidString'

/* Component imports -------------------------------------------------------- */
import Drawer from 'components/Drawer/Drawer'
import DrawerContent from 'components/Drawer/DrawerContent'
import CaseSidebarHeader from './CaseSidebarComponents/CaseSidebarHeader/CaseSidebarHeader'
import CaseWorkflow from './CaseWorkflow/CaseWorkflow'
import CaseSidebarPerson from './CaseSidebarPerson/CaseSidebarPerson'
import CaseSidebarTiers from './CaseSidebarTiers/CaseSidebarTiers'
import CaseSidebarPostIt from './CaseSidebarPostIt/CaseSidebarPostIt'
import CaseSidebarMedia from './CaseSidebarMedia/CaseSidebarMedia'
import CaseSidebarTask from './CaseSidebarTask/CaseSidebarTask'
import CaseSidebarInternalExchange from './CaseSidebarInternalExchange/CaseSidebarInternalExchange'
import CaseSidebarNotification from './CaseSidebarNotification/CaseSidebarNotification'
import CaseSidebarHistory from './CaseSidebarHistory/CaseSidebarHistory'
import CaseSidebarHeaderSubMenu from './CaseSidebarHeaderSubMenu/CaseSidebarHeaderSubMenu'
import CaseSidebarMediaMenu from './CaseSidebarMedia/CaseSidebarMediaMenu'
import CaseSidebarTaskMenu from './CaseSidebarTask/CaseSidebarTaskMenu'
import CaseSidebarInternalExchangeMenu from './CaseSidebarInternalExchange/CaseSidebarInternalExchangeMenu'
import CaseSidebarNotificationMenu from './CaseSidebarNotification/CaseSidebarNotificationMenu'
import CaseSidebarHistoryMenu from './CaseSidebarHistory/CaseSidebarHistoryMenu'

/* Type imports ------------------------------------------------------------- */
import { TypePersonne } from 'API/__generated__/Api'
import type { CaseSidebarMenu } from 'types/CaseSidebarMenu'

/* Component declaration ---------------------------------------------------- */
interface CaseSidebarProps {}

const CaseSidebar: React.FC<CaseSidebarProps> = () => {
  const authInfo = useAuthInfo()
  const caseSidebar = useCaseSidebar()
  const isConnected = useIsConnected()
  const view: HTMLElement | null = document.getElementById('case-sidebar')
  const [ openedMenu, setOpenedMenu ] = useState<CaseSidebarMenu>('main')

  const {
    currentData: workflow = [],
    isFetching: isFetchingWorkflow,
  } = useGetCaseWorkflowQuery(
    caseSidebar.state.caseId || '',
    { skip: caseSidebar.state.caseId === null },
  )
  const {
    currentData: caseInfos,
    isFetching: isFetchingCaseInfos,
  } = useGetCaseInfosQuery(
    caseSidebar.state.caseId || '',
    { skip: caseSidebar.state.caseId === null },
  )
  const {
    currentData: documents = [],
    isFetching: isFetchingDocuments,
  } = useGetCaseDocumentsQuery(
    { dossier: caseSidebar.state.caseId || '' },
    { skip: caseSidebar.state.caseId === null },
  )
  const {
    currentData: tasks = [],
    isFetching: isFetchingTasks,
  } = useGetTasksQuery(
    {
      dossier: caseSidebar.state.caseId || '',
      collaborateur: authInfo?.currentCollaborateur?.refAnnuaire.refComplete || '',
    },
    { skip: !isValidString(authInfo?.currentCollaborateur?.refAnnuaire.refComplete) || caseSidebar.state.caseId === null },
  )
  const {
    currentData: internalExchanges = [],
    isFetching: isFetchingInternalExchanges,
  } = useGetInternalExchangesQuery(
    {
      dossier: caseSidebar.state.caseId || '',
      destinataire: authInfo?.currentCollaborateur?.refAnnuaire?.refComplete || '',
    },
    { skip: caseSidebar.state.caseId === null },
  )
  const {
    currentData: notifications = [],
    isFetching: isFetchingNotifications,
  } = useGetNotificationsQuery(
    {
      dossier: caseSidebar.state.caseId || '',
      destinataire: authInfo?.currentCollaborateur?.refAnnuaire?.refComplete || '',
    },
    { skip: caseSidebar.state.caseId === null },
  )
  const {
    currentData: history = [],
    isFetching: isFetchingHistory,
  } = useGetHistoriqueQuery(
    caseSidebar.state.caseId || '',
    { skip: caseSidebar.state.caseId === null },
  )

  useEffect(() => {
    setOpenedMenu('main')
  }, [ caseSidebar.state.open ])

  useEffect(() => {
    view?.scroll({
      top: 0,
      left: 0,
      behavior: 'auto',
    })
  }, [ openedMenu, view ])

  const insuredPerson = useMemo(() => caseInfos?.personnes?.find((person) => person.type === TypePersonne.Assure), [ caseInfos ])

  const handleClose = (): void => {
    caseSidebar.close()
  }

  const getIdNumber = (id: string): number => parseInt(id.slice(id.lastIndexOf('-') + 1))

  const documentList = [ ...documents ].sort((a, b) => getIdNumber(b.id ?? '-0') - getIdNumber(a.id ?? '-0'))

  return (
    <Drawer
      open={caseSidebar.state.open}
      onClose={handleClose}
      width="500px"
      id="case-sidebar"
    >
      {
        openedMenu === 'main' ?
          <div>
            <CaseSidebarHeader
              title={`Dossier `}
              caseId={caseSidebar.state.caseId || ''}
              handleClose={handleClose}
            />
            <CaseWorkflow
              workflow={workflow}
              isFetching={isFetchingWorkflow}
            />
            <DrawerContent>
              <CaseSidebarPerson
                caseId={caseSidebar.state.caseId || ''}
                caseInfos={caseInfos}
                isFetching={isFetchingCaseInfos}
                insuredPerson={insuredPerson}
                isConnected={isConnected}
              />
              <CaseSidebarTiers
                stakeholders={caseInfos?.personnes}
                isFetching={isFetchingCaseInfos}
                insuredPerson={insuredPerson}
              />
              <CaseSidebarPostIt
                postIt={caseInfos?.postIt}
                isFetching={isFetchingCaseInfos}
              />
              <CaseSidebarMedia
                documents={documentList}
                isFetching={isFetchingDocuments}
                documentLimit={8}
                setOpenedMenu={() => setOpenedMenu('media')}
                onClose={handleClose}
              />
              <CaseSidebarTask
                tasks={tasks}
                isFetching={isFetchingTasks}
                taskLimit={3}
                setOpenedMenu={() => setOpenedMenu('task')}
              />
              <CaseSidebarInternalExchange
                exchanges={internalExchanges}
                isFetching={isFetchingInternalExchanges}
                strLenLimit={170}
                setOpenedMenu={() => setOpenedMenu('exchange')}
              />
              <CaseSidebarNotification
                notifications={notifications}
                isFetching={isFetchingNotifications}
                strLenLimit={170}
                setOpenedMenu={() => setOpenedMenu('notification')}
              />
              <CaseSidebarHistory
                items={history}
                isFetching={isFetchingHistory}
                itemLimit={3}
                strLenLimit={110}
                setOpenedMenu={() => setOpenedMenu('history')}
              />
            </DrawerContent>
          </div> :
          <>
            <CaseSidebarHeaderSubMenu
              title={`Dossier `}
              setOpenedMenu={() => setOpenedMenu('main')}
              caseId={caseSidebar.state.caseId || ''}
            />
            {
              openedMenu === 'media' &&
                <CaseSidebarMediaMenu
                  documents={documentList}
                  isFetching={isFetchingDocuments}
                  onClose={handleClose}
                  caseId={caseSidebar.state.caseId || ''}
                  isConnected={isConnected}
                />
            }
            {
              openedMenu === 'task' &&
                <CaseSidebarTaskMenu
                  tasks={tasks}
                  isFetching={isFetchingTasks}
                  onClose={handleClose}
                  caseId={caseSidebar.state.caseId || ''}
                  isConnected={isConnected}
                />
            }
            {
              openedMenu === 'exchange' &&
                <CaseSidebarInternalExchangeMenu
                  exchanges={internalExchanges}
                  isFetching={isFetchingInternalExchanges}
                  onClose={handleClose}
                  caseId={caseSidebar.state.caseId || ''}
                />
            }
            {
              openedMenu === 'notification' &&
                <CaseSidebarNotificationMenu
                  notifications={notifications}
                  isFetching={isFetchingNotifications}
                />
            }
            {
              openedMenu === 'history' &&
                <CaseSidebarHistoryMenu
                  items={history}
                  isFetching={isFetchingHistory}
                />
            }
          </>
      }
    </Drawer>
  )
}

export default CaseSidebar

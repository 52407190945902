/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { useGetCaseDocumentsQuery } from 'store/api'
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'

/* Component imports -------------------------------------------------------- */
import { Field } from 'formik'
import {
  Select,
  TextField,
} from 'formik-mui'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from '@mui/material'
import CloseButton from 'components/CloseButton/CloseButton'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import CheckableButton from 'components/CheckableButton/CheckableButton'
import Loader from 'components/Loader/Loader'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'

/* Type imports ------------------------------------------------------------- */
import type { FormikHelpers } from 'formik'
import type {
  CodeLabel,
  PieceJointe,
} from 'API/__generated__/Api'
import {
  TypePJ,
  Verif,
} from 'API/__generated__/Api'
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'

/* Type declarations -------------------------------------------------------- */
type ReportType = 'REX' | 'PRG'

const reportClosureSchema = Yup.object({
  reportType: Yup.mixed<ReportType>().required(),
  sinapps: Yup.boolean().required(),
  type: Yup.mixed<CodeLabel>().when('sinapps', {
    is: false,
    then: () => Yup.mixed<CodeLabel>().test(
      'is-not-empty',
      'Ce champ est obligatoire',
      (value) => value?.code !== '' && value?.code !== undefined,
    ).required('Ce champ est obligatoire'),
  }),
  garantiesREX: Yup.mixed<CodeLabel>().when([ 'reportType', 'sinapps' ], {
    is: (reportType: ReportType, sinapps: boolean) => reportType === 'REX' && sinapps === false,
    then: () => Yup.mixed<CodeLabel>().required(),
  }),
  garantiesPRG: Yup.mixed<CodeLabel>().when([ 'reportType', 'sinapps' ], {
    is: (reportType: ReportType, sinapps: boolean) => reportType === 'PRG' && sinapps === false,
    then: () => Yup.mixed<CodeLabel>().required(),
  }),
  commentaireGarantie: Yup.string().when('sinapps', {
    is: false,
    then: (schema) => schema.required('Ce champ est obligatoire'),
  }),
  conformiteDuRisque: Yup.mixed<Verif>().required(),
  descriptionDuRisque: Yup.string().required('Ce champ est obligatoire'),
  cloture: Yup.mixed<CodeLabel>().when([ 'reportType', 'sinapps' ], {
    is: (reportType: ReportType, sinapps: boolean) => !(sinapps === false && reportType === 'REX'),
    then: () => Yup.mixed<CodeLabel>().required(),
  }),
  actionSinapps: Yup.mixed<CodeLabel>().when('sinapps', {
    is: true,
    then: () => Yup.mixed<CodeLabel>().required(),
  }),
  listePieceJointes: Yup.array(Yup.string().required()),
}).required()

type ReportClosureRequest = Yup.InferType<typeof reportClosureSchema>

/* Internal variables ------------------------------------------------------- */
const reportTypeList: CodeLabel[]= [
  {
    code: '1',
    libelle: 'Type 1',
  },
  {
    code: '2',
    libelle: 'Type 2',
  },
]
const garantiesREXList: CodeLabel[]= [
  {
    code: '1',
    libelle: 'Garanties',
  },
  {
    code: '2',
    libelle: 'Non garantie',
  },
  {
    code: '3',
    libelle: 'Réserve',
  },
]
const garantiesPRGList: CodeLabel[]= [
  {
    code: '1',
    libelle: 'Acquises',
  },
  {
    code: '2',
    libelle: 'Non acquises',
  },
  {
    code: '3',
    libelle: 'Non vérifiée',
  },
  {
    code: '4',
    libelle: 'Partielle',
  },
]
const clotureList: CodeLabel[]= [
  {
    code: '1',
    libelle: 'Clôturer le dossier GEXSI',
  },
  {
    code: '2',
    libelle: 'Ne pas clôturer le dossier GEXSI',
  },
]
const sinappsList: CodeLabel[]= [
  {
    code: '1',
    libelle: 'Envoi dépôt conclusions SINAPPS',
  },
  {
    code: '2',
    libelle: 'Demande la fin de prestation SINAPPS',
  },
]

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 5px;
  padding-bottom: 0px;
  text-transform: uppercase;
  text-align: center;
`

const DialogContentContainer = styled(DialogContent)`
  padding-bottom: 0;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin-bottom: 20px;
`

const FormButton = styled(Button)`
  min-width: 120px;
`

const GridContainer = styled.div`
  display: grid;
  gap: 10px;
  align-items: stretch;
  justify-content: stretch;

  margin-bottom: 10px;
`

interface ErrorField {
  error?: boolean;
}

const TwoGridContainer = styled(GridContainer)<ErrorField>`
  grid-template-columns: repeat(2, 1fr);
  border: ${(props) => props.error ? '1px solid #d32f2f' : undefined};
  border-radius: 4px;
`

const FormContainer = styled.div`
  margin-bottom: 5px;
`

/* Component declaration ---------------------------------------------------- */
interface ReportClosureModalProps {
  open: boolean;
  handleClose: (isReportClosed?: boolean) => void;
  type: ReportType;
  sinapps: boolean;
  caseId: string;
}

const ReportClosureModal: React.FC<ReportClosureModalProps> = ({
  open,
  handleClose,
  type,
  sinapps,
  caseId,
}) => {
  const {
    currentData: documents = [],
    isFetching: isFetchingDocuments,
    // to change when new route will be added
  } = useGetCaseDocumentsQuery(
    { dossier: caseId },
    { skip: !sinapps },
  )

  const onSubmit = (values: ReportClosureRequest, { setSubmitting }: FormikHelpers<ReportClosureRequest>): void => {
    console.log('values', values)
    setSubmitting(false)
    handleClose(true)
  }

  const formikForm = useForm<ReportClosureRequest>(
    {
      initialValues: {
        reportType: type,
        sinapps: sinapps,
        type: { code: '', libelle: '' },
        actionSinapps: sinappsList[0],
        cloture: clotureList[0],
        commentaireGarantie: '',
        descriptionDuRisque: '',
        conformiteDuRisque: Verif.NonVerifie,
        garantiesPRG: garantiesPRGList[0],
        garantiesREX: garantiesREXList[0],
        listePieceJointes: [],
      },
      onSubmit: onSubmit,
      validationSchema: reportClosureSchema,
    },
  )

  const handleValue = (type: string, value?: string | boolean | string[]): void => {
    formikForm.setFieldValue(type, value).catch(console.error)
  }

  const handleCheckedButton = (type: 'listePieceJointes', value: string, checked: boolean): void => {
    const newValues: string[] | undefined = [ ...formikForm.values[type] ?? [] ]

    if (!checked) {
      handleValue(type, newValues?.filter((val) => val !== value))
    }
    else {
      newValues?.push(value)
      handleValue(type, newValues)
    }
  }

  const clotureOptions: SegmentedButtonOption[] = clotureList.map((value) => ({ value: value.code, label: value.libelle, ref: React.createRef() }))
  const garantieRexOptions: SegmentedButtonOption[] = garantiesREXList.map((value) => ({ value: value.code, label: value.libelle, ref: React.createRef() }))
  const garantiePrgOptions: SegmentedButtonOption[] = garantiesPRGList.map((value) => ({ value: value.code, label: value.libelle, ref: React.createRef() }))
  const sinappsOptions: SegmentedButtonOption[] = sinappsList.map((value) => ({ value: value.code, label: value.libelle, ref: React.createRef() }))
  const createNewVerifyOptions = (): SegmentedButtonOption[] => {
    return [
      { value: 'Oui' },
      { value: 'Non' },
      { value: 'NonVerifie', label: 'Non vérifié' },
    ].map((option) => ({ ...option, ref: React.createRef() }))
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      fullWidth
      maxWidth="md"
    >
      <DialogTitleContainer>
        Clôture
        <CloseButton handleClose={handleClose} />
      </DialogTitleContainer>
      <DialogContentContainer>
        {
          isFetchingDocuments ?
            <Loader /> :
            <FormContainer>
              <Form form={formikForm}>
                {
                  sinapps === false && (
                    <>
                      <FormBoldTitle>
                        Type de rapport
                      </FormBoldTitle>
                      <Field
                        component={Select}
                        name="type.code"
                        displayEmpty
                        value={formikForm.values.type?.code || ''}
                        renderValue={verifySelectFieldValue(formikForm.values.type?.code)}
                        formHelperText={{ children: formikForm.touched.type !== undefined && formikForm.errors.type !== undefined ? formikForm.errors.type : null }}
                        formControl={{ error: formikForm.touched.type !== undefined && formikForm.errors.type !== undefined }}
                      >
                        {
                          reportTypeList.map((value) => (
                            <MenuItem
                              value={value.code}
                              key={value.code}
                            >
                              {value.libelle}
                            </MenuItem>
                          ))
                        }
                      </Field>
                    </>
                  )
                }
                {
                  !(sinapps === false && type === 'REX') && (
                    <>
                      <FormBoldTitle>
                        Clôture du dossier
                      </FormBoldTitle>
                      <SegmentedButtons
                        options={clotureOptions}
                        selectedOption={formikForm.values.cloture?.code}
                        setSelectedOption={(newVal): void => handleValue('cloture.code', newVal)}
                      />
                    </>
                  )
                }
                {
                  sinapps === false && (
                    <>
                      <FormBoldTitle>
                        Garanties
                      </FormBoldTitle>
                      <SegmentedButtons
                        options={type === 'REX' ? garantieRexOptions : garantiePrgOptions}
                        selectedOption={type === 'REX' ? formikForm.values.garantiesREX?.code : formikForm.values.garantiesPRG?.code}
                        setSelectedOption={(newVal): void => handleValue(type === 'REX' ? 'garantiesREX.code' : 'garantiesPRG.code', newVal)}
                      />
                    </>
                  )
                }
                {
                  sinapps === false && (
                    <>
                      <FormBoldTitle>
                        Commentaire
                      </FormBoldTitle>
                      <Field
                        component={TextField}
                        placeholder="Votre message"
                        name="commentaireGarantie"
                        rows={3}
                        multiline
                      />
                    </>
                  )
                }
                <FormBoldTitle>
                  Conformité du risque
                </FormBoldTitle>
                <SegmentedButtons
                  options={createNewVerifyOptions()}
                  selectedOption={formikForm.values.conformiteDuRisque}
                  setSelectedOption={(newVal): void => handleValue('conformiteDuRisque', newVal)}
                />
                <FormBoldTitle>
                  Descriptif du risque
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Votre message"
                  name="descriptionDuRisque"
                  rows={3}
                  multiline
                />
                {
                  sinapps && (
                    <>
                      <FormBoldTitle>
                        Sinapps
                      </FormBoldTitle>
                      <SegmentedButtons
                        options={sinappsOptions}
                        selectedOption={formikForm.values.actionSinapps?.code}
                        setSelectedOption={(newVal): void => handleValue('actionSinapps.code', newVal)}
                      />
                      <FormBoldTitle>
                        Joindre des documents
                      </FormBoldTitle>
                      <TwoGridContainer>
                        {
                          documents
                            .filter((file: PieceJointe) => file.type === TypePJ.Doc)
                            .map((file, index) => (
                              <CheckableButton
                                key={`${file.fileName}-${index}`}
                                checked={formikForm.values.listePieceJointes?.some((value) => value === file.fileName) ?? false}
                                onChange={(e, c): void => handleCheckedButton('listePieceJointes', file.fileName, c)}
                                label={file.libelle}
                              />
                            ))
                        }
                      </TwoGridContainer>
                    </>
                  )
                }
              </Form>
            </FormContainer>
        }
      </DialogContentContainer>
      <DialogActionContainer>
        <FormButton
          onClick={() => handleClose()}
        >
          Annuler
        </FormButton>
        <form onSubmit={formikForm.handleSubmit}>
          <FormButton
            variant="contained"
            type="submit"
          >
            Valider
          </FormButton>
        </form>
      </DialogActionContainer>
    </Dialog>
  )
}

export default ReportClosureModal

/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Type imports ------------------------------------------------------------- */
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'

/* Type declarations -------------------------------------------------------- */
type ProtoEnum<KeyType extends string, ValueType extends string | number> = {
  [key in KeyType]: ValueType;
}

/* enumToSegmentedButtonOptions helper function ----------------------------- */
export const enumToSegmentedButtonOptions = <KeyType extends string, ValueType extends string | number>
  (inputEnum: ProtoEnum<KeyType, ValueType>): SegmentedButtonOption[] => {
  return Object.entries(inputEnum)
    .filter(([ key ]) => isNaN(Number(key)))
    .map(([ label, value ]): SegmentedButtonOption => ({
      value: value as string ?? label,
      label,
      ref: React.createRef(),
    }))
}

/* Framework imports -------------------------------------------------------- */
import { createSlice } from '@reduxjs/toolkit'

/* Module imports ----------------------------------------------------------- */

/* Type imports ------------------------------------------------------------- */
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'store/store'

/* Type declarations -------------------------------------------------------- */
export interface ServiceWorkerState {
  openSWPopup: boolean;
  updateReceived: boolean;
  lastUpdateDate?: Date;
}

/* Case Sidebar Redux slice ---------------------------- */
const serviceWorkerSlice = createSlice(
  {
    name: 'serviceWorker',
    initialState: {
      openSWPopup: false,
      updateReceived: false,
    } as ServiceWorkerState,
    reducers: {
      openSWPopup: (state) => {
        state.openSWPopup = true
      },
      closeSWPopup: (state) => {
        state.openSWPopup = false
      },
      toggleSWPopup: (state) => {
        state.openSWPopup = !state.openSWPopup
      },
      setOpenSWPopupState: (state, { payload }: PayloadAction<boolean>) => {
        state.openSWPopup = payload
      },
      setUpdateReceivedState: (state, { payload }: PayloadAction<boolean>) => {
        state.updateReceived = payload
        if (payload) {
          state.lastUpdateDate = new Date()
        }
      },
    },
  },
)

/* Export slice components ----------------------------- */
export const {
  openSWPopup,
  closeSWPopup,
  toggleSWPopup,
  setOpenSWPopupState,
} = serviceWorkerSlice.actions

export default serviceWorkerSlice.reducer

export const selectServiceWorker = (state: RootState): ServiceWorkerState => {
  return state.serviceWorker
}

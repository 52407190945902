/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useRef,
} from 'react'
import L from 'leaflet'
import styled from '@emotion/styled'
/* Module imports ----------------------------------------------------------- */
import { renderToString } from 'react-dom/server'
import { getCoordinates } from 'helpers/mapHelpers'
import { isValidString } from 'helpers/isValidString'
import { getAddress } from 'helpers/getAddress'

/* Component imports -------------------------------------------------------- */
import {
  Marker,
  Popup,
} from 'react-leaflet'

/* Asset imports --------------------------------------- */
import pinIcon from 'assets/map_pin_icon.svg'

/* Type imports ------------------------------------------------------------- */
import type {
  Map,
  Marker as LMarker,
} from 'leaflet'
import type { Planning } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const PopupTitle = styled.div`
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 10px;
`

/* Component declaration ---------------------------------------------------- */
interface PlanningMapMarkerProps {
  event: Planning;
  mapRef: Map | null;
  selectedEvent: string;
  setSelectedEvent: (event: Planning) => void;
  eventNumber: number;
}

const PlanningMapMarker: React.FC<PlanningMapMarkerProps> = ({
  event,
  mapRef,
  selectedEvent,
  setSelectedEvent,
  eventNumber,
}) => {
  const markerRef = useRef<LMarker | null>(null)

  const CustomMarker = () => {
    return (
      <div className="pin-container">
        <b>
          {eventNumber}
        </b>
        <img
          src={pinIcon}
          alt="Map marker"
        />
      </div>
    )
  }

  const CustomDivIcon = L.divIcon({
    html: renderToString(<CustomMarker />),
    iconSize: [ 40, 60 ],
    iconAnchor: [ 0, 0 ],
    popupAnchor: [ 0, -45 ],
  })

  useEffect(() => {
    if (selectedEvent === event?.id) {
      mapRef?.flyTo(getCoordinates(event.coordonnees))
      markerRef?.current?.openPopup()
    }
  }, [
    selectedEvent,
    event,
    mapRef,
  ])

  return (
    <Marker
      position={getCoordinates(event.coordonnees)}
      ref={markerRef}
      icon={CustomDivIcon}
      eventHandlers={{ click: () => setSelectedEvent(event) }}
    >
      <Popup>
        <PopupContainer>
          <PopupTitle>
            {event.assure ?? event.typeRDV.libelle}
          </PopupTitle>
          <div>
            {event.natureSinistre?.libelle}
          </div>
          <div>
            {getAddress(event.adresse)}
          </div>
          <div>
            {isValidString(event.adresse?.digicode) && `Digicode: ${event.adresse?.digicode}`}
          </div>
        </PopupContainer>
      </Popup>
    </Marker>
  )
}

export default PlanningMapMarker

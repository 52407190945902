/* Framework imports -------------------------------------------------------- */
/* Module imports ----------------------------------------------------------- */
import { createStakeholerUuid } from 'helpers/uuidUtils'
import {
  findCodeLabelUndefined,
  findCodeLabelUndefinedOrStrict,
} from 'helpers/findCodeLabel'
import { calculIndemnisation } from 'helpers/calculs-indemnisation/src/CalculIndemnisation'
import { getListeDommagesSinapps } from 'helpers/calculs-indemnisation/src/GetListeDommagesSinapps'
import { getListeBiensSinapps } from 'helpers/calculs-indemnisation/src/GetListeBiensSinapps'
import { calculIndemnisationSinapps } from 'helpers/calculs-indemnisation/src/CalculIndemnisationSinapps'

/* Type imports ------------------------------------------------------------- */
import type {
  Dommage as ApiDommage,
  Indemnisation as ApiIndemnisation,
  DommageSinapps as ApiDommageSinapps,
  DommageBienSinapps as ApiDommageBienSinapps,
  IndemnisationSinapps as ApiIndemnisationSinapps,
  TypeDeReparation,
  NatureBien,
  TypeDeBien,
  IndemnisationTraveller,
  Justifications,
} from 'API/__generated__/Api'
import type { Dommage as CalculDommage } from 'helpers/calculs-indemnisation/src/types/Dommage'
import type {
  Indemnisation as CalculIndemnisation,
  IndemnisationExistante,
} from 'helpers/calculs-indemnisation/src/types/Indemnisation'
import type {
  DommageSinapps as CalculDommageSinapps,
  DommageSinappsExistant,
} from 'helpers/calculs-indemnisation/src/types/DommageSinapps'
import type {
  DommageBienSinapps as CalculDommageBienSinapps,
  DommageBienSinappsExistant,
} from 'helpers/calculs-indemnisation/src/types/DommageBienSinapps'
import type {
  IndemnisationSinapps as CalculIndemnisationSinapps,
  IndemnisationSinappsExistante,
} from 'helpers/calculs-indemnisation/src/types/IndemnisationSinapps'

/* Type declaration ------------------------------------ */

/* Internal variables ------------------------------------------------------- */

/* CompensationService methods ------------------------- */
const toCalculDamages = (damages: ApiDommage[]): CalculDommage[] => {
  return damages.map((damage) => ({
    ...damage,
    codeNature: damage.natureDuBien?.code || '',
    codeDetail: damage.detailDuBien?.code || '',
    idDetailDommageSinapps: damage.sinapps?.idDetailDommageSinapps || '',
    typeReparation: damage.typeReparation?.code || '',
    quantite: damage.valeur.quantite || 0,
    prixUnitaire: damage.valeur.prixUnitaire || 0,
    age: damage.vetuste.age ?? undefined,
    tauxVetuste: damage.vetuste.tauxVetuste || 0,
    montantHT: damage.valeur.montantHT || 0,
    montantTva: damage.valeur.montantTva || 0,
    montantTTC: damage.valeur.montantTTC || 0,
    montantVetuste: damage.vetuste.montantVetuste || 0,
    montantVetusteRecuperable: damage.vetuste.montantVetusteRecuperable || 0,
    montantVetusteDeduite: damage.vetuste.montantVetusteDeduite || 0,
    tauxTva: damage.valeur.tauxTva?.taux || 0,
    justification: damage.vetuste.reparation?.justificatif?.code || '',
    dateAchat: damage.vetuste.dateAchat || '',
    reparation: damage.vetuste.reparation?.reparabilite?.code,
    codeNatureSinapps: damage.sinapps?.natureDuBienSinapps?.code || '',
    codeDetailSinapps: damage.sinapps?.detailDuBienSinapps?.code || '',
    typeDommageSinapps: damage.sinapps?.typeDommageSinapps,
  }))
}

const toCalculCompensation = (compensations: ApiIndemnisation[]): IndemnisationExistante[] => {
  return compensations.map((compensation) => ({
    ...compensation,
    codeNature: compensation.natureDuBien?.code || '',
    codeDetail: compensation.detailDuBien?.code || '',
    codeDetailSinapps: compensation.detailDuBienSinapps?.code || '',
    codeNatureSinapps: compensation.natureDuBienSinapps?.code || '',
    typeReparation: compensation.typeDeReparation?.code || '',
    idGarantie: compensation.garantie?.code || '',
  }))
}

const toCalculSinappsDamage = (damages: ApiDommageSinapps[]): DommageSinappsExistant[] => {
  return damages.map((damage) => ({
    ...damage,
    codeNature: damage.natureDuBien?.code || '',
    codeDetail: damage.detailDuBien?.code || '',
    idDetailDommageSinapps: damage.idDetailDommageSinapps || '',
  }))
}

const toCalculSinappsPropertyDamage = (damages: ApiDommageBienSinapps[]): DommageBienSinappsExistant[] => {
  return damages.map((damage) => ({
    ...damage,
    codeDetail: damage.bien?.code || '',
    idDetailDommageSinapps: damage.idDetailDommageSinapps || '',
  }))
}

const toCalculSinappsCompensation = (compensations: ApiIndemnisationSinapps[]): IndemnisationSinappsExistante[] => {
  return compensations.map((compensation) => ({
    ...compensation,
    idGarantie: compensation.garantie?.code || '',
  }))
}

const toApiCompensation = (compensations: CalculIndemnisation[], natureDetailBien: NatureBien[]): ApiIndemnisation[] => {
  return compensations.map((compensation) => ({
    ...compensation,
    id: createStakeholerUuid(),
    modeIndemnisation: { code: compensation.typeReparation as TypeDeReparation, libelle: compensation.typeReparation },
    detailDuBien: findCodeLabelUndefined(natureDetailBien.flatMap((nature) => nature.detailsDuBien ?? []), compensation.codeDetail),
    natureDuBien: findCodeLabelUndefinedOrStrict(natureDetailBien, compensation.codeNature),
    natureDuBienSinapps: compensation.codeNatureSinapps ? { code: compensation.codeNatureSinapps } : undefined,
    detailDuBienSinapps: compensation.codeDetailSinapps ? { code: compensation.codeDetailSinapps } : undefined,
    garantie: { code: compensation.idGarantie },
    typeDeReparation: { code: compensation.typeReparation as TypeDeReparation, libelle: compensation.typeReparation },
  }))
}

const toApiSinappsDamages = (sinappsDamages: CalculDommageSinapps[], natureDetailBien: NatureBien[]): ApiDommageSinapps[] => {
  return sinappsDamages.map((sinappsDamage: CalculDommageSinapps) => ({
    ...sinappsDamage,
    id: sinappsDamage.idDommageSinapps,
    detailDuBien: findCodeLabelUndefined(natureDetailBien.flatMap((nature) => nature.detailsDuBien ?? []), sinappsDamage.codeDetail),
    natureDuBien: findCodeLabelUndefinedOrStrict(natureDetailBien, sinappsDamage.codeNature),
    natureDuBienSinapps: sinappsDamage.codeNatureSinapps ? { code: sinappsDamage.codeNatureSinapps } : undefined,
    detailDuBienSinapps: sinappsDamage.codeDetailSinapps ? { code: sinappsDamage.codeDetailSinapps } : undefined,
    modeIndemnisation: { code: sinappsDamage.modeIndemnisation as TypeDeReparation, libelle: sinappsDamage.modeIndemnisation },
  }))
}

const toApiSinappsPropertyDamage = (sinappsDamages: CalculDommageBienSinapps[]): ApiDommageBienSinapps[] => {
  return sinappsDamages.map((sinappsDamage: CalculDommageBienSinapps) => ({
    ...sinappsDamage,
    id: sinappsDamage.idDommageSinapps,
    typeBien: sinappsDamage.typeBien as TypeDeBien,
    justification: sinappsDamage.justification ? { code: sinappsDamage.justification as Justifications, libelle: sinappsDamage.justification } : undefined,
    descriptif: sinappsDamage.libelle,
    montantVetusteRecuperableHT: sinappsDamage.montantVetusteRecuperableHT,
    montantVetusteRecuperableTTC: sinappsDamage.montantVetusteRecuperableTTC,
  }))
}

const toApiSinappsCompensation = (sinappsCompensations: CalculIndemnisationSinapps[]): ApiIndemnisationSinapps[] => {
  return sinappsCompensations.map((sinappsCompensation: CalculIndemnisationSinapps) => ({
    ...sinappsCompensation,
    garantie: { code: sinappsCompensation.idGarantie },
  }))
}

interface CalculateAllCompensationProps {
  damages: ApiDommage[];
  natureDetailBien: NatureBien[];
  isSinapps: boolean;
  oldCompensation?: IndemnisationTraveller;
}

export const calculateAllCompensations = ({ damages, isSinapps, natureDetailBien, oldCompensation }: CalculateAllCompensationProps): IndemnisationTraveller => {
  const newDamages: CalculDommage[] = toCalculDamages(damages)
  const compensations: CalculIndemnisation[] = calculIndemnisation(newDamages, toCalculCompensation(oldCompensation?.indemnisations ?? []))

  if (!isSinapps) {
    return ({
      indemnisations: toApiCompensation(compensations, natureDetailBien),
    })
  }

  const sinappsDamages: CalculDommageSinapps[] = getListeDommagesSinapps(newDamages, compensations, toCalculSinappsDamage(oldCompensation?.dommagesSinapps ?? []))
  const sinappsPropertyDamage: CalculDommageBienSinapps[] = getListeBiensSinapps(newDamages, compensations, toCalculSinappsPropertyDamage(oldCompensation?.dommagesBienSinapps ?? []))
  const sinappsCompensations: CalculIndemnisationSinapps[] = calculIndemnisationSinapps(sinappsDamages, sinappsPropertyDamage, toCalculSinappsCompensation(oldCompensation?.indemnisationsSinapps ?? []))

  return ({
    indemnisations: toApiCompensation(compensations, natureDetailBien),
    dommagesSinapps: toApiSinappsDamages(sinappsDamages, natureDetailBien),
    dommagesBienSinapps: toApiSinappsPropertyDamage(sinappsPropertyDamage),
    indemnisationsSinapps: toApiSinappsCompensation(sinappsCompensations),
    accord: oldCompensation?.accord,
    regime: oldCompensation?.regime,
  })
}

/* Framework imports -------------------------------------------------------- */
import React, { useMemo } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useDispatch } from 'react-redux'
import { setCaseSelectedReport } from 'store/slices/reportSlice'

/* Component imports -------------------------------------------------------- */
import { KeyboardArrowDownRounded } from '@mui/icons-material'
import ActionTitle from 'components/ActionTitle/ActionTitle'
import ReportStatusButton from 'components/ReportStatusButton/ReportStatusButton'

/* Type imports ------------------------------------------------------------- */
import { ReportStatus } from 'types/ReportStatus'
import { EtatRapport } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const ReportMenuItemLeftSideContainer = styled.div`
  display: flex;
  align-items: center;
`

interface DropDownArrowProps {
  open: boolean;
}

const DropDownArrow = styled(KeyboardArrowDownRounded)<DropDownArrowProps>`
  font-size: 2rem;
  margin-top: .4rem;
  transform: scaleY(${(props) => props.open ? '-1' : 1});
  transition: transform .2s;
`

interface ReportTitleProps {
  selected: boolean;
}

const ReportTitle = styled(ActionTitle)<ReportTitleProps>`
  padding: ${(props) => !props.selected ? '0 1rem' : ''};
`

const ReportMenuItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey};
  background-color: white;
  width: 100%;
  height: 70px;
`

/* Component declaration ---------------------------------------------------- */
interface ReportMenuItemProps {
  caseId: string;
  reportId?: string;
  reportStatus?: EtatRapport;
  children?: React.ReactNode;
  selected?: boolean;
  isOpen?: boolean;
}

const ReportMenuItem: React.FC<ReportMenuItemProps> = ({
  caseId,
  reportId,
  reportStatus,
  children,
  selected = false,
  isOpen = false,
}) => {
  const dispatch = useDispatch()

  const handleClick = (): void => {
    if (!selected && reportId) {
      dispatch(setCaseSelectedReport({ caseId, selectedReport: reportId }))
    }
  }

  const getReportStatus: ReportStatus= useMemo(() => {
    switch (reportStatus) {
      case EtatRapport.Cloture:
        return ReportStatus.Depose
      case EtatRapport.Gestion:
        return ReportStatus.ARediger
      case EtatRapport.Qualification:
        return ReportStatus.ARediger
      default:
        return ReportStatus.ARediger
    }
  }, [ reportStatus ])

  return (
    <ReportMenuItemContainer onClick={handleClick}>
      <ReportMenuItemLeftSideContainer>
        {selected && <DropDownArrow open={isOpen} />}
        <ReportTitle selected={selected}>
          {
            reportId === '' ?
              '' :
              reportId ?
                `Rapport n°${reportId.split('-').pop()}` :
                `Aucun rapport trouvé`
          }
        </ReportTitle>
        {
          reportId &&
            <ReportStatusButton
              status={getReportStatus}
              reportId={reportId}
              caseId={caseId}
            />
        }
      </ReportMenuItemLeftSideContainer>
      {children}
    </ReportMenuItemContainer>
  )
}

export default ReportMenuItem

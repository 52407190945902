/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */

/* Component imports -------------------------------------------------------- */
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'

/* Type imports ------------------------------------------------------------- */

/* Styled components -------------------------------------------------------- */
interface StepDivProps {
  sideBorder?: boolean;
}

const StepDiv = styled.div<StepDivProps>`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  margin-top: 5px;
  border-right: ${(props) => props.sideBorder ? `1px solid ${props.theme.colors.grey}` : 'none'};
`

const StepName = styled.span`
  color: ${(props) => props.theme.palette.secondary.main};
  text-align: center;
  font-size: .8rem;
`

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowStepProps {
  complete?: boolean;
  stepName: string;
  sideBorder?: boolean;
}

const CaseWorkflowStep: React.FC<CaseWorkflowStepProps> = ({
  complete = false,
  stepName,
  sideBorder = false,
}) => {

  return (
    <StepDiv sideBorder={sideBorder}>
      <CheckRoundedIcon color={complete ? 'primary' : 'disabled'} />
      <StepName>
        {stepName}
      </StepName>
    </StepDiv>
  )
}

export default CaseWorkflowStep

/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'
/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'
import { getAddress } from 'helpers/getAddress'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  CardContent,
  Popover,
} from '@mui/material'
import PlanningCalendarEventDayView from './PlanningCalendarEventView/PlanningCalendarEventDayView'
import PlanningCalendarEventWeekView from './PlanningCalendarEventView/PlanningCalendarEventWeekView'
import PlanningCalendarEventMonthView from './PlanningCalendarEventView/PlanningCalendarEventMonthView'

/* Type imports ------------------------------------------------------------- */
import type { EventContentArg } from '@fullcalendar/core'
import { CalendarViewTypes } from 'helpers/FullCalendarOptions'
import type { Planning } from 'API/__generated__/Api'
import type { PopoverInfoType } from 'types/EventHandler'

/* Styled components -------------------------------------------------------- */
const CardContentContainer = styled(CardContent)`
  font-size: .85rem;
`

const BoldText = styled.div`
  font-weight: bold;
  display: inline;
`

/* Component declaration ---------------------------------------------------- */
interface PlanningCalendarProps {
  viewType: CalendarViewTypes;
  event: Planning;
  eventInfo: EventContentArg;
  openPopoverInfo: PopoverInfoType;
  clearPopoverInfo: () => void;
}

const PlanningCalendarEventRender: React.FC<PlanningCalendarProps> = ({
  viewType,
  event,
  eventInfo,
  openPopoverInfo,
  clearPopoverInfo,
}) => {

  const renderEventContent = (eventInfo: EventContentArg): React.ReactElement | undefined => {
    switch (viewType) {
      case CalendarViewTypes.Day:
        if (eventInfo.backgroundColor !== 'transparent') return undefined
        return <PlanningCalendarEventDayView event={event} />
      case CalendarViewTypes.Week:
        return (
          <PlanningCalendarEventWeekView
            eventInfo={eventInfo}
            event={event}
          />
        )
      case CalendarViewTypes.Month:
        return (
          <PlanningCalendarEventMonthView
            event={event}
            eventInfo={eventInfo}
          />
        )
      default:
        return <div />
    }
  }

  return (
    <>
      {renderEventContent(eventInfo)}
      <Popover
        open={eventInfo.event.id === openPopoverInfo.id}
        anchorReference="anchorPosition"
        anchorPosition={{ top: openPopoverInfo.y || 0, left: openPopoverInfo.x || 0 }}
        onClose={clearPopoverInfo}
      >
        <Card>
          <CardContentContainer>
            <div>
              <BoldText>
                {DateUtils.apiStrToLocalTimeString(event?.dateDebut, { hour: '2-digit', minute: '2-digit' })}
                {' - '}
                {DateUtils.apiStrToLocalTimeString(event?.dateFin, { hour: '2-digit', minute: '2-digit' })}
                {' : '}
              </BoldText>
              {event?.typeRDV.libelle}
            </div>
            {
              openPopoverInfo.isRecurring === false &&
                <>
                  <div>
                    <BoldText>
                      {'Dossier : '}
                    </BoldText>
                    {event?.refDossier?.refComplete}
                  </div>
                  <div>
                    <BoldText>
                      {'Référence sinistre : '}
                    </BoldText>
                    {event?.referenceSinistre}
                  </div>
                  <div>
                    <BoldText>
                      {'Nature sinistre : '}
                    </BoldText>
                    {event?.natureSinistre?.libelle}
                  </div>
                  <div>
                    {' '}
                    <BoldText>
                      {'Personnes conviées : '}
                    </BoldText>
                    {
                      event?.conviees?.map((person) => (
                        <div key={person.id}>
                          {person.nom}
                          <br />
                        </div>
                      ))
                    }
                  </div>
                  <div>
                    <BoldText>
                      {'Lieu de rendez-vous : '}
                    </BoldText>
                    <br />
                    {getAddress(event.adresse)}
                  </div>
                </>
            }
            <div>
              <BoldText>
                {'Commentaire : '}
              </BoldText>
              <br />
              {event?.commentaire}
            </div>
          </CardContentContainer>
        </Card>
      </Popover>
    </>
  )
}

export default PlanningCalendarEventRender

/* Framework imports -------------------------------------------------------- */
import styled from '@emotion/styled'

/* Styled components -------------------------------------------------------- */
const CompensationTableBoldSeparator = styled.div`
  width: calc(100% + 40px);
  margin-left: -20px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
`

/* Export CompensationTableBoldSeparator component ----------------------- */
export default CompensationTableBoldSeparator

/* Framework imports -------------------------------------------------------- */
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */

/* Component imports -------------------------------------------------------- */

/* Type imports ------------------------------------------------------------- */

/* Component declaration ---------------------------------------------------- */
const TravelerLargeTitle = styled.div`
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;
  font-size: 1.75rem;
  margin-bottom: 10px;
  margin-top: 20px;
  text-transform: uppercase;
  display: flex;
`

export default TravelerLargeTitle

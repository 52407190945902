/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useAppSelector } from 'store/hooks'
import { usePostSaveEventDocumentMutation } from 'store/api'
import { getCourriers } from 'store/slices/courrierSlice'
import { isValidString } from 'helpers/isValidString'
import { isApiResponse } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import { Collapse } from '@mui/material'
import { KeyboardArrowDownRounded } from '@mui/icons-material'
import { toast } from 'react-toastify'
import PageContainer from 'layouts/PageContainer/PageContainer'
import HeaderAction from 'layouts/MainLayout/Headers/HeadersComponents/HeaderAction'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'
import MediaProcessorFallback from 'components/MediaProcessors/MediaProcessorFallback/MediaProcessorFallback'

/* Type imports ------------------------------------------------------------- */
import type { DocumentEditorContainerComponent } from '@syncfusion/ej2-react-documenteditor'
import type { DataDocument } from 'API/__generated__/Api'
import { TypeEnregistrementCourrier } from 'API/__generated__/Api'
import type { ApiResponse } from 'helpers/fetchHelpers'

/* Lazy component imports ------------------------------- */
const WordProcessor = React.lazy(() => import(/* webpackPrefetch: true */ 'components/MediaProcessors/WordProcessor/WordProcessor' /* webpackChunkName: "word" */))

/* Type declarations -------------------------------------------------------- */

/* Internal variables ------------------------------------------------------- */

/* Styled components -------------------------------------------------------- */
const WordPageContainer = styled(PageContainer)`
  padding: 0px 5px 7px 0px !important;
  margin: -10px 0px 0px -10px;
  overflow: hidden;
`

interface CollapseContainerProps {
  top: number;
}

const CollapseContainer = styled(Collapse)<CollapseContainerProps>`
  position: absolute;
  width: 100%;
  overflow-y: auto;
  max-height: calc(100vh - ${(props) => props.top}px);
  z-index: 204;
  top: ${(props) => props.top}px;
  background-color: white;
  padding: 0px !important;
`

const CourrierItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey};
  background-color: white;
  width: 100%;
  height: 50px;
  color: ${(props) => props.theme.palette.primary.main};
  cursor: pointer;
  padding: 10px;

  &:hover {
    background-color: #96b0f926;
  }
`

const ClickableTitle = styled.div`
  cursor: pointer;
  padding: 5px 10px;
  display: flex;

  &:hover {
    background-color: #96b0f926;
    border-radius: 4px;
  }
`

interface DropDownArrowProps {
  open: boolean;
}

const DropDownArrow = styled(KeyboardArrowDownRounded)<DropDownArrowProps>`
  font-size: 2rem;
  margin-top: -3px;
  transform: scaleY(${(props) => props.open ? '-1' : 1});
  transition: transform .2s;
`

/* Component declaration ---------------------------------------------------- */
interface DocumentProcessorPageProps {}

const DocumentProcessorPage: React.FC<DocumentProcessorPageProps> = () => {
  const courriers: DataDocument[] = useAppSelector(getCourriers).courriers
  const [ wordRef, setWordRef ] = useState<DocumentEditorContainerComponent | null>(null)
  const [ isSaving, setIsSaving ] = useState<boolean>(false)
  const [ isOpen, setIsOpen ] = useState<boolean>(false)
  const [ selected, setSelected ] = useState<number>(0)
  const [ editedCourriers, setEditedCourriers ] = useState<DataDocument[]>(courriers ?? [])

  const [
    saveDocument,
  ] = usePostSaveEventDocumentMutation()

  useEffect(() => {
    setEditedCourriers(courriers ?? [])
  }, [ courriers ])

  useEffect(() => {
    if (isValidString(editedCourriers[selected]?.jsonData ?? courriers[selected]?.jsonData)) {
      wordRef?.documentEditor?.open(editedCourriers[selected].jsonData ?? courriers[selected]?.jsonData)
    }
  }, [ courriers, wordRef?.documentEditor, selected ])

  const onSelectChange = (newValue: number) => {
    setEditedCourriers([ ...editedCourriers.map((courrier, index) => index === selected ? { ...courrier, jsonData: wordRef?.documentEditor.serialize() || '' } : courrier) ])
    setSelected(newValue)
    setIsOpen(false)
  }

  const onSave = async (saveType: TypeEnregistrementCourrier) => {
    for (let index = 0; index < editedCourriers.length; index++) {
      const response: ApiResponse<void> = await saveDocument({
        data: {
          ...editedCourriers[index],
          docId: editedCourriers[index].docId,
          typeEnregistrement: saveType,
          jsonData: index === selected ? wordRef?.documentEditor.serialize() || '' : editedCourriers[index].jsonData,
        },
      })
      if (isApiResponse<void>(response)) {
        toast.success(`Le document ${editedCourriers[index].docId} à bien été enregistré ${saveType === TypeEnregistrementCourrier.Definitif ? 'en définitif' : 'à la frappe'}`)
      } else {
        toast.error(`Une erreur est survenue lors de l'enregistrement du document ${editedCourriers[index].docId}`)
      }
    }
  }

  const onSubmit = (saveType: TypeEnregistrementCourrier) => {
    setIsSaving(true)
    onSave(saveType).catch(console.error).finally(() => setIsSaving(false))
  }

  const headerHeight = useMemo(() => document.getElementById('document-processor-id')?.offsetTop ?? 180, [])

  return (
    <>
      <>
        <HeaderAction
          title={
            <ClickableTitle onClick={() => setIsOpen(!isOpen)}>
              {`Document ${courriers[selected]?.docId || ''}`}
              {courriers.length > 1 && <DropDownArrow open={isOpen} />}
            </ClickableTitle>
          }
          onSubmit={() => {}}
        >
          <SubmitFormButton
            variant="contained"
            disabled={isSaving}
            onClick={() => onSubmit(TypeEnregistrementCourrier.Frappe)}
          >
            {courriers.length > 1 ? 'Tout enregistrer à la frappe': 'Enregistrer à la frappe'}
          </SubmitFormButton>
          <SubmitFormButton
            variant="contained"
            disabled={isSaving}
            onClick={() => onSubmit(TypeEnregistrementCourrier.Definitif)}
          >
            {courriers.length > 1 ? 'Tout enregistrer en définitif': 'Enregistrer en définitif'}
          </SubmitFormButton>
        </HeaderAction>
        <div id="document-processor-id" />
        <CollapseContainer
          in={isOpen}
          timeout="auto"
          unmountOnExit
          top={headerHeight}
        >
          {
            courriers.length > 1 && courriers.map((courrier, index) =>
              index !== selected &&
                <CourrierItem
                  key={`courrier-${courrier.docId}-${index}`}
                  onClick={() => onSelectChange(index)}
                >
                  {courrier.docId}
                </CourrierItem>,
            )
          }
        </CollapseContainer>
      </>
      <WordPageContainer>
        <MediaProcessorFallback type="word">
          <WordProcessor
            enableToolbar
            isLoading={isSaving}
            wordRef={wordRef}
            setWordRef={setWordRef}
            disabled={isSaving}
          />
        </MediaProcessorFallback>
      </WordPageContainer>
    </>
  )
}

export default DocumentProcessorPage

/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useGetDocumentCategoryListQuery } from 'store/api'
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'
import { useIsConnected } from 'helpers/hooks/useIsConnected'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  CircularProgress,
  MenuItem,
} from '@mui/material'
import { Field } from 'formik'
import {
  Select,
  TextField,
} from 'formik-mui'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'
import ImageHoverContainer from './ImageHoverContainer'
import VignetteRenderer from './VignetteRenderer'

/* Type imports ------------------------------------------------------------- */
import type { PieceJointe } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */

/* Internal variables ------------------------------------------------------- */

/* Styled components -------------------------------------------------------- */
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, auto));
  gap: 10px;

  align-items: stretch;
  justify-content: stretch;
`

const FormButton = styled(SubmitFormButton)`
  margin-left: 0;
`

const CardContainer = styled(Card)`
  height: 150px;
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  display: flex;
`

const ImageEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`

const HorizontalImageHoverContainer = styled(ImageHoverContainer)`
  width: fit-content;
  margin-right: 10px;
`

/* Component declaration ---------------------------------------------------- */
interface DocumentNameEditorProps {
  file?: File;
  document?: PieceJointe;
  onRemove: () => void;
  onEdition?: () => void;
  fieldNames: {name: string; category: string};
  category?: string | null;
}

const DocumentNameEditor: React.FC<DocumentNameEditorProps> = ({
  file,
  document,
  onRemove,
  onEdition,
  fieldNames,
  category,
}) => {
  const isConnected = useIsConnected()

  const {
    currentData: categoryList = [],
    isFetching: isFetchingCategoryList,
  } = useGetDocumentCategoryListQuery({ onlySinapps: false })

  return (
    <CardContainer>
      <HorizontalImageHoverContainer onClick={isConnected ? onEdition : (): void => {}}>
        <VignetteRenderer
          file={file}
          document={document}
          height="128px"
          width="100px"
        />
      </HorizontalImageHoverContainer>
      <ImageEditorContainer>
        <Field
          component={TextField}
          placeholder="Nom du fichier"
          size="small"
          name={fieldNames.name}
        />
        {
          isFetchingCategoryList ?
            <CircularProgress /> :
            <Field
              component={Select}
              name={fieldNames.category}
              displayEmpty
              size="small"
              renderValue={verifySelectFieldValue(category)}
              disabled={isFetchingCategoryList}
            >
              {
                categoryList.map((category, index) => (
                  <MenuItem
                    value={category.categorie.code}
                    key={`${category.categorie.code}-${index}`}
                  >
                    {category.categorie.libelle}
                  </MenuItem>
                ))
              }
            </Field>
        }
        <GridContainer>
          <FormButton
            onClick={onRemove}
            variant="outlined"
          >
            Supprimer
          </FormButton>
          {
            onEdition !== undefined &&
              <FormButton
                variant="contained"
                type="submit"
                disabled={!isConnected}
              >
                Editer
              </FormButton>
          }
        </GridContainer>
      </ImageEditorContainer>
    </CardContainer>
  )
}

export default DocumentNameEditor

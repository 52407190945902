/* Framework imports -------------------------------------------------------- */
import { createSlice } from '@reduxjs/toolkit'

/* Module imports ----------------------------------------------------------- */

/* Type imports ------------------------------------------------------------- */
import type { PayloadAction } from '@reduxjs/toolkit'
import type {
  PossiblePayloadSchema,
  RootState,
} from 'store/store'

export interface RequestsQueue {
  requests: PossiblePayloadSchema[];
  lastRequestId: string | null;
}

/* Requests Redux slice ------------------------------------ */
const requestsSlice = createSlice(
  {
    name: 'requestsQueue',
    initialState: {
      requests: [],
      lastRequestId: null,
    } as RequestsQueue,
    reducers: {
      pushRequest: (
        state,
        { payload }: PayloadAction<PossiblePayloadSchema>,
      ) => {
        return {
          ...state,
          requests: [ ...state.requests, payload ],
        }
      },
      pushRequestFirst: (
        state,
        { payload }: PayloadAction<PossiblePayloadSchema>,
      ) => {
        return {
          ...state,
          requests: [ payload, ...state.requests ],
        }
      },
      setRequests: (
        state,
        { payload }: PayloadAction<RequestsQueue>,
      ) => {
        return {
          ...state,
          requests: payload.requests,
        }
      },
      setLastRequestId: (
        state,
        { payload }: PayloadAction<string>,
      ) => {
        return {
          ...state,
          lastRequestId: payload,
        }
      },
      popFirstRequests: (
        state,
      ) => {
        const newRequestArray = [ ...state.requests ]
        // ?? Mehdy from Log in Line let that here
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const firstRequest = newRequestArray.shift()
        return {
          lastRequestId: null,
          requests: newRequestArray,
        }
      },
    },
  },
)

/* Export slice components ----------------------------- */
export const {
  pushRequest,
  pushRequestFirst,
  setRequests,
  setLastRequestId,
  popFirstRequests,
} = requestsSlice.actions

export default requestsSlice.reducer

export const selectRequestsInfo = (state: RootState): RequestsQueue => {
  return state.requests
}

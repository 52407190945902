/* Framework imports -------------------------------------------------------- */
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import { isValidString } from 'helpers/isValidString'

/* Type imports ------------------------------------------------------------- */
import type { FormikContextType } from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  Taxe,
  ActeurTraveller,
  IntervenantTraveller,
  ProtectionVol,
  Risque,
  Traveller,
  Dommage,
  DommageValeur,
  DommageVetuste,
  CodeLabel,
  TauxTVA,
  Adresse,
  RecoursTraveller,
  ReglementTraveller,
  ActeurRendezVousStatut,
  Garantie,
  CibleRecours,
  Irsi,
  Identification,
  Expert,
  TypeExpertiseIrsi,
  TypeExpertiseIrsiEnumLabel,
  RisqueIrsi,
  Circonstance,
  Cause,
  RecoursIrsi,
  Chiffrage,
  PersonneImplique,
  OuiNonIndetermine,
  RechercheAnnuaireListParams,
  Conformite,
  Sinistre,
  InfosSinistreSinapps,
} from 'API/__generated__/Api'
import {
  TypeDeBien,
  TypePersonne,
  Verif,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
export interface NewDommageRequest {
  personneConcernee: string;
  pieceCompteur: number;
  pieceLibelle: string;
}

interface NewDamage {
  newDamage: Dommage & NewDommageRequest;
}

interface NewPersonRequest {
  customQualifiants?: string[];
  tiersLie?: string;
  acteurStatus: ActeurRendezVousStatut;
  recherche: RechercheAnnuaireListParams;
}

export type NewPersonType = ActeurTraveller & IntervenantTraveller & NewPersonRequest

interface NewPerson {
  newPerson: NewPersonType;
}

interface NewRecourse {
  newRecourse: RecoursTraveller;
}

export interface NewPaymentRequest {
  surPlace: boolean;
  acteurId: string;
}

interface NewPayment {
  newPayment: ReglementTraveller & NewPaymentRequest;
}

interface IrsiTraveler {
  irsiData: Irsi;
}

interface UtilsTraveler {
  disabled: boolean;
}

export type TravelerAndUtils = Traveller & UtilsTraveler & IrsiTraveler & NewDamage & NewPerson & NewRecourse & NewPayment

export type TravelerForm = FormikContextType<TravelerAndUtils>

const dommageVerification = {
  libelle: Yup.string().required('Le libellé est obligatoire'),
  valeur: Yup.object<Shape<DommageValeur>>({
    quantite: Yup.number()
      .min(1, 'La quantité doit être supérieure à 0')
      .required('La quantité est obligatoire'),
    prixUnitaire: Yup.number()
      .min(0.0001, 'Le prix doit être supérieur à 0')
      .required('Le prix unitaire est obligatoire'),
    uniteMesure: Yup.mixed<CodeLabel>().nullable(),
    tauxTva: Yup.mixed<TauxTVA>().test(
      'taux-Tva-code-test',
      'Le taux de TVA est obligatoire',
      (value) => isValidString(value?.tva?.code),
    ).required('Le taux de TVA est obligatoire'),
    montantHT: Yup.number().min(0, 'Le montant doit être positif'),
    montantTTC: Yup.number().min(0, 'Le montant doit être positif'),
    montantTva: Yup.number().min(0, 'Le montant doit être positif'),
    taxe: Yup.mixed<Taxe>().required(),
  }).required(),
  vetuste: Yup.object<Shape<DommageVetuste>>({
    tauxVetuste: Yup.number()
      .min(0, 'Le taux doit être compris entre 0 et 100')
      .max(100, 'Le taux doit être compris entre 0 et 100'),
    tauxLimiteVetuste: Yup.number()
      .min(0, 'Le taux doit être compris entre 0 et 100')
      .max(100, 'Le taux doit être compris entre 0 et 100'),
    age: Yup.number()
      .min(0, "L'âge doit être positif")
      .nullable(),
    montantVetuste: Yup.number().min(0, 'Le montant doit être positif'),
    montantVetusteRecuperable: Yup.number().min(0, 'Le montant doit être positif'),
    montantVetusteDeduite: Yup.number().min(0, 'Le montant doit être positif'),
    dateAchat: Yup.string().nullable().when('typeBien', {
      is: (type: TypeDeBien) => type === TypeDeBien.Mobilier,
      then: (schema) => schema.nullable(),
    }),
  }).required(),
}

const irsiVerification = {
  irsiData: Yup.object<Shape<Irsi>>({
    identification: Yup.object<Shape<Identification>>({
      refSinistre: Yup.string().required('La référence sinistre est obligatoire'),
      noMission: Yup.string().required('Le numéro de mission est obligatoire'),
      dateMission: Yup.string().nullable(),
    }).required(),
    expert: Yup.object<Shape<Expert>>({
      nom: Yup.string().required('Le nom est obligatoire'),
      reference: Yup.string().required('Le nom est obligatoire'),
      dateExpertise: Yup.string().nullable().required('La date est obligatoire'),
      typeExpertise: Yup.object<Shape<TypeExpertiseIrsiEnumLabel>>({
        code: Yup.mixed<TypeExpertiseIrsi>().required("Le type d'expertise est obligatoire"),
      }).required(),
      email: Yup.string().required("L'email est obligatoire"),
      telephone: Yup.string().required('Le téléphone est obligatoire'),
    }).required(),
    risque: Yup.object<Shape<RisqueIrsi>>({
      statutImmeuble: Yup.mixed<CodeLabel>().test(
        'statutImmeuble-code-test',
        "Le statut de l'immeuble est obligatoire",
        (value) => isValidString(value?.code),
      ).required("Le statut de l'immeuble est obligatoire"),
      usageAssure: Yup.mixed<CodeLabel>().test(
        'usageAssure-code-test',
        "L'usage assuré est obligatoire",
        (value) => isValidString(value?.code),
      ).required("L'usage assuré est obligatoire"),
    }).required(),
    circonstance: Yup.object<Shape<Circonstance>>({
      dateCirconstance: Yup.string().nullable().required('La date est obligatoire'),
      natureSinistre: Yup.mixed<CodeLabel>().test(
        'natureSinistre-code-test',
        'La nature du sinistre est obligatoire',
        (value) => isValidString(value?.code),
      ).required('La nature du sinistre est obligatoire'),
      // datePremierSinistre: Yup.string().nullable(),
    }).required(),
    causes: Yup.array(Yup.object<Shape<Cause>>({
      causeSinistre: Yup.mixed<CodeLabel>().test(
        'causeSinistre-code-test',
        'La cause du sinistre est obligatoire',
        (value) => isValidString(value?.code),
      ).required('La cause du sinistre est obligatoire'),
      causeSupprimee: Yup.mixed<CodeLabel>().test(
        'causeSupprimee-code-test',
        'La cause supprimée est obligatoire',
        (value) => isValidString(value?.code),
      ).required('La cause du sinistre est obligatoire'),
      casBareme: Yup.mixed<CodeLabel>().test(
        'casBareme-code-test',
        'Le cas barême est obligatoire',
        (value) => isValidString(value?.code),
      ).required('La cause du sinistre est obligatoire'),
      causeBareme: Yup.mixed<CodeLabel>().test(
        'causeBareme-code-test',
        'La cause barême est obligatoire',
        (value) => isValidString(value?.code),
      ),
    })).required(),
    recours: Yup.object<Shape<RecoursIrsi>>({
      po: Yup.number().min(0, 'Le montant doit être positif').required('Ce montant est requis'),
      occupant: Yup.number().min(0, 'Le montant doit être positif').required('Ce montant est requis'),
      pnoCno: Yup.number().min(0, 'Le montant doit être positif').required('Ce montant est requis'),
      immeuble: Yup.number().min(0, 'Le montant doit être positif').required('Ce montant est requis'),
    }).required(),
    personnesImpliquees: Yup.array(Yup.object<Shape<PersonneImplique>>({
      presente: Yup.boolean().required('Ce champ est obligatoire'),
      qualite: Yup.mixed<CodeLabel>().test(
        'personneImpliqueQualite-code-test',
        'La qualité est obligatoire',
        (value) => isValidString(value?.code),
      ),
      id: Yup.string().required(),
      nom: Yup.string().required('Le nom est obligatoire'),
      chiffrage: Yup.object<Shape<Chiffrage>>({
      }).required(),
    })).required(),
  }),
}

export const travelerSchema = Yup.object<Shape<TravelerAndUtils>>({
  sinistre: Yup.object<Shape<Sinistre>>({
    infosSinapps: Yup.object<Shape<InfosSinistreSinapps>>({
      nature: Yup.mixed<CodeLabel>().nullable(),
      causeSupprimee: Yup.boolean().nullable(),
      motifCauseNonSupprimee: Yup.mixed<CodeLabel>(),
      commentaireCauseSupprimee: Yup.string().when([ 'causeSupprimee', 'motifCauseNonSupprimee', 'nature' ], {
        is: (causeSupprimee: boolean | undefined, motifCauseNonSupprimee: CodeLabel | undefined, nature: CodeLabel | null) => causeSupprimee === false && motifCauseNonSupprimee?.code === 'Autre' && nature?.code !== 'DegatsDesEaux',
        then: (schema) => schema.nullable().required('Quand le motif est "Autre", le commentaire est obligatoire'),
      }),
    }).nullable(),
  }).required(),
  conformiteDuRisque: Yup.object<Shape<Risque>>({
    protectionVol: Yup.object<Shape<ProtectionVol>>({
      commentaire: Yup.string().max(4000, 'Le commentaire est trop long (la limite est de 4000 caractères)'),
    }),
    conformite: Yup.object<Shape<Conformite>>({
      commentaire: Yup.string().nullable().when([ 'verification', 'motifNonVerification' ], {
        is: (verification: Verif, motifNonVerification: CodeLabel) => verification === Verif.NonVerifie && motifNonVerification?.code === 'Autre',
        then: (schema) => schema.nullable().required('Quand le motif est "Autre", le commentaire est obligatoire'),
      }),
      garantieEtOuCapitauxInsuffisantsCommentaire: Yup.string().nullable().when([ 'verification', 'garantieEtOuCapitauxInsuffisants' ], {
        is: (verification: Verif, garantieEtOuCapitauxInsuffisants: boolean) => verification === Verif.Non && garantieEtOuCapitauxInsuffisants,
        then: (schema) => schema.nullable().required('Le commentaire est obligatoire'),
      }),
      autreRejetCommentaire: Yup.string().nullable().when([ 'verification', 'autreRejet' ], {
        is: (verification: Verif, autreRejet: boolean) => verification === Verif.Non && autreRejet,
        then: (schema) => schema.nullable().required('Le commentaire est obligatoire'),
      }),
      // Added default to prevent an error of validation
    }).default({}),
  }),
  garanties: Yup.array(Yup.object<Shape<Garantie>>({
    code: Yup.mixed<CodeLabel>().test(
      'garanties-code-test',
      'Le type de garantie est obligatoire',
      (value) => isValidString(value?.code),
    ).required('Le type de garantie est obligatoire'),
    commentaire: Yup.string().when('acceptation', {
      is: (acceptation: CodeLabel) => acceptation?.code !== 'SansReserve',
      then: (schema) => schema.required("Le commentaire est obligatoire si l'acceptation de la garantie n'est pas sans reserve."),
    }),
  })),
  newDamage: Yup.object<Shape<Dommage & NewDommageRequest>>({
    ...dommageVerification,
    personneConcernee: Yup.string().required(),
    pieceCompteur: Yup.number().nullable().when('typeBien', {
      is: (type: TypeDeBien) => type === TypeDeBien.Immobilier || type === TypeDeBien.Embellissement,
      then: (schema) => schema.min(0, 'La pièce est obligatoire').required('La pièce est obligatoire'),
    }),
  }),
  newPerson: Yup.object<Shape<NewPersonType>>({
    type: Yup.mixed<TypePersonne>().required('Ce champ est obligatoire'),
    nom: Yup.string().required('Le nom est obligatoire'),
    qualite: Yup.mixed<CodeLabel>().test(
      'newPerson-qualite-code-test',
      'La qualité est obligatoire',
      (value) => isValidString(value?.code),
    ).required('La qualité est obligatoire'),
    adresse: Yup.object<Shape<Adresse>>({
    }).required("L'adresse est obligatoire"),
    mandant: Yup.boolean().required().default(false),
    tiersLie: Yup.string().when([ 'id', 'type' ], {
      is: (id: string, type: TypePersonne) => type === TypePersonne.Intervenant && !isValidString(id),
      then: (schema) => schema.required('Le tiers lié est obligatoire'),
    }),
    professionnel: Yup.boolean().when([ 'id', 'type' ], {
      is: (id: string, type: TypePersonne) => type === TypePersonne.Intervenant && !isValidString(id),
      then: (schema) => schema.required().default(false),
    }),
    recherche: Yup.object<Shape<RechercheAnnuaireListParams>>({
      code: Yup.string(),
      departement: Yup.string(),
      telephone: Yup.string(),
      ville: Yup.string(),
      famille: Yup.string().required('La famille est obligatoire'),
      pro: Yup.mixed<OuiNonIndetermine>(),
    }).required(),
  }),
  newRecourse: Yup.object<Shape<RecoursTraveller>>({
    cibleRecours: Yup.object<Shape<CibleRecours>>({
      id: Yup.string().required('La cible est obligatoire'),
    }).required(),
  }),
  newPayment: Yup.object<Shape<ReglementTraveller & NewPaymentRequest>>({
    acteurId: Yup.string().required(),
    surPlace: Yup.boolean().required(),
    emetteur: Yup.string().when('surPlace', {
      is: true,
      then: (schema) => schema.required("L'émetteur est obligatoire"),
    }),
    dateReglement: Yup.string().when('surPlace', {
      is: true,
      then: (schema) => schema.nullable().required('La date est obligatoire'),
    }),
    numeroCheque: Yup.string().when('surPlace', {
      is: true,
      then: (schema) => schema.length(7, 'Numéro incorrect').required('Le numéro du chèque est obligatoire'),
    }),
    codeTireur: Yup.string().when('surPlace', {
      is: true,
      then: (schema) => schema.max(10, 'Code incorrect').required('Le code tireur est obligatoire'),
    }),
  }),
  ...irsiVerification,
}).required()

/* Module imports -------------------------------------- */
import * as RTK from '@reduxjs/toolkit'
import * as RTKQuery from '@reduxjs/toolkit/query'
import {
  persistStore,
  persistReducer,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

/* Redux slice imports --------------------------------- */
import {
  runPendingActions,
  stackAction,
} from './helper/stackAction'
import { isDitseuqered } from 'types/Ditseuqered'
import { api } from './api'
import authReducer from './slices/authSlice'
import caseSidebarReducer from './slices/caseSidebarSlice'
import courrierReducer from './slices/courrierSlice'
import mediaLibraryReducer from './slices/mediaLibrarySlice'
import networkReducer from './slices/networkSlice'
import planningReducer from './slices/planningSlice'
import quickActionModalReducer from './slices/quickActionModalSlice'
import reportReducer from './slices/reportSlice'
import requestsReducer, { popFirstRequests } from './slices/requestsSlice'
import routerHistoryReducer from './slices/routerHistorySlice'
import serviceWorkerReducer from './slices/serviceWorkerSlice'

/* Type imports ------------------------------------------------------------- */
import type { Action } from '@reduxjs/toolkit'
import { HealthStatus } from 'API/__generated__/Api'

/* Store configuration --------------------------------- */
const appReducer = RTK.combineReducers(
  {
    [api.reducerPath]: api.reducer,
    auth: authReducer,
    caseSidebar: caseSidebarReducer,
    courrier: courrierReducer,
    mediaLibrary: mediaLibraryReducer,
    network: networkReducer,
    planning: planningReducer,
    quickActionModal: quickActionModalReducer,
    report: reportReducer,
    requests: requestsReducer,
    routerHistory: routerHistoryReducer,
    serviceWorker: serviceWorkerReducer,
  },
)

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: RTK.PayloadAction) => {
  if (action.type === 'auth/resetAuthInfo') {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

const persistConfig = {
  key: 'nomad9',
  storage: storage,
  whitelist: [ 'auth', 'courrier', 'planning', 'report', 'requests', 'routerHistory' ], // which reducer want to store
}

const pReducer = persistReducer(persistConfig, rootReducer)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>

// eslint-disable-next-line camelcase
const Nomad9API_reducerPath_executeMutation: string = [ api.reducerPath, 'executeMutation' ].join('/')

export interface PossiblePayloadSchema extends Action<string> {
  payload?: Record<string, unknown> | RTKQuery.FetchBaseQueryError;
  meta?: {
    arg: {
      type: 'mutation' | 'query';
      endpointName: string;
      originalArgs: unknown;
      queryCacheKey: string;
    };
    requestId: string;
    requestStatus: 'rejected' | 'pending' | 'fulfilled';
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const actionStatusMiddleware: RTK.Middleware< Record<string, never>, RootState> = (store) => (next) => (action: PossiblePayloadSchema) => {
  if (!action) {
    return
  }

  if (action.meta !== undefined && action.meta.requestStatus === 'pending') {
    runPendingActions().catch((error) => console.log(error))
  }

  if ('type' in action && action.type.startsWith(Nomad9API_reducerPath_executeMutation)) {
    /* Get current state */
    const lCurrentState = store.getState()

    /* Get network status */
    const lNetworkStatus: boolean = lCurrentState.network.hasConnection && lCurrentState.network.APIHealthStatus === HealthStatus.Healthy

    if (action.meta !== undefined) {
      if (action.meta.requestStatus === 'rejected') {
        const lRejectedPayload: RTKQuery.FetchBaseQueryError = action.payload as RTKQuery.FetchBaseQueryError
        if (
          lRejectedPayload.data !== undefined &&
          lRejectedPayload.data !== null
        ) {
          if (
            'statusCode' in (lRejectedPayload.data as Record<string, unknown>) &&
            (lRejectedPayload.data as { statusCode: number } & Record<string, unknown>).statusCode === 408
          ) {
            console.log(`[actionStatusMiddleware] stack action due to 408 : ${JSON.stringify(action)}`)
            /* Stack the action */
            stackAction(action)
          } else {
            const requestsQueue = store.getState().requests
            const lastRequestId = requestsQueue.lastRequestId

            if (
              isDitseuqered(action.meta.arg.originalArgs) &&
              action.meta.arg.originalArgs._ditseuqer === lastRequestId
            ) {
              store.dispatch(popFirstRequests())
            }
          }
        }
      } else if (action.meta.requestStatus === 'pending') {
        if (lNetworkStatus === false) {
          console.log(`[actionStatusMiddleware] stack action due to lNetworkStatus : ${JSON.stringify(action)}`)
          /* Stack the action */
          stackAction(action)
        }
      } else if (action.meta.requestStatus === 'fulfilled') {
        const requestsQueue = store.getState().requests
        const lastRequestId = requestsQueue.lastRequestId

        if (
          isDitseuqered(action.meta.arg.originalArgs) &&
          action.meta.arg.originalArgs._ditseuqer === lastRequestId
        ) {
          store.dispatch(popFirstRequests())
        }
      }
    }
  }

  try {
    return next(action)
  } catch(pException) {
    console.error(`[ERROR] <actionStatusMiddleware> Action ${action.type} (${action.meta?.arg.type}/${action.meta?.arg.endpointName}/${action.meta?.requestStatus}) failed :`, pException)
  }
}

export const store = RTK.configureStore(
  {
    reducer: pReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({ serializableCheck: false })
        .prepend(actionStatusMiddleware)
        .concat(api.middleware)
    },
  },
)

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

/* Add store to the window */
export const persistor = persistStore(store)

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
RTKQuery.setupListeners(store.dispatch)

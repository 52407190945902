/* Framework imports -------------------------------------------------------- */
import { useEffect } from 'react'

/* Module imports ----------------------------------------------------------- */
import {
  addOfflineCase,
  selectNetwork,
} from 'store/slices/networkSlice'
import DateUtils from 'helpers/DateUtils'
import {
  useAppDispatch,
  useAppSelector,
  useAuthInfo,
} from 'store/hooks'
import {
  useGetApplicationGarantieListQuery,
  useGetAssureTypeListQuery,
  useGetCaseDocumentsQuery,
  useGetCaseEventDocumentsQuery,
  useGetCaseInfosQuery,
  useGetCaseReportsQuery,
  useGetCaseStateCodeListQuery,
  useGetCaseWorkflowQuery,
  useGetCauseNotDeletedReasonListQuery,
  useGetCommentActionQuery,
  useGetCompensationDamagePositionListQuery,
  useGetConfidentialAnomalyListQuery,
  useGetConventionListQuery,
  useGetDisasterNatureListQuery,
  useGetDisasterRexTypeListQuery,
  useGetDisasterSinappsNatureCauseDetailListQuery,
  useGetDocumentCategoryListQuery,
  useGetEventDocumentsFamilleActionListQuery,
  useGetEventDocumentsRecipientListQuery,
  useGetFamilleAnnuaireListQuery,
  useGetHistoriqueQuery,
  useGetInternalExchangesRecipientListQuery,
  useGetJustificationListQuery,
  useGetLettreAcceptationListQuery,
  useGetMailRecipientsQuery,
  useGetMailSendersQuery,
  useGetNatureBienListQuery,
  useGetNatureDetailBienListQuery,
  useGetPaymentTypeListQuery,
  useGetPersonQualifiantListQuery,
  useGetPersonRoleListQuery,
  useGetPlanningQuery,
  useGetPlanningTypesQuery,
  useGetPolitenessListQuery,
  useGetQualiteListQuery,
  useGetReparabiliteListQuery,
  useGetResponsabilityListQuery,
  useGetRiskNotVerifiedReasonListQuery,
  useGetRiskTypeListQuery,
  useGetRiskUsageListQuery,
  useGetRoomTypeListQuery,
  useGetSinappsBienTypeListQuery,
  useGetSinappsCompensationModeListQuery,
  useGetSinappsGarantieListQuery,
  useGetSinappsReportTodoListQuery,
  useGetTVAAssujettissementListQuery,
  useGetTVARateListQuery,
  useGetUniteMesureListQuery,
  useGetYesNoIndeterminedQuery,
  useLazyGetCaseTravelerQuery,
} from 'store/api'

/* Type imports ------------------------------------------------------------- */
import { TypeEvenementiel } from 'API/__generated__/Api'
import type { NetworkState } from 'store/slices/networkSlice'

/* Type declaration ------------------------------------ */

/* Internal variables ------------------------------------------------------- */

/* OfflineDataService methods -------------------------- */
export const useNeutralDataService = () => {
  useGetPlanningTypesQuery()
  useGetCommentActionQuery()
  useGetRiskUsageListQuery()
  useGetRiskTypeListQuery()
  useGetRiskNotVerifiedReasonListQuery()
  useGetDisasterSinappsNatureCauseDetailListQuery()
  useGetDisasterRexTypeListQuery()
  useGetCauseNotDeletedReasonListQuery()
  useGetRoomTypeListQuery()
  useGetNatureBienListQuery()
  useGetNatureBienListQuery()
  useGetReparabiliteListQuery()
  useGetJustificationListQuery()
  useGetCompensationDamagePositionListQuery()
  useGetLettreAcceptationListQuery()
  useGetConfidentialAnomalyListQuery()
  useGetPaymentTypeListQuery()
  useGetSinappsReportTodoListQuery()
  useGetDocumentCategoryListQuery({ onlySinapps: false })
  useGetApplicationGarantieListQuery()
  useGetAssureTypeListQuery()
  useGetDisasterNatureListQuery()
  useGetCaseStateCodeListQuery()
  useGetPersonQualifiantListQuery()
  useGetFamilleAnnuaireListQuery()
  useGetTVAAssujettissementListQuery()
  useGetResponsabilityListQuery()
  useGetYesNoIndeterminedQuery()
  useGetUniteMesureListQuery()
  useGetTVARateListQuery()
  useGetPolitenessListQuery()
  useGetCauseNotDeletedReasonListQuery()
  useGetSinappsBienTypeListQuery()
  useGetSinappsCompensationModeListQuery()
}

export const useCaseDataService = (caseId: string) => {
  const dispatch = useAppDispatch()
  const authInfo = useAuthInfo()
  const { fetchingRouteList = []}: NetworkState = useAppSelector(selectNetwork)

  useNeutralDataService()
  const { currentData: reports = [], isFetching: isFetchingCaseReport } = useGetCaseReportsQuery(caseId)
  const [ getCaseTravelerQuery, { isFetching: isFetchingCaseTraveler } ] = useLazyGetCaseTravelerQuery()
  useGetCaseWorkflowQuery(caseId)
  useGetCaseInfosQuery(caseId)
  useGetHistoriqueQuery(caseId)
  useGetPlanningQuery({
    expert: authInfo?.currentCollaborateur?.refAnnuaire.refComplete || '',
    dateDebut: DateUtils.planningStartDateApi,
    dateFin: DateUtils.planningEndDateApi,
  })
  useGetMailSendersQuery(caseId)
  useGetMailRecipientsQuery({ dossier: caseId, externe: true, interne: true })
  useGetInternalExchangesRecipientListQuery({ dossier: caseId, assistante: true, expert: true })
  useGetNatureDetailBienListQuery(caseId)
  useGetSinappsGarantieListQuery(caseId)
  useGetConventionListQuery(caseId)
  useGetCaseDocumentsQuery({ dossier: caseId })
  useGetCaseEventDocumentsQuery({ dossier: caseId, avecBrouillons: true })
  useGetEventDocumentsFamilleActionListQuery({ dossier: caseId, filtreEvenementiel: TypeEvenementiel.Dossier })
  useGetEventDocumentsFamilleActionListQuery({ dossier: caseId, filtreEvenementiel: TypeEvenementiel.Rapport })
  useGetEventDocumentsRecipientListQuery(caseId)
  useGetQualiteListQuery(caseId)
  useGetPersonRoleListQuery(caseId)

  useEffect(() => {
    if (!isFetchingCaseReport) {
      reports.forEach((report) => {
        getCaseTravelerQuery(report.id).catch(console.error)
      })
    }
  }, [ isFetchingCaseReport ])

  useEffect(() => {
    if (!isFetchingCaseReport && !isFetchingCaseTraveler && fetchingRouteList.length === 0) {
      dispatch(addOfflineCase(caseId))
    }
  }, [ isFetchingCaseReport, isFetchingCaseTraveler, fetchingRouteList ])
}

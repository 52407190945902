/* Module imports -------------------------------------- */
import { store } from 'store/store'
import {
  pushRequest,
  setLastRequestId,
} from 'store/slices/requestsSlice'
import { api } from 'store/api'
import { isDitseuqered } from 'types/Ditseuqered'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */

/* Type imports ------------------------------------------------------------- */
import type { PossiblePayloadSchema } from 'store/store'

export const stackAction = (action: PossiblePayloadSchema): void => {
  const requestsSlice = store.getState().requests
  const lastRequestId = requestsSlice.lastRequestId
  /* Check if action is rejected and if _ditseuqer is the same than lastRequestId
   * If so, stack it at the start of the queue. If no, stack it at the end of the queue
  */
  if (action.meta !== undefined) {
    if (
      !isDitseuqered(action.meta.arg.originalArgs) ||
      action.meta.arg.originalArgs._ditseuqer !== lastRequestId
    ) {
      store.dispatch(pushRequest(action))
    }
  }
}

let isProcessing = false

export const runPendingActions = async (): Promise<void> => {
  if (!isProcessing) {
    isProcessing = true

    const requestsStack = store.getState().requests
    const requests = requestsStack.requests

    if (requests.length > 0) {
      for await (const request of requests) {
        try {
          if (request.meta !== undefined) {
            const requestName = request.meta.arg.endpointName
            const Nomad9APIEnpoints = api.endpoints

            if (requestName in Nomad9APIEnpoints) {
              const action = Nomad9APIEnpoints[requestName as keyof typeof Nomad9APIEnpoints]

              type ActionParameters = Parameters<typeof action.initiate>[0]

              console.log(`[DEBUG] <runPendingActions> initiate stacked action with id : ${request.meta.requestId}`)

              store.dispatch(setLastRequestId(request.meta.requestId))

              // we use _ditseuqer to store the requestId
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              const response = await store.dispatch(action.initiate({ _ditseuqer: request.meta.requestId, ...request.meta.arg.originalArgs } as keyof ActionParameters))

              console.log(`[DEBUG] <runPendingActions> success stacked action with id : ${request.meta.requestId}`)
              if (isApiError(response)) {
                break
              }
            }
          }
        } catch(error) {
          break
        }
      }
    }
    isProcessing = false
  }
}

/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useParams } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'
import { selectNetwork } from 'store/slices/networkSlice'
import { runPendingActions } from 'store/helper/stackAction'
import {
  getCaseReportLastSync,
  getCaseSelectedReport,
} from 'store/slices/reportSlice'
import DateUtils from 'helpers/DateUtils'
import { isValidString } from 'helpers/isValidString'

/* Component imports -------------------------------------------------------- */
import {
  CircularProgress,
  Tooltip,
} from '@mui/material'
import {
  WifiOffRounded,
  WifiRounded,
  ChecklistRtlRounded,
} from '@mui/icons-material'
import { ReactComponent as Nomad9Logo } from 'assets/Nomad9.svg'

/* Type imports ------------------------------------------------------------- */
import type { NetworkState } from 'store/slices/networkSlice'

/* Styled components -------------------------------------------------------- */
const BannerContainer = styled.div`
  background-color: ${(props) => props.theme.palette.secondary.main};
  color: white;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  z-index: 201;
  @media screen and (max-width: 768px) {
    padding: 0 0.5rem;
  }
`

const Logo = styled(Nomad9Logo)`
  width: 90px;
  height: 90px;
`

const ConnectionContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  font-size: .9rem;
  svg {
    font-size: 28px !important;
  }
`

const InternetStatusContainer = styled(ConnectionContainer)`
  font-weight: bold;
  gap: 7px;
`

/* Component declaration ---------------------------------------------------- */
interface InternetStatusBannerProps {}

const InternetStatusBanner: React.FC<InternetStatusBannerProps> = () => {
  const { caseId = '' } = useParams<{ caseId: string }>()
  const networkState: NetworkState = useAppSelector(selectNetwork)
  const selectedReport: string | null = useAppSelector(getCaseSelectedReport(caseId))
  const lastSync = useAppSelector(getCaseReportLastSync(caseId, selectedReport || ''))

  const runPromise = async (): Promise<void> => {
    await runPendingActions()
  }

  return (
    <BannerContainer>
      <Logo />
      <ConnectionContainer>
        {
          (networkState.fetchingRouteList?.length || 0) > 0 &&
            <ConnectionContainer>
              <Tooltip
                title="Chargement du mode hors-ligne"
                arrow
                placement="bottom"
              >
                <CircularProgress
                  color="info"
                  size={28}
                />
              </Tooltip>
            </ConnectionContainer>
        }
        {
          (networkState.offlineCases?.length || 0) > 0 &&
            <ConnectionContainer>
              <Tooltip
                title={
                  <div>
                    Dossiers chargés pour le mode hors-ligne :
                    <br />
                    {
                      networkState.offlineCases.map((cases) => (
                        <div key={cases}>
                          {cases}
                          <br />
                        </div>
                      ))
                    }
                  </div>
                }
                arrow
                placement="bottom"
              >
                <ChecklistRtlRounded />
              </Tooltip>
            </ConnectionContainer>
        }
        {
          isValidString(caseId) && isValidString(selectedReport) && lastSync &&
            <div>
              {
                isValidString(lastSync.withoutValidation) &&
                  <>
                    {'Sauvegarde auto. le '}
                    {DateUtils.apiStrToLocalDateString(lastSync.withoutValidation, { month: 'short', day: 'numeric' })}
                    {' à '}
                    {DateUtils.apiStrToLocalTimeString(lastSync.withoutValidation, { hour: '2-digit', minute: '2-digit' })}
                    {isValidString(lastSync.withValidation) && ' - '}
                  </>
              }
              {
                isValidString(lastSync.withValidation) &&
                  <>
                    {'Enregistrement le '}
                    {DateUtils.apiStrToLocalDateString(lastSync.withValidation, { month: 'short', day: 'numeric' })}
                    {' à '}
                    {DateUtils.apiStrToLocalTimeString(lastSync.withValidation, { hour: '2-digit', minute: '2-digit' })}
                  </>
              }
            </div>
        }
        <InternetStatusContainer>
          {
            networkState.hasConnection ?
              <InternetStatusContainer onClick={() => {void runPromise()}}>
                <Tooltip
                  title="En ligne"
                  arrow
                  placement="bottom"
                >
                  <WifiRounded />
                </Tooltip>
              </InternetStatusContainer> :
              <Tooltip
                title="Hors ligne"
                arrow
                placement="bottom"
              >
                <WifiOffRounded />
              </Tooltip>
          }
        </InternetStatusContainer>
      </ConnectionContainer>
    </BannerContainer>
  )
}

export default InternetStatusBanner

/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'
/* Module imports ----------------------------------------------------------- */
import {
  useGetCasesChipListQuery,
  useGetCaseStateCodeListQuery,
  useGetDisasterNatureListQuery,
  useLazyGetCasesByStatusQuery,
} from 'store/api'
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'

/* Component imports -------------------------------------------------------- */
import {
  MenuItem,
  Select,
} from '@mui/material'
import PageContainer from 'layouts/PageContainer/PageContainer'
import HeaderWithSearch from 'layouts/MainLayout/Headers/HeaderWithSearch'
import CasesList from 'components/CasesList/CasesList'
import Loader from 'components/Loader/Loader'
import Tabs from 'components/Tabs/Tabs'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import CaseListPageChanger from 'components/CaseListPageChanger/CaseListPageChanger'

/* Type imports ------------------------------------------------------------- */
import type { DossierRecherche } from 'API/__generated__/Api'
import { FiltreDossier } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const GridContainer = styled.div`
  display: grid;
  gap: 20px;
  align-items: end;
  justify-content: stretch;

  grid-template-columns: repeat(3, minmax(0, 1fr));
`

/* Component declaration ---------------------------------------------------- */
interface CasesPageProps {}

const CasesPage: React.FC<CasesPageProps> = () => {
  const [ filteredList, setFilteredList ] = useState<DossierRecherche[]>([])
  const [ pagination, setPagination ] = useState<{startIndex: number; limit: number}>({ startIndex: 0, limit: 20 })
  const [ tabFilter, setTabFilter ] = useState<FiltreDossier>(FiltreDossier.EnCours)
  const [ mandantList, setMandantList ] = useState<string[]>([])
  const [ mandantFilter, setMandantFilter ] = useState<string>('')
  const [ typeFilter, setTypeFilter ] = useState<string>('')
  const [ statutFilter, setStatutFilter ] = useState<string>('')

  const {
    currentData: disasterNatureList = [],
    isFetching: isFetchingDisasterNatureList,
  } = useGetDisasterNatureListQuery()
  const {
    currentData: caseStateCodeList = [],
    isFetching: isFetchingCaseStateCodeList,
  } = useGetCaseStateCodeListQuery()

  const [
    getCases,
    {
      currentData: caseList = [],
      isFetching: isFetchingCaseList,
    },
  ] = useLazyGetCasesByStatusQuery()
  const {
    currentData: casesChips = [],
    isFetching: isFetchingCasesChips,
  } = useGetCasesChipListQuery(
    {
      modeSimple: true,
      dossiers: caseList.map((caseItem) => caseItem.id),
    },
    { skip: caseList.length === 0 },
  )

  const fetchCase = (filtre: FiltreDossier, startIndex: number, limit: number, resetStartIndex: boolean = false): void => {
    getCases({ filtre, startIndex, limit }, true).then(({ data }) => {
      setMandantFilter('')
      setMandantList(
        [ ...new Set(data?.map((exchange) => exchange.compagnie?.libelle ?? 'Compagnie inconnue')) ]
          .sort((a, b) => a.localeCompare(b)),
      )
      if (resetStartIndex) {
        setPagination({ ...pagination, startIndex: 0 })
      }
    }).catch(console.error)
  }

  useEffect(() => {
    fetchCase(tabFilter, pagination.startIndex, pagination.limit, true)
  }, [])

  useEffect(() => {
    if (!isFetchingCaseList) {
      setFilteredList(caseList.filter((dossier) =>
        (mandantFilter === '' || (dossier.compagnie?.libelle ?? 'Compagnie inconnue') === mandantFilter) &&
        (typeFilter === '' || dossier.sinistre.nature?.code === typeFilter) &&
        (statutFilter === '' || dossier.etat.code === statutFilter),
      ))
    }
  }, [
    mandantFilter,
    typeFilter,
    statutFilter,
    caseList,
    isFetchingCaseList,
  ])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number): void => {
    setTabFilter(Object.keys(FiltreDossier)[newValue] as FiltreDossier)
    fetchCase(Object.keys(FiltreDossier)[newValue] as FiltreDossier, pagination.startIndex, pagination.limit, true)
  }

  const onClickStartIndexButtonPrev = (): void => {
    const newStartIndex = pagination.startIndex - parseInt(pagination.limit.toString()) >= 0 ? pagination.startIndex - parseInt(pagination.limit.toString()) : 0
    setPagination({
      ...pagination,
      startIndex: newStartIndex,
    })
    fetchCase(tabFilter, newStartIndex, pagination.limit, false)
  }

  const onClickStartIndexButtonNext = (): void => {
    const newStartIndex = pagination.startIndex + parseInt(pagination.limit.toString())
    setPagination({ ...pagination, startIndex: newStartIndex })
    fetchCase(tabFilter, newStartIndex, pagination.limit, false)
  }

  const handleLimitChange = (newLimit: number): void => {
    setPagination({ ...pagination, limit: newLimit })
    fetchCase(tabFilter, pagination.startIndex, newLimit, false)
  }

  return (
    <>
      <HeaderWithSearch
        title="Dossiers"
        displayDownloadOfflineCasesButton
      />
      <PageContainer>
        <Tabs
          value={Object.keys(FiltreDossier).indexOf(tabFilter)}
          onChange={handleTabChange}
          tabs={[ 'Dossiers du jour', 'Dossiers en cours', 'Dossiers traités' ]}
        />
        <GridContainer>
          <div>
            <FormBoldTitle>
              Mandant du dossier
            </FormBoldTitle>
            <Select
              value={mandantFilter}
              onChange={(e): void => setMandantFilter(e.target.value)}
              fullWidth
              renderValue={verifySelectFieldValue(mandantFilter)}
              displayEmpty
            >
              <MenuItem
                value=""
                key=""
              >
                Sélectionner
              </MenuItem>
              {
                mandantList.map((option) => (
                  <MenuItem
                    value={option}
                    key={option}
                  >
                    {option}
                  </MenuItem>
                ))
              }
            </Select>
          </div>
          <div>
            <FormBoldTitle>
              Type de sinistre
            </FormBoldTitle>
            <Select
              value={typeFilter}
              onChange={(e): void => setTypeFilter(e.target.value)}
              fullWidth
              renderValue={verifySelectFieldValue(typeFilter)}
              displayEmpty
            >
              <MenuItem
                value=""
                key=""
              >
                Sélectionner
              </MenuItem>
              {
                disasterNatureList.map((option, index) => (
                  <MenuItem
                    value={option.code}
                    key={`${option.code}-${index}`}
                  >
                    {option.libelle}
                  </MenuItem>
                ))
              }
            </Select>
          </div>
          <div>
            <FormBoldTitle>
              Statut du dossier
            </FormBoldTitle>
            <Select
              value={statutFilter}
              onChange={(e): void => setStatutFilter(e.target.value)}
              fullWidth
              renderValue={verifySelectFieldValue(statutFilter)}
              displayEmpty
            >
              <MenuItem
                value=""
                key=""
              >
                Sélectionner
              </MenuItem>
              {
                caseStateCodeList.map((option, index) => (
                  <MenuItem
                    value={option.code?.code}
                    key={`${option.code?.code}-${index}`}
                  >
                    {option.code?.libelle}
                  </MenuItem>
                ))
              }
            </Select>
          </div>
        </GridContainer>
        <CaseListPageChanger
          disablePrev={pagination.startIndex === 0}
          disableNext={caseList.length < parseInt(pagination.limit.toString())}
          limitValue={pagination.limit}
          onClickPrev={onClickStartIndexButtonPrev}
          onClickNext={onClickStartIndexButtonNext}
          onLimitChange={handleLimitChange}
        />
        {(isFetchingCaseList || isFetchingDisasterNatureList || isFetchingCaseStateCodeList) && <Loader />}
        <CasesList
          cases={filteredList}
          loading={isFetchingCaseList}
          casesChips={casesChips}
          isFetchingChips={isFetchingCasesChips}
        />
        <CaseListPageChanger
          disablePrev={pagination.startIndex === 0}
          disableNext={caseList.length < parseInt(pagination.limit.toString())}
          limitValue={pagination.limit}
          onClickPrev={onClickStartIndexButtonPrev}
          onClickNext={onClickStartIndexButtonNext}
          onLimitChange={handleLimitChange}
        />
        <br />
      </PageContainer>
    </>
  )
}

export default CasesPage

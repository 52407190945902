/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */

/* Component imports -------------------------------------------------------- */
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import CloseButton from 'components/CloseButton/CloseButton'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'

/* Type imports ------------------------------------------------------------- */

/* Type declarations -------------------------------------------------------- */

/* Internal variables ------------------------------------------------------- */

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;
`

const DialogContentContainer = styled(DialogContent)`
  margin: 20px 0px;
  text-align: center;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin-bottom: 20px;
`

const FormButton = styled(SubmitFormButton)`
  margin-left: 0;
`

/* Component declaration ---------------------------------------------------- */
interface DocumentConfirmationStatusModalProps {
  open: boolean;
  handleClose: (isDocumentDefinitive?: boolean) => void;
}

const DocumentConfirmationStatusModal: React.FC<DocumentConfirmationStatusModalProps> = ({
  open,
  handleClose,
}) => {

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitleContainer>
        Confirmation du statut
        <CloseButton handleClose={handleClose} />
      </DialogTitleContainer>
      <DialogContentContainer>
        Confirmer le document en définitif ?
      </DialogContentContainer>
      <DialogActionContainer>
        <FormButton onClick={() => handleClose()}>
          Annuler
        </FormButton>
        <FormButton
          variant="contained"
          type="submit"
          onClick={() => handleClose(true)}
        >
          Valider
        </FormButton>
      </DialogActionContainer>
    </Dialog>
  )
}

export default DocumentConfirmationStatusModal

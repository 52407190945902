/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */

/* Component imports -------------------------------------------------------- */
import HeaderContainer from 'layouts/MainLayout/Headers/HeadersComponents/HeaderContainer'
import HeaderGroupContainer from 'layouts/MainLayout/Headers/HeadersComponents/HeaderGroupContainer'
import BackButton from 'components/BackButton/BackButton'

/* Type imports ------------------------------------------------------------- */

/* Styled components -------------------------------------------------------- */
const HeaderTitleContainer = styled.div`
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;

  display: flex;
  align-items: center;
  font-size: 1rem;
  padding-left: 15px;
`

const TitleContainer = styled.div`
  font-weight: bold;

  font-size: 1rem;
  text-transform: none;
`

const ButtonGroupContainer = styled(HeaderGroupContainer)`
  gap: 5px;
  margin-right: 20px;
`

/* Component declaration ---------------------------------------------------- */
interface MediaSidebarHeaderSubMenuProps {
  title: string;
  caseId: string;
  onClickBack: () => void;
  children: React.ReactNode;
}

const MediaSidebarHeaderSubMenu: React.FC<MediaSidebarHeaderSubMenuProps> = ({
  title,
  onClickBack,
  children,
}) => {

  return (
    <HeaderContainer>
      <HeaderGroupContainer>
        <HeaderTitleContainer>
          <BackButton onClick={onClickBack}>
            <TitleContainer>
              {title}
            </TitleContainer>
          </BackButton>
        </HeaderTitleContainer>
      </HeaderGroupContainer>
      <ButtonGroupContainer>
        {children}
      </ButtonGroupContainer>
    </HeaderContainer>
  )
}

export default MediaSidebarHeaderSubMenu

/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */

/* Component imports -------------------------------------------------------- */
import HeaderWithSearch from 'layouts/MainLayout/Headers/HeaderWithSearch'
import PageContainer from 'layouts/PageContainer/PageContainer'
import SignOutButton from 'components/SignOutButton/SignOutButton'

/* Component declaration ---------------------------------------------------- */
interface AccountPageProps {}

const AccountPage: React.FC<AccountPageProps> = () => {

  return (
    <>
      <HeaderWithSearch title="Mon compte" />
      <PageContainer>
        <h2>
          Déconnexion
          <SignOutButton />
        </h2>
      </PageContainer>
    </>
  )
}

export default AccountPage

/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetInternalExchangesMessageTypeListQuery,
  useLazyGetInternalExchangesRecipientListQuery,
  usePostAnswerInternalExchangeMutation,
  usePostForwardInternalExchangeMutation,
} from 'store/api'
import { isValidString } from 'helpers/isValidString'
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  CircularProgress,
  MenuItem,
} from '@mui/material'
import { Field } from 'formik'
import {
  Select,
  TextField,
} from 'formik-mui'
import { toast } from 'react-toastify'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import AutocompleteField from 'components/FieldWithInputAdornment/AutocompleteField'

/* Type imports ------------------------------------------------------------- */
import type { FormikHelpers } from 'formik'
import type {
  CodeLabel,
  EchangeInterne,
} from 'API/__generated__/Api'
import type { ApiResponse } from 'helpers/fetchHelpers'

/* Type declarations -------------------------------------------------------- */
const messageSchema = Yup.object({
  action: Yup.mixed<'repondre' | 'transferer'>().required().nullable(),
  message: Yup.string().when('action', {
    is: 'repondre',
    then: (schema) => schema.required('Le message est obligatoire'),
  }),
  messageType: Yup.string(),
  destinataires: Yup.mixed<CodeLabel>().when('action', {
    is: 'transferer',
    then: () => Yup.mixed<CodeLabel>().test(
      'is-not-empty',
      'Le destinataire est obligatoire',
      (value) => isValidString(value?.code),
    ).required('Le destinataire est obligatoire'),
  }),
})

type MessageRequest = Yup.InferType<typeof messageSchema>

/* Internal variables ------------------------------------------------------- */

/* Styled components -------------------------------------------------------- */
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
  align-items: center;
  justify-content: stretch;
  margin: 10px 0px;
`

interface ButtonLineProps {
  fullWidth: boolean;
}

const ButtonLine = styled.div<ButtonLineProps>`
  margin-top: 20px;
  display: ${(props) => props.fullWidth ? 'flex' : 'inital'};
  justify-content: space-between;
`

const AnswerButton = styled(Button)<ButtonLineProps>`
  width: ${(props) => props.fullWidth ? '48%' : '180px'};
  margin-right: ${(props) => props.fullWidth ? '0px' : '10px'};
`

const ForwardTo = styled(FormBoldTitle)`
  margin-top: 0px;
  margin-bottom: 0px;
  align-self: center;
  font-size: .9rem;
`

const FieldContainer = styled.div`
  margin-top: 10px;
`

/* Component declaration ---------------------------------------------------- */
interface InternalExchangeAnswerForwardProps {
  exchange: EchangeInterne;
  sidebarVersion?: boolean;
}

const InternalExchangeAnswerForward: React.FC<InternalExchangeAnswerForwardProps> = ({ exchange, sidebarVersion = false }) => {
  const [
    getRecipientList,
    {
      currentData: recipientList = [],
      isFetching: isFetchingRecipientList,
    },
  ] = useLazyGetInternalExchangesRecipientListQuery()

  const {
    currentData: messageTypeList = [],
    isFetching: isFetchingMessageTypeList,
  } = useGetInternalExchangesMessageTypeListQuery()

  const [
    submitAnswerInternalExchange,
  ] = usePostAnswerInternalExchangeMutation()

  const [
    submitForwardInternalExchange,
  ] = usePostForwardInternalExchangeMutation()

  const onSubmit = async (values: MessageRequest, { setSubmitting, resetForm }: FormikHelpers<MessageRequest>): Promise<void> => {
    let response = null

    const onFinish = (response: ApiResponse<void>): void => {
      if (!isApiError(response)) {
        resetForm()
      } else {
        toast.error(`Une erreur est survenue lors ${values.action === 'repondre' ? "l'envoi de la réponse" : 'du transfert'}`)
        setSubmitting(false)
      }
    }

    if (values.action === 'repondre') {
      response = await submitAnswerInternalExchange({
        id: exchange.id,
        data: {
          message: values.message || '',
          objet: exchange.objet,
        },
      })
    }
    if (values.action === 'transferer') {
      response = await submitForwardInternalExchange({
        id: exchange.id,
        data: {
          destinataires: [ values.destinataires?.code || '' ],
        },
      })
    }

    if (response !== null) {
      onFinish(response)
    } else {
      setSubmitting(false)
    }
  }

  const formikForm = useForm<MessageRequest>(
    {
      initialValues: {
        message: '',
        messageType: '',
        action: null,
        destinataires: { code: '', libelle: '' },
      },
      onSubmit: onSubmit,
      validationSchema: messageSchema,
    },
  )

  const handleValue = (type: string, value?: string | boolean | string[] | null): void => {
    formikForm.setFieldValue(type, value).catch(console.error)
  }

  useEffect(() => {
    if (formikForm.values.action === 'transferer') {
      getRecipientList({
        dossier: exchange.dossier.id,
        assistante: true,
        expert: true,
      }).catch(console.error)
    }
  }, [
    formikForm.values.action,
    getRecipientList,
    exchange.dossier.id,
  ])

  useEffect(() => {
    handleValue('message', messageTypeList.find((message) => message.code === formikForm.values.messageType)?.libelle || '')
  }, [ formikForm.values.messageType ])

  const isLoading = useMemo(() => isFetchingMessageTypeList || isFetchingRecipientList || formikForm.isSubmitting, [ isFetchingMessageTypeList, isFetchingRecipientList, formikForm.isSubmitting ])

  return (
    <Form form={formikForm}>
      {
        formikForm.values.action === 'transferer' &&
          <GridContainer>
            <ForwardTo required>
              Transférer à :
            </ForwardTo>
            {
              isFetchingRecipientList ?
                <CircularProgress /> :
                <AutocompleteField
                  name="destinataires"
                  options={recipientList}
                />
            }
          </GridContainer>
      }
      {
        formikForm.values.action === 'repondre' &&
          <>
            <FieldContainer>
              {
                isFetchingMessageTypeList ?
                  <CircularProgress size={24} /> :
                  <Field
                    name="messageType"
                    component={Select}
                    displayEmpty
                    renderValue={verifySelectFieldValue(formikForm.values.messageType)}
                    size="small"
                    disabled={isFetchingMessageTypeList}
                  >
                    {
                      messageTypeList.map((messageType, index) => (
                        <MenuItem
                          value={messageType.code}
                          key={`${messageType.code}-${index}`}
                        >
                          {messageType.libelle}
                        </MenuItem>
                      ))
                    }
                  </Field>
              }
            </FieldContainer>
            <FieldContainer>
              <Field
                component={TextField}
                rows={3}
                multiline
                name="message"
                placeholder="Votre message"
              />
            </FieldContainer>
          </>
      }
      <ButtonLine fullWidth={sidebarVersion}>
        {
          formikForm.values.action !== null &&
            <AnswerButton
              fullWidth={sidebarVersion}
              variant="contained"
              type="submit"
              disabled={isLoading}
            >
              {
                isLoading ?
                  <CircularProgress /> :
                  'Envoyer'
              }
            </AnswerButton>
        }
        {
          formikForm.values.action !== 'transferer' &&
            <AnswerButton
              fullWidth={sidebarVersion}
              variant="outlined"
              onClick={() => handleValue('action', 'transferer')}
              disabled={formikForm.isSubmitting}
            >
              Transférer
            </AnswerButton>
        }
        {
          formikForm.values.action !== 'repondre' &&
            <AnswerButton
              fullWidth={sidebarVersion}
              variant={formikForm.values.action === null ? 'contained' : 'outlined'}
              onClick={() => handleValue('action', 'repondre')}
              disabled={formikForm.isSubmitting}
            >
              Répondre
            </AnswerButton>
        }
      </ButtonLine>
    </Form>
  )
}

export default InternalExchangeAnswerForward

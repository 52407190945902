/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'
/* Module imports ----------------------------------------------------------- */
import { useParams } from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetCaseDocumentsQuery,
  useGetMailRecipientsQuery,
  useGetMailSendersQuery,
  useGetMailTypeListQuery,
  useLazyGetMailTypeQuery,
  usePostNewMailMutation,
} from 'store/api'
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'
import { useIsConnected } from 'helpers/hooks/useIsConnected'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import { MenuItem } from '@mui/material'
import { Field } from 'formik'
import {
  TextField,
  Select,
} from 'formik-mui'
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from '@syncfusion/ej2-react-richtexteditor'
import { toast } from 'react-toastify'
import PageContainer from 'layouts/PageContainer/PageContainer'
import HeaderAction from 'layouts/MainLayout/Headers/HeadersComponents/HeaderAction'
import Loader from 'components/Loader/Loader'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'
import CheckableButton from 'components/CheckableButton/CheckableButton'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'
import ErrorMessage from 'components/ErrorMessage/ErrorMessage'
import AutocompleteField from 'components/FieldWithInputAdornment/AutocompleteField'

/* Type imports ------------------------------------------------------------- */
import type { FormikHelpers } from 'formik'
import type {
  MailRequest,
  PieceJointe,
  CodeLabel,
  MailDestinataire,
  MailEmetteur,
} from 'API/__generated__/Api'
import {
  TypeDestinataireEmetteur,
  TypePJ,
} from 'API/__generated__/Api'
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'
import type { ApiResponse } from 'helpers/fetchHelpers'

/* Type declarations -------------------------------------------------------- */
interface AdditionnalMailProperties {
  type: CodeLabel;
  listeDestinataires: string[] | CodeLabel[];
  listeDestinatairesCC: string[] | CodeLabel[];
  listeDestinatairesCCI: string[] | CodeLabel[];
  afficherSignature: boolean;
  mailSimple: boolean;
}

const mailSchema = Yup.mixed<MailRequest & AdditionnalMailProperties>().when('emetteur', {
  is: (emetteur: MailEmetteur) => emetteur !== null,
  then: () => Yup.object({
    mailSimple: Yup.boolean(),
    destinataires: Yup.array(Yup.mixed<MailDestinataire>()).when('mailSimple', {
      is: true,
      then: () => Yup.array(Yup.mixed<MailDestinataire>()).min(1, 'Il doit y avoir au moins un destinataire').required(),
    }),
    listeDestinataires: Yup.array(Yup.mixed<CodeLabel | string>()).when('mailSimple', {
      is: false,
      then: () => Yup.array(Yup.mixed<CodeLabel | string>()).min(1, 'Il doit y avoir au moins un destinataire').required(),
    }),
    objet: Yup.string().required("L'objet est obligatoire"),
    message: Yup.string().required('Le message est obligatoire'),
  }).required(),
}).required()

type NewMailRequest = Yup.InferType<typeof mailSchema>

/* Internal variables ------------------------------------------------------- */
const RichTextSettings = {
  items: [
    'Bold', 'Italic', 'Underline', 'StrikeThrough', 'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
    '|', 'Undo', 'Redo', 'Formats', 'Alignments', 'OrderedList', 'UnorderedList', 'Outdent', 'Indent',
    '|', 'LowerCase', 'UpperCase', 'ClearFormat', 'FullScreen',
  ],
}

const RichTextServices = [ HtmlEditor, Toolbar, Image, Link, QuickToolbar ]

/* Styled components -------------------------------------------------------- */
const GridContainer = styled.div`
  display: grid;

  grid-template-columns: repeat(2, minmax(0, 1fr));
  @media screen and (max-width: 450px) {
    grid-template-columns: minmax(0, 1fr)
  }
  gap: 20px;

  align-items: stretch;
  justify-content: stretch;

  margin-bottom: 10px;
`

const SegmentedGridContainer = styled(GridContainer)`
  margin-top: 20px;
`

interface ErrorField {
  error?: boolean;
}

const ThreeGridContainer = styled(GridContainer)<ErrorField>`
  grid-template-columns: repeat(3, 1fr);
  @media screen and (max-width: 450px) {
    grid-template-columns: 1fr;
  }
  gap: 10px;
  border: ${(props) => props.error ? '1px solid #d32f2f' : undefined};
  border-radius: 4px;
`

const BigCheckableButton = styled(CheckableButton)`
  height: 80px;
`

const MediumCheckableButton = styled(CheckableButton)`
  height: 60px;
`

const NameContainer = styled.div`
  font-weight: bold;
  font-size: .9rem;
`

const DescriptionContainer = styled.div`
  font-size: .8rem;
`

const RichTextEditorComponentContainer = styled.div<ErrorField>`
  margin-bottom: 10px;
  border: ${(props) => props.error ? '1px solid #d32f2f' : undefined};
  border-radius: 4px;
`

/* Component declaration ---------------------------------------------------- */
interface QaMailPageProps {}

const QaMailPage: React.FC<QaMailPageProps> = () => {
  const isConnected = useIsConnected()
  const { caseId = '' } = useParams<{caseId: string}>()
  const initialValues: NewMailRequest = {
    emetteur: {
      nom: '',
      type: TypeDestinataireEmetteur.Moi,
      email: '',
      signature: '',
    },
    destinataires: [],
    destinatairesLibres: [],
    destinatairesCC: [],
    destinatairesLibresCC: [],
    destinatairesCCI: [],
    destinatairesLibresCCI: [],
    objet: '',
    message: '',
    signature: '',
    brouillon: false,
    confirmationReception: false,
    receptionCopie: false,
    passerEnPDF: false,
    idsDocuments: [],
    type: { code: '', libelle: '' },
    listeDestinataires: [],
    listeDestinatairesCC: [],
    listeDestinatairesCCI: [],
    afficherSignature: false,
    mailSimple: true,
  }

  const {
    currentData: senders = [],
    isFetching: isFetchingSenders,
  } = useGetMailSendersQuery(caseId)
  const {
    currentData: recipients = [],
    isFetching: isFetchingRecipients,
  } = useGetMailRecipientsQuery(
    {
      dossier: caseId,
      interne: true,
      externe: true,
    },
  )
  const {
    currentData: mailTypes = [],
    isFetching: isFetchingMailTypeList,
  } = useGetMailTypeListQuery(caseId)
  const {
    currentData: documents = [],
    isFetching: isFetchingDocuments,
  } = useGetCaseDocumentsQuery({ dossier: caseId })
  const [
    getMailType,
  ] = useLazyGetMailTypeQuery()
  const [
    submitNewMail,
  ] = usePostNewMailMutation()

  const onSubmit = (values: NewMailRequest, { resetForm }: FormikHelpers<NewMailRequest>): void => {
    const onFinish = (response: ApiResponse<void>): void => {
      if (!isApiError(response)) {
        toast.success(`Le mail "${values.objet}" à bien été envoyé`)
      } else {
        toast.error(`Une erreur est survenue lors de l'envoi du mail "${values.objet}"`)
      }
    }

    submitNewMail({
      caseId,
      data: values,
    }).then(onFinish).catch(console.error)
    toast.success(`Envoi mail "${values.objet}" en cours...`)

    resetForm({ values: { ...initialValues, emetteur: values.emetteur }})
  }

  const formikForm = useForm<NewMailRequest>(
    {
      initialValues,
      onSubmit: onSubmit,
      validationSchema: mailSchema,
    },
  )

  const handleValue = (type: string, value?: boolean | string | string[] | MailDestinataire | MailDestinataire[]): void => {
    formikForm.setFieldValue(type, value).catch(console.error)
  }

  useEffect(() => {
    if (!isFetchingSenders) {
      handleValue('emetteur', senders.find((sender) => sender.type === TypeDestinataireEmetteur.Moi))
    }
  }, [ isFetchingSenders ])

  useEffect(() => {
    handleValue('signature', formikForm.values.emetteur.signature || '')
  }, [ formikForm.values.emetteur.signature ])

  useEffect(() => {
    if (formikForm.values.type?.code !== undefined && formikForm.values.type?.code !== '') {
      getMailType(formikForm.values.type.code).then((values) => {
        handleValue('objet', values.data?.object)
        handleValue('message', values.data?.message)
      }).catch(console.error)
    }
  }, [ formikForm.values.type?.code, getMailType ])

  const verifyRecipients = (
    recipientFormFieldName: 'listeDestinataires' | 'listeDestinatairesCC' | 'listeDestinatairesCCI',
    recipientFieldName: string,
    recipientFreeFieldName: string,
  ) => {
    const newRecipients: MailDestinataire[] = []
    const newFree: string[] = []

    formikForm.values[recipientFormFieldName]?.forEach(((destinataire) => {
      if (typeof destinataire === 'object') {
        const found = recipients.find((recipient) => recipient.email === destinataire.code)
        if (found) {
          newRecipients.push(found)
        }
      } else {
        newFree.push(destinataire)
      }
    }))

    handleValue(recipientFieldName, newRecipients)
    handleValue(recipientFreeFieldName, newFree)
  }

  useEffect(() => {
    verifyRecipients('listeDestinataires', 'destinataires', 'destinatairesLibres')
  }, [ formikForm.values.listeDestinataires ])

  useEffect(() => {
    verifyRecipients('listeDestinatairesCC', 'destinatairesCC', 'destinatairesLibresCC')
  }, [ formikForm.values.listeDestinatairesCC ])

  useEffect(() => {
    verifyRecipients('listeDestinatairesCCI', 'destinatairesCCI', 'destinatairesLibresCCI')
  }, [ formikForm.values.listeDestinatairesCCI ])

  const handleCheckedRecipientButton = (value: string, checked: boolean): void => {
    const newValues = [ ...(formikForm.values.destinataires ?? []) ]

    if (!checked) {
      handleValue('destinataires', newValues.filter((val) => val.email !== value))
    }
    else {
      newValues.push(recipients.find((recipient) => recipient.email === value) ?? { nom: '', email: value, type: TypeDestinataireEmetteur.Moi, role: '' } )
      handleValue('destinataires', newValues)
    }
  }

  const handleCheckedFileButton = (value: string, checked: boolean): void => {
    const newValues = [ ...(formikForm.values.idsDocuments ?? []) ]

    if (!checked) {
      handleValue('idsDocuments', newValues.filter((val) => val !== value))
    }
    else {
      newValues.push(value)
      handleValue('idsDocuments', newValues)
    }
  }

  const isLoading = useMemo(() => isFetchingSenders || isFetchingMailTypeList || isFetchingRecipients || isFetchingDocuments || formikForm.isSubmitting,
    [
      isFetchingSenders,
      isFetchingMailTypeList,
      isFetchingRecipients,
      isFetchingDocuments,
      formikForm.isSubmitting,
    ])

  const recipientList = useMemo(() => [ ...recipients ].sort((a, b) => a.nom.localeCompare(b.nom)), [ recipients ])
  const mailTypeList = useMemo(() => [ ...mailTypes ].sort((a, b) => a.code.localeCompare(b.code)), [ mailTypes ])
  const senderOptions: SegmentedButtonOption[] = senders.map((option) => ({ value: option.type, ref: React.createRef() }))
  const createNewBooleanOptions = (): SegmentedButtonOption[] => [ { value: 'true', label: 'Oui' }, { value: 'false', label: 'Non' } ].map((option) => ({ ...option, ref: React.createRef() }))
  const mailSimpleOptions: SegmentedButtonOption[] = [ { value: 'true', label: 'Mail simple' }, { value: 'false', label: 'Mail complet' } ].map((option) => ({ ...option, ref: React.createRef() }))

  return (
    <>
      <HeaderAction
        title="Nouveau Mail"
        onSubmit={formikForm.handleSubmit}
      >
        <SubmitFormButton
          type="submit"
          variant="contained"
          disabled={isLoading}
        >
          Envoyer
        </SubmitFormButton>
      </HeaderAction>
      <PageContainer>
        {isLoading && <Loader />}
        <Form form={formikForm}>
          <SegmentedGridContainer>
            <SegmentedButtons
              options={mailSimpleOptions}
              selectedOption={String(formikForm.values.mailSimple)}
              setSelectedOption={(newVal) => handleValue(`mailSimple`, newVal === 'true')}
            />
          </SegmentedGridContainer>
          {
            formikForm.values.mailSimple === false && (
              <>
                <FormBoldTitle>
                  Expediteur
                </FormBoldTitle>
                <SegmentedButtons
                  options={senderOptions}
                  selectedOption={formikForm.values.emetteur.type}
                  setSelectedOption={(newVal) => handleValue('emetteur', senders.find((sender) => sender.type === newVal as TypeDestinataireEmetteur))}
                />
              </>
            )
          }
          <FormBoldTitle required>
            Destinataire
          </FormBoldTitle>
          {
            formikForm.values.mailSimple ?
              <>
                <ThreeGridContainer error={formikForm.touched.destinataires !== undefined && formikForm.errors.destinataires !== undefined}>
                  {
                    recipientList.map((recipient, index) => (
                      <BigCheckableButton
                        key={`${recipient.email}-${index}`}
                        checked={formikForm.values.destinataires?.some((to) => to.email === recipient.email) ?? false}
                        onChange={(e, c) => handleCheckedRecipientButton(recipient.email, c)}
                        label={
                          (
                            <>
                              <NameContainer>
                                {recipient.nom}
                                {' '}
                                {recipient.type !== null && `(${recipient.type})`}
                              </NameContainer>
                              <DescriptionContainer>
                                {recipient.email}
                              </DescriptionContainer>
                            </>
                          )
                        }
                      />
                    ))
                  }
                </ThreeGridContainer>
                <ErrorMessage name="destinataires" />
              </>:
              <AutocompleteField
                name="listeDestinataires"
                options={recipientList.map((recipient) => ({ code: recipient.email, libelle: recipient.nom }))}
                multiple
                freeSolo
              />
          }
          {
            formikForm.values.mailSimple === false && (
              <>
                <GridContainer>
                  <div>
                    <FormBoldTitle>
                      CC
                    </FormBoldTitle>
                    <AutocompleteField
                      name="listeDestinatairesCC"
                      options={recipientList.map((recipient) => ({ code: recipient.email, libelle: recipient.nom }))}
                      multiple
                      freeSolo
                    />
                  </div>
                  <div>
                    <FormBoldTitle>
                      CCI
                    </FormBoldTitle>
                    <AutocompleteField
                      name="listeDestinatairesCCI"
                      options={recipientList.map((recipient) => ({ code: recipient.email, libelle: recipient.nom }))}
                      multiple
                      freeSolo
                    />
                  </div>
                </GridContainer>
                <FormBoldTitle>
                  Pièces jointes
                </FormBoldTitle>
                <ThreeGridContainer>
                  {
                    documents
                      .filter((file: PieceJointe) => file.type === TypePJ.Doc || file.type === TypePJ.DocEvenementiel)
                      .map((file, index) => (
                        <MediumCheckableButton
                          key={`${file.fileName}-${index}`}
                          checked={formikForm.values.idsDocuments?.some((value) => value === file.id) ?? false}
                          onChange={(e, c): void => handleCheckedFileButton(file.id || '', c)}
                          label={
                            (
                              <>
                                <NameContainer>
                                  {file.libelle}
                                </NameContainer>
                                <DescriptionContainer>
                                  {file.categorie?.libelle}
                                </DescriptionContainer>
                              </>
                            )
                          }
                        />
                      ))
                  }
                </ThreeGridContainer>
              </>
            )
          }
          <FormBoldTitle>
            Mail type
          </FormBoldTitle>
          <Field
            name="type.code"
            component={Select}
            displayEmpty
            renderValue={verifySelectFieldValue(formikForm.values.type?.code)}
            disabled={!isConnected}
          >
            {
              mailTypeList.map((mailType, index) => (
                <MenuItem
                  value={mailType.code}
                  key={`${mailType.code}-${index}`}
                >
                  {mailType.libelle}
                </MenuItem>
              ))
            }
          </Field>
          <FormBoldTitle required>
            Objet
          </FormBoldTitle>
          <Field
            component={TextField}
            placeholder="Objet"
            name="objet"
          />
          <FormBoldTitle required>
            Message
          </FormBoldTitle>
          <RichTextEditorComponentContainer error={formikForm.touched.message !== undefined && formikForm.errors.message !== undefined}>
            <RichTextEditorComponent
              value={formikForm.values.message}
              change={(e: {value: string}): void => handleValue('message', e.value)}
              toolbarSettings={RichTextSettings}
            >
              <Inject services={RichTextServices} />
            </RichTextEditorComponent>
          </RichTextEditorComponentContainer>
          <ErrorMessage name="message" />
          {
            formikForm.values.mailSimple === false && (
              <>
                <ThreeGridContainer>
                  <div>
                    <FormBoldTitle>
                      Recevoir une copie
                    </FormBoldTitle>
                    <SegmentedButtons
                      smaller
                      options={createNewBooleanOptions()}
                      selectedOption={String(formikForm.values.receptionCopie)}
                      setSelectedOption={(newVal): void => handleValue(`receptionCopie`, newVal === 'true')}
                    />
                  </div>
                  <div>
                    <FormBoldTitle>
                      Envoyer les PJ en .pdf
                    </FormBoldTitle>
                    <SegmentedButtons
                      smaller
                      options={createNewBooleanOptions()}
                      selectedOption={String(formikForm.values.passerEnPDF)}
                      setSelectedOption={(newVal): void => handleValue(`passerEnPDF`, newVal === 'true')}
                    />
                  </div>
                  <div>
                    <FormBoldTitle>
                      Modifier la signature
                    </FormBoldTitle>
                    <SegmentedButtons
                      smaller
                      options={createNewBooleanOptions()}
                      selectedOption={String(formikForm.values.afficherSignature)}
                      setSelectedOption={(newVal): void => handleValue(`afficherSignature`, newVal === 'true')}
                    />
                  </div>
                </ThreeGridContainer>
                {
                  formikForm.values.afficherSignature && (
                    <>
                      <FormBoldTitle>
                        Signature
                      </FormBoldTitle>
                      <RichTextEditorComponentContainer>
                        <RichTextEditorComponent
                          value={formikForm.values.signature || ''}
                          change={(e: {value: string}): void => handleValue('signature', e.value)}
                          toolbarSettings={RichTextSettings}
                        >
                          <Inject services={RichTextServices} />
                        </RichTextEditorComponent>
                      </RichTextEditorComponentContainer>
                    </>
                  )
                }
              </>
            )
          }
        </Form>
      </PageContainer>
    </>
  )
}

export default QaMailPage

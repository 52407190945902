/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ActeurRendezVousStatut {
  /** @minLength 1 */
  acteurId: string;
  convoque: boolean;
  present: boolean;
  represente: boolean;
  /** @maxLength 80 */
  representePar?: string | null;
}

export interface ActeurTraveller {
  /** @minLength 1 */
  id: string;
  type: TypePersonne;
  role?: CodeLabel;
  libelle?: string | null;
  /**
   * @minLength 1
   * @maxLength 32
   */
  nom: string;
  /** @maxLength 20 */
  prenom?: string | null;
  adresse: Adresse;
  police?: string | null;
  /** @maxLength 17 */
  reference?: string | null;
  profession?: string | null;
  qualite?: CodeLabel;
  politesse?: CodeLabel;
  assujettissementTva?: CodeLabel;
  responsabilite?: CodeLabel;
  lese?: OuiNonIndetermineEnumLabel;
  qualifiants: CodeLabel[];
  mandant: boolean;
  /** @maxLength 20 */
  telDomicile?: string | null;
  /** @maxLength 20 */
  telBureau?: string | null;
  /** @maxLength 20 */
  telPortable?: string | null;
  /** @maxLength 80 */
  mail?: string | null;
  intervenants?: IntervenantTraveller[] | null;
  dommagesMobilierDivers?: Dommage[] | null;
  pieces?: PersonnePiece[] | null;
  indemnisation?: IndemnisationTraveller;
  responsabiliteRecours?: ResponsabiliteTraveller;
  recours?: RecoursTraveller[] | null;
  reglements?: ReglementTraveller[] | null;
  isDeleted?: boolean;
  canBeDeleted?: boolean;
}

export interface Action {
  typePJDefautSINAPPS?: CodeLabel;
  logiciel: InfoLogiciel;
  /** @minLength 1 */
  nomDocumentAssocie: string;
  parametrage: ActionParametrage;
  acteurParDefaut?: CodeLabel;
  destinatairesParDefaut?: CodeValueStr[] | null;
  envoiEditique?: EnvoiEditique;
  /** @minLength 1 */
  code: string;
  libelle?: string | null;
}

export interface ActionParametrage {
  /** @format int32 */
  nombreExemplaire?: number;
  /** @format int32 */
  nombreMemo?: number;
  affichable?: boolean;
  effacable?: boolean;
  facturable?: boolean;
  datable?: boolean;
  arPostal?: boolean;
  actSon?: boolean;
  dependante?: boolean;
  rendezVous?: boolean;
  cinematique?: boolean;
  image?: boolean;
  cache?: boolean;
}

export enum ActionRecours {
  Recours = "Recours",
  ActionRemboursement = "ActionRemboursement",
  AbandonRecours = "AbandonRecours",
}

export interface Adresse {
  /** @maxLength 32 */
  adresse1?: string | null;
  /** @maxLength 32 */
  adresse2?: string | null;
  /** @maxLength 32 */
  adresse3?: string | null;
  /** @maxLength 8 */
  digicode?: string | null;
  /** @maxLength 10 */
  codePostal?: string | null;
  /** @maxLength 26 */
  ville?: string | null;
  /** @maxLength 80 */
  pays?: string | null;
  /** @maxLength 32 */
  nom?: string | null;
}

export interface AdresseRisque {
  adresse?: Adresse;
  verification?: Verif;
}

export interface AffectationCPSource {
  /**
   * Département
   * @minLength 1
   * @maxLength 2
   */
  departement: string;
  /**
   * source
   * @minLength 1
   * @maxLength 2
   */
  source: string;
}

export enum AffranchissementEsker {
  Simple = "Simple",
  LRAR = "LRAR",
  Ecopli = "Ecopli",
}

export interface Agence {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  nom: string;
  /** @minLength 1 */
  email: string;
  adresse: Adresse;
  telephones: Telephones;
}

export interface AgrementsDarva {
  /**
   * Réception
   * @minLength 1
   * @maxLength 3
   */
  reception: string;
  /**
   * Emission
   * @maxLength 30
   */
  emission?: string | null;
  /** Flag Sinapps */
  flagSinapps?: boolean;
}

export interface AnnuaireResume {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  nom: string;
  prenom?: string | null;
  pro: boolean;
  codePostal?: string | null;
  ville?: string | null;
  /** @deprecated */
  telephone?: string | null;
  telephones: Telephones;
}

export interface Anomalie {
  /** @minLength 1 */
  code: string;
  libelle?: string | null;
  reponse?: boolean;
}

export interface ArticleEmbellissment {
  article: CodeLabel;
  famille: FamilleArticleEmbellissement;
  /**
   * Description
   * @maxLength 255
   */
  description?: string | null;
  /**
   * Unité de mesure
   * @maxLength 5
   */
  unite?: string | null;
}

export interface ArticleEmbellissmentREX {
  article?: ArticleEmbellissment;
  /**
   * Prix 1
   * @format double
   */
  prix1?: number | null;
  /**
   * Prix 2
   * @format double
   */
  prix2?: number | null;
  /**
   * Prix 3
   * @format double
   */
  prix3?: number | null;
  /**
   * Prix 4
   * @format double
   */
  prix4?: number | null;
  /**
   * Prix 5
   * @format double
   */
  prix5?: number | null;
  /**
   * Prix 6
   * @format double
   */
  prix6?: number | null;
  /**
   * Prix 7
   * @format double
   */
  prix7?: number | null;
  /**
   * Prix 8
   * @format double
   */
  prix8?: number | null;
  /**
   * Prix 9
   * @format double
   */
  prix9?: number | null;
  /**
   * Prix 10
   * @format double
   */
  prix10?: number | null;
  /**
   * Prix 11
   * @format double
   */
  prix11?: number | null;
  /**
   * Prix 12
   * @format double
   */
  prix12?: number | null;
  /**
   * Prix 13
   * @format double
   */
  prix13?: number | null;
  /**
   * Prix 14
   * @format double
   */
  prix14?: number | null;
  /**
   * Prix 15
   * @format double
   */
  prix15?: number | null;
}

export interface AssureIrsi {
  assure?: PersonneResume;
  adresse?: Adresse;
  qualifiants?: CodeLabel[] | null;
}

export interface AssureurCible {
  /** @minLength 1 */
  id: string;
  nom?: string | null;
  cabinet?: string | null;
  refExpert?: string | null;
}

export interface AutreTelephone {
  type: TypeAutreMedia;
  /** @minLength 1 */
  valeur: string;
}

export interface BailCommercial {
  commentaire?: string | null;
  isBC?: boolean;
}

export interface BordereauCompagnieREX {
  /**
   * Code annuaire
   * @minLength 1
   * @maxLength 32
   */
  codeAnnuaire: string;
  /**
   * Famille annuaire
   * @minLength 1
   * @maxLength 2
   */
  familleAnnuaire: string;
  /** Professionnel de l'assurance */
  professionnelAssurance: boolean;
  article?: ArticleEmbellissmentREX;
}

export interface CacheInfo {
  type?: CacheType;
  /** @format int32 */
  tailleTotale?: number;
  entrees?: Record<string, number | null>;
}

export enum CacheType {
  Database = "Database",
  Traveller = "Traveller",
  Document = "Document",
}

export interface CasBareme {
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  libelle: string;
  causesBareme: CodeLabel[];
}

export interface CategoriePJ {
  categorie: CodeLabel;
  /**
   * Catégorie de pièce jointe Sinapps
   * @maxLength 50
   */
  categorieSinapps?: string | null;
}

export interface Cause {
  causeSinistre: CodeLabel;
  causeSupprimee: CodeLabel;
  casBareme: CodeLabel;
  causeBareme?: CodeLabel;
}

export interface CauseRequest {
  causeSinistre?: string | null;
  causeSupprimee?: string | null;
  casBareme?: string | null;
  causeBareme?: string | null;
}

export interface CauseSinapps {
  listeDetailSinapps?: DetailSinapps[] | null;
  /** @minLength 1 */
  code: string;
  libelle?: string | null;
}

export interface ChangePasswordRequest {
  /**
   * @minLength 1
   * @maxLength 40
   */
  newPassword: string;
  /** @minLength 1 */
  oldPassword: string;
}

export interface ChangePasswordResetRequest {
  /** @minLength 1 */
  token: string;
  /**
   * @minLength 1
   * @maxLength 40
   */
  newPassword: string;
}

export interface Chiffrage {
  beneficiaire?: string | null;
  regimeFiscal?: RegimeFiscal;
  accord?: CodeLabel;
  prejudicesMateriels?: PrejudiceMateriel[] | null;
  prejudicesImmateriels?: PrejudiceImmateriel[] | null;
}

export interface ChiffrageRequest {
  beneficiaire?: string | null;
  regimeFiscal?: RegimeFiscal;
  accord?: string | null;
  prejudicesMateriels?: PrejudiceMaterielRequest[] | null;
  prejudicesImmateriels?: PrejudiceImmaterielRequest[] | null;
}

export interface CibleRecours {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  nom: string;
  police?: string | null;
  assureurCible?: AssureurCible;
}

export interface Circonstance {
  /** @format date-time */
  dateCirconstance: string;
  natureSinistre: CodeLabel;
  sinistreRepetitif?: boolean;
  /** @format date-time */
  datePremierSinistre?: string | null;
  naturePremierSinistre?: string | null;
  auteurPremierSinistre?: string | null;
  rechercheDeFuite?: boolean;
  commentaire?: string | null;
  causesMultiples?: boolean;
}

export interface CirconstanceRequest {
  /** @format date-time */
  dateCirconstance?: string | null;
  natureSinistre?: string | null;
  sinistreRepetitif?: boolean;
  /** @format date-time */
  datePremierSinistre?: string | null;
  naturePremierSinistre?: string | null;
  auteurPremierSinistre?: string | null;
  rechercheDeFuite?: boolean;
  commentaire?: string | null;
  causesMultiples?: boolean;
}

export interface CodeEtatDossier {
  code?: CodeLabel;
  /** Code état du dossier automatique */
  dossierAuto?: boolean;
}

export interface CodeLabel {
  /** @minLength 1 */
  code: string;
  libelle?: string | null;
}

export interface CodePostal {
  code: CodeLabel;
  /**
   * Localisation (toujours 1)
   * @format int32
   */
  localisation: number;
}

export interface CodePostalEtranger {
  codePostal: CodeLabel;
  /**
   * Pays
   * @minLength 1
   */
  pays: string;
}

export interface CodeValueDec {
  /** @format double */
  value?: number | null;
  /** @minLength 1 */
  code: string;
  libelle?: string | null;
}

export interface CodeValueStr {
  value?: string | null;
  /** @minLength 1 */
  code: string;
  libelle?: string | null;
}

export interface CoefficientsDepartementsREX {
  /**
   * Code annuaire
   * @minLength 1
   * @maxLength 32
   */
  codeAnnuaire: string;
  /**
   * Famille annuaire
   * @minLength 1
   * @maxLength 2
   */
  familleAnnuaire: string;
  /** Professionnel de l'assurance */
  professionnelAssurance: boolean;
  /**
   * Famille de l'article
   * @minLength 1
   * @maxLength 5
   */
  familleArticle: string;
  /**
   * Aiguillage
   * @minLength 1
   * @maxLength 1
   */
  aiguillage: string;
  /**
   * Article
   * @minLength 1
   */
  article: string;
  /**
   * Article
   * @minLength 1
   * @maxLength 3
   */
  codeDepartement: string;
  /**
   * Coefficient
   * @format double
   */
  coefficient?: number | null;
}

export interface CommandeCloturerInsatisfactionRequest {
  estSatisfait: boolean;
  motif?: string | null;
  commentaire?: string | null;
}

export interface CommandeCloturerSollicitationRequest {
  /** @minLength 1 */
  guidSollicitation: string;
  commentaire?: string | null;
}

export interface CommandeDemanderFinPrestationRequest {
  /** @minLength 1 */
  motif: string;
  commentaire?: string | null;
}

export interface CommandeDeposerCompteRenduRequest {
  resteAFaire: string[];
  commentaire?: string | null;
  idFichiers?: string[] | null;
}

export interface CommandeDeposerConclusionsRequest {
  motif?: string | null;
  commentaire?: string | null;
  idFichiers?: string[] | null;
}

export interface CommandeLeverDifficulteRequest {
  idDifficulte?: string | null;
  /** @format int32 */
  compteur?: number;
  commentaire?: string | null;
}

export interface CommandeLeverFinPrestationRequest {
  commentaire?: string | null;
}

export interface CommandeSignalerUneDifficulteRequest {
  /** @minLength 1 */
  etape: string;
  type?: string | null;
  /** @minLength 1 */
  motif: string;
  /** @format date-time */
  datePrevisionnelleReprise?: string | null;
  commentaire?: string | null;
}

export interface CommandeSolliciterIntervenantRequest {
  /** @minLength 1 */
  typeDestinataire: string;
  intervention: TypeIntervention;
  complementPrestation?: string | null;
  natureDommages?: string | null;
  sousTypeIntervention?: SousTypeIntervention;
  natureOperation?: NatureOperation;
  estComplexe?: boolean;
  jePeuxIntervenir: boolean;
  criticite?: Criticite;
  motifUrgence?: MotifUrgence;
  convention?: string | null;
  detailConvention?: TranchesIRSI;
  commentaire?: string | null;
}

export interface CommandeTraiterInsatisfactionRequest {
  /** @minLength 1 */
  commentaire: string;
  documents?: DocumentAvecTypeRequest[] | null;
}

export interface CommentaireDossier {
  /** @minLength 1 */
  id: string;
  /** @format int32 */
  action: number;
  /** @minLength 1 */
  libelle: string;
  /** @minLength 1 */
  commentaire: string;
}

export interface CommentaireDossierRequest {
  /**
   * Libellé du commentaire
   * @minLength 1
   */
  libelle: string;
  /**
   * Action du commentaire
   * @minLength 1
   */
  action: string;
  /**
   * Commentaire
   * @minLength 1
   */
  commentaire: string;
}

export interface Confidentiel {
  anomalies: Anomalie[];
  commentaire?: string | null;
  noteConfidentielle?: string | null;
}

export interface Conformite {
  commentaire?: string | null;
  verification?: Verif;
  motifNonVerification?: CodeLabel;
  descriptionRisqueErrone?: boolean;
  garantieEtOuCapitauxInsuffisants?: boolean;
  garantieEtOuCapitauxInsuffisantsCommentaire?: string | null;
  autreRejet?: boolean;
  autreRejetCommentaire?: string | null;
}

export interface ContratGarantieRecolte {
  /**
   * Code annuaire
   * @minLength 1
   * @maxLength 32
   */
  codeAnnuaire: string;
  /**
   * Famille annuaire
   * @minLength 1
   * @maxLength 2
   */
  familleAnnuaire: string;
  /** Professionnel de l'assurance */
  professionnelAssurance: boolean;
  /**
   * Contrat
   * @minLength 1
   * @maxLength 2
   */
  contrat: string;
  /**
   * Garantie
   * @minLength 1
   * @maxLength 2
   */
  garantie: string;
}

export type ContratPrg = object;

export interface ContratRex {
  nom?: string | null;
  /** @format date-time */
  dateSouscription?: string | null;
  /** @format double */
  indiceSouscription?: number | null;
  /** @format date-time */
  dateEffet?: string | null;
  /** @format double */
  franchise?: number;
  indexation?: boolean;
  /** @format double */
  indiceReglement?: number | null;
  referenceAnnexe?: string | null;
  referenceDisposition?: string | null;
  risqueExceptionnel?: boolean;
  plafondsDetailDeBien?: CodeValueDec[] | null;
  garanties?: GarantieContrat[] | null;
}

export interface Convocation {
  convoque: boolean;
  present: boolean;
  represente: boolean;
  /**
   * @minLength 1
   * @maxLength 80
   */
  representePar: string;
  /** @minLength 1 */
  url: string;
}

export interface Courrier {
  /** @minLength 1 */
  codeAction: string;
  /** @format int32 */
  compteurSequence: number;
  destinataire: EvenementielDestinataire;
  commentaire?: string | null;
  typeEnregistrement: TypeEnregistrementCourrier;
}

export interface CreationDossierCabinetRequest {
  /** @minLength 1 */
  cabinet: string;
  /** @minLength 1 */
  agence: string;
  /** @minLength 1 */
  expert: string;
}

export interface CreationDossierDommageRequest {
  /** @format double */
  montant?: number | null;
  commentaire?: string | null;
}

export interface CreationDossierExpertiseRequest {
  caractere?: string | null;
  type?: TypeExpertise;
  /** @format int32 */
  typeEAD?: number | null;
}

export interface CreationDossierPersonneRequest {
  /** @minLength 1 */
  nom: string;
  prenom?: string | null;
  telephoneMobile?: string | null;
  telephoneFixe?: string | null;
  adresse1?: string | null;
  adresse2?: string | null;
  adresse3?: string | null;
  /** @minLength 1 */
  codePostal: string;
  /** @minLength 1 */
  ville: string;
}

export interface CreationDossierRequest {
  /** @minLength 1 */
  compagnie: string;
  /** @minLength 1 */
  numeroPolice: string;
  typeMission?: string | null;
  causeEtCirconstances?: string | null;
  dommage: CreationDossierDommageRequest;
  assure: CreationDossierPersonneRequest;
  tiers: CreationDossierPersonneRequest[];
  sinistre: CreationDossierSinistreRequest;
  expertise: CreationDossierExpertiseRequest;
  cabinetEtAgence: CreationDossierCabinetRequest;
}

export interface CreationDossierSinistreRequest {
  /** @minLength 1 */
  numero: string;
  /** @minLength 1 */
  nature: string;
  /** @format date-time */
  date?: string | null;
  nom?: string | null;
  adresse1?: string | null;
  adresse2?: string | null;
  /** @minLength 1 */
  codePostal: string;
  /** @minLength 1 */
  ville: string;
}

export enum Criticite {
  Urgente = "Urgente",
  Normale = "Normale",
}

export interface CultureRecolte {
  culture: CodeLabel;
  /**
   * Famille de la culture
   * @minLength 1
   * @maxLength 5
   */
  familleCulture: string;
}

export interface CurrentCollaborateur {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  nom: string;
  /** @format int32 */
  numAgence: number;
  refAnnuaire: RefAnnuaire;
  /** @minLength 1 */
  userName: string;
  isSuperAdmin: boolean;
  currentCollaborateurType: CurrentCollaborateurType;
  loginContext: LoginContext;
  epassPermissions?: EpassPermissions;
  gexsiRoles?: string[];
}

export enum CurrentCollaborateurType {
  Nomad = "Nomad",
  Epass = "Epass",
}

export interface DataDocument {
  /**
   * @minLength 1
   * @pattern ^[ISD]{0,1}[-]{0,1}[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}-[0-9]{1,4}$
   */
  docId: string;
  dataDocumentType: DataDocumentType;
  typeEnregistrement: TypeEnregistrementCourrier;
  /** @minLength 1 */
  jsonData: string;
}

export enum DataDocumentType {
  NonDefinis = "NonDefinis",
  SFDT = "SFDT",
  SpreadSheet = "SpreadSheet",
  InfosSuivi = "InfosSuivi",
}

export interface DatesCle {
  /** @format date-time */
  sinistre?: string | null;
  /** @format date-time */
  envoiMission?: string | null;
  /** @format date-time */
  receptionMission?: string | null;
  /** @format date-time */
  accuseDeReception?: string | null;
  /** @format date-time */
  premierContact?: string | null;
  /** @format int32 */
  nombreDeRendezVous?: number | null;
  /** @format date-time */
  premierRendezVous?: string | null;
  /** @format date-time */
  dernierRendezVous?: string | null;
  /** @format int32 */
  nombreDeRapports?: number | null;
  /** @format date-time */
  dernierRapport?: string | null;
  /** @format date-time */
  preRapport?: string | null;
}

export enum DestinataireTache {
  Moi = "moi",
  Assistante = "assistante",
}

export interface DetailBien {
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  libelle: string;
  natureSinapps?: CodeLabel;
  detailSinapps?: CodeLabel;
  typeDommageSinapps?: TypeDommageSinapps;
}

export interface DetailSinapps {
  /** @minLength 1 */
  code: string;
  libelle?: string | null;
}

export interface Difficulte {
  refDossierCpt: RefDossierCpt;
  /** @minLength 1 */
  type: string;
  /** @format date-time */
  dateCreation: string;
  etape: EtapeEnumLabel;
  motif: CodeLabel;
  commentaire?: string | null;
  levee: boolean;
  /** @format date-time */
  dateLevee?: string;
  /** @format date-time */
  dateReprise?: string;
  commentaireLevee?: string | null;
  idDifficulte?: string | null;
}

export interface DocumentAvecTypeRequest {
  /**
   * @minLength 1
   * @pattern ^[ISD]{0,1}[-]{0,1}[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}-[0-9]{1,4}$
   */
  idFichier: string;
  type?: TypeDocumentSinapps;
}

export interface DocumentGestion {
  document?: CodeLabel;
  /**
   * Famille du sinistre
   * @maxLength 80
   */
  familleSinistre?: string | null;
  /**
   * Ordre d'affichage des documents
   * @format int32
   */
  ordreAffichage?: number;
}

export interface DocumentRequest {
  /** @format binary */
  fichier: File;
  categorie?: string | null;
  /** @minLength 1 */
  nom: string;
}

export interface Dommage {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  libelle: string;
  typeBien?: TypeDeBien;
  natureDuBien?: CodeLabel;
  detailDuBien?: CodeLabel;
  typeReparation?: TypeDeReparationEnumLabel;
  valeur: DommageValeur;
  vetuste: DommageVetuste;
  sinapps?: DommageInfosSinapps;
}

export interface DommageBienSinapps {
  /** @minLength 1 */
  id: string;
  typeBien?: TypeDeBien;
  bien?: CodeLabel;
  justification?: JustificationsEnumLabel;
  /** @format double */
  montantHT?: number;
  /** @format double */
  montantTva?: number;
  /** @format double */
  montantTTC?: number;
  /** @format double */
  montantVetusteHT?: number;
  /** @format double */
  montantVetusteTTC?: number;
  /** @format double */
  montantVetusteRecuperableHT?: number;
  /** @format double */
  montantVetusteRecuperableTTC?: number;
  /** @format double */
  plafond?: number;
  /** @format date-time */
  dateAchat?: string | null;
  descriptif?: string | null;
  reparation?: string | null;
  idDommageSinapps?: string | null;
  idDetailDommageSinapps?: string | null;
}

export interface DommageInfosSinapps {
  idDommageSinapps?: string | null;
  idDetailDommageSinapps?: string | null;
  natureDuBienSinapps?: CodeLabel;
  detailDuBienSinapps?: CodeLabel;
  typeDommageSinapps?: TypeDommageSinapps;
}

export interface DommageSinapps {
  /** @minLength 1 */
  id: string;
  typeBien?: TypeDeBien;
  natureDuBien?: CodeLabel;
  detailDuBien?: CodeLabel;
  modeIndemnisation?: CodeLabel;
  /** @format double */
  montantHT?: number;
  /** @format double */
  tauxTva?: number;
  /** @format double */
  montantTva?: number;
  /** @format double */
  montantTTC?: number;
  /** @format double */
  tauxVetuste?: number;
  /** @format double */
  montantVetusteHT?: number;
  /** @format double */
  montantVetusteTTC?: number;
  /** @format double */
  montantVetusteDeduiteHT?: number;
  /** @format double */
  montantVetusteDeduiteTTC?: number;
  /** @format double */
  montantVetusteRecuperableHT?: number;
  /** @format double */
  montantVetusteRecuperableTTC?: number;
  /** @format double */
  plafond?: number;
  idDommageSinapps?: string | null;
  idDetailDommageSinapps?: string | null;
}

export interface DommageValeur {
  /** @format double */
  quantite?: number;
  /** @format double */
  prixUnitaire?: number;
  uniteMesure?: CodeLabel;
  /** @format double */
  montantHT?: number;
  tauxTva?: TauxTVA;
  taxe?: Taxe;
  /** @format double */
  montantTva?: number;
  /** @format double */
  montantTTC?: number;
}

export interface DommageVetuste {
  /** @format double */
  tauxVetuste?: number;
  /** @format int32 */
  age?: number | null;
  /** @format double */
  montantVetuste?: number;
  /** @format double */
  tauxLimiteVetuste?: number;
  /** @format double */
  montantVetusteRecuperable?: number;
  /** @format double */
  montantVetusteDeduite?: number;
  /** @format date-time */
  dateAchat?: string | null;
  reparation?: Reparation;
}

export interface Dossier {
  id?: string | null;
  sinistre?: SinistreResume;
  compagnie: CodeLabel;
  agence?: Agence;
  cabinet: CodeLabel;
  mandants: Mandants;
  mission: Mission;
  personnes?: PersonneResumeAvecIntervenants[] | null;
  /** @maxLength 255 */
  postIt?: string | null;
  /** @format date-time */
  objectifCharteRapport?: string | null;
  caractereExpertise: CodeLabel;
  /** @minLength 1 */
  typeExpertise: string;
  etat?: CodeLabel;
  conventionApplicable?: CodeLabel;
}

export interface DossierRecherche {
  /** @minLength 1 */
  id: string;
  /** @format date-time */
  dateRendezVous?: string | null;
  assure: PersonneResume;
  tiers: PersonneResume[];
  etat: CodeLabel;
  sinistre: SinistreResume;
  compagnie?: CodeLabel;
  origine: CodeLabel;
  /** @format date-time */
  dateCreation?: string | null;
  /** @format date-time */
  objectifCharteRapport?: string | null;
  /** @minLength 1 */
  agenceTraitante: string;
}

export type DossierResume = object;

export interface DoubleCodeLabel {
  /** @minLength 1 */
  code2: string;
  /** @minLength 1 */
  code: string;
  libelle?: string | null;
}

export interface EchangeInterne {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  objet: string;
  /** @minLength 1 */
  message: string;
  /** @format date-time */
  date: string;
  lu: boolean;
  emetteur: EchangeInterneEmetteur;
  destinataire: EchangeInterneDestinataire;
  dossier: InfoDossier;
  rapport: InfoRapport;
}

export interface EchangeInterneDestinataire {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  nom: string;
}

export interface EchangeInterneEmetteur {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  nom: string;
}

export interface EchangeInterneRequest {
  /** Le ou les destinataire de l'EI */
  destinataires: string[];
  /**
   * L'objet de l'EI
   * @minLength 1
   */
  objet: string;
  /**
   * Message de l'EI
   * @minLength 1
   */
  message: string;
}

export interface EnvoiEditique {
  envoiImpressionCentralise?: boolean;
  envoiMaileva?: boolean;
  envoiEsker?: boolean;
  scenarioEsker?: CodeLabel;
}

export interface EnvoiEsker {
  /** @minLength 1 */
  idSuivi: string;
  idEsker?: string | null;
  idInfoSuivi?: string | null;
  scenario: ScenarioEsker;
  adresseDestinataire: Adresse;
  courrier?: PieceJointe;
  piecesJointesEsker?: PieceJointe[] | null;
  preuveDeDepot?: string | null;
  accuseReception?: string | null;
  urlSuiviRecommande?: string | null;
  historiqueEtatEsker?: EtatEsker[] | null;
  /** @format date-time */
  dateCreation?: string;
  /** @format date-time */
  dateModification?: string | null;
  /** @format date-time */
  dateEnvoi?: string | null;
}

export interface EpassPermissions {
  roleEpass?: RoleEpass;
  refCollaborateur?: RefAnnuaire;
  refDossier?: RefDossier;
}

export enum Etape {
  Qualification = "Qualification",
  RendezVousExpert = "RendezVousExpert",
  Expertise = "Expertise",
  Facturation = "Facturation",
  AcceptationPrestation = "AcceptationPrestation",
  RefusPrestation = "RefusPrestation",
  CompteRenduIntervention = "CompteRenduIntervention",
  EstimationTravaux = "EstimationTravaux",
  EstimationIntervention = "EstimationIntervention",
  Planification = "Planification",
  RendezVous = "RendezVous",
  CompteRenduRDF = "CompteRenduRDF",
  PlanificationIntervention = "PlanificationIntervention",
  Devis = "Devis",
}

export interface EtapeEnumLabel {
  code: Etape;
  libelle?: string | null;
}

export interface EtatEsker {
  etat?: CodeLabel;
  /** @format date-time */
  dateEtat?: string;
}

export enum EtatPapier {
  Frappe = "Frappe",
  Definitif = "Definitif",
}

export interface EtatPapierEnumLabel {
  code: EtatPapier;
  libelle?: string | null;
}

export enum EtatRapport {
  Qualification = "Qualification",
  Gestion = "Gestion",
  Cloture = "Cloture",
}

export interface EtatRapportEnumLabel {
  code: EtatRapport;
  libelle?: string | null;
}

export interface Eurogex {
  /**
   * Agence
   * @format int32
   */
  agence: number;
  /**
   * Section
   * @minLength 1
   * @maxLength 30
   */
  section: string;
  /**
   * Rubrique
   * @minLength 1
   * @maxLength 80
   */
  rubrique: string;
  /**
   * Valeur
   * @minLength 1
   * @maxLength 2500
   */
  valeur: string;
}

export interface EvenementExterieur {
  evenement: CodeLabel;
  /**
   * Etat du dossier suite à cet évènement extérieur
   * @maxLength 5
   */
  etatResultant?: string | null;
}

export interface EvenementielDestinataire {
  /** @minLength 1 */
  id: string;
  nom?: string | null;
  role?: string | null;
  adresse?: Adresse;
}

export interface Expert {
  /** @minLength 1 */
  nom: string;
  /** @minLength 1 */
  reference: string;
  /** @format date-time */
  dateExpertise: string;
  typeExpertise: TypeExpertiseIrsiEnumLabel;
  /** @minLength 1 */
  email: string;
  /** @minLength 1 */
  telephone: string;
  listeTelephones?: string[] | null;
}

export interface ExpertRequest {
  nom?: string | null;
  reference?: string | null;
  /** @format date-time */
  dateExpertise?: string | null;
  typeExpertise?: TypeExpertiseIrsi;
  email?: string | null;
  telephone?: string | null;
  listeTelephones?: string[] | null;
}

export interface FamilleAction {
  actions?: Action[] | null;
  /** @minLength 1 */
  code: string;
  libelle?: string | null;
}

export interface FamilleAnnuaire {
  /** Professionnel de l'assurance */
  professionnelAssurance: boolean;
  typePersonne: TypePersonneFamille;
  /** Famille Sinapps */
  familleSinapps?: string | null;
  /** @minLength 1 */
  code: string;
  libelle?: string | null;
}

export interface FamilleArticleEmbellissement {
  familleNatureSinistre: CodeLabel;
  /**
   * Aiguillage
   * @minLength 1
   * @maxLength 1
   */
  aiguillage: string;
  /**
   * Type de calcul
   * @maxLength 1
   */
  typeCalcul?: string | null;
  /**
   * Corps d'état
   * @maxLength 2
   */
  corpsEtat?: string | null;
}

export interface FamilleNatureSinistre {
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  libelle: string;
  causeSinistres: CodeLabel[];
  casBaremes: CasBareme[];
}

export interface FicheVigilance {
  guidVigilance?: string | null;
  idSinappsVigilance?: string | null;
  idEmetteur?: string | null;
  commentaire?: string | null;
  /** @format date-time */
  dateCreation: string;
  /** @format date-time */
  dateModification: string;
  ficheSupprimee: boolean;
  qualificationAlerte: CodeLabel;
  circonstanceSinistre?: CodeLabel[] | null;
  comportementIntervenant?: CodeLabel[] | null;
  contexteDossier?: CodeLabel[] | null;
  elementContrat?: CodeLabel[] | null;
  exageration?: CodeLabel[] | null;
  pieceJustificative?: CodeLabel[] | null;
  pieceJointes?: PieceJointe[] | null;
}

export interface FicheVigilanceRequest {
  /** @minLength 1 */
  codeQualificationAlerte: string;
  commentaire?: string | null;
  codesCirconstanceSinistre?: string[] | null;
  codesComportementIntervenant?: string[] | null;
  codesContexte?: string[] | null;
  codesElementContrat?: string[] | null;
  codesExageration?: string[] | null;
  codesPieceJustificative?: string[] | null;
  idFichiers?: string[] | null;
}

export enum FiltreDossier {
  Jour = "Jour",
  EnCours = "EnCours",
  Traites = "Traites",
}

export enum FiltreFamilleAnnuaire {
  Toutes = "Toutes",
  Professionnelles = "Professionnelles",
  NonProfessionnelles = "NonProfessionnelles",
  IntervenantTiers = "IntervenantTiers",
  IntervenantAssure = "IntervenantAssure",
  EntreprisePartenaire = "EntreprisePartenaire",
}

export interface FraisAfferent {
  typeFrais: CodeLabel;
  /** @format double */
  montantVAN: number;
  /** @format double */
  montantVD?: number;
  tauxTVA?: TauxTVA;
}

export interface FraisAfferentRequest {
  typeFrais?: string | null;
  /** @format double */
  montantVAN?: number;
  /** @format double */
  montantVD?: number;
  tauxTVA?: TauxTVA;
}

export interface Garantie {
  code: CodeLabel;
  acceptation: CodeLabel;
  /** @format double */
  montantDegatsNonCouverts?: number;
  commentaire?: string | null;
}

export interface GarantieContrat {
  franchises?: GarantieFranchise[] | null;
  plafonds?: CodeValueDec[] | null;
  /** @format double */
  value?: number | null;
  /** @minLength 1 */
  code: string;
  libelle?: string | null;
}

export interface GarantieFranchise {
  typeFranchise?: CodeLabel;
  /** @format double */
  montantFranchise?: number;
  /** @format float */
  tauxFranchise?: number;
  /** @format double */
  montantPlancherFranchise?: number;
  /** @format double */
  montantPlafondFranchise?: number;
}

export interface HealthReport {
  entries?: Record<string, HealthReportEntry>;
  status?: HealthStatus;
  /** @format date-span */
  totalDuration?: string;
}

export interface HealthReportEntry {
  data?: Record<string, any>;
  description?: string | null;
  /** @format date-span */
  duration?: string;
  exception?: any;
  status?: HealthStatus;
  tags?: string[] | null;
}

export enum HealthStatus {
  Unhealthy = "Unhealthy",
  Degraded = "Degraded",
  Healthy = "Healthy",
}

export interface Identification {
  numeroDePolice?: string | null;
  refSinistre?: string | null;
  noMission?: string | null;
  /** @format date-time */
  dateMission?: string | null;
  assureur?: string | null;
  agence1?: string | null;
  agence2?: string | null;
}

export interface Indemnisation {
  /** @minLength 1 */
  id: string;
  typeDeReparation?: TypeDeReparationEnumLabel;
  natureDuBien?: CodeLabel;
  detailDuBien?: CodeLabel;
  natureDuBienSinapps?: CodeLabel;
  detailDuBienSinapps?: CodeLabel;
  /** @format double */
  montantHT?: number;
  /** @format double */
  montantTTC?: number;
  /** @format double */
  montantVetusteDeduiteTTC?: number;
  /** @format double */
  montantVetusteRecuperable?: number;
  /** @format double */
  montantDecouvert?: number;
  garantie?: CodeLabel;
}

export interface IndemnisationSinapps {
  garantie?: CodeLabel;
  /** @format double */
  montantIndemniteImmediateHTAvant?: number;
  /** @format double */
  montantIndemniteImmediateTTCAvant?: number;
  /** @format double */
  montantIndemniteImmediateHTApres?: number;
  /** @format double */
  montantIndemniteImmediateTTCApres?: number;
  /** @format double */
  montantIndemniteDiffereeHTAvant?: number;
  /** @format double */
  montantIndemniteDiffereeTTCAvant?: number;
  /** @format double */
  montantIndemniteDiffereeHTApres?: number;
  /** @format double */
  montantIndemniteDiffereeTTCApres?: number;
  /** @format double */
  tauxPenalite?: number;
  /** @format double */
  montantPenaliteImmediateHT?: number;
  /** @format double */
  montantPenaliteImmediateTTC?: number;
  /** @format double */
  montantPenaliteDiffereeHT?: number;
  /** @format double */
  montantPenaliteDiffereeTTC?: number;
  /** @format double */
  montantFranchiseImmediateHT?: number;
  /** @format double */
  montantFranchiseImmediateTTC?: number;
  /** @format double */
  montantFranchiseDiffereeHT?: number;
  /** @format double */
  montantFranchiseDiffereeTTC?: number;
  /** @format double */
  montantFranchiseHT?: number;
  /** @format double */
  montantFranchiseTTC?: number;
}

export interface IndemnisationTraveller {
  accord?: CodeLabel;
  regime?: RegimeFiscalEnumLabel;
  indemnisations?: Indemnisation[] | null;
  dommagesSinapps?: DommageSinapps[] | null;
  dommagesBienSinapps?: DommageBienSinapps[] | null;
  indemnisationsSinapps?: IndemnisationSinapps[] | null;
}

export interface InfoDossier {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  mandant: string;
  natureSinistre: CodeLabel;
  etat: CodeLabel;
  /** @minLength 1 */
  nomAssure: string;
  /** @format date-time */
  objectifCharteRapport?: string | null;
}

export interface InfoLogiciel {
  /** @minLength 1 */
  extentionDocument: string;
  cheminDocument?: string | null;
  /** @minLength 1 */
  code: string;
  libelle?: string | null;
}

export interface InfoRapport {
  /** @minLength 1 */
  id: string;
  etat: EtatRapportEnumLabel;
}

export interface InformationsGroupe {
  /** @minLength 1 */
  nomDuGroupe: string;
  /** @minLength 1 */
  urlLogo: string;
  /** @minLength 1 */
  telephoneCRC: string;
  /** @minLength 1 */
  mailCRC: string;
  /** @minLength 1 */
  urlPlaquette: string;
}

export interface InfosDossierTraveller {
  /** @minLength 1 */
  id: string;
  pastilles?: Pastille[] | null;
  compagnie?: CodeLabel;
}

export interface InfosSinistreSinapps {
  nature?: CodeLabel;
  cause?: CodeLabel;
  detail?: CodeLabel;
  concordanceDeclaration?: boolean;
  causeSupprimee?: boolean | null;
  commentaireCauseSupprimee?: string | null;
  motifCauseNonSupprimee?: CodeLabel;
  commentaireMesuresConservatoires?: string | null;
}

export interface InitialisationDarva {
  /**
   * Compteur
   * @format int32
   */
  compteur: number;
  /**
   * Code abonné Darva
   * @maxLength 20
   */
  codeAbonne?: string | null;
  /**
   * Editel IRD WEB
   * @maxLength 1
   */
  editelIRDdWeb?: string | null;
  /**
   * URL IRDWEB
   * @maxLength 80
   */
  urlIRDdWeb?: string | null;
  /**
   * Login portail IRDWEB
   * @maxLength 20
   */
  loginPortailIRDdWeb?: string | null;
  /**
   * Mot de passe portail IRDWEB
   * @maxLength 20
   */
  mdpPortailIRDdWeb?: string | null;
  /**
   * Login export IRDWEB
   * @maxLength 20
   */
  loginExportIRDdWeb?: string | null;
  /**
   * Mot de passe export IRDWEB
   * @maxLength 20
   */
  mdpExportIRDdWeb?: string | null;
  /**
   * Login import IRDWEB
   * @maxLength 20
   */
  loginImportIRDdWeb?: string | null;
  /**
   * Mot de passe import IRDWEB
   * @maxLength 20
   */
  mdpImportIRDdWeb?: string | null;
  /**
   * Source par défaut
   * @maxLength 2
   */
  sourceDefaut?: string | null;
  /**
   * Multiple abonné
   * @maxLength 1
   */
  multiAbonne?: string | null;
  /**
   * Chemin fichier import
   * @maxLength 80
   */
  cheminFichierImport?: string | null;
  /**
   * Chemin fichier export
   * @maxLength 80
   */
  cheminFichierExport?: string | null;
  /**
   * Intégration automatique
   * @maxLength 1
   */
  integrationAutomatique?: string | null;
  /**
   * URL pré-remplissage REC
   * @maxLength 80
   */
  urlPreRemplissageREC?: string | null;
  /**
   * Login pré-remplissage REC
   * @maxLength 20
   */
  loginPreRemplissageREC?: string | null;
  /**
   * Mot de passe pré-remplissage REC
   * @maxLength 20
   */
  mdpPreRemplissageREC?: string | null;
}

export interface InitialisationSinapps {
  /**
   * Compteur
   * @format int32
   */
  compteur: number;
  /**
   * Source
   * @maxLength 2
   */
  source?: string | null;
  /**
   * ID du partenaire
   * @maxLength 25
   */
  partenaireID?: string | null;
  /**
   * Code abonné Darva
   * @maxLength 20
   */
  codeAbonne?: string | null;
  /**
   * Login Sinapps
   * @maxLength 20
   */
  loginSinapps?: string | null;
  /**
   * Mot de passe Sinapps
   * @maxLength 20
   */
  mdpSinapps?: string | null;
  /**
   * Login export lien transparent
   * @maxLength 20
   */
  loginLienTransparent?: string | null;
  /**
   * Mot de passe lien transparent
   * @maxLength 20
   */
  mdpLienTransparent?: string | null;
  /**
   * Secrétaire  par défaut
   * @maxLength 32
   */
  secretaireDefaut?: string | null;
  /**
   * Collaborateur par défaut
   * @maxLength 32
   */
  collaborateurDefaut?: string | null;
  /**
   * Int famille
   * @maxLength 2
   */
  intFamille?: string | null;
  /**
   * Famille mandataire
   * @maxLength 2
   */
  familleMandataire?: string | null;
  /**
   * URL Web Service Sinapps
   * @maxLength 100
   */
  urlWebServiceSinapps?: string | null;
  /**
   * URL IRDWEB
   * @maxLength 100
   */
  urlTokenLienTransparent?: string | null;
  /**
   * Login import IRDWEB
   * @format int32
   */
  dureeTokenLienTransparent?: number;
}

export interface IntervenantParticipant {
  /** @minLength 1 */
  reference: string;
  qualite?: CodeLabel;
  /** @minLength 1 */
  id: string;
  type: TypePersonne;
  /** @minLength 1 */
  nom: string;
  /** @minLength 1 */
  prenom: string;
  /** @minLength 1 */
  role: string;
  convocation: Convocation;
}

export interface IntervenantResume {
  id?: string | null;
  /** @maxLength 32 */
  nom?: string | null;
  role?: string | null;
  /** @maxLength 20 */
  tel?: string | null;
  saisieRapide: boolean;
  adresse?: Adresse;
  email?: string | null;
  telephones: Telephones;
}

export interface IntervenantTraveller {
  /** @minLength 1 */
  id: string;
  type: TypePersonne;
  libelle?: string | null;
  /**
   * @minLength 1
   * @maxLength 32
   */
  nom: string;
  /** @maxLength 20 */
  prenom?: string | null;
  professionnel: boolean;
  adresse: Adresse;
  /** @maxLength 17 */
  reference?: string | null;
  qualite?: CodeLabel;
  politesse?: CodeLabel;
  mandant: boolean;
  /** @maxLength 20 */
  telDomicile?: string | null;
  /** @maxLength 255 */
  telBureau?: string | null;
  /** @maxLength 255 */
  telPortable?: string | null;
  /** @maxLength 80 */
  mail?: string | null;
  role?: string | null;
  saisieRapide: boolean;
  refAnnuaireSaisie?: string | null;
  cabinet?: string | null;
  refExpert?: string | null;
  isDeleted?: boolean;
  canBeDeleted?: boolean;
}

export interface Irsi {
  /** @minLength 1 */
  id: string;
  identification: Identification;
  expert: Expert;
  assure: AssureIrsi;
  risque: RisqueIrsi;
  circonstance: Circonstance;
  causes: Cause[];
  personnesImpliquees: PersonneImplique[];
  recours: RecoursIrsi;
  /** @format double */
  assiette?: number;
  commentaire?: string | null;
}

export interface IrsiRequest {
  identification?: Identification;
  expert?: ExpertRequest;
  risque?: RisqueIrsiRequest;
  circonstance?: CirconstanceRequest;
  causes?: CauseRequest[] | null;
  personnesImpliquees?: PersonneImpliqueRequest[] | null;
  recours?: RecoursIrsi;
  /** @format double */
  assiette?: number;
  commentaire?: string | null;
}

export interface JalonSimplifie {
  /** @minLength 1 */
  libelle: string;
  fait: boolean;
  /** @format date-time */
  date?: string | null;
}

export enum Justifications {
  J = "J",
  JE = "JE",
  SJ = "SJ",
}

export interface JustificationsEnumLabel {
  code: Justifications;
  libelle?: string | null;
}

export interface LocalisationGPS {
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
}

export interface LoginContext {
  type: LoginContextType;
  refDossier?: RefDossier;
}

export enum LoginContextType {
  Complete = "Complete",
  Dossier = "Dossier",
}

export interface LoginRequest {
  /** @minLength 1 */
  username: string;
  /** @minLength 1 */
  password: string;
}

export interface LoginResponse {
  currentCollaborateur: CurrentCollaborateur;
  /** @minLength 1 */
  token: string;
}

export interface MailDestinataire {
  /** @minLength 1 */
  nom: string;
  role?: string | null;
  type: TypeDestinataireEmetteur;
  /**
   * @format email
   * @minLength 1
   */
  email: string;
}

export interface MailEmetteur {
  /** @minLength 1 */
  nom: string;
  type: TypeDestinataireEmetteur;
  /**
   * @format email
   * @minLength 1
   */
  email: string;
  signature?: string | null;
}

export interface MailRequest {
  emetteur: MailEmetteur;
  /** Le ou les emails des destinataires */
  destinataires?: MailDestinataire[] | null;
  /** Le ou les emails des destinataires libres */
  destinatairesLibres?: string[] | null;
  /** Le ou les emails des copies */
  destinatairesCC?: MailDestinataire[] | null;
  destinatairesLibresCC?: string[] | null;
  /** Le ou les emails des copies cachées */
  destinatairesCCI?: MailDestinataire[] | null;
  destinatairesLibresCCI?: string[] | null;
  /**
   * L'objet du mail
   * @minLength 1
   */
  objet: string;
  /**
   * Message du mail
   * @minLength 1
   */
  message: string;
  /** Signature du mail */
  signature?: string | null;
  /** Mail brouillon */
  brouillon?: boolean;
  /** Demander une confirmation de réception */
  confirmationReception?: boolean;
  /** Réception d'une copie dans votre boite mail */
  receptionCopie?: boolean;
  /** Ne pas laisser les pièces jointes dans leur format original (donc passé en PDF) */
  passerEnPDF?: boolean;
  idsDocuments?: string[] | null;
}

export interface MailType {
  /** @minLength 1 */
  object: string;
  /** @minLength 1 */
  message: string;
}

export interface Mandants {
  compagnie?: CodeLabel;
  /** @minLength 1 */
  referenceCompagnie: string;
  delegation?: CodeLabel;
  /** @minLength 1 */
  referenceDelegation: string;
  inspecteur?: CodeLabel;
  /** @minLength 1 */
  referenceInspecteur: string;
  gestionnaire?: CodeLabel;
  /** @minLength 1 */
  referenceGestionnaire: string;
  agent?: CodeLabel;
  /** @minLength 1 */
  referenceAgent: string;
  courtier?: CodeLabel;
  /** @minLength 1 */
  referenceCourtier: string;
}

export interface MesDossierResultat {
  /** @minLength 1 */
  id: string;
  /** @format date-time */
  dateRendezVous?: string | null;
  assure: PersonneResume;
  tiers: PersonneResume[];
  etat: CodeLabel;
  sinistre: SinistreResume;
  compagnie?: CodeLabel;
  origine: CodeLabel;
  /** @format date-time */
  dateDepotRapport?: string | null;
  /** @minLength 1 */
  agenceTraitante: string;
  /**
   * @deprecated
   * @format int32
   */
  nombreElementMediatheque: number;
}

export interface MessageSMS {
  message: CodeLabel;
  /**
   * Libellé court du message
   * @maxLength 30
   */
  libelleCourt?: string | null;
}

export interface MesureConservatoire {
  mesure?: CodeLabel;
  /**
   * Famille du sinistre
   * @maxLength 80
   */
  familleSinistre?: string | null;
  /**
   * Ordre d'affichage des documents
   * @format int32
   */
  ordreAffichage?: number;
}

export interface MesureDeclareeReleveeVerifiee {
  /** @format double */
  valeurDeclaree?: number | null;
  /** @format double */
  valeurRelevee?: number | null;
  valeurVerifiee?: Verif;
}

export interface Mission {
  origine?: CodeLabel;
  /** @format date-time */
  recueLe?: string | null;
  reference?: string | null;
  expert?: AnnuaireResume;
  assistante?: AnnuaireResume;
}

export enum ModeRDV {
  Assure = "Assure",
  Cabinet = "Cabinet",
}

export interface ModeRDVEnumLabel {
  code: ModeRDV;
  libelle?: string | null;
}

export interface MonCompteEpass {
  intitule?: CodeLabel;
  /** @minLength 1 */
  nom: string;
  /** @minLength 1 */
  prenom: string;
  /** @minLength 1 */
  email: string;
  /** @minLength 1 */
  identifiant: string;
  /** @minLength 1 */
  urlPhotoDeProfil: string;
}

export interface MonCompteEpassRequest {
  intitule?: string | null;
  /** @minLength 1 */
  nom: string;
  prenom?: string | null;
  /** @minLength 1 */
  email: string;
  /** @minLength 1 */
  identifiant: string;
}

export interface MotifDifficulte {
  motif: CodeLabel;
  /**
   * Type de difficulté
   * @minLength 1
   * @maxLength 10
   */
  typeDifficulte: string;
  /** Date de reprise obligatoire */
  dateRepriseObligatoire: boolean;
  /** Commentaire obligatoire */
  commentaireObligatoire: boolean;
}

export interface MotifIRSI {
  motif: CodeLabel;
  /**
   * Tranche
   * @minLength 1
   * @maxLength 4
   */
  tranche: string;
}

export interface MotifRectification {
  motif: CodeLabel;
  /** Commentaire obligatoire ou non */
  commentaireObligatoire?: boolean | null;
}

export enum MotifUrgence {
  Inhabitabilite = "Inhabitabilite",
  PerteExploitation = "PerteExploitation",
  SauvegardeBiens = "SauvegardeBiens",
  SecurisationLieu = "SecurisationLieu",
  SinistreMajeur = "SinistreMajeur",
}

export interface NatureBien {
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  libelle: string;
  detailsDuBien?: DetailBien[] | null;
}

export interface NatureExpertise {
  /** @format int32 */
  code?: number;
  libelle?: string | null;
  famille?: string | null;
}

export enum NatureOperation {
  Destructive = "Destructive",
  NonDestructive = "NonDestructive",
}

export interface NatureSinapps {
  listeCauseSinapps?: CauseSinapps[] | null;
  /** @minLength 1 */
  code: string;
  libelle?: string | null;
}

export interface NatureSinistre {
  famille?: CodeLabel;
  /** @minLength 1 */
  code: string;
  libelle?: string | null;
}

export interface NatureSinistreGenerali {
  /**
   * @minLength 1
   * @maxLength 3
   */
  codeBilan: string;
  natureSinistre: CodeLabel;
  /**
   * @minLength 1
   * @maxLength 25
   */
  detailNature: string;
  natureSinistreGexsi?: NatureSinistre;
}

export interface NatureSinistreReseau {
  natureReseau?: DoubleCodeLabel;
  natureGexsi?: NatureSinistre;
}

export interface NotificationUtilisateur {
  /** @minLength 1 */
  id: string;
  type: TypeNotificationUtilisateurEnumLabel;
  /** @format date-time */
  date: string;
  /** @minLength 1 */
  commentaire: string;
  lu: boolean;
  infoDossier: NotificationUtilisateurInfoDossier;
}

export interface NotificationUtilisateurInfoDossier {
  /** @minLength 1 */
  id: string;
  /** @format date-time */
  dateRendezVous?: string | null;
  assure: PersonneResume;
  tiers: PersonneResume[];
  etat: CodeLabel;
  sinistre: SinistreResume;
  compagnie?: CodeLabel;
  origine: CodeLabel;
  /** @format date-time */
  dateDepotRapport?: string | null;
  /** @minLength 1 */
  agenceTraitante: string;
}

export interface NotificationUtilisateurRequest {
  type: TypeNotificationUtilisateur;
  /** @minLength 1 */
  commentaire: string;
  /**
   * @minLength 1
   * @pattern ^[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}$
   */
  refDossier: string;
}

export enum OrigineDossier {
  Autre = "Autre",
  Sinapps = "Sinapps",
  Darva = "Darva",
  Epass = "Epass",
}

export enum OuiNonIndetermine {
  Indetermine = "Indetermine",
  Oui = "Oui",
  Non = "Non",
}

export interface OuiNonIndetermineEnumLabel {
  code: OuiNonIndetermine;
  libelle?: string | null;
}

export interface Pastille {
  typePastille?: TypePastillesEnumLabel;
  tooltip?: string | null;
  couleur?: string | null;
}

export interface Pastilles {
  listePastilles: Pastille[];
  /** @minLength 1 */
  dossier: string;
}

export interface PersonneImplique {
  nomAssurance?: CodeLabel;
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  nom: string;
  prenom?: string | null;
  adresse?: Adresse;
  presente?: boolean;
  lrar?: boolean;
  qualite?: CodeLabel;
  role?: CodeLabel;
  qualifiants?: CodeLabel[] | null;
  references: Reference[];
  chiffrage: Chiffrage;
}

export interface PersonneImpliqueRequest {
  id?: string | null;
  nom?: string | null;
  prenom?: string | null;
  adresse?: Adresse;
  presente?: boolean;
  lrar?: boolean;
  qualite?: string | null;
  role?: string | null;
  qualifiants?: string[] | null;
  references?: Reference[] | null;
  nomAssurance?: string | null;
  chiffrage?: ChiffrageRequest;
}

export interface PersonnePiece {
  libelle?: string | null;
  /** @format int32 */
  compteur: number;
  dommagesImmobilierEmbellissement: Dommage[];
}

export interface PersonneResume {
  /** @minLength 1 */
  id: string;
  type: TypePersonne;
  /**
   * @minLength 1
   * @maxLength 32
   */
  nom: string;
  /** @maxLength 20 */
  prenom?: string | null;
  police?: string | null;
  qualite?: CodeLabel;
  /** @maxLength 20 */
  tel?: string | null;
  adresse?: Adresse;
  email?: string | null;
  telephones: Telephones;
}

export interface PersonneResumeAvecIntervenants {
  intervenants: IntervenantResume[];
  /** @minLength 1 */
  id: string;
  type: TypePersonne;
  /**
   * @minLength 1
   * @maxLength 32
   */
  nom: string;
  /** @maxLength 20 */
  prenom?: string | null;
  police?: string | null;
  qualite?: CodeLabel;
  /** @maxLength 20 */
  tel?: string | null;
  adresse?: Adresse;
  email?: string | null;
  telephones: Telephones;
}

export interface PieceJointe {
  id?: string | null;
  idSInapps?: string | null;
  categorie?: CodeLabel;
  categorieSinapps?: TypeDocumentSinappsEnumLabel;
  type: TypePJ;
  /** @minLength 1 */
  fileType: string;
  /** @format date-time */
  dateCreation?: string | null;
  /** @format date-time */
  dateEdition?: string | null;
  /** @minLength 1 */
  fileName: string;
  /** @minLength 1 */
  origine: string;
  /** @minLength 1 */
  emetteur: string;
  url?: string | null;
  urlVignette?: string | null;
  /**
   * @minLength 1
   * @maxLength 30
   */
  libelle: string;
  familleAction?: CodeLabel;
  envoiEditique?: EnvoiEditique;
}

export interface PieceJointeSimplifie {
  libelle?: string | null;
  fileName?: string | null;
  url?: string | null;
}

export interface Planning {
  /** @minLength 1 */
  id: string;
  caractereExpertise?: CodeLabel;
  /** @format date-time */
  dateDebut: string;
  /** @format date-time */
  dateFin: string;
  refDossier?: RefDossier;
  referenceSinistre?: string | null;
  compagnie?: CodeLabel;
  origine?: CodeLabel;
  natureSinistre?: CodeLabel;
  adresse?: Adresse;
  assure?: string | null;
  tel?: string | null;
  coordonnees?: LocalisationGPS;
  commentaire?: string | null;
  typeRDV: TypeRdv;
  conviees?: PersonneResume[] | null;
  /** @format int32 */
  compteurPlanning?: number;
  journeeComplete?: boolean;
  idRecurrence?: string | null;
  preneurRDV?: string | null;
}

export interface PlanningActionGeneraleRequest {
  /**
   * Le Collaborateur
   * @minLength 1
   */
  collaborateur: string;
  /**
   * Date de début de l'action
   * @format date-time
   */
  dateDebut: string;
  /**
   * Date de fin de l'action
   * @format date-time
   */
  dateFin: string;
  /** Commentaire */
  commentaire?: string | null;
  /**
   * Code de l'occupation
   * @minLength 1
   */
  typeRdv: string;
  /** Code solde */
  solde?: string | null;
  /** ID de la personne qui a verrouillé l'action générale */
  verrouilleur?: string | null;
  journeeComplete: boolean;
}

export interface PrejudiceImmateriel {
  /** @minLength 1 */
  id: string;
  type: CodeLabel;
  tauxTVA?: TauxTVA;
  /** @format double */
  montantDMG: number;
}

export interface PrejudiceImmaterielRequest {
  /** @format int32 */
  type?: number;
  tauxTVA?: string | null;
  /** @format double */
  montantDMG?: number;
}

export interface PrejudiceMateriel {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  libelle: string;
  tauxTVA?: TauxTVA;
  /** @format double */
  montantVetusteDetuite: number;
  /** @format double */
  montantVAN: number;
  fraisAfferents?: FraisAfferent[] | null;
}

export interface PrejudiceMaterielRequest {
  libelle?: string | null;
  tauxTVA?: string | null;
  /** @format double */
  montantVetusteDetuite?: number;
  /** @format double */
  montantVAN?: number;
  fraisAfferents?: FraisAfferentRequest[] | null;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export interface ProtectionVol {
  /** @maxLength 4000 */
  commentaire?: string | null;
  verification?: Verif;
}

export interface QualificationCollaborateur {
  qualification?: CodeLabel;
  /**
   * Date d'examem
   * @format date-time
   */
  dateExamem?: string;
}

export interface QualiteAssure {
  qualite?: CodeLabel;
  verification?: Verif;
}

export interface QuestionREX {
  question: CodeLabel;
  /**
   * Type de question
   * @minLength 1
   * @maxLength 3
   */
  type: string;
}

export interface RapportPapier {
  /** @minLength 1 */
  id: string;
  /** @format date-time */
  dateCreation: string;
  etat: EtatPapierEnumLabel;
}

export interface RapportResume {
  /** @minLength 1 */
  id: string;
  /** @format date-time */
  dateCreation: string;
  etat: EtatRapportEnumLabel;
  rapportPapier?: RapportPapier;
}

export interface RecoursIrsi {
  /** @format double */
  po?: number;
  /** @format double */
  occupant?: number;
  /** @format double */
  pnoCno?: number;
  /** @format double */
  immeuble?: number;
}

export interface RecoursTraveller {
  type: ActionRecours;
  aSubir?: boolean;
  neuf?: boolean;
  /** @format double */
  montant: number;
  /** @maxLength 255 */
  motif?: string | null;
  cibleRecours: CibleRecours;
  idSinapps?: string | null;
  guid?: string | null;
  /** @format int32 */
  compteur: number;
}

export interface RefAnnuaire {
  /** @minLength 1 */
  refComplete: string;
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  famille: string;
  professionnel: boolean;
}

export interface RefDossier {
  /** @minLength 1 */
  annee: string;
  /** @minLength 1 */
  source: string;
  /** @minLength 1 */
  numero: string;
  /** @minLength 1 */
  refComplete: string;
}

export interface RefDossierCpt {
  /** @format int32 */
  cpt: number;
  type?: TypeRefDossierCpt;
  /** @minLength 1 */
  refComplete: string;
  /** @minLength 1 */
  annee: string;
  /** @minLength 1 */
  source: string;
  /** @minLength 1 */
  numero: string;
}

export interface Reference {
  id?: string | null;
  libelle?: string | null;
}

export enum RegimeFiscal {
  HT = "HT",
  TTC = "TTC",
}

export interface RegimeFiscalEnumLabel {
  code: RegimeFiscal;
  libelle?: string | null;
}

export interface Reglement {
  id?: string | null;
  personne?: CodeLabel;
  emetteur?: string | null;
  type?: CodeLabel;
  surPlace?: boolean;
  /** @format date-time */
  dateReglement?: string | null;
  numeroCheque?: string | null;
  recupTVA?: boolean;
  codeTireur?: string | null;
  commentaire?: string | null;
  idSinapps?: string | null;
  garantiesReglement?: CodeValueDec[] | null;
}

export interface ReglementTraveller {
  id?: string | null;
  emetteur?: string | null;
  type?: CodeLabel;
  /** @format date-time */
  dateReglement?: string | null;
  numeroCheque?: string | null;
  recupTVA?: boolean;
  codeTireur?: string | null;
  commentaire?: string | null;
  idSinapps?: string | null;
  garantiesReglement?: CodeValueDec[] | null;
}

export interface RendezVous {
  /** @minLength 1 */
  id: string;
  /** @format date-time */
  dateDebut: string;
  /** @format date-time */
  dateFin: string;
  aVenir: boolean;
  annule: boolean;
  participants: TiersParticipant[];
  typeActiviteRDV?: TypeActiviteEnumLabel;
  preneurRDV?: ModeRDVEnumLabel;
}

export interface RendezVousTraveller {
  /** @minLength 1 */
  id: string;
  /** @format date-time */
  dateRDV: string;
  /** @minLength 1 */
  libelle: string;
  enCours: boolean;
  acteursStatus: ActeurRendezVousStatut[];
}

export enum ReparabiliteMob {
  Reparable = "Reparable",
  NonReparable = "NonReparable",
  Vole = "Vole",
}

export interface ReparabiliteMobEnumLabel {
  code: ReparabiliteMob;
  libelle?: string | null;
}

export interface Reparation {
  reparabilite?: ReparabiliteMobEnumLabel;
  justificatif?: JustificationsEnumLabel;
}

export interface RepondreEchangeInterneRequest {
  /**
   * L'objet de l'EI
   * @minLength 1
   */
  objet: string;
  /**
   * Message de l'EI
   * @minLength 1
   */
  message: string;
}

export enum Responsabilite {
  Aucune = "Aucune",
  Entiere = "Entiere",
  Partielle = "Partielle",
}

export interface ResponsabiliteTraveller {
  responsabilite: Responsabilite;
  /** @format double */
  tauxResponsabilite: number;
  typeResponsabilites: TypeResponsabilite[];
  lettreAcceptation?: CodeLabel;
}

export interface ResteAFaire {
  codeResteAFaire: CodeLabel;
  /** Evènements dépendants */
  evenementsDependants?: string | null;
  /** Commentaire obligatoire ou non */
  commentaireObligatoire?: boolean | null;
}

export interface ResultatRechercheMesDossier {
  /** @format int32 */
  totalResultats?: number;
  resultatsParCodeEtatDossier?: Record<string, number | null>;
  resultats?: MesDossierResultat[] | null;
}

export interface ResumeDocumentsDossier {
  dossier?: string | null;
  /** @format int32 */
  nombreMedia?: number;
  /** @format int32 */
  nombreRapport?: number;
  /** @format int32 */
  nombrePriseRendezVousJuridique?: number;
  /** @format int32 */
  nombreLettreAcceptation?: number;
}

export interface Risque {
  adresseRisque?: AdresseRisque;
  type?: TypeRisque;
  usage?: UsageRisque;
  verification?: Verification;
  qualiteAssure?: QualiteAssure;
  contratRex?: ContratRex;
  contratPrg?: ContratPrg;
  conformite?: Conformite;
  protectionVol?: ProtectionVol;
  bailCommercial?: BailCommercial;
}

export interface RisqueIrsi {
  adresse?: Adresse;
  statutImmeuble: CodeLabel;
  usageAssure: CodeLabel;
}

export interface RisqueIrsiRequest {
  adresse?: Adresse;
  statutImmeuble?: string | null;
  usageAssure?: string | null;
}

export enum RoleEpass {
  Standard = "Standard",
  Exterieur = "Exterieur",
  Collaborateur = "Collaborateur",
  Responsable = "Responsable",
  Administrateur = "Administrateur",
}

export interface ScenarioEsker {
  nom?: string | null;
  affranchissement?: AffranchissementEsker;
  impressionCouleur?: boolean;
  agrafage?: boolean;
  rectoVerso?: boolean;
  filtre?: boolean;
  protege?: boolean;
}

export interface Sinistre {
  id?: string | null;
  type?: TypeRexSinistreEnumLabel;
  infosSinapps?: InfosSinistreSinapps;
  commentaireCause?: string | null;
  commentaireCirconstance?: string | null;
  commentaireDommages?: string | null;
  commentaireAutresInfos?: string | null;
}

export interface SinistreResume {
  reference?: string | null;
  referenceCompagnie?: string | null;
  nature?: NatureSinistre;
  /** @format date-time */
  date?: string | null;
  adresse?: Adresse;
}

export interface Sollicitation {
  refDossier: RefDossier;
  /** @minLength 1 */
  guid: string;
  cloturee?: boolean;
  /** @format date-time */
  dateCreation?: string;
  /** @format date-time */
  dateModification?: string;
  emetteur?: string | null;
  destinataire?: string | null;
  typeDeSollicitation?: string | null;
  commentaireDemande?: string | null;
  commentaireCloture?: string | null;
  messageInfo?: string | null;
  typeIntervention?: string | null;
}

export interface SourceCabinet {
  /**
   * source
   * @minLength 1
   * @maxLength 2
   */
  source: string;
  /**
   * Cabinet
   * @minLength 1
   * @maxLength 32
   */
  cabinet: string;
}

export enum SousTypeIntervention {
  NettoyageDecontaminationAssechement = "NettoyageDecontaminationAssechement",
  Toiture = "Toiture",
  TousCorpsDEtats = "TousCorpsDEtats",
}

export interface SpreadSheetSettings {
  /** @format uri */
  saveUrl?: string | null;
  jsonData?: string | null;
  typeFile?: TypeFile;
  fileName?: string | null;
  pdfLayoutSettings?: string | null;
}

export interface SuiviSimplifie {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  libelle: string;
  type: TypeSuiviEnumLabel;
  /** @format date-time */
  date?: string | null;
  /** @minLength 1 */
  commentaire: string;
  /** @minLength 1 */
  emetteur: string;
  destinataires: string[];
  pieceJointes: PieceJointeSimplifie[];
  /** @minLength 1 */
  statut: string;
}

export interface Tache {
  /** @minLength 1 */
  id: string;
  dossier: InfoDossier;
  /** @format date-time */
  dateCreation?: string;
  typeTache: TypeTache;
  /** @minLength 1 */
  libelle: string;
  /** @format date-time */
  prevuPour?: string | null;
}

export interface TacheRapideRequest {
  /** @format int32 */
  codeAction: number;
  /** @minLength 1 */
  libelle: string;
  /** @format date-time */
  dateEcheance: string;
  /** @minLength 1 */
  commentaire: string;
  destinataire: DestinataireTache;
}

export interface TauxTVA {
  tva: CodeLabel;
  /**
   * Taux de TVA
   * @format float
   */
  taux: number;
}

export enum Taxe {
  HT = "HT",
  TTC = "TTC",
}

export interface Telephones {
  /** @minLength 1 */
  personnel: string;
  /** @minLength 1 */
  bureau: string;
  /** @minLength 1 */
  fax: string;
  autres: AutreTelephone[];
}

export interface TiersParticipant {
  qualite?: CodeLabel;
  /** @minLength 1 */
  police: string;
  intervenants: IntervenantParticipant[];
  /** @minLength 1 */
  id: string;
  type: TypePersonne;
  /** @minLength 1 */
  nom: string;
  /** @minLength 1 */
  prenom: string;
  /** @minLength 1 */
  role: string;
  convocation: Convocation;
}

export interface TrancheIRSI {
  tranche: CodeLabel;
  /**
   * Tranche Sinapps
   * @maxLength 80
   */
  trancheSinapps?: string | null;
}

export enum TranchesIRSI {
  Tranche1 = "Tranche1",
  Tranche2 = "Tranche2",
}

export interface TransfertEchangeInterneRequest {
  destinataires: string[];
}

export interface Traveller {
  acteurs: ActeurTraveller[];
  rendezVous: RendezVousTraveller[];
  conformiteDuRisque: Risque;
  sinistre: Sinistre;
  modeIndemnisationAssure?: CodeLabel;
  accordAssure?: CodeLabel;
  convention?: CodeLabel;
  confidentiel: Confidentiel;
  garanties?: Garantie[] | null;
  infosDossier: InfosDossierTraveller;
  /** @format date-time */
  objectifCharte?: string | null;
  etat: EtatRapportEnumLabel;
}

export enum TypeActivite {
  EAD = "EAD",
  ESP = "ESP",
  VISIO = "VISIO",
}

export interface TypeActiviteEnumLabel {
  code: TypeActivite;
  libelle?: string | null;
}

export enum TypeAssure {
  Particulier = "Particulier",
  Professionel = "Professionel",
}

export interface TypeAssureEnumLabel {
  code: TypeAssure;
  libelle?: string | null;
}

export enum TypeAutreMedia {
  TelPortable = "TelPortable",
  TelBureau = "TelBureau",
  SiteWeb = "SiteWeb",
  EmailREN = "EmailREN",
  Fax = "Fax",
  Email = "Email",
}

export enum TypeDeBien {
  Immobilier = "Immobilier",
  Embellissement = "Embellissement",
  Mobilier = "Mobilier",
  Autre = "Autre",
}

export enum TypeDeReparation {
  AR = "AR",
  IP = "IP",
  REN = "REN",
  RAN = "RAN",
  RM = "RM",
}

export interface TypeDeReparationEnumLabel {
  code: TypeDeReparation;
  libelle?: string | null;
}

export enum TypeDestinataireEmetteur {
  Expert = "Expert",
  Assistante = "Assistante",
  Assure = "Assuré",
  Tiers = "Tiers",
  Intervenant = "Intervenant",
  Cabinet = "Cabinet",
  Bureau = "Bureau",
  Moi = "Moi",
}

export enum TypeDocumentSinapps {
  Autre = "Autre",
  ConstatAmiable = "ConstatAmiable",
  ConvocationAExpertise = "ConvocationAExpertise",
  AttestationTVA = "AttestationTVA",
  BulletinIntervention = "BulletinIntervention",
  DevisDetaille = "DevisDetaille",
  EtatDePertes = "EtatDePertes",
  Courrier = "Courrier",
  DelegationDePaiement = "DelegationDePaiement",
  CommandeDeMateriaux = "CommandeDeMateriaux",
  DescriptionDesDommages = "DescriptionDesDommages",
  DocumentAdministratif = "DocumentAdministratif",
  Facture = "Facture",
  ProcesVerbalDePolice = "ProcesVerbalDePolice",
  Rapport = "Rapport",
  PhotoCroquis = "PhotoCroquis",
  LettreAcceptationDesDommages = "LettreAcceptationDesDommages",
  LettreAcceptationIndemnisationQuittance = "LettreAcceptationIndemnisationQuittance",
  ChiffrageDetaille = "ChiffrageDetaille",
  ChiffrageDetailleAssureur = "ChiffrageDetailleAssureur",
  Bail = "Bail",
  ProcesVerbalFinDeTravaux = "ProcesVerbalFinDeTravaux",
  PVExpertiseProtocole = "PVExpertiseProtocole",
  RapportDeRechercheDeFuite = "RapportDeRechercheDeFuite",
  FactureAutrePrestataire = "FactureAutrePrestataire",
  RapportInterventionSecours = "RapportInterventionSecours",
  JustificatifExistenceValeurAuthenticite = "JustificatifExistenceValeurAuthenticite",
  FicheConformite = "FicheConformite",
  FactureRamonage = "FactureRamonage",
  DevisFactureRechercheFuite = "DevisFactureRechercheFuite",
  DevisFactureMesuresConservatoires = "DevisFactureMesuresConservatoires",
  NoteConfidentielle = "NoteConfidentielle",
  AttestationNotarieePropriete = "AttestationNotarieePropriete",
  ReleveHypothecaire = "ReleveHypothecaire",
  ReglementCopropriete = "ReglementCopropriete",
  AttestationAssuranceProfessionnelle = "AttestationAssuranceProfessionnelle",
  JustificatifsConstructionRenovation = "JustificatifsConstructionRenovation",
  RapportExpertiseCommun = "RapportExpertiseCommun",
  NoteHonoraires = "NoteHonoraires",
  FactureDeLaPrestation = "FactureDeLaPrestation",
  BonsDeTravaux = "BonsDeTravaux",
  Kbis = "Kbis",
  AttestationDePropriete = "AttestationDePropriete",
  RapportGeotechnique = "RapportGeotechnique",
  BilanComptable = "BilanComptable",
  BonDEquarrissage = "BonDEquarrissage",
  CertificatVeterinaire = "CertificatVeterinaire",
  ConstatHuissier = "ConstatHuissier",
  CourrierReclamationAdverse = "CourrierReclamationAdverse",
  CourrierReclamationClient = "CourrierReclamationClient",
  DeclarationDeSinistre = "DeclarationDeSinistre",
  DocumentsJudiciaires = "DocumentsJudiciaires",
  EstimationGreAGre = "EstimationGreAGre",
  Plan = "Plan",
  RapportDeRechercheDeCause = "RapportDeRechercheDeCause",
  RapportDExpertise = "RapportDExpertise",
  Temoignage = "Temoignage",
  ConditionsPersonnelles = "ConditionsPersonnelles",
  Notices = "Notices",
  ConditionsGenerales = "ConditionsGenerales",
  FicheDeDetection = "FicheDeDetection",
  PreRapport = "PreRapport",
  DevisRDFComplexe = "DevisRDFComplexe",
}

export interface TypeDocumentSinappsEnumLabel {
  code: TypeDocumentSinapps;
  libelle?: string | null;
}

export enum TypeDommageSinapps {
  Dommage = "Dommage",
  Bien = "Bien",
}

export enum TypeEnregistrementCourrier {
  Definitif = "Definitif",
  Frappe = "Frappe",
  Visualisation = "Visualisation",
}

export enum TypeEvenementiel {
  NonDefinit = "NonDefinit",
  Dossier = "Dossier",
  Rapport = "Rapport",
  Facture = "Facture",
  Construction = "Construction",
}

export enum TypeExpertise {
  EAD = "EAD",
  ESP = "ESP",
}

export enum TypeExpertiseIrsi {
  ExpertiseSurSite = "ExpertiseSurSite",
  TeleExpertise = "TeleExpertise",
  VisioExpertise = "VisioExpertise",
}

export interface TypeExpertiseIrsiEnumLabel {
  code: TypeExpertiseIrsi;
  libelle?: string | null;
}

export enum TypeFile {
  Xlsx = "Xlsx",
  Xls = "Xls",
  Csv = "Csv",
  Pdf = "Pdf",
}

export enum TypeIntervention {
  EAD = "EAD",
  ESS = "ESS",
  ReparationEnNature = "ReparationEnNature",
  RechercheDeFuite = "RechercheDeFuite",
}

export interface TypeMail {
  type: CodeLabel;
  /**
   * Objet du mail
   * @maxLength 160
   */
  objet?: string | null;
  /** Corps du mail */
  texte?: string | null;
  /**
   * Numéro de site
   * @format int32
   */
  site?: number;
  /** REN or Not REN */
  ren?: boolean;
}

export interface TypeMailACM {
  type: CodeLabel;
  /**
   * Famille de l'action
   * @maxLength 10
   */
  visibilite?: string | null;
}

export interface TypeMailMAE {
  type: CodeLabel;
  /**
   * Famille de l'action
   * @maxLength 80
   */
  familleAction?: string | null;
  /**
   * Catégorie de la pièce jointe
   * @maxLength 80
   */
  categoriePJ?: string | null;
}

export enum TypeNotificationUtilisateur {
  AccuseReception = "AccuseReception",
  PriseDeRendezVous = "PriseDeRendezVous",
  PreRapport = "PreRapport",
  Rapport = "Rapport",
}

export interface TypeNotificationUtilisateurEnumLabel {
  code: TypeNotificationUtilisateur;
  libelle?: string | null;
}

export enum TypePJ {
  Image = "Image",
  Doc = "Doc",
  DocEvenementiel = "DocEvenementiel",
  DocEvenementielBrouillon = "DocEvenementielBrouillon",
}

export enum TypePastilles {
  ANNULER = "ANNULER",
  DELAI = "DELAI",
  ANOMALIE = "ANOMALIE",
  INSATISFACTION = "INSATISFACTION",
  SIC_A = "SIC_A",
  SIC_D = "SIC_D",
  SIC_DA = "SIC_DA",
  SOLLICITATION = "SOLLICITATION",
}

export interface TypePastillesEnumLabel {
  code: TypePastilles;
  libelle?: string | null;
}

export enum TypePersonne {
  Assure = "Assure",
  Tiers = "Tiers",
  Intervenant = "Intervenant",
}

export enum TypePersonneFamille {
  Morale = "Morale",
  Physique = "Physique",
  LesDeux = "LesDeux",
}

export interface TypeRDV {
  type: CodeLabel;
  /**
   * Caractère de l'expertise
   * @maxLength 1
   */
  caractereExpertise?: string | null;
  /**
   * Famille de l'action
   * @maxLength 5
   */
  familleAction?: string | null;
}

export interface TypeRapportDarvaCompagnie {
  compagnie: RefAnnuaire;
  /**
   * Code du rapport Darva
   * @minLength 1
   * @maxLength 1
   */
  codeRapportDarva: string;
}

export interface TypeRdv {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  libelle: string;
  /** @minLength 1 */
  color: string;
  type: TypeRdvType;
}

export enum TypeRdvType {
  ActionGenerale = "ActionGenerale",
  Dossier = "Dossier",
  Inconnu = "Inconnu",
}

export enum TypeRefDossierCpt {
  Autre = "Autre",
  Image = "Image",
  Suivi = "Suivi",
}

export interface TypeReglement {
  type: CodeLabel;
  /**
   * Type de règlement Sinapps
   * @maxLength 50
   */
  typeSinapps?: string | null;
}

export interface TypeRenfort {
  renfort: CodeLabel;
  /**
   * Pourcentage
   * @format float
   */
  pourcentage?: number;
}

export enum TypeResponsabilite {
  Auteur = "Auteur",
  Lese = "Lese",
  Autre = "Autre",
}

export enum TypeRexSinistre {
  Autre = "Autre",
  DAB = "DAB",
  Vol = "Vol",
}

export interface TypeRexSinistreEnumLabel {
  code: TypeRexSinistre;
  libelle?: string | null;
}

export interface TypeRisque {
  type?: CodeLabel;
  verification?: Verif;
}

export enum TypeSuivi {
  DocumentAlaFrappe = "DocumentAlaFrappe",
  Rapport = "Rapport",
  TravailAdministratif = "TravailAdministratif",
  EchangeInterne = "EchangeInterne",
  DelaisCharte = "DelaisCharte",
  RendezVous = "RendezVous",
  RendezVousAnnule = "RendezVousAnnule",
  Email = "Email",
  Relance = "Relance",
  Differe = "Differe",
  Datable = "Datable",
  Info = "Info",
  Systeme = "Système",
  Divers = "Divers",
}

export interface TypeSuiviEnumLabel {
  code: TypeSuivi;
  libelle?: string | null;
}

export enum TypeTache {
  Relance = "Relance",
  Administratif = "Administratif",
  EvenementCharte = "EvenementCharte",
}

export interface UpdateDocumentRequest {
  categorie?: string | null;
  /** @minLength 1 */
  nom: string;
}

export interface UsageRisque {
  usage?: CodeLabel;
  verification?: Verif;
}

export interface ValidationProblemDetails {
  errors?: Record<string, string[]>;
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export enum Verif {
  NonVerifie = "NonVerifie",
  Oui = "Oui",
  Non = "Non",
}

export interface Verification {
  nombreDeDependance?: MesureDeclareeReleveeVerifiee;
  nombreDEtage?: MesureDeclareeReleveeVerifiee;
  nombreDePiece?: MesureDeclareeReleveeVerifiee;
  nombreDeSalarie?: MesureDeclareeReleveeVerifiee;
  surface?: MesureDeclareeReleveeVerifiee;
  surfaceAnnexe?: MesureDeclareeReleveeVerifiee;
  chiffreDAffaire?: MesureDeclareeReleveeVerifiee;
}

export interface CollaborateursAnnuaireListParams {
  experts?: boolean;
  assistantes?: boolean;
}

export interface FamillesAnnuaireListParams {
  filtre?: FiltreFamilleAnnuaire;
}

export interface RechercheAnnuaireListParams {
  famille: string;
  pro?: OuiNonIndetermine;
  code?: string;
  departement?: string;
  ville?: string;
  telephone?: string;
}

export interface LoginEpassExterieurAuthentificationCreateParams {
  /** token contenu dans le lien */
  token?: string;
}

export interface LoginEpassGexsiAuthentificationCreateParams {
  /** token contenu dans le lien */
  session?: string;
  /** token contenu dans le lien */
  cleAnnuaire?: string;
  /** token contenu dans le lien */
  contexte?: string;
  /** token contenu dans le lien */
  origineMission?: string;
}

export interface ForceLoginAuthentificationCreateParams {
  /**
   * Collaborateur à connecter
   * @pattern ^[ON]{1}¤[a-zA-Z]{2}¤.{1,32}$
   */
  collaborateur: string;
}

export interface ResetEpassPasswordAuthentificationCreateParams {
  /** Url de redirection pour le changement de mot de passe envoyé dans le mail */
  redirectUrl: string;
  /**
   * mail lié au wcs abonne
   * @format email
   */
  mail: string;
}

export interface ReinitialiserCacheListParams {
  type?: CacheType;
}

export interface RemoveCacheListParams {
  type?: CacheType;
  entree?: string;
}

export interface EnvoiEskerCommunicationCreatePayload {
  requests?: EnvoiEsker[];
}

export interface PhotoProfilCompteCreatePayload {
  /** @format binary */
  Fichier: File;
}

export interface DocumentDossierDetailParams {
  /**
   * Nombre de documents désirés
   * @format int32
   */
  limit?: number;
  /**
   * Référence du dossier cible
   * @pattern ^[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}$
   */
  dossier: string;
}

export interface DocumentDossierCreatePayload {
  requests?: DocumentRequest[];
}

export interface UpdateFichierDocumentCreatePayload {
  ContentType?: string;
  ContentDisposition?: string;
  Headers?: Record<string, string[]>;
  /** @format int64 */
  Length?: number;
  Name?: string;
  FileName?: string;
}

export interface DocumentDetailParams {
  brouillon?: boolean;
  /**
   * Référence de l'image cible
   * @pattern ^[ISD]{0,1}[-]{0,1}[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}-[0-9]{1,4}$
   */
  id: string;
}

export interface DocumentDeleteParams {
  typePJ: TypePJ;
  /**
   * La référence du document
   * @pattern ^[ISD]{0,1}[-]{0,1}[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}-[0-9]{1,4}$
   */
  id: string;
}

export interface EvenementielDocumentDetailParams {
  /** Avec brouillons */
  avecBrouillons?: boolean;
  /**
   * Référence du dossier
   * @pattern ^[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}$
   */
  dossier: string;
}

export interface FamilleEtActionsEvenementielDetailParams {
  filtreEvenementiel?: TypeEvenementiel;
  /** @pattern ^[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}$ */
  dossier: string;
}

export type EnregistrementSfdtDocumentCreatePayload = string;

export type SpreadSheetDocumentCreatePayload = string;

export interface ResumeDocumentListParams {
  dossiers?: string[];
}

export interface RendezVousDossierDetailParams {
  avecAnnules?: boolean;
  /** @pattern ^[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}$ */
  dossier: string;
}

export interface EchangeInterneListParams {
  /** @pattern ^[ON]{1}¤[a-zA-Z]{2}¤.{1,32}$ */
  destinataire?: string;
  /** @pattern ^[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}$ */
  dossier?: string;
  codeEtatDossier?: string;
  codeTypeSinistre?: string;
  emetteur?: string;
  mandant?: string;
  /** @format int32 */
  startIndex?: number;
  /**
   * @format int32
   * @min 0
   * @max 100
   * @default 100
   */
  limit?: number;
}

export interface CountEchangeInterneListParams {
  /** @pattern ^[ON]{1}¤[a-zA-Z]{2}¤.{1,32}$ */
  destinataire?: string;
  /** @pattern ^[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}$ */
  dossier?: string;
  codeEtatDossier?: string;
  codeTypeSinistre?: string;
  emetteur?: string;
  mandant?: string;
  /**
   * @format int32
   * @min 0
   * @max 100
   * @default 100
   */
  limit?: number;
}

export interface DestinatairesEchangeInterneDetailParams {
  /** retourner les assistantes */
  assistante?: boolean;
  /** retourner les experts */
  expert?: boolean;
  responsables?: boolean;
  /**
   * la référence du dossier
   * @pattern ^[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}$
   */
  dossier: string;
}

export interface IndexLogListParams {
  /** @format date-time */
  date?: string;
  /**
   * @format int32
   * @min 0
   * @max 10
   * @default 10
   */
  nbJours?: number;
  /** @pattern ^[ON]{1}¤[a-zA-Z]{2}¤.{1,32}$ */
  refCollaborateur?: string;
  path?: string;
}

export interface DestinatairesMailDetailParams {
  /** récupérer les destinataires en interne */
  interne?: boolean;
  /** récupérer les destinataires  tiers et intervenants */
  externe?: boolean;
  /**
   * référence du dossier
   * @pattern ^[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}$
   */
  dossier: string;
}

export interface NotificationsListParams {
  /**
   * Reférence dossier
   * @pattern ^[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}$
   */
  dossier?: string;
  /**
   * Reférence destinataire
   * @pattern ^[ON]{1}¤[a-zA-Z]{2}¤.{1,32}$
   */
  destinataire?: string;
  /** @format int32 */
  startIndex?: number;
  /**
   * @format int32
   * @min 0
   * @max 100
   * @default 100
   */
  limit?: number;
}

export interface CountNotificationsListParams {
  /**
   * Reférence dossier
   * @pattern ^[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}$
   */
  dossier?: string;
  /**
   * Reférence destinataire
   * @pattern ^[ON]{1}¤[a-zA-Z]{2}¤.{1,32}$
   */
  destinataire?: string;
  /**
   * @format int32
   * @min 0
   * @max 100
   * @default 100
   */
  limit?: number;
}

export interface PlanningDetailParams {
  /**
   * Date de début du planning
   * @format date-time
   */
  dateDebut?: string;
  /**
   * Date de fin du planning
   * @format date-time
   */
  dateFin?: string;
  /**
   * Information annuaire de l'expert
   * @pattern ^[ON]{1}¤[a-zA-Z]{2}¤.{1,32}$
   */
  expert: string;
}

export interface RechercheRapideDossierListParams {
  recherche?: string;
  /** @format int32 */
  limite?: number;
}

export interface ListesDossierDetailParams {
  mandant?: string;
  sinistre?: string;
  etat?: string;
  /** @format int32 */
  startIndex: number;
  /**
   * @format int32
   * @min 1
   * @max 100
   */
  limit: number;
  filtre: FiltreDossier;
}

export interface MesDossiersDossierListParams {
  Compagnie?: string;
  NumeroDossier?: string;
  NatureSinistre?: string;
  Agence?: string;
  CodesEtatsDossier?: string[];
  /** @format int32 */
  NombreDeJour?: number;
  /** @format int32 */
  StartIndex: number;
  /**
   * @format int32
   * @min 1
   * @max 100
   */
  Limit: number;
}

export interface RechercheDossierListParams {
  Reference?: string;
  Mandant?: string;
  PoliceAssure?: string;
  Assure?: string;
  Tiers?: string;
  AdresseSinistre?: string;
  CodePostalVilleSinistre?: string;
  MesDossiers?: boolean;
  Origine?: OrigineDossier;
  /** @format date-time */
  DateDebut?: string;
  /** @format date-time */
  DateFin?: string;
  Compagnie?: string;
  NatureSinistre?: string;
  Agence?: string;
  /** @format int32 */
  StartIndex: number;
  /**
   * @format int32
   * @min 1
   * @max 100
   */
  Limit: number;
}

export interface AgencesListParams {
  /** @pattern ^[ON]{1}¤[a-zA-Z]{2}¤.{1,32}$ */
  cabinet?: string;
}

export interface ExpertsListParams {
  /** @pattern ^[ON]{1}¤[a-zA-Z]{2}¤.{1,32}$ */
  cabinet: string;
  agence?: string;
}

export interface CategoriesPjListParams {
  /** Ne retourne que les catégories mappées à sinapps */
  onlySinapps?: boolean;
}

export interface CausesSinistreSinappsFiltreListParams {
  codeNature?: string;
}

export interface DetailsSinistreSinappsFiltreListParams {
  /** Code de la nature sinistre */
  codeNature?: string;
  /** Code de la cause sinistre */
  codeCause?: string;
}

export interface QualitesPersonneIrsiListParams {
  /** @pattern ^[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}$ */
  dossier?: string;
}

export type LeverDifficulteSinappsCreatePayload = CommandeLeverDifficulteRequest[];

export type CloturerSollicitationSinappsCreatePayload = CommandeCloturerSollicitationRequest[];

export interface PastillesDossiersListParams {
  /** liste des dossiers dont on veut les pastilles */
  dossiers?: string[];
  /**
   * true = > ne charge que les difficultés et les insatisfactions, false => charge toutes les pastilles
   * @default false
   */
  modeSimple?: boolean;
}

export interface TachesListParams {
  /** @pattern ^[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}$ */
  dossier?: string;
  /** @pattern ^[ON]{1}¤[a-zA-Z]{2}¤.{1,32}$ */
  collaborateur?: string;
  /** @format int32 */
  startIndex?: number;
  /**
   * @format int32
   * @min 0
   * @max 100
   * @default 100
   */
  limit?: number;
}

export interface TachesCreateParams {
  /** @pattern ^[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}$ */
  dossier?: string;
}

export interface CountTachesListParams {
  /** @pattern ^[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}$ */
  dossier?: string;
  /** @pattern ^[ON]{1}¤[a-zA-Z]{2}¤.{1,32}$ */
  collaborateur?: string;
  /**
   * @format int32
   * @min 0
   * @max 100
   * @default 100
   */
  limit?: number;
}

export interface SolderTachesCreateParams {
  type: TypeTache;
  commentaire?: string;
  toutesRelances?: boolean;
  /**
   * Identifiant de la tache à solder
   * @pattern ^[ISD]{0,1}[-]{0,1}[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}-[0-9]{1,4}$|^[0-9]{4}¤[a-zA-Z0-9]{2}¤[0-9]{8}¤[0-9]{1,4}¤[0-9]{2}-[0-9]{2}-[0-9]{4}¤[0-9]{1,4}$
   */
  id: string;
}

export interface ReporterTachesCreateParams {
  libelle?: string;
  /**
   * Date du report de la tache
   * @format date-time
   */
  dateReport: string;
  /** Commentaire du report de la tache */
  commentaire?: string;
  /**
   * Identifiant de la tache à solder
   * @pattern ^[ISD]{0,1}[-]{0,1}[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}-[0-9]{1,4}$
   */
  id: string;
}

export interface TravellerDossierCreateParams {
  validation?: boolean;
  /** @pattern ^[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}-[0-9]{1,3}$ */
  id: string;
}

export interface ReglementsPanelTravellerCreateParams {
  validation?: boolean;
  /** @pattern ^[ISD]{0,1}[-]{0,1}[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}-[0-9]{1,4}$ */
  rapport: string;
}

export interface InitIntervenantTravellerDetailParams {
  assure: boolean;
  /** @pattern ^[ON]{1}¤[a-zA-Z]{2}¤.{1,32}$ */
  intervenantId: string;
  /** @pattern ^[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}-[0-9]{1,3}$ */
  id: string;
}

export interface IrsiTravellerCreateParams {
  validation?: boolean;
  /** @pattern ^[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}$ */
  dossier: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) => {
      const formData = new FormData();

      const typeIsArray = (array: any[], key: string) => {
        array.forEach((value: any, index: number) => {
          if (typeof value === "object") {
            Object.keys(value).forEach((k) => formData.append(`${key}[${index}].${k}`, value[k]));
          } else {
            formData.append(`${key}[${index}]`, value);
          }
        });
      };

      // Only if array was tested. Maybe the other paths need some fixes.
      Object.keys(input || {}).forEach((data) => {
        if (Array.isArray(input[data])) {
          typeIsArray(input[data], data);
        } else {
          if (typeof data === "object") {
            Object.keys(data).forEach((key, index) => formData.append(`${data}[${index}].${key}`, data[key]));
          } else {
            formData.append(`${data}`, data);
          }
        }
      });
      return formData;
    },
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Nomad9API
 * @version 1.0.0
 * @contact <euroinfo@euroinfo.fr> (http://www.euroinfo.fr )
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  annuaire = {
    /**
     * No description
     *
     * @tags Annuaire
     * @name CollaborateursAnnuaireList
     * @summary Récupère les collaborateurs (experts et/ou assistantes)
     * @request GET:/Annuaire/Collaborateurs
     * @secure
     */
    collaborateursAnnuaireList: (query: CollaborateursAnnuaireListParams, params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/Annuaire/Collaborateurs`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Annuaire
     * @name FamillesAnnuaireList
     * @summary Récupère les familles annuaires
     * @request GET:/Annuaire/Familles
     * @secure
     */
    famillesAnnuaireList: (query: FamillesAnnuaireListParams, params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/Annuaire/Familles`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Annuaire
     * @name RechercheAnnuaireList
     * @summary Recherche dans l'annuaire
     * @request GET:/Annuaire/Recherche
     * @secure
     */
    rechercheAnnuaireList: (query: RechercheAnnuaireListParams, params: RequestParams = {}) =>
      this.request<AnnuaireResume[], ValidationProblemDetails>({
        path: `/Annuaire/Recherche`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  authentification = {
    /**
     * No description
     *
     * @tags Authentification
     * @name LoginAuthentificationCreate
     * @summary Authentification à l'API
     * @request POST:/Authentification/Login
     * @secure
     */
    loginAuthentificationCreate: (data: LoginRequest, params: RequestParams = {}) =>
      this.request<LoginResponse, ValidationProblemDetails | ProblemDetails>({
        path: `/Authentification/Login`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentification
     * @name LoginEpassExterieurAuthentificationCreate
     * @summary Authentification à l'API pour ePass via lien exterieur
     * @request POST:/Authentification/LoginEpassExterieur
     * @secure
     */
    loginEpassExterieurAuthentificationCreate: (
      query: LoginEpassExterieurAuthentificationCreateParams,
      params: RequestParams = {},
    ) =>
      this.request<LoginResponse, ValidationProblemDetails>({
        path: `/Authentification/LoginEpassExterieur`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentification
     * @name LoginEpassGexsiAuthentificationCreate
     * @summary Authentification à l'API pour ePass via gexsi
     * @request POST:/Authentification/LoginEpassGexsi
     * @secure
     */
    loginEpassGexsiAuthentificationCreate: (
      query: LoginEpassGexsiAuthentificationCreateParams,
      params: RequestParams = {},
    ) =>
      this.request<LoginResponse, ValidationProblemDetails>({
        path: `/Authentification/LoginEpassGexsi`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentification
     * @name LoginEPassAuthentificationCreate
     * @summary Authentification à l'API pour ePass
     * @request POST:/Authentification/LoginEPass
     * @secure
     */
    loginEPassAuthentificationCreate: (data: LoginRequest, params: RequestParams = {}) =>
      this.request<LoginResponse, ValidationProblemDetails | ProblemDetails>({
        path: `/Authentification/LoginEPass`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentification
     * @name ForceLoginAuthentificationCreate
     * @summary Force l'authentification d'un user en qualité d'admin
     * @request POST:/Authentification/ForceLogin
     * @secure
     */
    forceLoginAuthentificationCreate: (query: ForceLoginAuthentificationCreateParams, params: RequestParams = {}) =>
      this.request<LoginResponse, ProblemDetails | ValidationProblemDetails>({
        path: `/Authentification/ForceLogin`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentification
     * @name ResetEpassPasswordAuthentificationCreate
     * @summary Reset Password Epass
     * @request POST:/Authentification/ResetEpassPassword
     * @secure
     */
    resetEpassPasswordAuthentificationCreate: (
      query: ResetEpassPasswordAuthentificationCreateParams,
      params: RequestParams = {},
    ) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/Authentification/ResetEpassPassword`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentification
     * @name ChangeEpassPasswordAuthentificationCreate
     * @summary Change Password après reset
     * @request POST:/Authentification/ChangeEpassPassword
     * @secure
     */
    changeEpassPasswordAuthentificationCreate: (data: ChangePasswordResetRequest, params: RequestParams = {}) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/Authentification/ChangeEpassPassword`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  cache = {
    /**
     * No description
     *
     * @tags Cache
     * @name InfoCacheList
     * @summary Récupère les infos de cache
     * @request GET:/Cache/Info
     * @secure
     */
    infoCacheList: (params: RequestParams = {}) =>
      this.request<CacheInfo[], ValidationProblemDetails | ProblemDetails>({
        path: `/Cache/Info`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cache
     * @name ReinitialiserCacheList
     * @summary Réinitialise un cache
     * @request GET:/Cache/Reinitialiser
     * @secure
     */
    reinitialiserCacheList: (query: ReinitialiserCacheListParams, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Cache/Reinitialiser`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cache
     * @name RemoveCacheList
     * @summary Réinitialise une entrée du cache
     * @request GET:/Cache/Remove
     * @secure
     */
    removeCacheList: (query: RemoveCacheListParams, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Cache/Remove`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
  };
  dossier = {
    /**
     * No description
     *
     * @tags Communication
     * @name EnvoiEskerCommunicationCreate
     * @request POST:/Dossier/{dossier}/Evenementiel/Communication/EnvoiEsker
     * @secure
     */
    envoiEskerCommunicationCreate: (
      dossier: string,
      data: EnvoiEskerCommunicationCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Evenementiel/Communication/EnvoiEsker`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contrat
     * @name ContratDossierDetail
     * @summary Récupère le contrat et les plafonds d'un dossier
     * @request GET:/Dossier/{dossier}/Contrat
     * @secure
     */
    contratDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<ContratRex, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Contrat`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name DocumentDossierDetail
     * @summary Récupère les documents d'un dossier
     * @request GET:/Dossier/{dossier}/Document
     * @secure
     */
    documentDossierDetail: ({ dossier, ...query }: DocumentDossierDetailParams, params: RequestParams = {}) =>
      this.request<PieceJointe[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Document`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name DocumentDossierCreate
     * @request POST:/Dossier/{dossier}/Document
     * @secure
     */
    documentDossierCreate: (dossier: string, data: DocumentDossierCreatePayload, params: RequestParams = {}) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Document`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name EvenementielDocumentDetail
     * @summary Récupère le document d'un dossier
     * @request GET:/Dossier/{dossier}/Document/Evenementiel
     * @secure
     */
    evenementielDocumentDetail: ({ dossier, ...query }: EvenementielDocumentDetailParams, params: RequestParams = {}) =>
      this.request<PieceJointe[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Document/Evenementiel`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name EpassDocumentDetail
     * @summary Récupère les documents d'expertise définitif RA, LA, PV d'une dossier
     * @request GET:/Dossier/{dossier}/Document/Epass
     * @secure
     */
    epassDocumentDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<PieceJointe[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Document/Epass`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name FamilleEtActionsEvenementielDetail
     * @summary Liste des actions
     * @request GET:/Dossier/{dossier}/Document/Evenementiel/FamilleEtActions
     * @secure
     */
    familleEtActionsEvenementielDetail: (
      { dossier, ...query }: FamilleEtActionsEvenementielDetailParams,
      params: RequestParams = {},
    ) =>
      this.request<FamilleAction[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Document/Evenementiel/FamilleEtActions`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name DestinataireEvenementielDetail
     * @request GET:/Dossier/{dossier}/Document/Evenementiel/Destinataire
     * @secure
     */
    destinataireEvenementielDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<EvenementielDestinataire[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Document/Evenementiel/Destinataire`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name NouveauEvenementielCreate
     * @summary Créer un nouveau document évènementiel et l'enregistre en base en foncion du type d'enregistrement
     * @request POST:/Dossier/{dossier}/Document/Evenementiel/Nouveau
     * @secure
     */
    nouveauEvenementielCreate: (dossier: string, data: Courrier, params: RequestParams = {}) =>
      this.request<DataDocument, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Document/Evenementiel/Nouveau`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name SpreadSheetDocumentCreate
     * @request POST:/Dossier/Document/{docId}/SpreadSheet
     * @deprecated
     * @secure
     */
    spreadSheetDocumentCreate: (docId: string, data: SpreadSheetDocumentCreatePayload, params: RequestParams = {}) =>
      this.request<string, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/Document/${docId}/SpreadSheet`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name EnregistrementSpreadSheetDocumentCreate
     * @request POST:/Dossier/Document/{docId}/EnregistrementSpreadSheet
     * @deprecated
     * @secure
     */
    enregistrementSpreadSheetDocumentCreate: (docId: string, data: SpreadSheetSettings, params: RequestParams = {}) =>
      this.request<boolean, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/Document/${docId}/EnregistrementSpreadSheet`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name DossierDetail
     * @summary Récupère les informations d'un dossier
     * @request GET:/Dossier/{dossier}
     * @secure
     */
    dossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<Dossier, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name DossierCreate
     * @request POST:/Dossier
     * @secure
     */
    dossierCreate: (data: CreationDossierRequest, params: RequestParams = {}) =>
      this.request<RefDossier, ValidationProblemDetails>({
        path: `/Dossier`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name ResumeDossierDetail
     * @summary Récupère le résumé du dossier
     * @request GET:/Dossier/{dossier}/Resume
     * @deprecated
     * @secure
     */
    resumeDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<DossierResume, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Resume`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name RendezVousDossierDetail
     * @request GET:/Dossier/{dossier}/RendezVous
     * @secure
     */
    rendezVousDossierDetail: ({ dossier, ...query }: RendezVousDossierDetailParams, params: RequestParams = {}) =>
      this.request<RendezVous[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/RendezVous`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name HistoriqueDossierDetail
     * @summary Récupère l'historique du dossier
     * @request GET:/Dossier/{dossier}/Historique
     * @secure
     */
    historiqueDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<SuiviSimplifie[], ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Historique`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name ReglementsDossierDetail
     * @summary Liste des règlements selon l'origine du dossier
     * @request GET:/Dossier/{dossier}/Reglements
     * @secure
     */
    reglementsDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<Reglement[], ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Reglements`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name NouveauCommentaireDossierCreate
     * @summary Création d'un nouveau commentaire
     * @request POST:/Dossier/{dossier}/NouveauCommentaire
     * @secure
     */
    nouveauCommentaireDossierCreate: (dossier: string, data: CommentaireDossierRequest, params: RequestParams = {}) =>
      this.request<CommentaireDossier[], ValidationProblemDetails>({
        path: `/Dossier/${dossier}/NouveauCommentaire`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name ListeGarantiesSinappsDossierDetail
     * @summary Liste des garanties sur un dossier Sinapps
     * @request GET:/Dossier/{dossier}/ListeGarantiesSinapps
     * @secure
     */
    listeGarantiesSinappsDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/Dossier/${dossier}/ListeGarantiesSinapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name WorkflowDossierDetail
     * @summary Récupère le Workflow du dossier
     * @request GET:/Dossier/{dossier}/Workflow
     * @secure
     */
    workflowDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<JalonSimplifie[], ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Workflow`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name PortailSinappsDossierDetail
     * @summary Retourne l'Url du lien Transparent Sinapps
     * @request GET:/Dossier/{dossier}/PortailSinapps
     * @secure
     */
    portailSinappsDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<string, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/PortailSinapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name PortailIrsiDossierDetail
     * @summary Retourne l'Url du lien Transparent Sinapps
     * @request GET:/Dossier/{dossier}/PortailIrsi
     * @secure
     */
    portailIrsiDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<string, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/PortailIrsi`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name PortailEpassDossierDetail
     * @summary Retourne l'Url du lien Transparent Sinapps
     * @request GET:/Dossier/{dossier}/PortailEpass
     * @secure
     */
    portailEpassDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<string, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/PortailEpass`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name DatesCleDossierDetail
     * @summary Fonction de chargement des date clé d'un (Workflow)
     * @request GET:/Dossier/{dossier}/DatesCle
     * @secure
     */
    datesCleDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<DatesCle, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/DatesCle`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DossierListes
     * @name TypeRisqueDossierDetail
     * @summary Liste des types de risque selon l'origine du dossier
     * @request GET:/Dossier/{dossier}/SelectList/TypeRisque
     * @secure
     */
    typeRisqueDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<TypeRisque[], ProblemDetails | ValidationProblemDetails>({
        path: `/Dossier/${dossier}/SelectList/TypeRisque`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DossierListes
     * @name UsageRisqueDossierDetail
     * @summary Liste des usages de risque selon l'origine du dossier
     * @request GET:/Dossier/{dossier}/SelectList/UsageRisque
     * @secure
     */
    usageRisqueDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<UsageRisque[], ProblemDetails | ValidationProblemDetails>({
        path: `/Dossier/${dossier}/SelectList/UsageRisque`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DossierListes
     * @name PersonnesReglementDossierDetail
     * @summary Liste des personnes possible pour un règlement selon l'origine du dossier
     * @request GET:/Dossier/{dossier}/SelectList/PersonnesReglement
     * @secure
     */
    personnesReglementDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<CodeLabel[], ProblemDetails | ValidationProblemDetails>({
        path: `/Dossier/${dossier}/SelectList/PersonnesReglement`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DossierListes
     * @name NatureEtDetailDeBienDossierDetail
     * @request GET:/Dossier/{dossier}/SelectList/NatureEtDetailDeBien
     * @secure
     */
    natureEtDetailDeBienDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<NatureBien[], ProblemDetails | ValidationProblemDetails>({
        path: `/Dossier/${dossier}/SelectList/NatureEtDetailDeBien`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DossierListes
     * @name RolesPersonneDossierDetail
     * @summary Liste des qualités d'une personne PEI_9072_QUALITE
     * @request GET:/Dossier/{dossier}/SelectList/RolesPersonne
     * @secure
     */
    rolesPersonneDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/SelectList/RolesPersonne`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DossierListes
     * @name QualitesPersonneDossierDetail
     * @summary Liste qualités d'une personne par dossier PEI_9087_STATUT_PERSONNE
     * @request GET:/Dossier/{dossier}/SelectList/QualitesPersonne
     * @secure
     */
    qualitesPersonneDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/SelectList/QualitesPersonne`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DossierListes
     * @name ConventionsApplicablesDossierDetail
     * @summary List des conventions applicable pour un dossier
     * @request GET:/Dossier/{dossier}/SelectList/ConventionsApplicables
     * @secure
     */
    conventionsApplicablesDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/SelectList/ConventionsApplicables`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DossierListes
     * @name AdressesRisqueIrsiDossierDetail
     * @summary Liste des adresses de risque IRSI
     * @request GET:/Dossier/{dossier}/SelectList/AdressesRisqueIRSI
     * @secure
     */
    adressesRisqueIrsiDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<Adresse[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/SelectList/AdressesRisqueIRSI`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DossierListes
     * @name TelephonesExpertIrsiDossierDetail
     * @summary Liste des numéros de téléphone de l'expert
     * @request GET:/Dossier/{dossier}/SelectList/TelephonesExpertIRSI
     * @secure
     */
    telephonesExpertIrsiDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<string[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/SelectList/TelephonesExpertIRSI`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DossierListes
     * @name DifficultesSinappsDossierCreate
     * @summary Liste des difficulté SINAPPS en Cours
     * @request POST:/Dossier/{dossier}/SelectList/DifficultesSinapps
     * @secure
     */
    difficultesSinappsDossierCreate: (dossier: string, params: RequestParams = {}) =>
      this.request<Difficulte[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/SelectList/DifficultesSinapps`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EchangeInterne
     * @name NouveauEchangeInterneCreate
     * @summary Création d'un nouvel échange interne
     * @request POST:/Dossier/{dossier}/EchangeInterne/Nouveau
     * @secure
     */
    nouveauEchangeInterneCreate: (dossier: string, data: EchangeInterneRequest, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/EchangeInterne/Nouveau`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EchangeInterne
     * @name DestinatairesEchangeInterneDetail
     * @summary Permet de récupérer la liste des destinataires d'échanges internes pour un dossier
     * @request GET:/Dossier/{dossier}/EchangeInterne/Destinataires
     * @secure
     */
    destinatairesEchangeInterneDetail: (
      { dossier, ...query }: DestinatairesEchangeInterneDetailParams,
      params: RequestParams = {},
    ) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/EchangeInterne/Destinataires`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Mail
     * @name DestinatairesMailDetail
     * @summary Récupère la liste des destinataires possible pour un dossier
     * @request GET:/Dossier/{dossier}/Mail/Destinataires
     * @secure
     */
    destinatairesMailDetail: ({ dossier, ...query }: DestinatairesMailDetailParams, params: RequestParams = {}) =>
      this.request<MailDestinataire[], ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Mail/Destinataires`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Mail
     * @name EmetteursMailDetail
     * @request GET:/Dossier/{dossier}/Mail/Emetteurs
     * @secure
     */
    emetteursMailDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<MailDestinataire[], ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Mail/Emetteurs`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Mail
     * @name MailTypesMailDetail
     * @summary Récupère la liste des mails types
     * @request GET:/Dossier/{dossier}/Mail/MailTypes
     * @secure
     */
    mailTypesMailDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Mail/MailTypes`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Mail
     * @name NouveauMailCreate
     * @summary Création d'un nouveau mail (Simple pour l'instant)
     * @request POST:/Dossier/{dossier}/Mail/Nouveau
     * @secure
     */
    nouveauMailCreate: (dossier: string, data: MailRequest, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Mail/Nouveau`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RechercheDossier
     * @name RechercheRapideDossierList
     * @request GET:/Dossier/RechercheRapide
     * @secure
     */
    rechercheRapideDossierList: (query: RechercheRapideDossierListParams, params: RequestParams = {}) =>
      this.request<DossierRecherche[], ValidationProblemDetails>({
        path: `/Dossier/RechercheRapide`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RechercheDossier
     * @name ListesDossierDetail
     * @summary Récupère la liste des dossiers affectés à l'expert qui ont un RDV aujourd'hui et selon les filtres fournis en paramètre
     * @request GET:/Dossier/Listes/{filtre}
     * @secure
     */
    listesDossierDetail: ({ filtre, ...query }: ListesDossierDetailParams, params: RequestParams = {}) =>
      this.request<DossierRecherche[], ValidationProblemDetails>({
        path: `/Dossier/Listes/${filtre}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RechercheDossier
     * @name MesDossiersDossierList
     * @request GET:/Dossier/MesDossiers
     * @secure
     */
    mesDossiersDossierList: (query: MesDossiersDossierListParams, params: RequestParams = {}) =>
      this.request<ResultatRechercheMesDossier, ValidationProblemDetails>({
        path: `/Dossier/MesDossiers`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RechercheDossier
     * @name RechercheDossierList
     * @summary Récupère la liste des dossiers affectés à l'expert qui ont un RDV aujourd'hui et selon les filtres fournis en paramètre
     * @request GET:/Dossier/Recherche
     * @secure
     */
    rechercheDossierList: (query: RechercheDossierListParams, params: RequestParams = {}) =>
      this.request<DossierRecherche[], ValidationProblemDetails>({
        path: `/Dossier/Recherche`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Risque
     * @name RisqueDernierRapportDefinitifDossierDetail
     * @summary Récupère le risque du dernier rapport définitif d'un dossier
     * @request GET:/Dossier/{dossier}/RisqueDernierRapportDefinitif
     * @secure
     */
    risqueDernierRapportDefinitifDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<Risque, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/RisqueDernierRapportDefinitif`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sinapps
     * @name DemanderFinPrestationSinappsCreate
     * @summary Sauvegarde le panel de la commande DemanderFinPrestation
     * @request POST:/Dossier/{dossier}/Sinapps/DemanderFinPrestation
     * @secure
     */
    demanderFinPrestationSinappsCreate: (
      dossier: string,
      data: CommandeDemanderFinPrestationRequest,
      params: RequestParams = {},
    ) =>
      this.request<boolean, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Sinapps/DemanderFinPrestation`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sinapps
     * @name LeverFinPrestationSinappsCreate
     * @summary Sauvegarde le panel de la commande LeverFinPrestation
     * @request POST:/Dossier/{dossier}/Sinapps/LeverFinPrestation
     * @secure
     */
    leverFinPrestationSinappsCreate: (
      dossier: string,
      data: CommandeLeverFinPrestationRequest,
      params: RequestParams = {},
    ) =>
      this.request<boolean, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Sinapps/LeverFinPrestation`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sinapps
     * @name SignalerUneDifficulteSinappsCreate
     * @summary Sauvegarde le panel de la commande SignalerUneDifficulte
     * @request POST:/Dossier/{dossier}/Sinapps/SignalerUneDifficulte
     * @secure
     */
    signalerUneDifficulteSinappsCreate: (
      dossier: string,
      data: CommandeSignalerUneDifficulteRequest,
      params: RequestParams = {},
    ) =>
      this.request<boolean, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Sinapps/SignalerUneDifficulte`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sinapps
     * @name LeverDifficulteSinappsCreate
     * @summary Sauvegarde le panel de la commande LeverDifficulte
     * @request POST:/Dossier/{dossier}/Sinapps/LeverDifficulte
     * @secure
     */
    leverDifficulteSinappsCreate: (
      dossier: string,
      data: LeverDifficulteSinappsCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<boolean, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Sinapps/LeverDifficulte`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sinapps
     * @name TraiterInsatisfactionSinappsCreate
     * @summary Sauvegarde le panel de la commande TraiterInsatisfaction
     * @request POST:/Dossier/{dossier}/Sinapps/TraiterInsatisfaction
     * @secure
     */
    traiterInsatisfactionSinappsCreate: (
      dossier: string,
      data: CommandeTraiterInsatisfactionRequest,
      params: RequestParams = {},
    ) =>
      this.request<boolean, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Sinapps/TraiterInsatisfaction`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sinapps
     * @name CloturerInsatisfactionSinappsCreate
     * @summary Sauvegarde le panel de la commande CloturerInsatisfaction
     * @request POST:/Dossier/{dossier}/Sinapps/CloturerInsatisfaction
     * @secure
     */
    cloturerInsatisfactionSinappsCreate: (
      dossier: string,
      data: CommandeCloturerInsatisfactionRequest,
      params: RequestParams = {},
    ) =>
      this.request<boolean, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Sinapps/CloturerInsatisfaction`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sinapps
     * @name CloturerPrestationSinappsCreate
     * @summary Sauvegarde le panel de la commande CloturerPrestation
     * @request POST:/Dossier/{dossier}/Sinapps/CloturerPrestation
     * @secure
     */
    cloturerPrestationSinappsCreate: (dossier: string, params: RequestParams = {}) =>
      this.request<boolean, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Sinapps/CloturerPrestation`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sinapps
     * @name LeverCloturePrestationSinappsCreate
     * @summary Sauvegarde le panel de la commande LeverCloturePrestation
     * @request POST:/Dossier/{dossier}/Sinapps/LeverCloturePrestation
     * @secure
     */
    leverCloturePrestationSinappsCreate: (dossier: string, params: RequestParams = {}) =>
      this.request<boolean, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Sinapps/LeverCloturePrestation`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sinapps
     * @name DeposerCompteRenduSinappsCreate
     * @summary Sauvegarde le panel de la commande DeposerCompteRendu
     * @request POST:/Dossier/{dossier}/Sinapps/DeposerCompteRendu
     * @secure
     */
    deposerCompteRenduSinappsCreate: (
      dossier: string,
      data: CommandeDeposerCompteRenduRequest,
      params: RequestParams = {},
    ) =>
      this.request<boolean, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Sinapps/DeposerCompteRendu`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sinapps
     * @name DeposerConclusionsSinappsCreate
     * @summary Sauvegarde le panel de la commande DeposerConclusions
     * @request POST:/Dossier/{dossier}/Sinapps/DeposerConclusions
     * @secure
     */
    deposerConclusionsSinappsCreate: (
      dossier: string,
      data: CommandeDeposerConclusionsRequest,
      params: RequestParams = {},
    ) =>
      this.request<boolean, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Sinapps/DeposerConclusions`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sinapps
     * @name SolliciterIntervenantSinappsCreate
     * @summary Sauvegarde le panel de la commande SolliciterIntervenant
     * @request POST:/Dossier/{dossier}/Sinapps/SolliciterIntervenant
     * @secure
     */
    solliciterIntervenantSinappsCreate: (
      dossier: string,
      data: CommandeSolliciterIntervenantRequest,
      params: RequestParams = {},
    ) =>
      this.request<boolean, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Sinapps/SolliciterIntervenant`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sinapps
     * @name CloturerSollicitationSinappsCreate
     * @summary Sauvegarde le panel de la commande CloturerSollicitation
     * @request POST:/Dossier/{dossier}/Sinapps/CloturerSollicitation
     * @secure
     */
    cloturerSollicitationSinappsCreate: (
      dossier: string,
      data: CloturerSollicitationSinappsCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<boolean, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Sinapps/CloturerSollicitation`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sinapps
     * @name CreerUneFicheSinappsCreate
     * @request POST:/Dossier/{dossier}/Sinapps/CreerUneFiche
     * @secure
     */
    creerUneFicheSinappsCreate: (dossier: string, data: FicheVigilanceRequest, params: RequestParams = {}) =>
      this.request<boolean, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Sinapps/CreerUneFiche`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sinapps
     * @name SupprimerUneFicheSinappsCreate
     * @request POST:/Dossier/{dossier}/Sinapps/SupprimerUneFiche/{ficheGuid}
     * @secure
     */
    supprimerUneFicheSinappsCreate: (dossier: string, ficheGuid: string, params: RequestParams = {}) =>
      this.request<boolean, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Sinapps/SupprimerUneFiche/${ficheGuid}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sinapps
     * @name DifficultesSinappsDetail
     * @summary Liste des difficulté SINAPPS en Cours
     * @request GET:/Dossier/{dossier}/Sinapps/Difficultes
     * @secure
     */
    difficultesSinappsDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<Difficulte[], ProblemDetails | ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Sinapps/Difficultes`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sinapps
     * @name SollicitationsSinappsDetail
     * @summary Liste des sollicitations sur un dossier Sinapps
     * @request GET:/Dossier/{dossier}/Sinapps/Sollicitations
     * @secure
     */
    sollicitationsSinappsDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<Sollicitation[], ProblemDetails | ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Sinapps/Sollicitations`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sinapps
     * @name FichesVigilanceSinappsDetail
     * @request GET:/Dossier/{dossier}/Sinapps/FichesVigilance
     * @secure
     */
    fichesVigilanceSinappsDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<FicheVigilance[], ProblemDetails | ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Sinapps/FichesVigilance`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Traveller
     * @name RapportsDossierDetail
     * @summary Récupère tous les rapports d'un dossier
     * @request GET:/Dossier/{dossier}/Rapports
     * @secure
     */
    rapportsDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<RapportResume[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Rapports`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Traveller
     * @name TravellerDossierDetail
     * @request GET:/Dossier/Traveller/{id}
     * @secure
     */
    travellerDossierDetail: (id: string, params: RequestParams = {}) =>
      this.request<Traveller, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/Traveller/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Traveller
     * @name TravellerDossierCreate
     * @request POST:/Dossier/Traveller/{id}
     * @secure
     */
    travellerDossierCreate: (
      { id, ...query }: TravellerDossierCreateParams,
      data: Traveller,
      params: RequestParams = {},
    ) =>
      this.request<ProblemDetails, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/Traveller/${id}`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Traveller
     * @name NouveauTravellerCreate
     * @request POST:/Dossier/{idDossier}/Traveller/Nouveau
     * @secure
     */
    nouveauTravellerCreate: (idDossier: string, params: RequestParams = {}) =>
      this.request<Traveller, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${idDossier}/Traveller/Nouveau`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Traveller
     * @name ReglementsPanelTravellerCreate
     * @summary Sauvegarde le panel règlement
     * @request POST:/Dossier/Traveller/{rapport}/ReglementsPanel
     * @secure
     */
    reglementsPanelTravellerCreate: (
      { rapport, ...query }: ReglementsPanelTravellerCreateParams,
      data: Reglement,
      params: RequestParams = {},
    ) =>
      this.request<boolean, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/Traveller/${rapport}/ReglementsPanel`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Traveller
     * @name IrsiTravellerDetail
     * @summary Récupère le panel Irsi du traveller
     * @request GET:/Dossier/Traveller/{id}/Irsi
     * @secure
     */
    irsiTravellerDetail: (id: string, params: RequestParams = {}) =>
      this.request<Irsi, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/Traveller/${id}/Irsi`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Traveller
     * @name InitIntervenantTravellerDetail
     * @summary Initialise un intervenant avec une entité de l'annuaire
     * @request GET:/Dossier/Traveller/{id}/InitIntervenant
     * @secure
     */
    initIntervenantTravellerDetail: (
      { id, ...query }: InitIntervenantTravellerDetailParams,
      params: RequestParams = {},
    ) =>
      this.request<IntervenantTraveller, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/Traveller/${id}/InitIntervenant`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Traveller
     * @name CiblesRecoursTravellerDetail
     * @request GET:/Dossier/Traveller/{id}/CiblesRecours
     * @secure
     */
    ciblesRecoursTravellerDetail: (id: string, params: RequestParams = {}) =>
      this.request<CibleRecours[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/Traveller/${id}/CiblesRecours`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description <table> <tr> <td> <strong> <font color="green" size="4">Règles de gestion</font> </strong> </td> </tr> <tr> <td> <strong>Code</strong> </td> <td> <strong>Description</strong> </td> </tr> <tr> <td colspan="2"> <HR></HR> </td> </tr> <tr> <td>CAUSES_CIRCONSTANCES_CAUSE_SUPPRIMEE</td> <td>Sélection de la cause supprimée obligatoire si le cas barême est différent de '1.4 : PAS DE RESPONSABLE CONVENTIONNEL'</td> </tr> <tr> <td>CAUSES_CIRCONSTANCES_CAUSE_BAREME</td> <td>Sélection de la cause barême obligatoire si le cas barême est '1.1 : Responsabilité Occupant'</td> </tr> <tr> <td>PERSONNE_IMPLIQUEE_LOCALITE</td> <td>Saisie de la localité de la personne obligatoire si une ligne d'adresse est saisie</td> </tr> </table>
     *
     * @tags Traveller
     * @name IrsiTravellerCreate
     * @summary Sauvegarde le panel Irsi
     * @request POST:/Dossier/Traveller/{dossier}/Irsi
     * @secure
     */
    irsiTravellerCreate: (
      { dossier, ...query }: IrsiTravellerCreateParams,
      data: IrsiRequest,
      params: RequestParams = {},
    ) =>
      this.request<boolean, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/Traveller/${dossier}/Irsi`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  compte = {
    /**
     * No description
     *
     * @tags Compte
     * @name MonCompteEpassCompteList
     * @summary Retourne les informations de compte de l'utilisateur epass
     * @request GET:/Compte/MonCompteEpass
     * @secure
     */
    monCompteEpassCompteList: (params: RequestParams = {}) =>
      this.request<MonCompteEpass, ProblemDetails | ValidationProblemDetails>({
        path: `/Compte/MonCompteEpass`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Compte
     * @name MonCompteEpassCompteUpdate
     * @summary Modifie les informations de compte de l'utilisateur epass
     * @request PUT:/Compte/MonCompteEpass
     * @secure
     */
    monCompteEpassCompteUpdate: (data: MonCompteEpassRequest, params: RequestParams = {}) =>
      this.request<MonCompteEpass, ProblemDetails | ValidationProblemDetails>({
        path: `/Compte/MonCompteEpass`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Compte
     * @name PhotoProfilCompteCreate
     * @summary modifie la photo de profil de l'utilisateur
     * @request POST:/Compte/PhotoProfil
     * @secure
     */
    photoProfilCompteCreate: (data: PhotoProfilCompteCreatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Compte/PhotoProfil`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Compte
     * @name ChangePasswordCompteCreate
     * @summary Modifie le mot de passe l'utilisateur epass
     * @request POST:/Compte/ChangePassword
     * @secure
     */
    changePasswordCompteCreate: (data: ChangePasswordRequest, params: RequestParams = {}) =>
      this.request<MonCompteEpass, ProblemDetails | ValidationProblemDetails>({
        path: `/Compte/ChangePassword`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  document = {
    /**
     * No description
     *
     * @tags Document
     * @name UpdateFichierDocumentCreate
     * @request POST:/Document/{id}/UpdateFichier
     * @secure
     */
    updateFichierDocumentCreate: (id: string, data: UpdateFichierDocumentCreatePayload, params: RequestParams = {}) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/Document/${id}/UpdateFichier`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name DocumentCreate
     * @request POST:/Document/{id}
     * @secure
     */
    documentCreate: (id: string, data: UpdateDocumentRequest, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Document/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name DocumentDetail
     * @summary Récupère le document d'un dossier
     * @request GET:/Document/{id}
     * @secure
     */
    documentDetail: ({ id, ...query }: DocumentDetailParams, params: RequestParams = {}) =>
      this.request<PieceJointe, ValidationProblemDetails | ProblemDetails>({
        path: `/Document/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name DocumentDelete
     * @summary Supprime (logiquement) un document
     * @request DELETE:/Document/{id}
     * @secure
     */
    documentDelete: ({ id, ...query }: DocumentDeleteParams, params: RequestParams = {}) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/Document/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name EnregistrementEvenementielCreate
     * @request POST:/Document/Evenementiel/Enregistrement
     * @secure
     */
    enregistrementEvenementielCreate: (data: DataDocument, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Document/Evenementiel/Enregistrement`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name ReeditionEvenementielDetail
     * @request GET:/Document/{docId}/Evenementiel/Reedition
     * @secure
     */
    reeditionEvenementielDetail: (docId: string, params: RequestParams = {}) =>
      this.request<DataDocument, ValidationProblemDetails | ProblemDetails>({
        path: `/Document/${docId}/Evenementiel/Reedition`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name SfdtDocumentCreate
     * @request POST:/Document/{docId}/SFDT
     * @deprecated
     * @secure
     */
    sfdtDocumentCreate: (docId: string, params: RequestParams = {}) =>
      this.request<string, ValidationProblemDetails | ProblemDetails>({
        path: `/Document/${docId}/SFDT`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name EnregistrementSfdtDocumentCreate
     * @request POST:/Document/{docId}/EnregistrementSFDT
     * @deprecated
     * @secure
     */
    enregistrementSfdtDocumentCreate: (
      docId: string,
      data: EnregistrementSfdtDocumentCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<boolean, ValidationProblemDetails | ProblemDetails>({
        path: `/Document/${docId}/EnregistrementSFDT`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name ResumeDocumentList
     * @summary Retourne des résumés des documents des dossiers passés en paramètre
     * @request GET:/Document/Resume
     * @secure
     */
    resumeDocumentList: (query: ResumeDocumentListParams, params: RequestParams = {}) =>
      this.request<ResumeDocumentsDossier[], ValidationProblemDetails>({
        path: `/Document/Resume`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  echangeInterne = {
    /**
     * No description
     *
     * @tags EchangeInterne
     * @name EchangeInterneList
     * @summary Liste des echanges internes
     * @request GET:/EchangeInterne
     * @secure
     */
    echangeInterneList: (query: EchangeInterneListParams, params: RequestParams = {}) =>
      this.request<EchangeInterne[], ValidationProblemDetails>({
        path: `/EchangeInterne`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EchangeInterne
     * @name CountEchangeInterneList
     * @summary CompteurListe des echanges internes
     * @request GET:/EchangeInterne/Count
     * @secure
     */
    countEchangeInterneList: (query: CountEchangeInterneListParams, params: RequestParams = {}) =>
      this.request<number, ValidationProblemDetails>({
        path: `/EchangeInterne/Count`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EchangeInterne
     * @name RepondreEchangeInterneCreate
     * @summary Permet de répondre à un échange interne
     * @request POST:/EchangeInterne/Repondre/{id}
     * @secure
     */
    repondreEchangeInterneCreate: (id: string, data: RepondreEchangeInterneRequest, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/EchangeInterne/Repondre/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EchangeInterne
     * @name TransfertEchangeInterneCreate
     * @summary Permet de transférer un échange interne vers une ou plusieurs autres personnes
     * @request POST:/EchangeInterne/Transfert/{id}
     * @secure
     */
    transfertEchangeInterneCreate: (id: string, data: TransfertEchangeInterneRequest, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/EchangeInterne/Transfert/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EchangeInterne
     * @name MarquerCommeLuEchangeInterneCreate
     * @request POST:/EchangeInterne/MarquerCommeLu/{id}
     * @secure
     */
    marquerCommeLuEchangeInterneCreate: (id: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/EchangeInterne/MarquerCommeLu/${id}`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EchangeInterne
     * @name MarquerCommeNonLuEchangeInterneCreate
     * @request POST:/EchangeInterne/MarquerCommeNonLu/{id}
     * @secure
     */
    marquerCommeNonLuEchangeInterneCreate: (id: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/EchangeInterne/MarquerCommeNonLu/${id}`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EchangeInterne
     * @name EchangesTypeEchangeInterneList
     * @request GET:/EchangeInterne/EchangesType
     * @secure
     */
    echangesTypeEchangeInterneList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/EchangeInterne/EchangesType`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  groupe = {
    /**
     * No description
     *
     * @tags Groupe
     * @name InformationsGroupeList
     * @summary Récupère les informations sur le groupe
     * @request GET:/Groupe/Informations
     * @secure
     */
    informationsGroupeList: (params: RequestParams = {}) =>
      this.request<InformationsGroupe, ValidationProblemDetails>({
        path: `/Groupe/Informations`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  health = {
    /**
     * No description
     *
     * @tags Health
     * @name StatusHealthList
     * @request GET:/health/status
     * @secure
     */
    statusHealthList: (params: RequestParams = {}) =>
      this.request<HealthReport, ValidationProblemDetails>({
        path: `/health/status`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  log = {
    /**
     * No description
     *
     * @tags Log
     * @name IndexLogList
     * @summary Récupère les dossiers selon un filtre
     * @request GET:/Log/Index
     * @secure
     */
    indexLogList: (query: IndexLogListParams, params: RequestParams = {}) =>
      this.request<File, ValidationProblemDetails | ProblemDetails>({
        path: `/Log/Index`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  mail = {
    /**
     * No description
     *
     * @tags Mail
     * @name MailTypeMailDetail
     * @summary Récupére un mail type par son code
     * @request GET:/Mail/MailType/{code}
     * @secure
     */
    mailTypeMailDetail: (code: string, params: RequestParams = {}) =>
      this.request<MailType, ValidationProblemDetails | ProblemDetails>({
        path: `/Mail/MailType/${code}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  notifications = {
    /**
     * No description
     *
     * @tags Notification
     * @name NotificationsList
     * @summary Récupère les notifications en fonction du filtre
     * @request GET:/Notifications
     * @secure
     */
    notificationsList: (query: NotificationsListParams, params: RequestParams = {}) =>
      this.request<EchangeInterne[], ValidationProblemDetails>({
        path: `/Notifications`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name CountNotificationsList
     * @summary Récupère les notifications en fonction du filtre
     * @request GET:/Notifications/Count
     * @secure
     */
    countNotificationsList: (query: CountNotificationsListParams, params: RequestParams = {}) =>
      this.request<number, ValidationProblemDetails>({
        path: `/Notifications/Count`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  notificationsUtilisateur = {
    /**
     * No description
     *
     * @tags NotificationsUtilisateur
     * @name NotificationsUtilisateurList
     * @request GET:/NotificationsUtilisateur
     * @secure
     */
    notificationsUtilisateurList: (params: RequestParams = {}) =>
      this.request<NotificationUtilisateur[], ValidationProblemDetails>({
        path: `/NotificationsUtilisateur`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags NotificationsUtilisateur
     * @name NotificationsUtilisateurCreate
     * @request POST:/NotificationsUtilisateur
     * @deprecated
     * @secure
     */
    notificationsUtilisateurCreate: (data: NotificationUtilisateurRequest, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails>({
        path: `/NotificationsUtilisateur`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags NotificationsUtilisateur
     * @name MarquerCommeLuNotificationsUtilisateurCreate
     * @request POST:/NotificationsUtilisateur/MarquerCommeLu/{id}
     * @secure
     */
    marquerCommeLuNotificationsUtilisateurCreate: (id: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/NotificationsUtilisateur/MarquerCommeLu/${id}`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags NotificationsUtilisateur
     * @name MarquerCommeNonLuNotificationsUtilisateurCreate
     * @request POST:/NotificationsUtilisateur/MarquerCommeNonLu/{id}
     * @secure
     */
    marquerCommeNonLuNotificationsUtilisateurCreate: (id: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/NotificationsUtilisateur/MarquerCommeNonLu/${id}`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags NotificationsUtilisateur
     * @name NotificationsUtilisateurDelete
     * @request DELETE:/NotificationsUtilisateur/{id}
     * @deprecated
     * @secure
     */
    notificationsUtilisateurDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails>({
        path: `/NotificationsUtilisateur/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  planning = {
    /**
     * No description
     *
     * @tags Planning
     * @name PlanningDetail
     * @summary Récupère les différents RDV sur la plage définie
     * @request GET:/Planning/{expert}
     * @secure
     */
    planningDetail: ({ expert, ...query }: PlanningDetailParams, params: RequestParams = {}) =>
      this.request<Planning[], ValidationProblemDetails | ProblemDetails>({
        path: `/Planning/${expert}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Planning
     * @name TypesPlanningList
     * @summary Récupère les différents types de  RDV
     * @request GET:/Planning/Types
     * @secure
     */
    typesPlanningList: (params: RequestParams = {}) =>
      this.request<TypeRdv[], ValidationProblemDetails>({
        path: `/Planning/Types`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Planning
     * @name NouvelleActionGeneralePlanningCreate
     * @summary Création d'une nouvelle action générale
     * @request POST:/Planning/NouvelleActionGenerale
     * @secure
     */
    nouvelleActionGeneralePlanningCreate: (data: PlanningActionGeneraleRequest, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Planning/NouvelleActionGenerale`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Planning
     * @name DeleteTestsPlanningsPlanningDelete
     * @summary Route pour supprimer les planning créé par les tests front.
     * @request DELETE:/Planning/DeleteTestsPlannings
     * @secure
     */
    deleteTestsPlanningsPlanningDelete: (params: RequestParams = {}) =>
      this.request<string, ValidationProblemDetails>({
        path: `/Planning/DeleteTestsPlannings`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Planning
     * @name ModifieActionGeneralePlanningCreate
     * @summary Modification d'une action générale
     * @request POST:/Planning/ModifieActionGenerale/{id}
     * @secure
     */
    modifieActionGeneralePlanningCreate: (
      id: string,
      data: PlanningActionGeneraleRequest,
      params: RequestParams = {},
    ) =>
      this.request<string, ValidationProblemDetails | ProblemDetails>({
        path: `/Planning/ModifieActionGenerale/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  selectList = {
    /**
     * No description
     *
     * @tags SelectList
     * @name NaturesAccordList
     * @summary Liste des natures d'accord ANN_9019_NATURE_ACCORD
     * @request GET:/SelectList/NaturesAccord
     * @secure
     */
    naturesAccordList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/NaturesAccord`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name StatutsJuridiquesList
     * @summary Liste des statuts juridiques ANN_9094_STATUT_JURIDIQUE
     * @request GET:/SelectList/StatutsJuridiques
     * @secure
     */
    statutsJuridiquesList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/StatutsJuridiques`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name NaturesInscriptionList
     * @summary Liste des natures d'inscription ANN_9095_NATURE_INSCRIPTION
     * @request GET:/SelectList/NaturesInscription
     * @secure
     */
    naturesInscriptionList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/NaturesInscription`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypesReparateursList
     * @summary Liste des types de réparateurs ANN_9729_TYPE_REPARATEUR
     * @request GET:/SelectList/TypesReparateurs
     * @secure
     */
    typesReparateursList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TypesReparateurs`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name QualificationsList
     * @summary Liste des qualifications ANN_QUALIFICATION
     * @request GET:/SelectList/Qualifications
     * @secure
     */
    qualificationsList: (params: RequestParams = {}) =>
      this.request<QualificationCollaborateur[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/Qualifications`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name SituationsFamilialesList
     * @summary Liste des situations familiales ANN_SITUATION_FAMILLIALE
     * @request GET:/SelectList/SituationsFamiliales
     * @secure
     */
    situationsFamilialesList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/SituationsFamiliales`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name FormulesPolitesseList
     * @summary Liste des formules de politesse ANN_FORMULE_POLITESSE
     * @request GET:/SelectList/FormulesPolitesse
     * @secure
     */
    formulesPolitesseList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/SelectList/FormulesPolitesse`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name IntitulesList
     * @summary Liste des intitulés ANN_INTITULE
     * @request GET:/SelectList/Intitules
     * @secure
     */
    intitulesList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/Intitules`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name PaysList
     * @summary Liste des pays ANN_PEI_DOS_9109_PAYS
     * @request GET:/SelectList/Pays
     * @secure
     */
    paysList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/Pays`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypeMediasList
     * @summary Liste des types de médias ANN_TYPE_DE_MEDIA
     * @request GET:/SelectList/TypeMedias
     * @secure
     */
    typeMediasList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TypeMedias`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypeAdressesList
     * @summary Liste des types d'adresses ANN_TYPE_D_ADRESSE
     * @request GET:/SelectList/TypeAdresses
     * @secure
     */
    typeAdressesList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TypeAdresses`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name FamillesAnnuaireList
     * @summary Liste des familles de l'annuaire ANN_FAMILLE_ANNUAIRE
     * @request GET:/SelectList/FamillesAnnuaire
     * @secure
     */
    famillesAnnuaireList: (params: RequestParams = {}) =>
      this.request<FamilleAnnuaire[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/FamillesAnnuaire`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ConnexionsPortailMercureList
     * @summary Liste des connexions au portail Mercure ANN_MERCURE_PORTAIL
     * @request GET:/SelectList/ConnexionsPortailMercure
     * @secure
     */
    connexionsPortailMercureList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/ConnexionsPortailMercure`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name CabinetsList
     * @request GET:/SelectList/Cabinets
     * @secure
     */
    cabinetsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/SelectList/Cabinets`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name AgencesList
     * @request GET:/SelectList/Agences
     * @secure
     */
    agencesList: (query: AgencesListParams, params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/SelectList/Agences`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ExpertsList
     * @request GET:/SelectList/Experts
     * @secure
     */
    expertsList: (query: ExpertsListParams, params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/SelectList/Experts`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypesMissionList
     * @summary Liste des types de mission DOS_TYPE_MISSION
     * @request GET:/SelectList/TypesMission
     * @secure
     */
    typesMissionList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TypesMission`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypesAssureList
     * @summary Liste des types d'assuré DOS_TYPE_ASSURE
     * @request GET:/SelectList/TypesAssure
     * @secure
     */
    typesAssureList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TypesAssure`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name MotifsRetardList
     * @summary Liste des motifs de retard DOS_9016_MOTIF_RETARD
     * @request GET:/SelectList/MotifsRetard
     * @secure
     */
    motifsRetardList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/MotifsRetard`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ReglementsCompagnieList
     * @summary Liste des règlements par compagnie DOS_9011_REGLEMENT_PAR_CIE
     * @request GET:/SelectList/ReglementsCompagnie
     * @secure
     */
    reglementsCompagnieList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/ReglementsCompagnie`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name CaracteresExpertiseList
     * @summary Liste des caractères d'expertise DOS_9071_CARACTERE_EXPERTISE
     * @request GET:/SelectList/CaracteresExpertise
     * @secure
     */
    caracteresExpertiseList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/SelectList/CaracteresExpertise`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypeMissionList
     * @summary Liste des types de mission DOS_9074_TYPE_MISSION
     * @request GET:/SelectList/TypeMission
     * @secure
     */
    typeMissionList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TypeMission`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ConventionsApplicablesList
     * @summary Liste des  conventions applicables DOS_9083_CONVENTION_APPLICABLE
     * @request GET:/SelectList/ConventionsApplicables
     * @secure
     */
    conventionsApplicablesList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/ConventionsApplicables`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name FamillesNaturesSinistreList
     * @summary Liste des familles de natures de sinistre DOS_FAMILLE_NATURE_SINISTRE
     * @request GET:/SelectList/FamillesNaturesSinistre
     * @secure
     */
    famillesNaturesSinistreList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/FamillesNaturesSinistre`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name NaturesSinistreList
     * @summary Liste des natures de sinistre DOS_9209_NATURE_SINISTRE
     * @request GET:/SelectList/NaturesSinistre
     * @secure
     */
    naturesSinistreList: (params: RequestParams = {}) =>
      this.request<NatureSinistre[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/NaturesSinistre`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name NaturesExpertiseList
     * @summary Liste des natures d'expertise DOS_NATURE_EXPERTISE
     * @request GET:/SelectList/NaturesExpertise
     * @secure
     */
    naturesExpertiseList: (params: RequestParams = {}) =>
      this.request<NatureExpertise[], ValidationProblemDetails>({
        path: `/SelectList/NaturesExpertise`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name OriginesMissionList
     * @summary Liste des origines mission DOS_ORIGINE_MISSION
     * @request GET:/SelectList/OriginesMission
     * @secure
     */
    originesMissionList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/OriginesMission`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypesExpertiseList
     * @summary Liste des types d'expertise DOS_TYPE_EXPERTISE
     * @request GET:/SelectList/TypesExpertise
     * @secure
     */
    typesExpertiseList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TypesExpertise`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name NaturesSinistreReseauList
     * @summary Liste des natures de sinistre réseau DOS_9209_SINISTRE_RESEAUX
     * @request GET:/SelectList/NaturesSinistreReseau
     * @secure
     */
    naturesSinistreReseauList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/NaturesSinistreReseau`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name CodesEtatDossierList
     * @summary Liste des codes état dossier DOS_CODE_ETAT_DOSSIER
     * @request GET:/SelectList/CodesEtatDossier
     * @secure
     */
    codesEtatDossierList: (params: RequestParams = {}) =>
      this.request<CodeEtatDossier[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/CodesEtatDossier`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name NaturesSinsitreReseauList
     * @summary Liste des natures de sinistre réseau DOS_9209_SINISTRE_RESEAUX
     * @request GET:/SelectList/NaturesSinsitreReseau
     * @secure
     */
    naturesSinsitreReseauList: (params: RequestParams = {}) =>
      this.request<NatureSinistreReseau[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/NaturesSinsitreReseau`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name NaturesSinsitreGeneraliList
     * @summary Liste des natures de sinistre Générali DOS_NATURE_SINISTRE_GENERALI
     * @request GET:/SelectList/NaturesSinsitreGenerali
     * @secure
     */
    naturesSinsitreGeneraliList: (params: RequestParams = {}) =>
      this.request<NatureSinistreGenerali[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/NaturesSinsitreGenerali`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypesRenfortList
     * @summary Liste des types de renfort DOS_TYPE_RENFORT
     * @request GET:/SelectList/TypesRenfort
     * @secure
     */
    typesRenfortList: (params: RequestParams = {}) =>
      this.request<TypeRenfort[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TypesRenfort`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name AffectationsCpSourceList
     * @summary Liste des affectations de codes postaux par source DOS_AFFECTATION_CP_SOURCE
     * @request GET:/SelectList/AffectationsCPSource
     * @secure
     */
    affectationsCpSourceList: (params: RequestParams = {}) =>
      this.request<AffectationCPSource[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/AffectationsCPSource`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name OuiNonIndetermineList
     * @summary Oui, non, indéterminé
     * @request GET:/SelectList/OuiNonIndetermine
     * @secure
     */
    ouiNonIndetermineList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/OuiNonIndetermine`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name SourcesCabinetList
     * @summary Liste des sources par cabinet DIV_SOURCE_DU_CABINET
     * @request GET:/SelectList/SourcesCabinet
     * @secure
     */
    sourcesCabinetList: (params: RequestParams = {}) =>
      this.request<SourceCabinet[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/SourcesCabinet`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name CodesPostauxList
     * @summary Liste des codes postaux DIV_CP
     * @request GET:/SelectList/CodesPostaux
     * @secure
     */
    codesPostauxList: (params: RequestParams = {}) =>
      this.request<CodePostal[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/CodesPostaux`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name CodesPostauxEtrangersList
     * @summary Liste des codes postaux étrangers DIV_CP_ETRANGERS
     * @request GET:/SelectList/CodesPostauxEtrangers
     * @secure
     */
    codesPostauxEtrangersList: (params: RequestParams = {}) =>
      this.request<CodePostalEtranger[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/CodesPostauxEtrangers`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name QualifiantsList
     * @summary Liste des qualifiants DIV_9224_QUALIFIANT_REF
     * @request GET:/SelectList/Qualifiants
     * @secure
     */
    qualifiantsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/Qualifiants`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name MotifsTransfertEspList
     * @summary Liste des motifs de transfert en ESP DIV_MOTIF_TRANSFERT_ESP
     * @request GET:/SelectList/MotifsTransfertESP
     * @secure
     */
    motifsTransfertEspList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/MotifsTransfertESP`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name EurogexList
     * @summary Liste des Eurogex GEX_EUROGEX
     * @request GET:/SelectList/Eurogex
     * @secure
     */
    eurogexList: (params: RequestParams = {}) =>
      this.request<Eurogex[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/Eurogex`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ProfilsUtilisateursList
     * @summary Liste des profils utilisateur SEC_PROFIL_UTILISATEUR
     * @request GET:/SelectList/ProfilsUtilisateurs
     * @secure
     */
    profilsUtilisateursList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/ProfilsUtilisateurs`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name AgrementsDarvaList
     * @summary Liste des agréments Darva DIV_DARVA_AGREMENT
     * @request GET:/SelectList/AgrementsDarva
     * @secure
     */
    agrementsDarvaList: (params: RequestParams = {}) =>
      this.request<AgrementsDarva[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/AgrementsDarva`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypesCommentairesList
     * @summary Liste des types de commentaires DIV_9000_TYPE_DE_COMMENTAIRES
     * @request GET:/SelectList/TypesCommentaires
     * @secure
     */
    typesCommentairesList: (params: RequestParams = {}) =>
      this.request<DoubleCodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TypesCommentaires`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ParametragesDarvaList
     * @summary Paramétrage Darva DIV_INIT_DARVA
     * @request GET:/SelectList/ParametragesDarva
     * @secure
     */
    parametragesDarvaList: (params: RequestParams = {}) =>
      this.request<InitialisationDarva[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/ParametragesDarva`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ParametragesSinappsList
     * @summary Paramétrage Darva DIV_INIT_SINAPPS
     * @request GET:/SelectList/ParametragesSinapps
     * @secure
     */
    parametragesSinappsList: (params: RequestParams = {}) =>
      this.request<InitialisationSinapps[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/ParametragesSinapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name PrecisionsRisqueList
     * @summary Liste des précisions du risque RIS_9299_PRECISION_RISQUE
     * @request GET:/SelectList/PrecisionsRisque
     * @secure
     */
    precisionsRisqueList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/PrecisionsRisque`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypesRisqueList
     * @summary Liste des types de risque RIS_9709_TYPE_DE_RISQUE
     * @request GET:/SelectList/TypesRisque
     * @secure
     */
    typesRisqueList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TypesRisque`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name UsagesRisqueList
     * @summary Liste des usages du risque RIS_9710_USAGE_DU_RISQUE
     * @request GET:/SelectList/UsagesRisque
     * @secure
     */
    usagesRisqueList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/UsagesRisque`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name CategoriesRisqueList
     * @summary Liste des catégories du risque RIS_9711_CATEGORIE_DU_RISQUE
     * @request GET:/SelectList/CategoriesRisque
     * @secure
     */
    categoriesRisqueList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/CategoriesRisque`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name EnvironnementsRisqueList
     * @summary Liste des environnements du risque RIS_9712_ENVIRONNEMENT_RISQUE
     * @request GET:/SelectList/EnvironnementsRisque
     * @secure
     */
    environnementsRisqueList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/EnvironnementsRisque`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name SituationsAggravantesRisqueList
     * @summary Liste des situations aggravantes du risque RIS_9713_SITUATION_AGGRAVANTE
     * @request GET:/SelectList/SituationsAggravantesRisque
     * @secure
     */
    situationsAggravantesRisqueList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/SituationsAggravantesRisque`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name EtatsOccupationRisqueList
     * @summary Liste des états d'occupation du risque RIS_9714_ETAT_OCCUPATION
     * @request GET:/SelectList/EtatsOccupationRisque
     * @secure
     */
    etatsOccupationRisqueList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/EtatsOccupationRisque`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name RisquesConstructionList
     * @summary Liste des risques de contruction  RIS_9715_RISQUE_CONSTRUCTION
     * @request GET:/SelectList/RisquesConstruction
     * @secure
     */
    risquesConstructionList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/RisquesConstruction`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ClassesCouvertureRisqueList
     * @summary Liste des classes de couverture du risque  RIS_9716_CLASSE_COUVERTURE
     * @request GET:/SelectList/ClassesCouvertureRisque
     * @secure
     */
    classesCouvertureRisqueList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/ClassesCouvertureRisque`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name IndicateursRisqueList
     * @summary Liste des indicateurs du risque RIS_9717_INDICATEUR_RISQUE
     * @request GET:/SelectList/IndicateursRisque
     * @secure
     */
    indicateursRisqueList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/IndicateursRisque`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ValeursIndicateursRisqueList
     * @summary Liste des valeurs des indicateurs du risque RIS_9718_VALEUR_INDICATEUR
     * @request GET:/SelectList/ValeursIndicateursRisque
     * @secure
     */
    valeursIndicateursRisqueList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/ValeursIndicateursRisque`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name QualifiantsMesuresRisqueList
     * @summary Liste des qualifiants des mesures du risque RIS_9719_QUALIFIANT_MESURE
     * @request GET:/SelectList/QualifiantsMesuresRisque
     * @secure
     */
    qualifiantsMesuresRisqueList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/QualifiantsMesuresRisque`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name QualitesList
     * @summary Liste des qualités d'une personne PEI_9072_QUALITE
     * @request GET:/SelectList/Qualites
     * @deprecated
     * @secure
     */
    qualitesList: (params: RequestParams = {}) =>
      this.request<DoubleCodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/Qualites`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name QualitesDuRisqueList
     * @summary Liste des qualités d'une personne PEI_9072_QUALITE
     * @request GET:/SelectList/QualitesDuRisque
     * @secure
     */
    qualitesDuRisqueList: (params: RequestParams = {}) =>
      this.request<DoubleCodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/QualitesDuRisque`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ResponsabilitesList
     * @summary Liste des responsabilites PEI_9078_RESPONSABILITE
     * @request GET:/SelectList/Responsabilites
     * @secure
     */
    responsabilitesList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/Responsabilites`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name StatutsPersonneList
     * @summary Liste des statuts d'une personne PEI_9087_STATUT_PERSONNE
     * @request GET:/SelectList/StatutsPersonne
     * @deprecated
     * @secure
     */
    statutsPersonneList: (params: RequestParams = {}) =>
      this.request<DoubleCodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/StatutsPersonne`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name AssujettissementTvaList
     * @summary Liste des assujettissements à la TVA PEI_9205_ASSUJETTI_TVA
     * @request GET:/SelectList/AssujettissementTVA
     * @secure
     */
    assujettissementTvaList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/AssujettissementTVA`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name QualifiantsPersonneList
     * @summary Liste des qualifiants de personnes PEI_9720_QUALIFIANT
     * @request GET:/SelectList/QualifiantsPersonne
     * @secure
     */
    qualifiantsPersonneList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/QualifiantsPersonne`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name NaturesRapportList
     * @summary Liste des natures de rapport RAP_9076_NATURE_DU_RAPPORT
     * @request GET:/SelectList/NaturesRapport
     * @secure
     */
    naturesRapportList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/NaturesRapport`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name CorpsEtatList
     * @summary Liste des corsp d'état RAP_9079_CORPS_ETAT
     * @request GET:/SelectList/CorpsEtat
     * @secure
     */
    corpsEtatList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/CorpsEtat`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name NaturesPrejudiceList
     * @summary Liste des natures de préjudice RAP_9080_NATURE_PREJUDICE
     * @request GET:/SelectList/NaturesPrejudice
     * @secure
     */
    naturesPrejudiceList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/NaturesPrejudice`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name QualifiantsChiffrageList
     * @summary Liste des qualifiants du chiffrage RAP_9723_QUALIFIANT_CHIFFRAGE
     * @request GET:/SelectList/QualifiantsChiffrage
     * @secure
     */
    qualifiantsChiffrageList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/QualifiantsChiffrage`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name JustificatifsRapportList
     * @summary Liste des justificatifs RAP_9304_JUSTIFICATIF
     * @request GET:/SelectList/JustificatifsRapport
     * @secure
     */
    justificatifsRapportList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/JustificatifsRapport`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name FamillesObjetList
     * @summary Liste des famille de l'objet RAP_9305_FAMILLE_DE_L_OBJET
     * @request GET:/SelectList/FamillesObjet
     * @secure
     */
    famillesObjetList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/FamillesObjet`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name OrigineDepotPlainteList
     * @summary Liste des origines du dépôt de plainte RAP_9306_ORIGINE_DEPOT_PLAINTE
     * @request GET:/SelectList/OrigineDepotPlainte
     * @secure
     */
    origineDepotPlainteList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/OrigineDepotPlainte`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name NaturesPrejudiceComplementairesList
     * @summary Liste des natures de préjudice complémentaires RAP_9724_NATURE_PREJ_CPLT
     * @request GET:/SelectList/NaturesPrejudiceComplementaires
     * @secure
     */
    naturesPrejudiceComplementairesList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/NaturesPrejudiceComplementaires`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name UnitesMesureList
     * @summary Liste des unités de mesure RAP_9726_UNITE_DE_MESURE
     * @request GET:/SelectList/UnitesMesure
     * @secure
     */
    unitesMesureList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/SelectList/UnitesMesure`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name OriginesChiffrageList
     * @summary Liste des origines du chiffrage RAP_9728_ORIGINE_CHIFFRAGE
     * @request GET:/SelectList/OriginesChiffrage
     * @secure
     */
    originesChiffrageList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/OriginesChiffrage`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name LettresAcceptationList
     * @summary Liste des lettres d'acceptation (ou Accords IRSI) RAP_9731_LETTRE_ACCEPTATION
     * @request GET:/SelectList/LettresAcceptation
     * @secure
     */
    lettresAcceptationList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/SelectList/LettresAcceptation`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name PvExpertiseList
     * @summary Liste des procès-verbaux d'expertise RAP_9732_PV_EXPERTISE
     * @request GET:/SelectList/PVExpertise
     * @secure
     */
    pvExpertiseList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/PVExpertise`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ReservesCouvertureList
     * @summary Liste des réserves de couveture RAP_9733_RESERVE_COUVERTURE
     * @request GET:/SelectList/ReservesCouverture
     * @secure
     */
    reservesCouvertureList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/ReservesCouverture`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name MotifsAbsenceList
     * @summary Liste des motifs d'absence PRG_ABSENCE_MOTIF
     * @request GET:/SelectList/MotifsAbsence
     * @secure
     */
    motifsAbsenceList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/MotifsAbsence`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name AppareilsOrigineList
     * @summary Liste des appareils à l'origine PRG_APPAREIL_ORIGINE
     * @request GET:/SelectList/AppareilsOrigine
     * @secure
     */
    appareilsOrigineList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/AppareilsOrigine`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name AppareilsOrigineIncendieList
     * @summary Liste des appareils à l'origine incendie PRG_APPAREIL_ORIGINE_INC
     * @request GET:/SelectList/AppareilsOrigineIncendie
     * @secure
     */
    appareilsOrigineIncendieList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/AppareilsOrigineIncendie`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name CaracteristiquesVolList
     * @summary Liste des caractéristiques du vol PRG_CARACTERISTIQUES_VOL
     * @request GET:/SelectList/CaracteristiquesVol
     * @secure
     */
    caracteristiquesVolList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/CaracteristiquesVol`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name DatesTravauxList
     * @summary Liste des dates de travaux PRG_DATE_TRAVAUX
     * @request GET:/SelectList/DatesTravaux
     * @secure
     */
    datesTravauxList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/DatesTravaux`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name DecouvertesSinistreList
     * @summary Liste des personnes ayant découvert le sinistre PRG_DECOUVERTE_SINISTRE
     * @request GET:/SelectList/DecouvertesSinistre
     * @secure
     */
    decouvertesSinistreList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/DecouvertesSinistre`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name DommagesVolList
     * @summary Liste des dommages VOL PRG_DOMMAGE_VOL
     * @request GET:/SelectList/DommagesVol
     * @secure
     */
    dommagesVolList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/DommagesVol`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name EntreesProprieteList
     * @summary Liste des entrées d'une propriété PRG_ENTREE_PROPRIETE
     * @request GET:/SelectList/EntreesPropriete
     * @secure
     */
    entreesProprieteList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/EntreesPropriete`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name FuitesCanalisationList
     * @summary Liste des fuites sur canalisation PRG_FUITE_CANALISATION
     * @request GET:/SelectList/FuitesCanalisation
     * @secure
     */
    fuitesCanalisationList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/FuitesCanalisation`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name FuitesEnveloppeList
     * @summary Liste des fuites sur enveloppe PRG_FUITE_ENVELOPPE
     * @request GET:/SelectList/FuitesEnveloppe
     * @secure
     */
    fuitesEnveloppeList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/FuitesEnveloppe`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name JointsPeripheriquesList
     * @summary Liste des joints périphériques PRG_JOINT_PERIPHERIQUE
     * @request GET:/SelectList/JointsPeripheriques
     * @secure
     */
    jointsPeripheriquesList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/JointsPeripheriques`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypesVehiculesList
     * @summary Liste des types de véhicules PRG_TYPE_VEHICULE
     * @request GET:/SelectList/TypesVehicules
     * @secure
     */
    typesVehiculesList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TypesVehicules`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypesPieceList
     * @summary Liste des types de pièce PRG_TYPE_DE_PIECE
     * @request GET:/SelectList/TypesPiece
     * @secure
     */
    typesPieceList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/SelectList/TypesPiece`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name SpecificitesBailList
     * @summary Liste des spécificités du bail PRG_SPECIF_BAIL
     * @request GET:/SelectList/SpecificitesBail
     * @secure
     */
    specificitesBailList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/SpecificitesBail`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ReceptionsList
     * @summary Liste des réceptions PRG_RECEPTION
     * @request GET:/SelectList/Receptions
     * @secure
     */
    receptionsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/Receptions`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name RealisateursTravauxList
     * @summary Liste des réalisateurs de travaux PRG_REALISATEUR_TRAVAUX
     * @request GET:/SelectList/RealisateursTravaux
     * @secure
     */
    realisateursTravauxList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/RealisateursTravaux`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name QualificationsVolList
     * @summary Liste des qualifications Vol PRG_VOL_QUALIFICATION
     * @request GET:/SelectList/QualificationsVol
     * @secure
     */
    qualificationsVolList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/QualificationsVol`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name PointEntreeVolList
     * @summary Liste des points d'entrée Vol PRG_VOL_PT_ENTREE
     * @request GET:/SelectList/PointEntreeVol
     * @secure
     */
    pointEntreeVolList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/PointEntreeVol`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name OriginesCauseTngList
     * @summary Liste des origines cause TNG PRG_ORIGINE_CAUSE_TNG
     * @request GET:/SelectList/OriginesCauseTNG
     * @secure
     */
    originesCauseTngList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/OriginesCauseTNG`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name OriginesCauseIncList
     * @summary Liste des origines cause incendie PRG_ORIGINE_CAUSE_INC
     * @request GET:/SelectList/OriginesCauseINC
     * @secure
     */
    originesCauseIncList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/OriginesCauseINC`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name OriginesCauseList
     * @summary Liste des origines cause PRG_ORIGINE_CAUSE
     * @request GET:/SelectList/OriginesCause
     * @secure
     */
    originesCauseList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/OriginesCause`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name OptionGarantiesList
     * @summary Liste des options des garanties PRG_MAAF_GAR_OPTION
     * @request GET:/SelectList/OptionGaranties
     * @secure
     */
    optionGarantiesList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/OptionGaranties`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name MotifsNonGarantieList
     * @summary Liste des motifss de non garantie PRG_MOTIF_NON_GARANTIE
     * @request GET:/SelectList/MotifsNonGarantie
     * @secure
     */
    motifsNonGarantieList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/MotifsNonGarantie`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name NaturesBienGarantiesList
     * @summary Liste des natures de bien des garanties PRG_MAAF_GAR_NATURE_DU_BIEN
     * @request GET:/SelectList/NaturesBienGaranties
     * @secure
     */
    naturesBienGarantiesList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/NaturesBienGaranties`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name DetailsBienGarantiesList
     * @summary Liste des détails de bien des garanties PRG_MAAF_GAR_DETAIL_DU_BIEN
     * @request GET:/SelectList/DetailsBienGaranties
     * @secure
     */
    detailsBienGarantiesList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/DetailsBienGaranties`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name DescriptionDommagesList
     * @summary Liste des descriptions de dommages PRG_DESC_DOMMAGE
     * @request GET:/SelectList/DescriptionDommages
     * @secure
     */
    descriptionDommagesList: (params: RequestParams = {}) =>
      this.request<DoubleCodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/DescriptionDommages`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name DescriptionDommagesParticuliersList
     * @summary Liste des descriptions de dommages particuliers PRG_DESC_DOMMPART
     * @request GET:/SelectList/DescriptionDommagesParticuliers
     * @secure
     */
    descriptionDommagesParticuliersList: (params: RequestParams = {}) =>
      this.request<DoubleCodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/DescriptionDommagesParticuliers`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name DescriptionEndommagesList
     * @summary Liste des descriptions de dommages PRG_DESC_ENDOM
     * @request GET:/SelectList/DescriptionEndommages
     * @secure
     */
    descriptionEndommagesList: (params: RequestParams = {}) =>
      this.request<DoubleCodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/DescriptionEndommages`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name DocumentsGestionList
     * @summary Liste des documents d'un rapport PRG_GESTION_DOCUMENT
     * @request GET:/SelectList/DocumentsGestion
     * @secure
     */
    documentsGestionList: (params: RequestParams = {}) =>
      this.request<DocumentGestion[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/DocumentsGestion`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ListMesuresConservatoiresList
     * @summary Liste des documents d'un rapport PRG_GESTION_DOCUMENT
     * @request GET:/SelectList/ListMesuresConservatoires
     * @secure
     */
    listMesuresConservatoiresList: (params: RequestParams = {}) =>
      this.request<MesureConservatoire[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/ListMesuresConservatoires`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name CommentairesgarantiesAcquisesList
     * @summary Liste des commentaires sur les garanties acquises PRG_GAR_ACQUISE_COMMENTAIRES
     * @request GET:/SelectList/CommentairesgarantiesAcquises
     * @secure
     */
    commentairesgarantiesAcquisesList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/CommentairesgarantiesAcquises`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypesRapportDarvaCompagnieList
     * @summary Liste des types de rapport Darva disponibles pour une compagnie donnée dans la table PRG_CIE_RAPPORT_DARVA
     * @request GET:/SelectList/TypesRapportDarvaCompagnie
     * @secure
     */
    typesRapportDarvaCompagnieList: (params: RequestParams = {}) =>
      this.request<TypeRapportDarvaCompagnie[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TypesRapportDarvaCompagnie`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name FamillesArticlesEmbellissementList
     * @summary Liste des familles d'articles d'embellissement PRG_FAM_EMB_ARTICLE
     * @request GET:/SelectList/FamillesArticlesEmbellissement
     * @secure
     */
    famillesArticlesEmbellissementList: (params: RequestParams = {}) =>
      this.request<FamilleArticleEmbellissement[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/FamillesArticlesEmbellissement`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ArticlesEmbellissementList
     * @summary Liste des articles d'embellissement PRG_ARTICLE_EMBELLISSEMENT
     * @request GET:/SelectList/ArticlesEmbellissement
     * @secure
     */
    articlesEmbellissementList: (params: RequestParams = {}) =>
      this.request<ArticleEmbellissment[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/ArticlesEmbellissement`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ReparationsRexList
     * @summary Liste des REX_TYPE_REPARATION
     * @request GET:/SelectList/ReparationsREX
     * @secure
     */
    reparationsRexList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/ReparationsREX`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name EtatsRisqueRexList
     * @summary Liste des états du risque REX_ETAT_RISQUE
     * @request GET:/SelectList/EtatsRisqueREX
     * @secure
     */
    etatsRisqueRexList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/EtatsRisqueREX`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ReclamationsRexList
     * @summary Liste des réclamations REX REX_TYPE_RECLAM
     * @request GET:/SelectList/ReclamationsREX
     * @secure
     */
    reclamationsRexList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/ReclamationsREX`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name QuestionsRexList
     * @summary Liste des questions REX_QUESTIONS
     * @request GET:/SelectList/QuestionsREX
     * @secure
     */
    questionsRexList: (params: RequestParams = {}) =>
      this.request<QuestionREX[], ValidationProblemDetails>({
        path: `/SelectList/QuestionsREX`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name NaturesBienRexList
     * @summary Liste des natures de bien REX REX_NATURE_DU_BIEN
     * @request GET:/SelectList/NaturesBienREX
     * @secure
     */
    naturesBienRexList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/NaturesBienREX`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name DetailsBienRexList
     * @summary Liste des détails de bien REX REX_DETAIL_DU_BIEN
     * @request GET:/SelectList/DetailsBienREX
     * @secure
     */
    detailsBienRexList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/DetailsBienREX`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name FamillesArticlesEmbellissementRexList
     * @summary Liste des familles d'articles d'embellissement REX REX_FAM_EMB_ARTICLE
     * @request GET:/SelectList/FamillesArticlesEmbellissementREX
     * @secure
     */
    famillesArticlesEmbellissementRexList: (params: RequestParams = {}) =>
      this.request<FamilleArticleEmbellissement[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/FamillesArticlesEmbellissementREX`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ArticlesEmbellissementRexList
     * @summary Liste des articles d'embellissement REX_ARTICLE_EMBELLISSEMENT
     * @request GET:/SelectList/ArticlesEmbellissementREX
     * @secure
     */
    articlesEmbellissementRexList: (params: RequestParams = {}) =>
      this.request<ArticleEmbellissmentREX[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/ArticlesEmbellissementREX`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name CoefficientsDepartementList
     * @summary Liste des coefficients par département REX_COEF_DEPARTEMENT
     * @request GET:/SelectList/CoefficientsDepartement
     * @secure
     */
    coefficientsDepartementList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/CoefficientsDepartement`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name FraisAfferentsRexList
     * @summary Liste des frais afférents REX_9856_FRAIS_AFFERENTS
     * @request GET:/SelectList/FraisAfferentsREX
     * @secure
     */
    fraisAfferentsRexList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/FraisAfferentsREX`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name BordereauxCompagniesList
     * @summary Liste des boredereaux de compagnie REX REX_BORDEREAU_COMPAGNIE
     * @request GET:/SelectList/BordereauxCompagnies
     * @secure
     */
    bordereauxCompagniesList: (params: RequestParams = {}) =>
      this.request<BordereauCompagnieREX[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/BordereauxCompagnies`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name CoefficientsDepartmentsRexList
     * @summary Liste des coefficients par département REX REX_COEF_DEPARTEMENT
     * @request GET:/SelectList/CoefficientsDepartmentsREX
     * @secure
     */
    coefficientsDepartmentsRexList: (params: RequestParams = {}) =>
      this.request<CoefficientsDepartementsREX[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/CoefficientsDepartmentsREX`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypeRexSinistreList
     * @summary Liste des types de sinistre REX Traveller (DAB, Vol, ...)
     * @request GET:/SelectList/TypeRexSinistre
     * @secure
     */
    typeRexSinistreList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TypeRexSinistre`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypeReglementsList
     * @summary Liste des types de règlements
     * @request GET:/SelectList/TypeReglements
     * @secure
     */
    typeReglementsList: (params: RequestParams = {}) =>
      this.request<TypeReglement[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TypeReglements`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name JustificationsList
     * @summary Liste des types des justifications pour mobilier
     * @request GET:/SelectList/Justifications
     * @secure
     */
    justificationsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/Justifications`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ReparabilitesList
     * @summary Liste des types des réparabilités pour mobilier
     * @request GET:/SelectList/Reparabilites
     * @secure
     */
    reparabilitesList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/Reparabilites`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name CaracteresExperstiseSmsList
     * @summary Liste des caractères d'expertise pour SMS EVE_SMS_CARACTERE_EXPERTISE
     * @request GET:/SelectList/CaracteresExperstiseSMS
     * @secure
     */
    caracteresExperstiseSmsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/CaracteresExperstiseSMS`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name CompagniesSmsList
     * @summary Liste des SMS par compagnie EVE_SMS_COMPAGNIE
     * @request GET:/SelectList/CompagniesSMS
     * @secure
     */
    compagniesSmsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/CompagniesSMS`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name SourcesSmsList
     * @summary Liste des SMS par source EVE_SMS_SOURCE
     * @request GET:/SelectList/SourcesSMS
     * @secure
     */
    sourcesSmsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/SourcesSMS`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypesExpertiseSmsList
     * @summary Liste des SMS par type d'expertise EVE_SMS_TYPE_EXPERTISE
     * @request GET:/SelectList/TypesExpertiseSMS
     * @secure
     */
    typesExpertiseSmsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TypesExpertiseSMS`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypesSinistreSmsList
     * @summary Liste des SMS par type de sinistre EVE_SMS_TYPE_SINISTRE
     * @request GET:/SelectList/TypesSinistreSMS
     * @secure
     */
    typesSinistreSmsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TypesSinistreSMS`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name MessagesSmsList
     * @summary Messages EVE_MESSAGE_SMS
     * @request GET:/SelectList/MessagesSMS
     * @secure
     */
    messagesSmsList: (params: RequestParams = {}) =>
      this.request<MessageSMS[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/MessagesSMS`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name CaracteresExpertiseMailList
     * @summary Liste des entreprises mails EVE_MAIL_CARACTERE_EXPERTISE
     * @request GET:/SelectList/CaracteresExpertiseMail
     * @secure
     */
    caracteresExpertiseMailList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/CaracteresExpertiseMail`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name EntreprisesMailList
     * @summary Liste des entreprises mails EVE_MAIL_ENTREPRISE
     * @request GET:/SelectList/EntreprisesMail
     * @secure
     */
    entreprisesMailList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/EntreprisesMail`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypesMailList
     * @summary Liste des types de mails EVE_MAIL_TYPE
     * @request GET:/SelectList/TypesMail
     * @secure
     */
    typesMailList: (params: RequestParams = {}) =>
      this.request<TypeMail[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TypesMail`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name CaracteresExperstiseMailList
     * @summary Liste des caractères d'expertise pour mail EVE_MAIL_CARACTERE_EXPERTISE
     * @request GET:/SelectList/CaracteresExperstiseMail
     * @secure
     */
    caracteresExperstiseMailList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/CaracteresExperstiseMail`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name CompagniesMailList
     * @summary Liste des mails par compagnie EVE_MAIL_COMPAGNIE
     * @request GET:/SelectList/CompagniesMail
     * @secure
     */
    compagniesMailList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/CompagniesMail`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name SourcesMailList
     * @summary Liste des mails par source EVE_MAIL_SOURCE
     * @request GET:/SelectList/SourcesMail
     * @secure
     */
    sourcesMailList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/SourcesMail`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypesExpertiseMailList
     * @summary Liste des mails par type d'expertise EVE_MAIL_TYPE_EXPERTISE
     * @request GET:/SelectList/TypesExpertiseMail
     * @secure
     */
    typesExpertiseMailList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TypesExpertiseMail`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypesSinistreMailList
     * @summary Liste des mails par type de sinistre EVE_MAIL_TYPE_SINISTRE
     * @request GET:/SelectList/TypesSinistreMail
     * @secure
     */
    typesSinistreMailList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TypesSinistreMail`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name OccupationsList
     * @summary Liste des occupations EVE_OCCUPATION
     * @request GET:/SelectList/Occupations
     * @secure
     */
    occupationsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/Occupations`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ObjetsEchangesInternesList
     * @summary Liste des objets des messages internes EVE_MESSAGERIE_INTERNE
     * @request GET:/SelectList/ObjetsEchangesInternes
     * @secure
     */
    objetsEchangesInternesList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/ObjetsEchangesInternes`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name CommentairesEvenementsPredefinisList
     * @summary Liste des commentaires prédéfinis EVE_COMMENTAIRE
     * @request GET:/SelectList/CommentairesEvenementsPredefinis
     * @secure
     */
    commentairesEvenementsPredefinisList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/CommentairesEvenementsPredefinis`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypesRdvList
     * @summary Liste des types de rendez-vous EVE_TYPE_RDV
     * @request GET:/SelectList/TypesRDV
     * @secure
     */
    typesRdvList: (params: RequestParams = {}) =>
      this.request<TypeRDV[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TypesRDV`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypesMailsMaeList
     * @summary Liste des types de mails MAE
     * @request GET:/SelectList/TypesMailsMAE
     * @secure
     */
    typesMailsMaeList: (params: RequestParams = {}) =>
      this.request<TypeMailMAE[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TypesMailsMAE`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypesMailsAcmList
     * @summary Liste des types de mails ACM
     * @request GET:/SelectList/TypesMailsACM
     * @secure
     */
    typesMailsAcmList: (params: RequestParams = {}) =>
      this.request<TypeMailACM[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TypesMailsACM`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name SousCategoriesPjList
     * @summary Liste des sous catégorie de pièces jointes EVE_SS_CATEGORIE_PJ
     * @request GET:/SelectList/SousCategoriesPJ
     * @secure
     */
    sousCategoriesPjList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/SousCategoriesPJ`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name CategoriesPjList
     * @summary Liste des catégories de pièces jointes EVE_CATEGORIE_PJ
     * @request GET:/SelectList/CategoriesPJ
     * @secure
     */
    categoriesPjList: (query: CategoriesPjListParams, params: RequestParams = {}) =>
      this.request<CategoriePJ[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/CategoriesPJ`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name EtatsEskerList
     * @summary Liste des états ESKER EVE_ETAT_ESKER
     * @request GET:/SelectList/EtatsESKER
     * @secure
     */
    etatsEskerList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/EtatsESKER`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name EvenementsExterieursList
     * @summary Liste des évènements extérieurs EVE_EXTERIEUR
     * @request GET:/SelectList/EvenementsExterieurs
     * @secure
     */
    evenementsExterieursList: (params: RequestParams = {}) =>
      this.request<EvenementExterieur[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/EvenementsExterieurs`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ScenarioEskerList
     * @request GET:/SelectList/ScenarioEsker
     * @secure
     */
    scenarioEskerList: (params: RequestParams = {}) =>
      this.request<ScenarioEsker[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/ScenarioEsker`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name UsagesRiqueSinappsList
     * @summary Liste des usages de risque Sinapps SID_USAGE_RISQUE
     * @request GET:/SelectList/UsagesRiqueSinapps
     * @secure
     */
    usagesRiqueSinappsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/UsagesRiqueSinapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypesRiqueSinappsList
     * @summary Liste des types de risque Sinapps SID_TYPE_RISQUE
     * @request GET:/SelectList/TypesRiqueSinapps
     * @secure
     */
    typesRiqueSinappsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TypesRiqueSinapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypesIndicateursSinappsList
     * @summary Liste des types d'indicateurs Sinapps SID_TYPE_INDICATEUR
     * @request GET:/SelectList/TypesIndicateursSinapps
     * @secure
     */
    typesIndicateursSinappsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TypesIndicateursSinapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypesBienSinappsList
     * @summary Liste des types de bien Sinapps SID_TYPE_BIEN
     * @request GET:/SelectList/TypesBienSinapps
     * @secure
     */
    typesBienSinappsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TypesBienSinapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name QualitesJuridiquesSinappsList
     * @summary Liste des qualités juridiques Sinapps SID_QUALITE_JURIDIQUE
     * @request GET:/SelectList/QualitesJuridiquesSinapps
     * @secure
     */
    qualitesJuridiquesSinappsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/QualitesJuridiquesSinapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name QualifiantsMesureSinappsList
     * @summary Liste des qualifiants de mesures Sinapps SID_QUALIFIANT_MESURE
     * @request GET:/SelectList/QualifiantsMesureSinapps
     * @secure
     */
    qualifiantsMesureSinappsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/QualifiantsMesureSinapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name PositionsDommagesIndemnisationList
     * @summary Liste des position sur les dommages de l'indemnisation SID_POSITION_DOM_INDEM
     * @request GET:/SelectList/PositionsDommagesIndemnisation
     * @secure
     */
    positionsDommagesIndemnisationList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/PositionsDommagesIndemnisation`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name NaturesDommagesList
     * @summary Liste des natures de dommages Sinapps SID_NATURE_DOMMAGES
     * @request GET:/SelectList/NaturesDommages
     * @secure
     */
    naturesDommagesList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/NaturesDommages`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name NaturesBienSinappsList
     * @summary Liste des natures de bien Sinapps SID_NATURE_BIEN
     * @request GET:/SelectList/NaturesBienSinapps
     * @secure
     */
    naturesBienSinappsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/NaturesBienSinapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name DetailsBienSinappsList
     * @summary Liste des détails de bien Sinapps SID_DETAIL_BIEN
     * @request GET:/SelectList/DetailsBienSinapps
     * @secure
     */
    detailsBienSinappsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/DetailsBienSinapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name MotifsUrgenceSinappsList
     * @summary Liste des motifs d'urgence Sinapps SID_MOTIF_URGENCE
     * @request GET:/SelectList/MotifsUrgenceSinapps
     * @secure
     */
    motifsUrgenceSinappsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/MotifsUrgenceSinapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name MotifsReportRdvSinappsList
     * @summary Liste des motifs de report d'un RDV Sinapps SID_MOTIF_REPORT_RDV
     * @request GET:/SelectList/MotifsReportRDVSinapps
     * @secure
     */
    motifsReportRdvSinappsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/MotifsReportRDVSinapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name MotifsNonSuppressionCauseSinappsList
     * @summary Liste des motifs de non suppression de la cause Sinapps SID_MOTIF_NON_SUPP_CAUSE
     * @request GET:/SelectList/MotifsNonSuppressionCauseSinapps
     * @secure
     */
    motifsNonSuppressionCauseSinappsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/MotifsNonSuppressionCauseSinapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name MotifsFinPrestationSinappsList
     * @summary Liste des motifs de fin de prestation Sinapps SID_MOTIF_FIN_PRESTATION
     * @request GET:/SelectList/MotifsFinPrestationSinapps
     * @secure
     */
    motifsFinPrestationSinappsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/MotifsFinPrestationSinapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name MotifsNonVerificationRisqueSinappsList
     * @summary Liste des motifs de non vérification du risque Sinapps SID_MOTIF_NON_VERIF_RISQUE
     * @request GET:/SelectList/MotifsNonVerificationRisqueSinapps
     * @secure
     */
    motifsNonVerificationRisqueSinappsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/MotifsNonVerificationRisqueSinapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name MotifsRectificationSinappsList
     * @summary Liste des motifs de rectification Sinapps SID_MOTIF_RECTIFICATION
     * @request GET:/SelectList/MotifsRectificationSinapps
     * @secure
     */
    motifsRectificationSinappsList: (params: RequestParams = {}) =>
      this.request<MotifRectification[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/MotifsRectificationSinapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name EtapesSinappsList
     * @summary Liste des étapes Sinapps enumérateur
     * @request GET:/SelectList/EtapesSinapps
     * @secure
     */
    etapesSinappsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/EtapesSinapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name EtapesconcerneesSinappsList
     * @summary Liste des étapes concernées Sinapps enumérateur
     * @request GET:/SelectList/EtapesconcerneesSinapps
     * @secure
     */
    etapesconcerneesSinappsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/EtapesconcerneesSinapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name MotifsDifficultesSinappsList
     * @summary Liste des motifs de difficulté Sinapps SID_MOTIF_DIFFICULTE
     * @request GET:/SelectList/MotifsDifficultesSinapps
     * @secure
     */
    motifsDifficultesSinappsList: (params: RequestParams = {}) =>
      this.request<MotifDifficulte[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/MotifsDifficultesSinapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ModesIndemnisationSinappsList
     * @summary Liste des modes d'indemnisation Sinapps SID_MODE_INDEMNISATION
     * @request GET:/SelectList/ModesIndemnisationSinapps
     * @secure
     */
    modesIndemnisationSinappsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/ModesIndemnisationSinapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name CausesSinistreSinappsList
     * @summary Liste des causes de sinsitre Sinapps SID_CAUSE_SINISTRE
     * @request GET:/SelectList/CausesSinistreSinapps
     * @secure
     */
    causesSinistreSinappsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/CausesSinistreSinapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name CausesSinistreSinappsFiltreList
     * @summary Liste des causes de sinsitre Sinapps filtré SID_CAUSE_SINISTRE
     * @request GET:/SelectList/CausesSinistreSinappsFiltre
     * @deprecated
     * @secure
     */
    causesSinistreSinappsFiltreList: (query: CausesSinistreSinappsFiltreListParams, params: RequestParams = {}) =>
      this.request<CodeLabel[], ProblemDetails | ValidationProblemDetails>({
        path: `/SelectList/CausesSinistreSinappsFiltre`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name DetailsSinistreSinappsList
     * @summary Liste des détails de sinsitre Sinapps SID_DETAIL_SINISTRE
     * @request GET:/SelectList/DetailsSinistreSinapps
     * @secure
     */
    detailsSinistreSinappsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/DetailsSinistreSinapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name DetailsSinistreSinappsFiltreList
     * @summary Liste des détails de sinsitre Sinapps filtré SID_DETAIL_SINISTRE
     * @request GET:/SelectList/DetailsSinistreSinappsFiltre
     * @deprecated
     * @secure
     */
    detailsSinistreSinappsFiltreList: (query: DetailsSinistreSinappsFiltreListParams, params: RequestParams = {}) =>
      this.request<CodeLabel[], ProblemDetails | ValidationProblemDetails>({
        path: `/SelectList/DetailsSinistreSinappsFiltre`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name NaturesSinistreSinappsList
     * @summary Liste des natures de sinsitre Sinapps SID_NATURE_SINISTRE
     * @request GET:/SelectList/NaturesSinistreSinapps
     * @secure
     */
    naturesSinistreSinappsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/NaturesSinistreSinapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name NaturesCausesDetailsSinappsList
     * @summary Liste des natures/causes/details de sinsitre Sinapps
     * @request GET:/SelectList/NaturesCausesDetailsSinapps
     * @secure
     */
    naturesCausesDetailsSinappsList: (params: RequestParams = {}) =>
      this.request<NatureSinapps[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/NaturesCausesDetailsSinapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name JustificatifsSinappsList
     * @summary Liste des justificatifs Sinapps SID_JUSTIFICATIF
     * @request GET:/SelectList/JustificatifsSinapps
     * @secure
     */
    justificatifsSinappsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/JustificatifsSinapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name CanauxPrestationSinappsList
     * @summary Liste des canaux de prestation Sinapps pour sollicitations (complément de prestation) SID_CANAL_PRESTATION
     * @request GET:/SelectList/CanauxPrestationSinapps
     * @secure
     */
    canauxPrestationSinappsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/CanauxPrestationSinapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name NaturesDommageSinappsList
     * @summary Liste des natures dommage Sinapps pour sollicitations (complément de prestation) SID_NATURE_DOMMAGES
     * @request GET:/SelectList/NaturesDommageSinapps
     * @secure
     */
    naturesDommageSinappsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/NaturesDommageSinapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ConventionsSinappsList
     * @summary Liste des conventions Sinapps SID_CONVENTION
     * @request GET:/SelectList/ConventionsSinapps
     * @secure
     */
    conventionsSinappsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/ConventionsSinapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name RestesAFaireList
     * @summary Liste des restes à faire
     * @request GET:/SelectList/RestesAFaire
     * @secure
     */
    restesAFaireList: (params: RequestParams = {}) =>
      this.request<ResteAFaire[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/RestesAFaire`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name EvenementsCommandesList
     * @summary Liste des évènements et commandes SID_EVENEMENTS_ET_COMMANDES
     * @request GET:/SelectList/EvenementsCommandes
     * @secure
     */
    evenementsCommandesList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/EvenementsCommandes`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ApplicationGarantieList
     * @summary Liste des applications de garantie
     * @request GET:/SelectList/ApplicationGarantie
     * @secure
     */
    applicationGarantieList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/ApplicationGarantie`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypeAssureList
     * @summary Liste des types d'assuré (régime fiscal)
     * @request GET:/SelectList/TypeAssure
     * @secure
     */
    typeAssureList: (params: RequestParams = {}) =>
      this.request<TypeAssureEnumLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TypeAssure`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name RegimeFiscalList
     * @request GET:/SelectList/RegimeFiscal
     * @secure
     */
    regimeFiscalList: (params: RequestParams = {}) =>
      this.request<RegimeFiscalEnumLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/RegimeFiscal`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypeDeReparationList
     * @request GET:/SelectList/TypeDeReparation
     * @secure
     */
    typeDeReparationList: (params: RequestParams = {}) =>
      this.request<TypeDeReparationEnumLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TypeDeReparation`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name CommandesManuellesSinappsList
     * @summary Liste des commandes manuelles Sinapps
     * @request GET:/SelectList/CommandesManuellesSinapps
     * @secure
     */
    commandesManuellesSinappsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/CommandesManuellesSinapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name MotifDesactivationManuelleList
     * @summary Liste des motifs de désactivation manuelle du suivi d'information Sinapps
     * @request GET:/SelectList/MotifDesactivationManuelle
     * @secure
     */
    motifDesactivationManuelleList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/MotifDesactivationManuelle`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypeInterventionList
     * @summary Liste des types d'intervention Sinapps pour sollicitations
     * @request GET:/SelectList/TypeIntervention
     * @secure
     */
    typeInterventionList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TypeIntervention`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name NatureOperationList
     * @summary Liste des natures d'opération Sinapps pour sollicitations
     * @request GET:/SelectList/NatureOperation
     * @secure
     */
    natureOperationList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/NatureOperation`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TranchesIrsiSinappsList
     * @summary Liste des tanches IRSI Sinapps pour sollicitations
     * @request GET:/SelectList/TranchesIRSISinapps
     * @secure
     */
    tranchesIrsiSinappsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TranchesIRSISinapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name CriticitesList
     * @summary Liste des criticités Sinapps pour sollicitations
     * @request GET:/SelectList/Criticites
     * @secure
     */
    criticitesList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/Criticites`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name EntiteAMandaterList
     * @summary Liste des entités à mandater Sinapps pour sollicitations (je peux intervenir ?)
     * @request GET:/SelectList/EntiteAMandater
     * @secure
     */
    entiteAMandaterList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/EntiteAMandater`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypeSollicitationAutoriseesList
     * @summary Liste des types de sollicitations Sinapps
     * @request GET:/SelectList/TypeSollicitationAutorisees
     * @secure
     */
    typeSollicitationAutoriseesList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TypeSollicitationAutorisees`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name SousTypeInterventionList
     * @summary Liste des sous-types d'intervention pour sollicitations
     * @request GET:/SelectList/SousTypeIntervention
     * @secure
     */
    sousTypeInterventionList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/SousTypeIntervention`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name MotifClotureInsatisfactionList
     * @summary Liste des motifs de clôture d'une insatisfaction
     * @request GET:/SelectList/MotifClotureInsatisfaction
     * @secure
     */
    motifClotureInsatisfactionList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/MotifClotureInsatisfaction`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypesDocumentsSinappsList
     * @summary Liste des types de PJ Sinapps
     * @request GET:/SelectList/TypesDocumentsSinapps
     * @secure
     */
    typesDocumentsSinappsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TypesDocumentsSinapps`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name QualificationAlerteList
     * @request GET:/SelectList/QualificationAlerte
     * @secure
     */
    qualificationAlerteList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/QualificationAlerte`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name CirconstanceSinistreList
     * @request GET:/SelectList/CirconstanceSinistre
     * @secure
     */
    circonstanceSinistreList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/CirconstanceSinistre`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ComportementIntervenantList
     * @request GET:/SelectList/ComportementIntervenant
     * @secure
     */
    comportementIntervenantList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/ComportementIntervenant`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ContexteDossierList
     * @request GET:/SelectList/ContexteDossier
     * @secure
     */
    contexteDossierList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/ContexteDossier`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ElementContratList
     * @request GET:/SelectList/ElementContrat
     * @secure
     */
    elementContratList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/ElementContrat`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ExagerationList
     * @request GET:/SelectList/Exageration
     * @secure
     */
    exagerationList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/Exageration`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name PieceJustificativeList
     * @request GET:/SelectList/PieceJustificative
     * @secure
     */
    pieceJustificativeList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/PieceJustificative`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name CausesSuppressionIrsiList
     * @summary Liste des causes de suppression IRSI REC_9204_CAUSE_SUPPRIME
     * @request GET:/SelectList/CausesSuppressionIRSI
     * @secure
     */
    causesSuppressionIrsiList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/SelectList/CausesSuppressionIRSI`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name PrejudicesImmaterielsIrsiList
     * @summary Liste des préjudices immatériels IRSI REC_9859_PREJ_IMMAT
     * @request GET:/SelectList/PrejudicesImmaterielsIRSI
     * @secure
     */
    prejudicesImmaterielsIrsiList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/PrejudicesImmaterielsIRSI`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name BaremesIrsiList
     * @summary Liste des barêmes IRSI DOS_IRSI_BAREME
     * @request GET:/SelectList/BaremesIRSI
     * @secure
     */
    baremesIrsiList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/SelectList/BaremesIRSI`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TranchesIrsiList
     * @summary Liste des tranches IRSI DOS_IRSI_TRANCHE
     * @request GET:/SelectList/TranchesIRSI
     * @secure
     */
    tranchesIrsiList: (params: RequestParams = {}) =>
      this.request<TrancheIRSI[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TranchesIRSI`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name MotifsIrsiList
     * @summary Liste des motifs IRSI DOS_IRSI_MOTIF
     * @request GET:/SelectList/MotifsIRSI
     * @secure
     */
    motifsIrsiList: (params: RequestParams = {}) =>
      this.request<MotifIRSI[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/MotifsIRSI`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name AccordsChiffrageList
     * @request GET:/SelectList/AccordsChiffrage
     * @secure
     */
    accordsChiffrageList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/SelectList/AccordsChiffrage`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name FamilleNaturesSinistreIrsiList
     * @summary Liste des natures de sinistre IRSI
     * @request GET:/SelectList/FamilleNaturesSinistreIRSI
     * @secure
     */
    familleNaturesSinistreIrsiList: (params: RequestParams = {}) =>
      this.request<FamilleNatureSinistre[], ValidationProblemDetails>({
        path: `/SelectList/FamilleNaturesSinistreIRSI`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name UsagesBienParAssureIrsiList
     * @summary Liste des types des usages du bien par l'assuré IRSI RIS_9710_USAGE_DU_RISQUE
     * @request GET:/SelectList/UsagesBienParAssureIRSI
     * @secure
     */
    usagesBienParAssureIrsiList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/UsagesBienParAssureIRSI`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name StatutsImmeubleIrsiList
     * @summary Liste des types des statuts de l'immeuble IRSI RIS_9709_TYPE_DE_RISQUE
     * @request GET:/SelectList/StatutsImmeubleIRSI
     * @secure
     */
    statutsImmeubleIrsiList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/StatutsImmeubleIRSI`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name QualifiantsPersonneIrsiList
     * @summary Liste des qualifiants de personnes pour IRSI PEI_9720_QUALIFIANT
     * @request GET:/SelectList/QualifiantsPersonneIRSI
     * @secure
     */
    qualifiantsPersonneIrsiList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/QualifiantsPersonneIRSI`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name RolesPersonneIrsiList
     * @summary Liste des rôles des personnes pour IRSI PEI_9072_QUALITE
     * @request GET:/SelectList/RolesPersonneIRSI
     * @secure
     */
    rolesPersonneIrsiList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/RolesPersonneIRSI`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name AssureursIrsiList
     * @summary Liste des assureurs IRSI (compagnies) ANN_ANNUAIRE
     * @request GET:/SelectList/AssureursIRSI
     * @secure
     */
    assureursIrsiList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/AssureursIRSI`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypeExpertiseIrsiList
     * @summary Liste des types d'expertise IRSI
     * @request GET:/SelectList/TypeExpertiseIRSI
     * @secure
     */
    typeExpertiseIrsiList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TypeExpertiseIRSI`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name QualitesPersonneIrsiList
     * @summary Liste des qualités personne pour dossiers Irsi
     * @request GET:/SelectList/QualitesPersonneIrsi
     * @secure
     */
    qualitesPersonneIrsiList: (query: QualitesPersonneIrsiListParams, params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/QualitesPersonneIrsi`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name CompagniesList
     * @request GET:/SelectList/Compagnies
     * @secure
     */
    compagniesList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/SelectList/Compagnies`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ContratRecolteList
     * @summary Liste des contrats Récolte RCT_CONTRAT
     * @request GET:/SelectList/ContratRecolte
     * @secure
     */
    contratRecolteList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/ContratRecolte`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name FamillesCulturesRecolteList
     * @summary Liste des familles de culture Récolte RCT_FAMILLE_CULTURE
     * @request GET:/SelectList/FamillesCulturesRecolte
     * @secure
     */
    famillesCulturesRecolteList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/FamillesCulturesRecolte`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name EtatsDossierRecolteList
     * @summary Liste des états dossier Récolte RCT_ETAT_DOSSIER
     * @request GET:/SelectList/EtatsDossierRecolte
     * @secure
     */
    etatsDossierRecolteList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/EtatsDossierRecolte`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name EtatsConformiteRecolteList
     * @summary Liste des états de conformité Récolte RCT_ETAT_CONFORMITE
     * @request GET:/SelectList/EtatsConformiteRecolte
     * @secure
     */
    etatsConformiteRecolteList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/EtatsConformiteRecolte`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name GarantiesRecolteList
     * @summary Liste des garanties Récolte RCT_GARANTIE
     * @request GET:/SelectList/GarantiesRecolte
     * @secure
     */
    garantiesRecolteList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/GarantiesRecolte`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name CulturesRecolteList
     * @summary Liste des cultures Récolte RCT_CULTURES
     * @request GET:/SelectList/CulturesRecolte
     * @secure
     */
    culturesRecolteList: (params: RequestParams = {}) =>
      this.request<CultureRecolte[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/CulturesRecolte`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ContratsCompagnieGarantieRecolteList
     * @summary Liste des contrats par compagnie et par garantie Récolte RCT_CONTRAT_CIE_GAR
     * @request GET:/SelectList/ContratsCompagnieGarantieRecolte
     * @secure
     */
    contratsCompagnieGarantieRecolteList: (params: RequestParams = {}) =>
      this.request<ContratGarantieRecolte[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/ContratsCompagnieGarantieRecolte`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name FinsActionList
     * @summary Liste des fins d'action ACT_CODE_FIN_ACTION
     * @request GET:/SelectList/FinsAction
     * @secure
     */
    finsActionList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/FinsAction`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name CalculPrixRevientList
     * @summary Liste des calculs de prix de revient ACT_TABLE_CALCUL_PR
     * @request GET:/SelectList/CalculPrixRevient
     * @secure
     */
    calculPrixRevientList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/CalculPrixRevient`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name FamillesActionsList
     * @summary Liste des familles d'actions ACT_FAMILLE_ACTION
     * @request GET:/SelectList/FamillesActions
     * @secure
     */
    famillesActionsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/FamillesActions`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ListeActionsFamillesActionsDetail
     * @summary Liste des actions de la famille action passé en paramètre
     * @request GET:/SelectList/FamillesActions/{CodeFamille}/ListeActions
     * @secure
     */
    listeActionsFamillesActionsDetail: (codeFamille: string, params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/FamillesActions/${codeFamille}/ListeActions`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ActionsCommentaireList
     * @summary Liste des actions commentaire (message suivi)
     * @request GET:/SelectList/ActionsCommentaire
     * @secure
     */
    actionsCommentaireList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/ActionsCommentaire`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name GarantiesList
     * @summary Liste des garanties GAR_9215_GARANTIE
     * @request GET:/SelectList/Garanties
     * @secure
     */
    garantiesList: (params: RequestParams = {}) =>
      this.request<DoubleCodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/Garanties`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name GarantiesComplementairesList
     * @summary Liste des garanties complémntaires GAR_9073_GARANTIE_CPLT
     * @request GET:/SelectList/GarantiesComplementaires
     * @secure
     */
    garantiesComplementairesList: (params: RequestParams = {}) =>
      this.request<DoubleCodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/GarantiesComplementaires`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name QualifiantsGarantiesList
     * @summary Liste des qualifiants de garanties GAR_9707_QUALIFIANT_GARANTIE
     * @request GET:/SelectList/QualifiantsGaranties
     * @secure
     */
    qualifiantsGarantiesList: (params: RequestParams = {}) =>
      this.request<DoubleCodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/QualifiantsGaranties`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name QualifiantsFranchisesList
     * @summary Liste des qualifiants de franchises GAR_9708_QUALIFIANT_FRANCHISE
     * @request GET:/SelectList/QualifiantsFranchises
     * @secure
     */
    qualifiantsFranchisesList: (params: RequestParams = {}) =>
      this.request<DoubleCodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/QualifiantsFranchises`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TauxTvaList
     * @summary Liste des taux de TVA FAC_TAUX_DE_TVA
     * @request GET:/SelectList/TauxTVA
     * @secure
     */
    tauxTvaList: (params: RequestParams = {}) =>
      this.request<TauxTVA[], ValidationProblemDetails>({
        path: `/SelectList/TauxTVA`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  pastillesDossiers = {
    /**
     * No description
     *
     * @tags Sinapps
     * @name PastillesDossiersList
     * @summary Charge les pastilles associées aux dossiers Sinapps
     * @request GET:/PastillesDossiers
     * @secure
     */
    pastillesDossiersList: (query: PastillesDossiersListParams, params: RequestParams = {}) =>
      this.request<Pastilles[], ValidationProblemDetails>({
        path: `/PastillesDossiers`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  taches = {
    /**
     * No description
     *
     * @tags Taches
     * @name TachesList
     * @summary Liste des taches
     * @request GET:/Taches
     * @secure
     */
    tachesList: (query: TachesListParams, params: RequestParams = {}) =>
      this.request<Tache[], ValidationProblemDetails | ProblemDetails>({
        path: `/Taches`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Taches
     * @name TachesCreate
     * @request POST:/Taches
     * @secure
     */
    tachesCreate: (query: TachesCreateParams, data: TacheRapideRequest, params: RequestParams = {}) =>
      this.request<Tache[], ValidationProblemDetails | ProblemDetails>({
        path: `/Taches`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Taches
     * @name CountTachesList
     * @summary Compteur des taches
     * @request GET:/Taches/Count
     * @secure
     */
    countTachesList: (query: CountTachesListParams, params: RequestParams = {}) =>
      this.request<number, ValidationProblemDetails | ProblemDetails>({
        path: `/Taches/Count`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Taches
     * @name SolderTachesCreate
     * @summary Permet de solder une tache
     * @request POST:/Taches/Solder/{id}
     * @secure
     */
    solderTachesCreate: ({ id, ...query }: SolderTachesCreateParams, params: RequestParams = {}) =>
      this.request<any, ValidationProblemDetails | ProblemDetails>({
        path: `/Taches/Solder/${id}`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Taches
     * @name ReporterTachesCreate
     * @summary Permet de reporter une tache (travail administratif ou charte) à une date ultérieure
     * @request POST:/Taches/Reporter/{id}
     * @secure
     */
    reporterTachesCreate: ({ id, ...query }: ReporterTachesCreateParams, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Taches/Reporter/${id}`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),
  };
}

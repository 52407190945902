/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import TravelerLargeTitle from 'components/TravelerLargeTitle/TravelerLargeTitle'
import PaymentCard from './PaymentCard'

/* Type imports ------------------------------------------------------------- */
import type { TravelerForm } from 'types/TravelerForm'

/* Type declarations -------------------------------------------------------- */

/* Internal variables ------------------------------------------------------- */

/* Styled components -------------------------------------------------------- */
const FormContainer = styled.div`
  padding-bottom: 40px;
`

const PaymentTitle = styled(FormBoldTitle)`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
`

/* Component declaration ---------------------------------------------------- */
interface PaymentPageProps {}

const PaymentPage: React.FC<PaymentPageProps> = () => {
  const formikForm = useOutletContext<TravelerForm>()
  const navigate = useNavigate()
  const { caseId } = useParams<{caseId: string}>()

  const addPayment = (): void => {
    navigate(`/dossiers/${caseId}/traveller/nouveau/reglement`)
  }

  if (formikForm.values.acteurs === undefined) {
    return null
  }

  return (
    <FormContainer>
      <TravelerLargeTitle>
        Règlement
      </TravelerLargeTitle>
      <PaymentTitle>
        Liste des règlements
        <Button
          variant="contained"
          onClick={addPayment}
          disabled={formikForm.values.disabled}
        >
          Ajouter un règlement
        </Button>
      </PaymentTitle>
      {
        formikForm.values.acteurs.map((acteur, acteurIndex) => acteur.reglements?.map((payment, paymentIndex) => (
          <PaymentCard
            key={`${payment.id}-${acteurIndex}-${paymentIndex}`}
            payment={payment}
            personName={`${acteur.nom} ${acteur.prenom || ''}${acteur.libelle ? ` - ${acteur.libelle}` : ''}`}
          />
        )))
      }
    </FormContainer>
  )

}

export default PaymentPage

/* Module imports -------------------------------------- */
import {
  useAppSelector,
  useAppDispatch,
} from 'store/hooks'
import {
  openQuickActions,
  closeQuickActions,
  toggleQuickActions,
  setQuickActionsOpenState,
  selectQuickActionModal,
} from 'store/slices/quickActionModalSlice'

/* Type imports ------------------------------------------------------------- */
import type { QuickActionModalState } from 'store/slices/quickActionModalSlice'

/* useQuickActionModal hook return type --------------------- */
export interface UseQuickActionModalReturnType {
  toggleQuickActions: () => ReturnType<typeof toggleQuickActions>;
  openQuickActions: (payload: QuickActionModalState) => ReturnType<typeof openQuickActions>;
  closeQuickActions: () => ReturnType<typeof closeQuickActions>;
  setQuickActionsOpen: (pOpen: boolean) => ReturnType<typeof setQuickActionsOpenState>;
  state: QuickActionModalState;
}

/* useQuickActionModal hook implementation ------------------ */
export const useQuickActionModal = (): UseQuickActionModalReturnType => {
  const dispatch = useAppDispatch()

  const toggleQuickActionsDispatch = (): ReturnType<typeof toggleQuickActions> => dispatch(toggleQuickActions())
  const openQuickActionsDispatch = (payload: QuickActionModalState): ReturnType<typeof openQuickActions> => dispatch(openQuickActions(payload))
  const closeQuickActionsDispatch = (): ReturnType<typeof closeQuickActions> => dispatch(closeQuickActions())
  const setQuickActionsOpenStateDispatch = (pOpen: boolean): ReturnType<typeof setQuickActionsOpenState> => dispatch(setQuickActionsOpenState(pOpen))

  const modalState: QuickActionModalState = useAppSelector(selectQuickActionModal)

  return {
    toggleQuickActions: toggleQuickActionsDispatch,
    openQuickActions: openQuickActionsDispatch,
    closeQuickActions: closeQuickActionsDispatch,
    setQuickActionsOpen: setQuickActionsOpenStateDispatch,
    state: modalState,
  }
}

/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useNavigate } from 'react-router-dom'
import { isValidString } from 'helpers/isValidString'
import { useAppDispatch } from 'store/hooks'
import { setRouterLastPath } from 'store/slices/routerHistorySlice'

/* Component imports -------------------------------------------------------- */
import CaseItemContainer from 'components/CasesList/CaseItem/CaseItemContainer/CaseItemContainer'
import CaseItemTitleContainer from 'components/CasesList/CaseItem/CaseItemTitleContainer/CaseItemTitleContainer'
import CaseItemBarHorizontal from 'components/CasesList/CaseItem/CaseItemBar/CaseItemBarHorizontal'

/* Type imports ------------------------------------------------------------- */
import type {
  DossierRecherche,
  Pastille,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const SmallCaseItemContainer = styled(CaseItemContainer)`
  font-size: 14px !important;
`

const CaseInfoRowContainer = styled.div`
  display: grid;
  gap: 10px;
  align-items: center;
  justify-content: stretch;
  padding: 0.5rem 0px;

  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
`

const CaseInfoContainer = styled.div`
  display: flex;
  justify-content: left;
  padding: 0px 1rem;
  align-items: center;
`

const TiersContainer = styled.div`
  display: grid;
  justify-content: left;
  padding: 0px 1rem;
  align-items: center;
`

/* Component declaration ---------------------------------------------------- */
interface CaseSearcherItemProps {
  caseItem: DossierRecherche;
  disabled?: boolean;
  caseChips?: Pastille[];
  isFetchingChip: boolean;
}

const CaseSearcherItem: React.FC<CaseSearcherItemProps> = ({
  caseItem,
  disabled = false,
  caseChips = [],
  isFetchingChip,
}) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const onCaseClick: React.MouseEventHandler<HTMLDivElement> = () => {
    dispatch(setRouterLastPath(window.location.pathname))
    navigate(`/dossiers/${caseItem.id}`)
  }

  return (
    <SmallCaseItemContainer
      onClick={!disabled ? onCaseClick : undefined}
      disabled={disabled}
    >
      <CaseItemTitleContainer
        caseId={caseItem.id}
        reportId={`${caseItem.id}-1`}
        assure={`${caseItem.assure.nom} ${caseItem.assure.prenom}`}
        reportObjective={caseItem.objectifCharteRapport}
        company={caseItem.compagnie?.libelle || ''}
        caseChips={caseChips}
        isFetchingChip={isFetchingChip}
        disableCasePageButton
      />
      <CaseItemBarHorizontal />
      <CaseInfoRowContainer>
        <CaseInfoContainer>
          {isValidString(caseItem.assure.police) && `Police : ${caseItem.assure.police}`}
          <br />
          {`Dossier ${caseItem.compagnie?.libelle ?? 'Compagnie inconnue'}`}
          <br />
          {isValidString(caseItem.sinistre.reference) && `Sinistre : ${caseItem.sinistre.reference}`}
        </CaseInfoContainer>
        <CaseInfoContainer>
          {caseItem.sinistre.nature?.libelle}
          <br />
          {caseItem.sinistre.adresse?.adresse1}
          {' '}
          {caseItem.sinistre.adresse?.adresse2}
          {' '}
          {caseItem.sinistre.adresse?.adresse3}
          <br />
          {caseItem.sinistre.adresse?.codePostal}
          {' '}
          {caseItem.sinistre.adresse?.ville}
        </CaseInfoContainer>
        <TiersContainer>
          {
            caseItem.tiers.map((tier, index) => (
              <div key={`${tier.id}-${index}`}>
                { `Tiers ${index + 1} : ${tier.nom}` }
              </div>
            ))
          }
        </TiersContainer>
      </CaseInfoRowContainer>
    </SmallCaseItemContainer>
  )
}

export default CaseSearcherItem

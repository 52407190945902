/* Type imports ------------------------------------------------------------- */
import type { PieceJointe } from 'API/__generated__/Api'

/* Type declaration ------------------------------------ */
export type FileType = 'img' | 'pdf' | 'word' | 'other'

/* getMediaFileType helper function -------------------- */
export const getMediaFileType = (document: PieceJointe): FileType => {
  const fileType = document.fileType.split('/')

  if (fileType[0] === 'image') {
    return 'img'
  }
  else if (fileType[1] === 'msword') {
    return 'word'
  }
  else if (fileType[1] === 'pdf') {
    return 'pdf'
  }

  return 'other'
}

/* isEditableFileType helper function ------------------ */
export const isEditableFileType = (document: PieceJointe): boolean => {
  return (getMediaFileType(document) === 'img' || getMediaFileType(document) === 'pdf')
}

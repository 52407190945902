/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'
/* Module imports ----------------------------------------------------------- */
import { useGetSinappsCloseDissatisfactionReasonListQuery } from 'store/api'

/* Component imports -------------------------------------------------------- */
import { MenuItem } from '@mui/material'
import { Field } from 'formik'
import {
  Select,
  TextField,
} from 'formik-mui'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'
import Loader from 'components/Loader/Loader'

/* Type imports ------------------------------------------------------------- */
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'

/* Styled components -------------------------------------------------------- */
const IsCustomerSatisfiedContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 5px;
  align-items: center;
  justify-content: stretch;
`

/* Component declaration ---------------------------------------------------- */
interface QaSinappsCloseDissatisfactionProps {
  estSatisfait?: boolean;
  onChangeIsCustomerSatisfied: (value?: boolean) => void;
  options: SegmentedButtonOption[];
}

const QaSinappsCloseDissatisfaction: React.FC<QaSinappsCloseDissatisfactionProps> = ({
  estSatisfait,
  onChangeIsCustomerSatisfied,
  options,
}) => {

  const {
    currentData: reasonList = [],
    isFetching: isFetchingReasonList,
  } = useGetSinappsCloseDissatisfactionReasonListQuery()

  return (
    <>
      {isFetchingReasonList && <Loader />}
      <IsCustomerSatisfiedContainer>
        <FormBoldTitle required>
          Client satisfait
        </FormBoldTitle>
        <SegmentedButtons
          options={options}
          selectedOption={String(estSatisfait ?? true)}
          setSelectedOption={(newVal): void => onChangeIsCustomerSatisfied(newVal === 'true')}
        />
      </IsCustomerSatisfiedContainer>
      <FormBoldTitle>
        Motif clôture
      </FormBoldTitle>
      <Field
        component={Select}
        name="closeDissatisfaction.motif"
        displayEmpty
      >
        <MenuItem
          value=""
          disabled
        >
          Sélectionner
        </MenuItem>
        {
          reasonList.map((reason) => (
            <MenuItem
              value={reason.code}
              key={reason.code}
            >
              {reason.libelle}
            </MenuItem>
          ))
        }
      </Field>
      <FormBoldTitle>
        Commentaire
      </FormBoldTitle>
      <Field
        component={TextField}
        name="closeDissatisfaction.commentaire"
        placeholder="Votre message"
        rows={3}
        multiline
      />
    </>
  )
}

export default QaSinappsCloseDissatisfaction

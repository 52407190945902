/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'
/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { usePostCompleteTaskMutation } from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { toast } from 'react-toastify'
import CloseButton from 'components/CloseButton/CloseButton'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'
import CheckableButton from 'components/CheckableButton/CheckableButton'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikContextType,
  FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  SolderTachesCreateParams,
  Tache,
} from 'API/__generated__/Api'
import { TypeTache } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const completeTaskSchema = Yup.object<Shape<SolderTachesCreateParams>>({
}).required()

type CompleteTaskRequest = FormikContextType<SolderTachesCreateParams>

/* Internal variables ------------------------------------------------------- */

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin-bottom: 20px;
`

const FormButton = styled(SubmitFormButton)`
  margin-left: 0;
`

const CheckableButtonContainer = styled(CheckableButton)`
  margin-bottom: 15px;
`

/* Component declaration ---------------------------------------------------- */
interface TaskMessageCompleteModalProps {
  open: boolean;
  handleClose: () => void;
  task: Tache;
}

const TaskMessageCompleteModal: React.FC<TaskMessageCompleteModalProps> = ({
  open,
  handleClose,
  task,
}) => {
  const [
    submitCompleteTask,
  ] = usePostCompleteTaskMutation()

  const onSubmit = async (values: SolderTachesCreateParams, { setSubmitting, resetForm }: FormikHelpers<SolderTachesCreateParams>): Promise<void> => {
    const response = await submitCompleteTask(values)

    if (!isApiError(response)) {
      resetForm()
      handleClose()
    } else {
      toast.error('Une erreur est survenue.')
      setSubmitting(false)
    }
  }

  const formikForm: CompleteTaskRequest = useForm<SolderTachesCreateParams>(
    {
      initialValues: {
        type: task.typeTache,
        id: task.id,
        commentaire: '',
        toutesRelances: false,
      },
      onSubmit: onSubmit,
      validationSchema: completeTaskSchema,
    },
  )

  const handleValue = (type: string, value?: boolean): void => {
    formikForm.setFieldValue(type, value)
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitleContainer>
        Solder
        <CloseButton handleClose={handleClose} />
      </DialogTitleContainer>
      <Form form={formikForm}>
        <DialogContent>
          {
            task.typeTache === TypeTache.Relance &&
              <>
                <CheckableButtonContainer
                  checked={formikForm.values.toutesRelances === true}
                  onChange={(e, c): void => handleValue('toutesRelances', c ? true : undefined)}
                  label="Solder toutes les relances"
                  type="radio"
                />
                <CheckableButton
                  checked={formikForm.values.toutesRelances === true}
                  onChange={(e, c): void => handleValue('toutesRelances', c ? false : undefined)}
                  label="Solder seulement cette relance"
                  type="radio"
                />
              </>
          }
        </DialogContent>
        <DialogActionContainer>
          <FormButton
            variant="contained"
            type="submit"
            disabled={formikForm.isSubmitting}
          >
            {formikForm.isSubmitting ? <CircularProgress /> : 'Valider'}
          </FormButton>
        </DialogActionContainer>
      </Form>
    </Dialog>
  )
}

export default TaskMessageCompleteModal

/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useRef,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import { Calendar } from '@fullcalendar/core'
import timeGridPlugin from '@fullcalendar/timegrid'
import {
  useGetPlanningQuery,
  useGetPlanningTypesQuery,
} from 'store/api'
import { defaultFullCalendarOptions } from 'helpers/FullCalendarOptions'
import DateUtils from 'helpers/DateUtils'
import { isValidString } from 'helpers/isValidString'
import {
  useAppDispatch,
  useAppSelector,
  useAuthInfo,
} from 'store/hooks'
import {
  getPlanningState,
  setPlanningStartDate,
} from 'store/slices/planningSlice'

/* Component imports -------------------------------------------------------- */
import PageContainer from 'layouts/PageContainer/PageContainer'
import HeaderWithBackArrow from 'layouts/MainLayout/Headers/HeaderWithBackArrow'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'
import Loader from 'components/Loader/Loader'
import PlanningCalendarButtons from 'pages/PlanningPages/PlanningComponents/PlanningCalendarButtons/PlanningCalendarButtons'
import PlanningFilters from 'pages/PlanningPages/PlanningComponents/PlanningFilters/PlanningFilters'
import PlanningEventList from 'pages/PlanningPages/PlanningComponents/PlanningEventList/PlanningEventList'
import PlanningMap from './PlanningMap/PlanningMap'

/* Type imports ------------------------------------------------------------- */
import type { Map } from 'leaflet'
import type {
  Planning,
  TypeRdv,
} from 'API/__generated__/Api'
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'

/* Type declarations -------------------------------------------------------- */
type PlanningMapDay = 'lundi' | 'mardi' | 'mercredi' | 'jeudi' | 'vendredi' | 'samedi'

/* Internal variables ------------------------------------------------------- */
const days: PlanningMapDay[] = [ 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi' ]

const calendarElmt: HTMLElement = document.createElement('div')
calendarElmt.id = 'mock-full-calendar'

/* Styled components -------------------------------------------------------- */
const ButtonsContainer = styled.div`
  padding-top: 1rem;
`

const DateContainer = styled.div`
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;
  font-size: 1.3rem;
  margin-bottom: 5px;
  text-transform: uppercase;
  margin-top: 20px;
`

/* Component declaration ---------------------------------------------------- */
interface PlanningMapPageProps {}

const PlanningMapPage: React.FC<PlanningMapPageProps> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const authInfo = useAuthInfo()
  const planningState = useAppSelector(getPlanningState)
  const { eventDate, eventId } = useParams<{eventDate: string; eventId: string}>()
  const calendarRef: React.MutableRefObject<Calendar> = useRef<Calendar>(
    new Calendar(
      calendarElmt,
      {
        ...defaultFullCalendarOptions,
        plugins: [ timeGridPlugin ],
        initialView: 'timeGridWeek',
        initialDate: eventDate && eventId ? new Date(eventDate) : planningState?.startDate ? new Date(planningState.startDate) : new Date(),
      },
    ),
  )
  const [ mapRef, setMapRef ] = useState<Map | null>(null)
  const [ sortedTypes, setSortedTypes ] = useState<TypeRdv[]>([])
  const [ selectedEvent, setSelectedEvent ] = useState<string>('')
  const [ currentDay, setCurrentDay ] = useState<Date>(new Date())
  const [ selectedFilters, setSelectedFilters ] = useState<string[]>([])

  const {
    currentData: currentPlanningData = [],
    isFetching: isFetchingPlanningData,
  } = useGetPlanningQuery(
    {
      expert: authInfo?.currentCollaborateur?.refAnnuaire.refComplete || '',
      dateDebut: DateUtils.planningStartDateApi,
      dateFin: DateUtils.planningEndDateApi,
    },
    { skip: !isValidString(authInfo?.currentCollaborateur?.refAnnuaire.refComplete) },
  )
  const {
    currentData: currentTypes = [],
    isFetching: isFetchingTypes,
  } = useGetPlanningTypesQuery()

  useEffect(() => {
    if (eventDate) {
      setCurrentDay(new Date(eventDate))
      if (eventId) {
        setSelectedEvent(eventId)
      }
    } else if (planningState.startDate) {
      setCurrentDay(new Date(planningState.startDate))
    } else {
      setCurrentDay(new Date())
    }
  }, [])

  useEffect(() => {
    if (!isFetchingTypes) {
      const types = [ ...currentTypes ].sort((a, b) => b.type.localeCompare(a.type))
      setSortedTypes(types)
    }
  }, [
    currentTypes,
    isFetchingTypes,
  ])

  const updateCalendarView = (today: boolean = false): void => {
    if (calendarRef !== null) {
      dispatch(setPlanningStartDate(calendarRef.current.view.currentStart.toISOString()))
      if (today) {
        setCurrentDay(new Date())
      }
      else {
        setCurrentDay(calendarRef.current.view.currentStart)
      }
    }
  }

  const onPreviousClicked: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (calendarRef !== null) {
      calendarRef.current.prev()
      updateCalendarView()
    }
  }

  const onNextClicked: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (calendarRef !== null) {
      calendarRef.current.next()
      updateCalendarView()
    }
  }

  const onTodayClicked: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (calendarRef !== null) {
      calendarRef.current.today()
      updateCalendarView(true)
    }
  }

  const onDateClicked = (value: Date | null) => {
    if (calendarRef !== null && value !== null) {
      calendarRef.current.gotoDate(value)
      updateCalendarView()
    }
  }

  const onCalendarOptionClick = (pOption: PlanningMapDay): void => {
    const newDay: Date = new Date(calendarRef.current.view.activeStart)
    const currentDay = new Date(newDay.setDate(newDay.getDate() + days.indexOf(pOption)))
    setCurrentDay(currentDay)
    dispatch(setPlanningStartDate(currentDay.toISOString()))
  }

  const filterEvents = (event: Planning): boolean => {
    const { activeStart, activeEnd } = calendarRef.current.view
    const startDate: Date = DateUtils.apiStrToDate(event.dateDebut)
    const endDate: Date = DateUtils.apiStrToDate(event.dateFin)

    return startDate >= activeStart && endDate < activeEnd && startDate.getDate() === currentDay.getDate() &&
      (!selectedFilters?.length || selectedFilters.includes(event.typeRDV?.code))
  }

  const setSelectedEventAndScroll = (event: Planning, scrollToTop: boolean = false): void => {
    const view: HTMLElement | null = document.getElementById('calendar-map-view')

    if (scrollToTop) {
      view?.scroll({ top: 150, left: 0, behavior: 'smooth' })
    }

    setSelectedEvent(event.id)
  }

  const getPrettyDate = (pDate: Date): string => {
    const dateStr: string = pDate.toLocaleDateString(
      'fr',
      {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      },
    )

    return dateStr
  }

  const filteredEvents: Planning[] = currentPlanningData.filter((e: Planning): boolean => filterEvents(e)).sort((a, b) => a.dateDebut.localeCompare(b.dateDebut))

  const calendarOptions: SegmentedButtonOption[] = days.map((option) => ({ value: option, label: option.charAt(0).toUpperCase() + option.slice(1), ref: React.createRef() }))

  return (
    <>
      {isFetchingPlanningData && <Loader />}
      <HeaderWithBackArrow
        onClick={() => navigate('/planning')}
        title="Carte"
      />
      <PageContainer id="calendar-map-view">
        <PlanningCalendarButtons
          calendarViewTitle={calendarRef?.current.view.title || ''}
          onPreviousClicked={onPreviousClicked}
          onTodayClicked={onTodayClicked}
          onNextClicked={onNextClicked}
          dateToClick={calendarRef?.current?.view.currentStart}
          onDateClicked={onDateClicked}
        />
        <ButtonsContainer>
          <SegmentedButtons
            options={calendarOptions}
            selectedOption={currentDay.toLocaleDateString('fr', { weekday: 'long' })}
            setSelectedOption={onCalendarOptionClick as ((pOption: string) => void)}
          />
          <DateContainer id="planning-map-current-day">
            {getPrettyDate(currentDay)}
          </DateContainer>
        </ButtonsContainer>
        <PlanningFilters
          filters={sortedTypes}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
        <PlanningMap
          events={filteredEvents}
          mapRef={mapRef}
          setMapRef={setMapRef}
          setSelectedEvent={setSelectedEventAndScroll}
          selectedEvent={selectedEvent}
        />
        <PlanningEventList
          events={filteredEvents}
          setSelectedEvent={setSelectedEventAndScroll}
          selectedEvent={selectedEvent}
          displayOrder
        />
      </PageContainer>
    </>
  )
}

export default PlanningMapPage

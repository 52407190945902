/* Framework imports -------------------------------------------------------- */
import type React from 'react'

/* Module imports ----------------------------------------------------------- */
import { useCaseDataService } from 'services/OfflineDataService'

/* Component imports -------------------------------------------------------- */

/* Type imports ------------------------------------------------------------- */

/* Component declaration ---------------------------------------------------- */
interface DownloadOfflineActiveProps {
  caseId: string;
}

const DownloadOfflineActive: React.FC<DownloadOfflineActiveProps> = ({ caseId }) => {
  useCaseDataService(caseId)

  return null
}

export default DownloadOfflineActive

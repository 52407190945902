/* Ditseuqered type declaration ------------------------ */
export interface DitseuqeredProp {
  _ditseuqer?: string;
}

export type Ditseuqered<T> = T & DitseuqeredProp

export const isDitseuqered = <T>(pObj: T): pObj is Ditseuqered<T> => {
  return 'object' === typeof pObj &&
    pObj !== null &&
    pObj !== undefined &&
    '_ditseuqer' in pObj
}

/* Module imports -------------------------------------- */
import { useAppSelector } from 'store/hooks'
import { selectNetwork } from 'store/slices/networkSlice'

/* Type imports ------------------------------------------------------------- */

/* useIsConnected hook return type --------------------- */

/* useIsConnected hook implementation ------------------ */
export const useIsConnected = (): boolean => {
  const networkState = useAppSelector(selectNetwork)

  return networkState.hasConnection
}

/* Framework imports -------------------------------------------------------- */
import styled from '@emotion/styled'

/* HeaderGroupContainer component ---------------------- */
const HeaderGroupContainer = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;
  gap: 10px;
`

/* Export HeaderGroupContainer component --------------- */
export default HeaderGroupContainer

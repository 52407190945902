/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'
/* Module imports ----------------------------------------------------------- */
import { isValidString } from 'helpers/isValidString'
import DateUtils from 'helpers/DateUtils'
import { useIsConnected } from 'helpers/hooks/useIsConnected'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  CardContent,
} from '@mui/material'
import CaseItemTitleContainer from 'components/CasesList/CaseItem/CaseItemTitleContainer/CaseItemTitleContainer'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'
import CompleteTaskModal from './CompleteTaskModal/CompleteTaskModal'
import PostponeTaskModal from './PostponeTaskModal/PostponeTaskModal'

/* Type imports ------------------------------------------------------------- */
import type {
  Tache,
  Pastille,
} from 'API/__generated__/Api'
import { TypeTache } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */

/* Internal variables ------------------------------------------------------- */

/* Styled components -------------------------------------------------------- */
const CardContainer = styled(Card)`
  margin-top: 20px;
  margin-bottom: 20px;
`

const CardTitleContainer = styled(CardContent)`
  padding: 10px 5px 0px 10px;
`

const BoldSeparator = styled.div`
  width: calc(100% + 40px);
  margin-left: -20px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
  margin-top: 10px;
`

const BoldText = styled.div`
  font-weight: bold;
  display: inline;
`

const CardContentContainer = styled(CardContent)`
  display: flex;
  justify-content: space-between;
`

/* Component declaration ---------------------------------------------------- */
interface TaskMessageProps {
  task: Tache;
  caseChips?: Pastille[];
  isFetchingChip: boolean;
}

const TaskMessage: React.FC<TaskMessageProps> = ({
  task,
  caseChips = [],
  isFetchingChip,
}) => {
  const isConnected = useIsConnected()
  const [ openModal, setOpenModal ] = useState<'complete' | 'postpone' | ''>('')

  return (
    <CardContainer>
      <CardTitleContainer>
        <CaseItemTitleContainer
          caseId={task.dossier.id}
          reportId={`${task.dossier.id}-1`}
          assure={task.dossier.nomAssure}
          reportObjective={task.dossier.objectifCharteRapport}
          company={task.dossier.mandant}
          caseChips={caseChips}
          isFetchingChip={isFetchingChip}
        />
      </CardTitleContainer>
      <BoldSeparator />
      <CardContentContainer>
        <div>
          <BoldText>
            {task.libelle}
          </BoldText>
          {
            isValidString(task.prevuPour) && (
              <div>
                Échéance :
                {' '}
                {DateUtils.apiStrToLocalDateString(task.prevuPour)}
              </div>
            )
          }
        </div>
        <div>
          <SubmitFormButton
            variant="contained"
            onClick={() => setOpenModal('complete')}
            disabled={!isConnected}
          >
            Solder
          </SubmitFormButton>
          {
            task.typeTache !== TypeTache.Relance &&
              <SubmitFormButton
                variant="outlined"
                onClick={() => setOpenModal('postpone')}
                disabled={!isConnected}
              >
                Reporter
              </SubmitFormButton>
          }
        </div>
      </CardContentContainer>
      <CompleteTaskModal
        open={openModal === 'complete'}
        handleClose={() => setOpenModal('')}
        task={task}
      />
      <PostponeTaskModal
        open={openModal === 'postpone'}
        handleClose={() => setOpenModal('')}
        task={task}
      />
    </CardContainer>
  )
}

export default TaskMessage

/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */

/* Component imports -------------------------------------------------------- */
import HeaderContainer from 'layouts/MainLayout/Headers/HeadersComponents/HeaderContainer'
import HeaderGroupContainer from 'layouts/MainLayout/Headers/HeadersComponents/HeaderGroupContainer'
import GoToCasePageButton from 'components/IconButtons/GoToCasePageButton/GoToCasePageButton'
import OpenQuickActionsButton from 'components/IconButtons/OpenQuickActionsButton/OpenQuickActionsButton'
import BackButton from 'components/BackButton/BackButton'

/* Type imports ------------------------------------------------------------- */

/* HeaderTitleContainer component ---------------------- */
const HeaderTitleContainer = styled.div`
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;

  display: flex;
  align-items: center;
  font-size: 1rem;
`

const TitleContainer = styled.div`
  font-weight: bold;

  font-size: 1rem;
  text-transform: none;
`

const ButtonGroupContainer = styled(HeaderGroupContainer)`
  gap: 5px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseSidebarHeaderSubMenuProps {
  title: string;
  caseId: string;
  setOpenedMenu: () => void;
}

const CaseSidebarHeaderSubMenu: React.FC<CaseSidebarHeaderSubMenuProps> = ({
  title,
  caseId,
  setOpenedMenu,
}) => {

  return (
    <HeaderContainer>
      <HeaderGroupContainer>
        <HeaderTitleContainer>
          <BackButton onClick={setOpenedMenu}>
            <TitleContainer>
              {title}
              {caseId}
            </TitleContainer>
          </BackButton>
        </HeaderTitleContainer>
      </HeaderGroupContainer>
      <ButtonGroupContainer>
        <GoToCasePageButton
          caseId={caseId}
          variant="outlined"
        />
        <OpenQuickActionsButton caseId={caseId} />
      </ButtonGroupContainer>
    </HeaderContainer>
  )
}

export default CaseSidebarHeaderSubMenu

/* Framework imports -------------------------------------------------------- */
import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import DateUtils from 'helpers/DateUtils'
import {
  useGetCaseEventDocumentsQuery,
  useGetEventDocumentsFamilleActionListQuery,
  useLazyGetEventDocumentSfdtQuery,
  usePostDeleteEventDocumentMutation,
  usePostNewEventDocumentMutation,
} from 'store/api'
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks'
import { setCourriers } from 'store/slices/courrierSlice'
import { getCaseSelectedReport } from 'store/slices/reportSlice'
import { isValidString } from 'helpers/isValidString'
import { useIsConnected } from 'helpers/hooks/useIsConnected'
import { isApiResponse } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import { Field } from 'formik'
import { Select } from 'formik-mui'
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
} from '@mui/material'
import { toast } from 'react-toastify'
import DocumentStatusButton from 'layouts/TravelerLayout/DocumentsModal/DocumentStatusButton/DocumentStatusButton'
import CloseButton from 'components/CloseButton/CloseButton'
import CheckableCollapseCard from 'components/CheckableCollapseCard/CheckableCollapseCard'
import VignetteRenderer from 'components/MediaRenderer/VignetteRenderer'
import LoadingOverlay from 'components/Loader/LoadingOverlay'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikHelpers,
  FormikContextType,
} from 'formik'
import { DocumentTypes } from 'types/Document'
import type {
  DataDocument,
  PieceJointe,
} from 'API/__generated__/Api'
import {
  TypePJ,
  TypeEnregistrementCourrier,
  TypeEvenementiel,
} from 'API/__generated__/Api'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type { ApiResponse } from 'helpers/fetchHelpers'

/* Type declarations -------------------------------------------------------- */
interface CourrierProperties {
  codeAction: string;
  selected: boolean;
}

interface CourrierRequest {
  courriers: CourrierProperties[];
}

const courrierSchema = Yup.object<Shape<CourrierRequest>>({
  courriers: Yup.array(Yup.object<Shape<CourrierProperties>>({
    codeAction: Yup.string().when('selected', {
      is: true,
      then: (schema) => schema.required('Le type de courrier est obligatoire'),
    }),
    selected: Yup.boolean(),
  })).required(),
}).required()

type NewCourrierForm = FormikContextType<CourrierRequest>

/* Internal variables ------------------------------------------------------- */

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;
`

const CardContainer = styled(Card)`
  height: 150px;
  margin-bottom: 15px;
  overflow-x: overlay;
`

const CardContentContainer = styled(CardContent)`
  display: flex;
  padding: 10px !important;
  height: 100%;
`

const DocumentContainer = styled.div`
  width: 100%;
  display: grid;
  margin-left:  10px;
  grid-template-columns: 1fr;
  align-items: stretch;
  justify-content: stretch;
`

const DescriptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const ButtonContainer = styled.div`
  height: min-content;
  align-self: end;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  align-items: stretch;
  justify-content: stretch;
`

const DocumentTypeContainer = styled.div`
  font-size: 16px;
  font-weight: bold;
`

const DocumentDateContainer = styled.div`
  font-size: 12px;
`

const GenerateButtonContainer = styled.div`
  text-align: center;

  button {
    width: 120px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface DocumentsModalProps {
  open: boolean;
  handleClose: () => void;
  disabled: boolean;
  mandant: string;
}

const DocumentsModal: React.FC<DocumentsModalProps> = ({
  open,
  handleClose,
  disabled,
  mandant,
}) => {
  const isConnected = useIsConnected()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { caseId = '' } = useParams<{caseId: string}>()
  const selectedReport: string | null = useAppSelector(getCaseSelectedReport(caseId))

  const {
    currentData: eventDocuments = [],
    isFetching: isFetchingEventDocuments,
  } = useGetCaseEventDocumentsQuery({ dossier: caseId, avecBrouillons: true })
  const {
    currentData: familleActionList = [],
    isFetching: isFetchingFamilleActionList,
  } = useGetEventDocumentsFamilleActionListQuery({ dossier: caseId, filtreEvenementiel: TypeEvenementiel.Rapport })
  const [
    submitNewDocument,
  ] = usePostNewEventDocumentMutation()
  const [
    deleteDocument,
  ] = usePostDeleteEventDocumentMutation()
  const [
    getEventDocumentSfdt,
    { isFetching: isFetchingEventDocumentSfdt },
  ] = useLazyGetEventDocumentSfdtQuery()

  const sendCourriers = async (courriers: CourrierProperties[]) => {
    const allData: DataDocument[] = []
    for (let index = 0; index < courriers.length; index++) {
      if (courriers[index].selected) {

        const response: ApiResponse<DataDocument> = await submitNewDocument({
          dossier: caseId,
          data: {
            codeAction: courriers[index].codeAction,
            compteurSequence: parseInt(selectedReport?.split('-')[selectedReport?.split('-').length - 1] ?? '1') ?? 1,
            destinataire: { id: mandant },
            typeEnregistrement: TypeEnregistrementCourrier.Visualisation,
          },
        })
        if (isApiResponse<DataDocument>(response)) {
          allData.push(response.data)
        } else {
          toast.error(`Une erreur est survenue lors du chargement du document ${DocumentTypes[index].libelle}`)
        }
      }
    }
    if (allData.length > 0) {
      dispatch(setCourriers(allData))
      navigate(`/dossiers/${caseId}/traveller/actions/document`)
    }
  }

  const onSubmit = (values: CourrierRequest, { setSubmitting }: FormikHelpers<CourrierRequest>): void => {
    sendCourriers(values.courriers).then(() => setSubmitting(false)).catch(console.error)
  }

  const formikForm: NewCourrierForm = useForm<CourrierRequest>(
    {
      initialValues: {
        courriers: DocumentTypes.map(() => ({ codeAction: '', selected: false })),
      },
      validationSchema: courrierSchema,
      onSubmit: onSubmit,
    },
  )

  const onDeleteClick = (file: PieceJointe) => {
    deleteDocument({ id: file.id || '', typePJ: file.type }).catch(console.error)
  }

  const onViewClick = (url?: string | null) => {
    if (isValidString(url)) {
      window.open(url || '', '_blank', 'noreferrer')
    }
  }

  const onEditClick = (id: string) => {
    getEventDocumentSfdt(id)
      .then(({ data, isError }) => {
        if (data?.jsonData && !isError) {
          dispatch(setCourriers([ { ...data, docId: id } ]))
          navigate(`/dossiers/${caseId}/traveller/actions/document`)
        } else {
          toast.error(`Une erreur est survenue lors du chargement du document`)
        }
      })
      .catch(console.error)
  }

  const isLoading = useMemo(() => isFetchingEventDocuments || isFetchingFamilleActionList || isFetchingEventDocumentSfdt, [ isFetchingEventDocuments, isFetchingFamilleActionList, isFetchingEventDocumentSfdt ])

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      maxWidth="sm"
      fullWidth
    >
      <Form form={formikForm}>
        <DialogTitleContainer>
          Documents
          <CloseButton handleClose={handleClose} />
        </DialogTitleContainer>
        <DialogContent>
          <LoadingOverlay isLoading={isLoading}>
            {
              eventDocuments.filter((value) => DocumentTypes.some((type) => type.code === value.familleAction?.code)).map((value, index) => (
                <CardContainer key={`${value.id}-${index}`}>
                  <CardContentContainer>
                    <VignetteRenderer
                      document={value}
                      height="auto"
                      width="100px"
                    />
                    <DocumentContainer>
                      <DescriptionContainer>
                        <div>
                          <DocumentTypeContainer>
                            {value.libelle}
                          </DocumentTypeContainer>
                          <DocumentDateContainer>
                            {
                              `Dernière modification le ${DateUtils.apiStrToLocalDateString(value.dateEdition)} à \
                              ${DateUtils.apiStrToLocalTimeString(value.dateEdition, { second: undefined, hour: '2-digit', minute: '2-digit' })}`
                            }
                          </DocumentDateContainer>
                        </div>
                        <DocumentStatusButton
                          status={value.type}
                          disabled={disabled}
                        />
                      </DescriptionContainer>
                      <ButtonContainer>
                        <Button
                          variant="outlined"
                          fullWidth
                          disabled={disabled || value.type !== TypePJ.DocEvenementielBrouillon}
                          onClick={() => onDeleteClick(value)}
                        >
                          Supprimer
                        </Button>
                        <Button
                          variant="outlined"
                          fullWidth
                          // disabled={disabled || value.type !== TypePJ.DocEvenementiel}
                          disabled
                        >
                          Signer
                        </Button>
                        <Button
                          variant="outlined"
                          fullWidth
                          disabled={disabled || value.type !== TypePJ.DocEvenementiel}
                        >
                          Envoyer
                        </Button>
                        <Button
                          variant="contained"
                          fullWidth
                          disabled={value.type === TypePJ.DocEvenementiel ? false : (disabled || !isConnected)}
                          onClick={() => value.type === TypePJ.DocEvenementiel ? onViewClick(value.url) : onEditClick(value.id || '')}
                        >
                          {value.type === TypePJ.DocEvenementiel ? 'Voir' : 'Modifier'}
                        </Button>
                      </ButtonContainer>
                    </DocumentContainer>
                  </CardContentContainer>
                </CardContainer>
              ))
            }
            {
              DocumentTypes.map((value, index) => (
                <CheckableCollapseCard
                  key={value.code}
                  checked={formikForm.values.courriers?.[index]?.selected ?? false}
                  onChange={() => formikForm.setFieldValue(`courriers[${index}].selected`, !formikForm.values.courriers[index].selected)}
                  label={value.libelle}
                  addType
                >
                  <Field
                    component={Select}
                    name={`courriers[${index}].codeAction`}
                    size="small"
                    disabled={disabled}
                    displayEmpty
                    renderValue={verifySelectFieldValue(formikForm.values.courriers[index]?.codeAction)}
                  >
                    {
                      familleActionList.find((famille) => famille.code === value.code)?.actions?.map((value, index) => (
                        <MenuItem
                          value={value.code}
                          key={`${value.code}-${index}`}
                        >
                          {value.libelle}
                        </MenuItem>
                      ))
                    }
                  </Field>
                </CheckableCollapseCard>
              ))
            }
            <GenerateButtonContainer>
              <Button
                variant="contained"
                type="submit"
                disabled={disabled || !formikForm.values.courriers.some((courrier) => courrier.selected === true) || formikForm.isSubmitting}
              >
                {formikForm.isSubmitting ? <CircularProgress size={24} /> : 'Générer'}
              </Button>
            </GenerateButtonContainer>
          </LoadingOverlay>
        </DialogContent>
      </Form>
    </Dialog>
  )
}

export default DocumentsModal

/* Framework imports -------------------------------------------------------- */
import * as Yup from 'yup'

/* Type imports ------------------------------------------------------------- */
import type {
  CommandeCloturerInsatisfactionRequest,
  CommandeDemanderFinPrestationRequest,
  CommandeLeverFinPrestationRequest,
  CommandeSignalerUneDifficulteRequest,
  CommandeSolliciterIntervenantRequest,
  CommandeTraiterInsatisfactionRequest,
  CloturerSollicitationSinappsCreatePayload,
  LeverDifficulteSinappsCreatePayload,
} from 'API/__generated__/Api'
import type { ElementType } from './ElementType'

/* SinappsCommandList type declaration ---------------- */
export const SinappsCommandList = [
  'Demander fin prestation',
  'Lever fin prestation',
  'Signaler une difficulté',
  'Lever une difficulté',
  'Traiter une insatisfaction',
  'Cloturer une insatisfaction',
  'Cloturer une prestation (expertise libérale)',
  'Lever une cloture de prestation (expertise libérale)',
  'Solliciter un intervenant',
  'Clôturer une sollicitation',
] as const

/* SinappsCommandType type declaration ---------------- */
export type SinappsCommandType = ElementType<typeof SinappsCommandList>

export const SinappsCommandsSchema = Yup.object({
  command: Yup.mixed<SinappsCommandType>().required(),
  requestServiceEnd: Yup.mixed<CommandeDemanderFinPrestationRequest>().when('command', {
    is: 'Demander fin prestation',
    then: () => Yup.object({
      motif: Yup.string().required('Le motif est obligatoire'),
      commentaire: Yup.string().when('motif', {
        is: 'Autres',
        then: (schema) => schema.required('Le commentaire est obligatoire quand le motif est "Autres"'),
      }),
    }).required(),
  }),
  liftServiceEnd: Yup.mixed<CommandeLeverFinPrestationRequest>().when('command', {
    is: 'Lever fin prestation',
    then: () => Yup.mixed<CommandeLeverFinPrestationRequest>().required(),
  }),
  signalDifficulty: Yup.mixed<CommandeSignalerUneDifficulteRequest>().when('command', {
    is: 'Signaler une difficulté',
    then: () => Yup.object({
      etape: Yup.string().required("L'étape est obligatoire"),
      motif: Yup.string().required('La difficulté est obligatoire'),
      commentaire: Yup.string(),
      type: Yup.string().required(),
      datePrevisionnelleReprise: Yup.string().when('type', {
        is: 'DELAI',
        then: (schema) => schema.nullable().required('La date de reprise est obligatoire'),
      }),
    }).required(),
  }),
  liftDifficulty: Yup.mixed<LeverDifficulteSinappsCreatePayload>().when('command', {
    is: 'Lever une difficulté',
    then: (schema) => schema.required(),
  }),
  dealDissatisfaction: Yup.mixed<CommandeTraiterInsatisfactionRequest>().when('command', {
    is: 'Traiter une insatisfaction',
    then: () => Yup.object({
      commentaire: Yup.string().required('Le commentaire est obligatoire'),
      idFichiers: Yup.array(Yup.string()),
    }).required(),
  }),
  closeDissatisfaction: Yup.mixed<CommandeCloturerInsatisfactionRequest>().when('command', {
    is: 'Cloturer une insatisfaction',
    then: (schema) => schema.required(),
  }),
  solicitStakeholder: Yup.mixed<CommandeSolliciterIntervenantRequest>().when('command', {
    is: 'Solliciter un intervenant',
    then: (schema) => schema.required(),
  }),
  closeSolicitation: Yup.mixed<CloturerSollicitationSinappsCreatePayload>().when('command', {
    is: 'Clôturer une sollicitation',
    then: (schema) => schema.required(),
  }),
}).required()

export type SinappsCommandsRequest = Yup.InferType<typeof SinappsCommandsSchema>

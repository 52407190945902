/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'
import CaseSidebarTaskItem from './CaseSidebarTaskItem'
import CaseSidebarArrowTitle from '../CaseSidebarComponents/CaseSidebarArrowTitle/CaseSidebarArrowTitle'

/* Type imports ------------------------------------------------------------- */
import type { Tache } from 'API/__generated__/Api'

/* Internal variables ------------------------------------------------------- */
const ITEM_DEFAULT_LIMIT: number = 3

/* Component declaration ---------------------------------------------------- */
interface CaseSidebarTaskProps {
  tasks?: Tache[];
  isFetching: boolean;
  taskLimit?: number;
  setOpenedMenu: () => void;
}

const CaseSidebarTask: React.FC<CaseSidebarTaskProps> = ({
  tasks = [],
  isFetching,
  taskLimit = ITEM_DEFAULT_LIMIT,
  setOpenedMenu,
}) => {

  return (
    <div>
      <CaseSidebarArrowTitle
        setOpenedMenu={setOpenedMenu}
        title="Tâches (3 dernières)"
      />
      {
        isFetching ?
          <CircularProgress /> :
          tasks.slice(0, taskLimit).map((task: Tache, index: number) => (
            <CaseSidebarTaskItem
              key={`history-${task.libelle?.replace(/\s/g, '_')}-${index}`}
              task={task}
            />
          ))
      }
    </div>
  )
}

export default CaseSidebarTask

/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  // Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'

/* Component imports -------------------------------------------------------- */
import { SignatureComponent } from '@syncfusion/ej2-react-inputs'
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material'
import {
  BorderColorRounded,
  CircleRounded,
  DeleteRounded,
  FormatColorFillRounded,
  KeyboardArrowRightRounded,
  RedoRounded,
  UndoRounded,
} from '@mui/icons-material'
import PageContainer from 'layouts/PageContainer/PageContainer'
import HeaderAction from 'layouts/MainLayout/Headers/HeadersComponents/HeaderAction'
import SubmitFormButton from 'components/SubmitFormButton/SubmitFormButton'

/* Type imports ------------------------------------------------------------- */
import type { Signature } from '@syncfusion/ej2-react-inputs'
import type { FormikHelpers } from 'formik'

/* Type declarations -------------------------------------------------------- */
const notepadSchema = Yup.object({
  content: Yup.string(),
})

type NotepadRequest = Yup.InferType<typeof notepadSchema>

/* Internal variables ------------------------------------------------------- */
const presetColors: string[] = [ '#000000', '#ffffff', '#f44336', '#e91e63', '#673ab7', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#8bc34a', '#cddc39', '#ffeb3b' ]

/* Styled components -------------------------------------------------------- */
const NotepadContainer = styled(PageContainer)`
  padding: 0 !important;
`

const Notepad = styled(SignatureComponent)`
  width: 100%;
  height: 100%;
`

const NotepadButtonContainer = styled.div`
  padding: 10px;
  text-align: center;
  @media screen and (min-width: 450px) {
    display: flex;
    justify-content: center;
  }
`

const NotepadButton = styled(Button)`
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
`

const ColorGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  align-items: stretch;
  justify-content: stretch;
`

const ArrowRightIcon = styled(KeyboardArrowRightRounded)`
  margin-right: -10px;
  margin-left: 10px;
  svg {
    font-size: 30px;
  }
`

interface ColorProps {
  iconcolor: string;
}

const StrokeColorIcon = styled(BorderColorRounded)<ColorProps>`
  color: ${(props) => props.iconcolor};
`

const BackgroundColorIcon = styled(FormatColorFillRounded)<ColorProps>`
  color: ${(props) => props.iconcolor};
`

const ColorIcon = styled(CircleRounded)<ColorProps>`
  color: ${(props) => props.iconcolor};
  border: ${(props) => props.iconcolor === '#ffffff' ? '2px solid black' : 'none'};
  border-radius: 50%;
`

/* Component declaration ---------------------------------------------------- */
interface NotepadPageProps {}

const NotepadPage: React.FC<NotepadPageProps> = () => {
  const [ notepadRef, setNotepadRef ] = useState<Signature | null>(null)

  const [ anchorStrokeColorMenu, setAnchorStrokeColorMenu ] = useState<null | HTMLElement>(null)
  const openStrokeColorMenu = Boolean(anchorStrokeColorMenu)

  const [ anchorStrokeWidthMenu, setAnchorStrokeWidthMenu ] = useState<null | HTMLElement>(null)
  const openStrokeWidthMenu = Boolean(anchorStrokeWidthMenu)

  const [ anchorBackgroundColorMenu, setAnchorBackgroundColorMenu ] = useState<null | HTMLElement>(null)
  const openBackgroundColorMenu = Boolean(anchorBackgroundColorMenu)

  const onSubmit = (values: NotepadRequest, { setSubmitting }: FormikHelpers<NotepadRequest>): void => {
    console.log('values', values)
    notepadRef?.save()
    setSubmitting(false)
  }

  const formikForm = useForm<NotepadRequest>(
    {
      initialValues: {
        content: '',
      },
      onSubmit: onSubmit,
      validationSchema: notepadSchema,
    },
  )

  const handleStrokeColorPick = (color: string): void => {
    setAnchorStrokeColorMenu(null)
    if (notepadRef?.strokeColor !== null && notepadRef?.strokeColor !== undefined) {
      notepadRef.strokeColor = color
    }
  }

  const handleBackgroundColorPick = (color: string): void => {
    setAnchorBackgroundColorMenu(null)
    if (notepadRef?.backgroundColor !== null && notepadRef?.backgroundColor !== undefined) {
      notepadRef.backgroundColor = color
    }
  }

  const handleStrokeWidthPick = (width: number | string): void => {
    setAnchorStrokeWidthMenu(null)
    if (notepadRef?.maxStrokeWidth !== null && notepadRef?.maxStrokeWidth !== undefined) {
      if (typeof width === 'string') {
        notepadRef.maxStrokeWidth = parseInt(width, 10)
      } else {
        notepadRef.maxStrokeWidth = width
      }
    }
  }

  return (
    <>
      <HeaderAction
        title="Bloc Note"
        onSubmit={formikForm.handleSubmit}
      >
        <SubmitFormButton
          type="submit"
          variant="contained"
          disabled={formikForm.isSubmitting}
        >
          Valider
        </SubmitFormButton>
      </HeaderAction>
      <NotepadContainer>
        <NotepadButtonContainer>
          <NotepadButton
            variant="outlined"
            startIcon={<UndoRounded />}
            onClick={() => notepadRef?.undo()}
          >
            Annuler
          </NotepadButton>
          <NotepadButton
            variant="outlined"
            startIcon={<RedoRounded />}
            onClick={() => notepadRef?.redo()}
          >
            Rétablir
          </NotepadButton>
          <NotepadButton
            variant="outlined"
            startIcon={<DeleteRounded />}
            onClick={() => notepadRef?.clear()}
          >
            Tout effacer
          </NotepadButton>
          <NotepadButton
            variant={notepadRef?.strokeColor === '#ffffff'? 'contained' : 'outlined'}
            onClick={(event): void => setAnchorStrokeColorMenu(event.currentTarget)}
          >
            <StrokeColorIcon iconcolor={notepadRef?.strokeColor ?? 'black'} />
            <ArrowRightIcon />
          </NotepadButton>
          <Menu
            anchorEl={anchorStrokeColorMenu}
            open={openStrokeColorMenu}
            onClose={() => setAnchorStrokeColorMenu(null)}
          >
            <ColorGridContainer>
              {
                presetColors?.map((value) => (
                  <IconButton
                    key={value}
                    size="small"
                    onClick={() => handleStrokeColorPick(value)}
                  >
                    <ColorIcon iconcolor={value} />
                  </IconButton>
                ))
              }
            </ColorGridContainer>
          </Menu>
          <NotepadButton
            variant="outlined"
            onClick={(event): void => setAnchorStrokeWidthMenu(event.currentTarget)}
          >
            {notepadRef?.maxStrokeWidth ?? '1'}
            <ArrowRightIcon />
          </NotepadButton>
          <Menu
            anchorEl={anchorStrokeWidthMenu}
            open={openStrokeWidthMenu}
            onClose={() => setAnchorStrokeWidthMenu(null)}
          >
            {
              [ 1, 2, 3, 4, 5 ].map((value) => (
                <MenuItem
                  key={value}
                  value={value}
                  onClick={() => handleStrokeWidthPick(value)}
                >
                  {value}
                </MenuItem>
              ))
            }
          </Menu>
          <NotepadButton
            variant={notepadRef?.backgroundColor === '#ffffff'? 'contained' : 'outlined'}
            onClick={(event): void => setAnchorBackgroundColorMenu(event.currentTarget)}
          >
            <BackgroundColorIcon iconcolor={notepadRef?.backgroundColor ?? 'whitye'} />
            <ArrowRightIcon />
          </NotepadButton>
          <Menu
            anchorEl={anchorBackgroundColorMenu}
            open={openBackgroundColorMenu}
            onClose={() => setAnchorBackgroundColorMenu(null)}
          >
            <ColorGridContainer>
              {
                presetColors?.map((value) => (
                  <IconButton
                    key={value}
                    size="small"
                    onClick={() => handleBackgroundColorPick(value)}
                  >
                    <ColorIcon iconcolor={value} />
                  </IconButton>
                ))
              }
            </ColorGridContainer>
          </Menu>
        </NotepadButtonContainer>
        <Notepad
          ref={setNotepadRef}
          id="notepad"
        />
      </NotepadContainer>
    </>
  )
}

export default NotepadPage

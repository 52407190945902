/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Type imports ------------------------------------------------------------- */
import type { EventContentArg } from '@fullcalendar/core'
import type { Planning } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
interface WeekEventContainerProps {
  color: string;
}

const WeekEventContainer = styled.div<WeekEventContainerProps>`
  background-color: ${(props) => props.color === 'transparent' ? 'white' : props.color};
  color: ${(props) => props.theme.palette.secondary.main};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1px 3px;
`

const DateContainer = styled.div`
  font-size: .7rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 12px;
`

const TitleContainer = styled.div`
  font-weight: bold;
  font-size: .8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

/* Component declaration ---------------------------------------------------- */
interface PlanningCalendarEventWeekViewProps {
  eventInfo: EventContentArg;
  event: Planning;
}

const PlanningCalendarEventWeekView: React.FC<PlanningCalendarEventWeekViewProps> = ({
  eventInfo,
  event,
}) => {

  return (
    <WeekEventContainer
      color={eventInfo.backgroundColor === 'transparent' ? eventInfo.event.textColor : eventInfo.backgroundColor}
      id={event ? `${event.commentaire}#${event.typeRDV?.libelle}` : `${eventInfo.event.id}`}
    >
      <DateContainer>
        {eventInfo.timeText}
      </DateContainer>
      <TitleContainer>
        {eventInfo.event.title}
      </TitleContainer>
    </WeekEventContainer>
  )
}

export default PlanningCalendarEventWeekView

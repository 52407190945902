/* Framework imports -------------------------------------------------------- */
import { createSlice } from '@reduxjs/toolkit'

/* Module imports ----------------------------------------------------------- */

/* Type imports ------------------------------------------------------------- */
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'store/store'
import type { RapportResume } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
export interface ReportState {
  [caseId: string]: {
    selectedReport: string | null;
    reports: RapportResume[];
    sync: {
      [reportId: string]: {
        withValidation: string | null;
        withoutValidation: string | null;
      };
    };
  };
}

/* Report Redux slice ---------------------------- */
const reportSlice = createSlice(
  {
    name: 'report',
    initialState: {} as ReportState,
    reducers: {
      setCaseSelectedReport: (state, { payload }: PayloadAction<{caseId: string; selectedReport: string}>) => {
        if (state[payload.caseId] !== undefined && state[payload.caseId].reports.find((report) => report.id === payload.selectedReport)) {
          state[payload.caseId].selectedReport = payload.selectedReport
        }
      },
      setCaseReports: (state, { payload }: PayloadAction<{caseId: string; reports: RapportResume[]}>) => {
        if (state[payload.caseId] !== undefined) {
          state[payload.caseId].reports = payload.reports
          if (!state[payload.caseId].reports.find((report) => report.id === state[payload.caseId].selectedReport)) {
            state[payload.caseId].selectedReport = payload.reports[payload.reports.length - 1]?.id ?? null
          }
        } else {
          state[payload.caseId] = {
            selectedReport: payload.reports[payload.reports.length - 1]?.id ?? null,
            reports: payload.reports,
            sync: {},
          }
          payload.reports.forEach((report) => {
            state[payload.caseId].sync[report.id] = { withoutValidation: null, withValidation: null }
          })
        }
      },
      setReportLastSync: (state, { payload }: PayloadAction<{caseId: string; reportId: string; validation: boolean; sync: string}>) => {
        if (payload.validation) {
          state[payload.caseId].sync[payload.reportId].withValidation = payload.sync
        } else {
          state[payload.caseId].sync[payload.reportId].withoutValidation = payload.sync
        }
      },
    },
  },
)

/* Export slice components ----------------------------- */
export const {
  setCaseSelectedReport,
  setCaseReports,
  setReportLastSync,
} = reportSlice.actions

export default reportSlice.reducer

export const selectReport = (state: RootState): ReportState => {
  return state.report
}

export const getCaseSelectedReport = (caseId: string) => (state: RootState): string | null => {
  return state.report[caseId]?.selectedReport ?? null
}

export const getCaseReports = (caseId: string) => (state: RootState): RapportResume[] => {
  return state.report[caseId]?.reports ?? []
}

export const getCaseReportLastSync = (caseId: string, reportId: string) => (state: RootState) => {
  return state.report[caseId]?.sync[reportId]
}

/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { isValidString } from 'helpers/isValidString'
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  CardContent,
} from '@mui/material'
import CompleteTaskModal from 'pages/TasksPage/TaskMessage/CompleteTaskModal/CompleteTaskModal'
import PostponeTaskModal from 'pages/TasksPage/TaskMessage/PostponeTaskModal/PostponeTaskModal'

/* Type imports ------------------------------------------------------------- */
import type { Tache } from 'API/__generated__/Api'
import { TypeTache } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const CardContainer = styled(Card)`
  margin-top: 20px;
  margin-bottom: 20px;
`

const ButtonsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;
`

const DateContainer = styled.div`
  display: inline;
  float: right;
`

const Line = styled.h5`
  font-weight: normal;
  margin: 5px 0px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseSidebarTaskMenuMessageProps {
  task: Tache;
  isConnected: boolean;
}

const CaseSidebarTaskMenuMessage: React.FC<CaseSidebarTaskMenuMessageProps> = ({ task, isConnected }) => {
  const [ openModal, setOpenModal ] = useState<'complete' | 'postpone' | ''>('')

  return (
    <CardContainer>
      <CardContent>
        <Line>
          <b>
            {task.libelle}
          </b>
          <DateContainer>
            {DateUtils.apiStrToLocalDateString(task.dateCreation)}
          </DateContainer>
        </Line>
        {
          isValidString(task.prevuPour) && (
            <Line>
              Échéance :
              {' '}
              <b>
                {DateUtils.apiStrToLocalDateString(task.prevuPour)}
              </b>
            </Line>
          )
        }
        <ButtonsContainer>
          <Button
            variant="contained"
            onClick={() => setOpenModal('complete')}
            fullWidth
            disabled={!isConnected}
          >
            Solder
          </Button>
          {
            task.typeTache !== TypeTache.Relance &&
              <Button
                variant="outlined"
                onClick={() => setOpenModal('postpone')}
                fullWidth
                disabled={!isConnected}
              >
                Reporter
              </Button>
          }
        </ButtonsContainer>
      </CardContent>
      <CompleteTaskModal
        open={openModal === 'complete'}
        handleClose={() => setOpenModal('')}
        task={task}
      />
      <PostponeTaskModal
        open={openModal === 'postpone'}
        handleClose={() => setOpenModal('')}
        task={task}
      />
    </CardContainer>
  )
}

export default CaseSidebarTaskMenuMessage

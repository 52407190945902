/* Framework imports -------------------------------------------------------- */
import { createSlice } from '@reduxjs/toolkit'

/* Module imports ----------------------------------------------------------- */

/* Type imports ------------------------------------------------------------- */
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'store/store'
import type { DataDocument } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
export interface CourrierState {
  courriers: DataDocument[];
}

/* Courrier Redux slice ---------------------------- */
const courrierSlice = createSlice(
  {
    name: 'courrier',
    initialState: {
      courriers: [],
    } as CourrierState,
    reducers: {
      setCourriers: (state, { payload }: PayloadAction<DataDocument[]>) => {
        state.courriers = payload
      },
    },
  },
)

/* Export slice components ----------------------------- */
export const { setCourriers } = courrierSlice.actions

export default courrierSlice.reducer

export const getCourriers = (state: RootState): CourrierState => {
  return state.courrier
}

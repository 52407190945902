/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  CardContent,
} from '@mui/material'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'

/* Type imports ------------------------------------------------------------- */
import type { ReglementTraveller } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */

/* Internal variables ------------------------------------------------------- */

/* Styled components -------------------------------------------------------- */
const BoldUppercaseTitle = styled(FormBoldTitle)`
  text-transform: uppercase;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const BoldText = styled.div`
  font-weight: bold;
`

const GridContainer = styled.div`
  display: grid;
  gap: 5px;
  grid-template-columns: 1.5fr repeat(4, 1fr) 0.7fr;

  align-items: stretch;
  justify-content: stretch;

  margin-bottom: 10px;
`

const GarantieGridContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 15px;
  margin-bottom: -5px;
`

const BorderedDiv = styled.div`
  padding-right: 10px;
  border-right: 2px solid ${(props) => props.theme.colors.grey};
  margin-right: 10px;
`

const BoldSeparator = styled.div`
  width: 100%;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
`

const CardContainer = styled(Card)`
  margin-bottom: 20px;
  font-size: 14px;
`

const CardContentContainer = styled(CardContent)`
  padding-top: 0px;
  overflow-x: overlay;
`

/* Component declaration ---------------------------------------------------- */
interface PaymentCardProps {
  payment: ReglementTraveller;
  personName: string;
}

const PaymentCard: React.FC<PaymentCardProps> = ({
  payment,
  personName,
}) => {

  return (
    <CardContainer>
      <CardContentContainer>
        <BoldUppercaseTitle>
          Règlement N°
          {payment.id}
        </BoldUppercaseTitle>
        <GridContainer>
          <BorderedDiv>
            Destinataire
            <BoldText>
              {personName}
            </BoldText>
          </BorderedDiv>
          <BorderedDiv>
            Emetteur
            <BoldText>
              {payment.emetteur}
            </BoldText>
          </BorderedDiv>
          <BorderedDiv>
            Type
            <BoldText>
              {payment.type?.libelle}
            </BoldText>
          </BorderedDiv>
          <BorderedDiv>
            Date
            <BoldText>
              {DateUtils.apiStrToLocalDateString(payment.dateReglement)}
            </BoldText>
          </BorderedDiv>
          <BorderedDiv>
            Numéro chèque
            <BoldText>
              {payment.numeroCheque}
            </BoldText>
          </BorderedDiv>
          <div>
            Code tireur
            <BoldText>
              {payment.codeTireur}
            </BoldText>
          </div>
        </GridContainer>
        <BoldSeparator />
        <GarantieGridContainer>
          Garanties et montant
          {
            payment.garantiesReglement?.map((garantie, indexGarantie) => (
              <BoldText key={`${garantie.code}-${indexGarantie}`}>
                {`${garantie.libelle} - ${(garantie.value || 0).toFixed(2)}€`}
              </BoldText>
            ))
          }
        </GarantieGridContainer>
      </CardContentContainer>
    </CardContainer>
  )
}

export default PaymentCard

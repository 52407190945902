/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'

/* Module imports ----------------------------------------------------------- */
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'

/* Component imports -------------------------------------------------------- */
import HeaderWithBackArrowTwoSmallButtons from 'layouts/MainLayout/Headers/HeaderWithBackArrowTwoSmallButtons'
import RouteTitle from 'routers/Router/RouteTitle'

/* Type imports ------------------------------------------------------------- */

/* Component declaration ---------------------------------------------------- */
interface ActionLayoutProps {}

const ActionLayout: React.FC<ActionLayoutProps> = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { caseId = '' } = useParams<{ caseId: string }>()
  const [ title, setTitle ] = useState<string>('')

  const tabs = [
    { title: 'Compte rendu', path: 'traveller/actions/compte-rendu' },
    { title: 'Bloc-note', path: 'traveller/actions/bloc-note' },
    { title: 'Document', path: 'traveller/actions/document' },
    { title: 'Tâche', path: 'tache' },
    { title: 'Commentaire', path: 'commentaire' },
    { title: 'Mail', path: 'mail' },
    { title: 'Échange interne', path: 'echange-interne' },
    { title: 'Commande Sinapps', path: 'commande-sinapps' },
    { title: 'Courrier', path: 'courrier' },
  ]

  useEffect(() => {
    const newTabValue = tabs.findIndex((tab) => location.pathname.indexOf(tab.path) !== -1)
    if (tabs[newTabValue]?.title) {
      setTitle(`${caseId} - ${tabs[newTabValue].title}`)
    }
  }, [ location.pathname ])

  return (
    <>
      <RouteTitle title={title} />
      <HeaderWithBackArrowTwoSmallButtons
        title={`Dossier ${caseId}`}
        caseId={caseId}
        onClickBack={() => navigate(-1)}
        caseButton
      />
      <Outlet />
    </>
  )
}

export default ActionLayout
